import React from 'react';
import './tile-arrow-text.scss';

interface TileArrowTextProps {
    text: string;
}

export const TileArrowText: React.FC<TileArrowTextProps> = ({ text }) => {
    return (
        <div data-testid="tile-arrow-text" className="tile-arrow-text">
            <p className="tile-arrow-text__label">
                <strong>{text}</strong>
            </p>

            <span className="tile-arrow-text__icon-wrapper">
                <i className="tile-arrow-text__arrow fa-kit fa-prizeout-forward-arrow"></i>
            </span>
        </div>
    );
};
