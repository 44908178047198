import React from 'react';
import { RoundImage } from '../../common/ui-widgets';
import { RoundImageSizes } from '../../../store/slices/common-slice';
import './cashback-logomarks.scss';

export const CashbackLogoMarks: React.FC = (): React.ReactElement => {
    const logomarks = [
        {
            src: 'https://assets.prizeout.com/logo-doordash.png',
            alt: 'Doordash',
        },
        {
            src: 'https://assets.prizeout.com/logo-uber.png',
            alt: 'Uber',
        },
        {
            src: 'https://assets.prizeout.com/logo-best-buy.png',
            alt: 'Best Buy',
        },
        {
            src: 'https://assets.prizeout.com/logo-target.png',
            alt: 'Target',
        },
        {
            src: 'https://assets.prizeout.com/logo-starbucks.png',
            alt: 'Starbucks',
        },
        {
            src: 'https://assets.prizeout.com/logo-dominos.png',
            alt: 'Dominos',
        },
    ];

    return (
        <div className="cashback-logomarks">
            {logomarks.map((card, index) => (
                <RoundImage key={index} size={RoundImageSizes.THREE} imageUrl={card.src} imageAltText={card.alt} />
            ))}
        </div>
    );
};
