import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../common';
import { OnboardingChecklistItem, OnboardingTagGroups } from './onboarding-types';

export const onboardingApiSlice = createApi({
    reducerPath: 'onboardingApi',
    baseQuery,
    tagTypes: [OnboardingTagGroups.ONBOARDING],
    endpoints: (builder) => ({
        getOnboardingChecklist: builder.query<OnboardingChecklistItem[], void>({
            query: () => '/onboarding/user/onboardingChecklist',
            providesTags: [OnboardingTagGroups.ONBOARDING],
        }),
    }),
});

export const { useGetOnboardingChecklistQuery } = onboardingApiSlice;
