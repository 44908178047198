import React from 'react';
import { CollapsibleText } from './collapsible-text';
import { BonusTag } from '../../common';
import { OfferName } from '../../../modules/widget/components/offers/offer-gift-card/common/offer-name';
import { PrizeoutOffer } from '../../../store/slices/offers-slice';
import { BrandAvailabilityTags } from '../../features/checkout-panel/common/brand-availability-tags';
import { useActiveCardBonusInfo } from '../../../hooks/use-active-card-bonus-info';
import { selectActiveCard } from '../../../store/slices/checkout-slice';
import { useAppSelector } from '../../../hooks';
import './brand-content.scss';

interface BrandContent {
    activeOffer: PrizeoutOffer;
    limit: number;
}

export const BrandContent: React.FC<BrandContent> = ({ activeOffer, limit }): React.ReactElement => {
    const activeCard = useAppSelector(selectActiveCard);
    const { bonusType, bonusValue } = useActiveCardBonusInfo(activeCard);
    return (
        <div className="brand-content">
            <div className="brand-content__tags">
                {activeCard?.display_bonus > 0 && (
                    <BonusTag
                        type={bonusType}
                        value={bonusValue}
                        currency={activeOffer.currency_code}
                        className="ml-m"
                        offerType={activeOffer.display_offer_type}
                    />
                )}
            </div>
            <BrandAvailabilityTags offer={activeOffer} />
            <div className="brand-content__inner">
                {activeOffer?.name && <OfferName name={activeOffer.name} isHeadline />}
                <CollapsibleText text={activeOffer?.description || ''} limit={limit} aboutText={activeOffer.name} />
            </div>
        </div>
    );
};
