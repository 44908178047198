import React from 'react';
import Classnames from 'classnames';

import './offer-name.scss';

interface OfferNameProps {
    className?: string;
    isBold?: boolean;
    isHeadline?: boolean;
    name: string;
    rows?: number;
}

export const OfferName: React.FC<OfferNameProps> = ({
    className,
    isBold,
    isHeadline,
    name,
    rows,
}): React.ReactElement => {
    const classes = Classnames(
        'offer-name',
        { [`offer-name--rows-${rows}`]: rows },
        { 'offer-name--bold': isBold },
        { 'offer-name--headline': isHeadline },
        className,
    );

    const renderName = () => {
        if (isHeadline) {
            return (
                <h2 data-testid="offer-name-headline" className={classes} title={name}>
                    {name}
                </h2>
            );
        } else {
            return (
                <p data-testid="offer-name" className={classes} title={name}>
                    {name}
                </p>
            );
        }
    };

    return <>{renderName()}</>;
};
