import React from 'react';
import { BonusTag, BonusTagTheme, RoundImage } from '../../../../../../components/common';
import { CurrentOfferDisplay } from '../offer-gift-card';
import { OfferName } from '../common/offer-name';
import { RoundImageSizes } from '../../../../../../store/slices/common-slice';

import './logomark-offer-display.scss';

interface RoundImageOfferDisplayProps {
    data: CurrentOfferDisplay;
    hideBonusText?: boolean;
    hasBonusPill?: boolean;
    size?: RoundImageSizes;
    hideIcon?: boolean;
}

export const RoundImageOfferDisplay: React.FC<RoundImageOfferDisplayProps> = ({
    data,
    hideBonusText = false,
    hasBonusPill = false,
    size = RoundImageSizes.FIVE,
    hideIcon = false,
}): React.ReactElement => {
    return (
        <>
            <RoundImage imageUrl={data.logomark_url} imageAltText={`${data.name} logomark`} size={size} />

            <OfferName name={data.name} rows={1} isBold />

            {data.offer_value > 0 && (
                <BonusTag
                    currency={data.currency}
                    hideText={hideBonusText}
                    offerType={data.display_offer_type}
                    theme={hasBonusPill && BonusTagTheme.PILL}
                    type={data.offer_type}
                    value={data.offer_value}
                    hideIcon={hideIcon}
                />
            )}
        </>
    );
};
