enum routes {
    CASHBACK = '/cashback',
    CASHBACK_BANK_TRANSFER = '/cashback/bank-transfer',
    DEALS = '/deals',
    EMPTY_OFFERS = '/empty-offers',
    FORGOT_PASSWORD = '/forgot-passcode',
    HELP = '/help',
    HOME = '/dashboard',
    CATEGORY_DASHBOARD = '/dashboard/category/:activeCategoryId/:countryCode',
    ONBOARDING = '/onboarding',
    ONBOARDING_FAVORITES = '/preferences',
    ONBOARDING_BRAND_FAVORITES = '/preferences-brands',
    ONBOARDING_CATEGORY_FAVORITES = '/preferences-categories',
    PRELOADER = '/',
    WALLET = '/wallet',
    NOT_FOUND = '/not-found',
    RESET_PASSWORD = '/Reset-Password',
    EXTENSION_LINKING = '/extension-linking',
    EXTENSION_CODE = '/extension-generate-code',

    // browser extension routes
    BE_HOME = '/',
    BE_PROFILE = '/profile',
    BE_SETTINGS = '/settings',

    // chrome browser extension routes
    CBE_CHECKOUT = '/checkout',
    CBE_CHECKOUT_COMPLETE = '/mobile-checkout-complete',
    CBE_DEV_TOOLS = '/dev-tools',
    CBE_GIFT_CARD_PROCESSING = '/gift-card-processing',
    CBE_HELP = '/help',
    CBE_HOME_OFFER_AVAILABILITY = '/',
    CBE_HOW_IT_WORKS = '/how-it-works',
    CBE_LINK_ACCOUNT = '/link-account',
    CBE_LINK_ACCOUNT_CONFIRMATION = '/link-account-confirmation',
    CBE_MOBILE_CHECKOUT = '/mobile-checkout',
    CBE_MOBILE_CHECKOUT_SUMMARY = '/mobile-checkout-summary',
    CBE_PROFILE = '/profile',
    CBE_WALLET = '/wallet',

    CLAIM_HOME = '/',
    CLAIM_GIFT_CARD_DETAILS = '/claim-gift-card-details',

    DTC_HOME = '/',
    DTC_SIGN_UP = '/SignUp',
    DTC_WALLET = '/wallet',

    GIFTING = '/gifting',
    GIFTING_CONFIRMATION = '/gifting/confirmation',
    GIFTING_CONTACT_EDIT = '/gifting/contacts/edit',
    GIFTING_CONTACTS = '/gifting/contacts',
    GIFTING_CONTACTS_EDIT_LIST = '/gifting/contacts/edit-list',
    GIFTING_CONTACTS_NEW = '/gifting/contacts/new',
    GIFTING_HISTORY_DETAIL = '/gifting-history-detail',
    GIFTING_HOME = '/gifting-home',
    GIFTING_ONBOARDING = '/gifting-onboarding',

    MINI_WIDGET_DASHBOARD = '/mini-widget/dashboard',

    STORE_FRONT_PRELOADER = '/preloader',
    STORE_FRONT_RECEIVE_FUNDS = '/receive-funds',
    STORE_FRONT_RECEIVE_OFFER = '/receive-offer',
    STORE_FRONT_WELCOME = '/',

    REWARDS_CASHBACK = '/cashback',
    REWARDS_CASHBACK_BANK_TRANSFER = '/cashback/bank-transfer',
    REWARDS_EXTENSION_LINKING = '/extension-linking',
    REWARDS_EXTENSION_CODE = '/extension-generate-code',
    REWARDS_HELP = '/help',
    REWARDS_HOME = '/dashboard',
    REWARDS_OFFERS = '/offers',
    REWARDS_OFFERS_CATEGORIES = '/dashboard/category/:activeCategoryId/:countryCode',
    REWARDS_ACTIONS = '/actions',
    REWARDS_WALLET = '/wallet',

    // Admin Portal
    AP_HOME = '/',
    AP_DASHBOARD_TOP = '/dashboard',
    AP_PARTNER_DASHBOARD = '/dashboard/partner',
    AP_CONSUMER_TRANSACTIONS = '/dashboard/transactions',
    AP_DASHBOARD_USERS = '/dashboard/users',
    AP_PROFILE = '/dashboard/profile',
    AP_LOGIN = '/login',

    // Partners Portal
    PP_FORGOT_PASSWORD = '/forgot-password',
    PP_HOME = '/',
    PP_LOGIN = '/login',
    PP_CREATE_PASSWORD = '/createPassword',
    PP_RESET_PASSWORD = '/passwordReset',
    PP_PARTNER_LIST = '/partnerList',
    PP_DASHBOARD_TOP = '/dashboards',
    PP_EMPTY_TAB = '/dashboards/noTabFound',
    PP_PARTNER_DASHBOARD = '/dashboards/partner',
    PP_PARTNER_REWARDS_DASHBOARD = '/dashboards/partner/rewards',
    PP_PARTNER_REWARDS_DETAILS = '/dashboards/partner/rewards/:partnerRewardId',
    PP_PARTNER_REWARDS_DETAILS_STATISTICS = '/dashboards/partner/rewards/:partnerRewardId/statistics',
    PP_PARTNER_REWARDS_DETAILS_USERS = '/dashboards/partner/rewards/:partnerRewardId/users',
    PP_PARTNER_REWARDS_MEMBER_PAYOUTS = '/dashboards/partner/rewards/member-payouts',
    PP_PARTNER_USERS_DASHBOARD = '/dashboards/partner/users',
    PP_PARTNER_USERS_DETAILS = '/dashboards/partner/users/:partnerUserId',
    PP_FUNDING_DASHBOARD = '/dashboards/funding',
    PP_FUNDING_TRANSACTIONS_DASHBOARD = '/dashboards/funding/transactions',
    PP_FUNDING_PAYMENTS_DASHBOARD = '/dashboards/funding/payments',
    PP_INTEGRATION_DASHBOARD = '/dashboards/integration',
    PP_USERS = '/dashboards/users',

    // Emulator Routes
    EMULATOR_HOME = '/',
    EMULATOR_MINI_WIDGET_PLAYGROUND = '/mini-widget-playground',
    EMULATOR_CRM = '/crm-emulator',
    EMULATOR_CRM_IMAGE_LIBRARY = '/crm-emulator/image-library',
}

const isValidRoute = (route: string): route is routes => {
    return Object.values(routes).includes(route as routes);
};

interface RouteParameters {
    [parameter: string]: string;
}

const replaceRouteParams = (routeTemplate: routes, params: RouteParameters): string => {
    return Object.keys(params).reduce((memo, paramName) => {
        return memo.replace(`:${paramName}`, params[paramName]);
    }, routeTemplate);
};

const findTabRoute = (pathname: string, dashboardBaseList: string[]): string => {
    const localDashboardBaseList = [...dashboardBaseList].sort((a, b) => b.length - a.length);
    const routeList = Object.values(routes) as string[];

    for (const dashboardBase of localDashboardBaseList) {
        if (pathname === dashboardBase) {
            return dashboardBase;
        }

        if (pathname.indexOf(dashboardBase) !== 0) {
            continue;
        }

        const routeCandidates = routeList
            .filter((route) => {
                return route !== dashboardBase && route.indexOf(dashboardBase) === 0 && pathname.indexOf(route) === 0;
            })
            .sort((a, b) => a.length - b.length);

        if (routeCandidates.length === 0) {
            continue;
        }

        return routeCandidates[0];
    }

    return '';
};

export { routes, isValidRoute, RouteParameters, replaceRouteParams, findTabRoute };
