import React from 'react';
import { SkeletonLoaderWidth } from './skeleton-loader-element-generic';
import { SkeletonLoaderElementGiftCard } from './skeleton-loader-element-gift-card';
import { SkeletonLoaderElementText } from './skeleton-loader-element-text';
import './skeleton-loader-layout-offer.scss';

export const SkeletonLoaderLayoutOffer: React.FC = (): React.ReactElement => {
    return (
        <div data-testid="skeleton-loader-layout-offer" className="skeleton-loader-layout-offer">
            <SkeletonLoaderElementGiftCard />
            <SkeletonLoaderElementText />
            <SkeletonLoaderElementText width={SkeletonLoaderWidth.TWO_THIRDS} />
        </div>
    );
};
