import React, { useMemo, useRef } from 'react';
import ClassNames from 'classnames';

import './sdc-option-wrapper.scss';

export interface SDCOptionProps<GenericObject> {
    option: SDCOption<GenericObject>;
    listId?: string;
    nextOption?: (arg0: HTMLLIElement) => void;
    prevOption?: (arg0: HTMLLIElement) => void;
    isSelectLabel?: boolean;
    onSelect?: (arg0: SDCOption<GenericObject>) => void;
    closeList?: () => void;
}

export interface SDCOption<GenericObject> {
    value: number | string;
    label: string;
    selected?: boolean;
    data?: GenericObject;
}

export function SDCOptionWrapper<P, GenericObject>(
    WrappedComponent: React.ComponentType<P & SDCOptionProps<GenericObject>>,
): (props: P) => JSX.Element {
    return (props: P & SDCOptionProps<GenericObject>) => {
        const optionRef = useRef<HTMLLIElement>();
        const { option, listId, isSelectLabel, onSelect } = props;

        const optionId = useMemo(() => {
            if (listId) {
                return listId + option.value;
            }
        }, []);

        const handleClick = () => {
            onSelect?.call(null, option);
        };

        const handleKeyDown = (evt: React.KeyboardEvent<HTMLLIElement>) => {
            evt.preventDefault();
            evt.stopPropagation();

            switch (evt.key) {
                case 'ArrowDown':
                    props.nextOption(optionRef.current);
                    break;

                case 'ArrowUp':
                    props.prevOption(optionRef.current);
                    break;

                case 'Enter':
                    handleClick();
                    break;

                case 'Escape':
                case 'Esc':
                    props.closeList();
                    break;

                case 'Tab':
                default:
                    return;
            }
        };

        const classes = ClassNames('sdc-option', {
            'sdc-option--selected': option.selected,
        });

        // Don't ever pass the onSelect handler to the wrapped component.
        // We don't want it hijacking things.
        const wrappedProps: P & SDCOptionProps<GenericObject> = {
            onSelect: undefined,
            ...props,
        };

        if (isSelectLabel) {
            return <WrappedComponent {...wrappedProps} />;
        }

        return (
            <>
                <li
                    id={optionId}
                    data-testid="sdc-option"
                    className={classes}
                    ref={optionRef}
                    data-value={props.option.value}
                    tabIndex={0}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    role="option"
                    aria-selected={option.selected}
                >
                    <WrappedComponent {...wrappedProps} />
                </li>
            </>
        );
    };
}
