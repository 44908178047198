import React from 'react';
import Classnames from 'classnames';

import './divider-row.scss';

export enum DividerRowEdgeType {
    SPACE_PRIMARY = 'space-primary',
    SPACE_SECONDARY = 'space-secondary',
    SPACE_TERTIARY = 'space-tertiary',
}

export enum DividerRowMode {
    DEFAULT = 'default',
    THICK = 'thick',
    THIN = 'thin',
}

interface DividerRowProps {
    shouldExtendEdges?: boolean; // Should the divider span the full width of the parent?
    edgeType?: DividerRowEdgeType; // Padding of the parent component (primary/secondary) to allow shouldExtendEdges
    hasNoBottomMargin?: boolean;
    mode?: DividerRowMode; // Dictates the vertical spacing and height
    hasMobileMargin?: boolean;
}

export const DividerRow: React.FC<DividerRowProps> = ({
    shouldExtendEdges,
    edgeType = DividerRowEdgeType.SPACE_PRIMARY,
    hasNoBottomMargin,
    hasMobileMargin,
    mode = DividerRowMode.DEFAULT,
}): React.ReactElement => {
    const classes = Classnames('divider-row', {
        'divider-row--extend-edges': shouldExtendEdges,
        [`divider-row--edge-${edgeType}`]: edgeType,
        [`divider-row--mode-${mode}`]: mode,
        'divider-row--no-bottom-margin': hasNoBottomMargin,
        'divider-row--mobile-margin': hasMobileMargin,
    });

    return <div data-testid="divider-row" className={classes}></div>;
};
