import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../widget/widget';
import { ButtonColor } from '../../components/common/buttons/types'; // Specify all the way down to avoid circular dependency
import { sharedAPI } from '../../utils/services/api';

export interface OnboardingSlice {
    totalPanels: number;
    panelList: OnboardingPanel[];
    ctaList: OnboardingCTA[];
}

// If subHeader is present, bullets will not be and vice versa.
export interface OnboardingPanel {
    imageUrl: string;
    header: string;
    subHeader?: string;
    bullets?: string[];
    showPoweredByPrizeout: boolean;
    orderIndex: number;
}

export interface OnboardingCTA {
    text: string;
    routeTo: string;
    buttonType: ButtonColor;
}

export const onboardingInitialState: OnboardingSlice = {
    totalPanels: null as number,
    panelList: [] as OnboardingPanel[],
    ctaList: [] as OnboardingCTA[],
};

export interface OnboardingRequest {
    consumerOnboardingFlowId: string;
}

interface CTAListPayload {
    text: string;
    routeTo: string;
    buttonType: string;
}

interface OnboardingPayload {
    totalPanels: number;
    panels: OnboardingPanel[];
    ctas: CTAListPayload[];
}

export const getOnboarding = createAsyncThunk(
    'onboarding/getOnboarding',
    async ({ consumerOnboardingFlowId }: OnboardingRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/onboarding/${consumerOnboardingFlowId}`,
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const onboardingSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getOnboarding.fulfilled, (state, action: PayloadAction<OnboardingPayload>) => {
            const getActualButtonType = (cta: CTAListPayload) => {
                if (Object.values<string>(ButtonColor).includes(cta.buttonType)) {
                    return cta.buttonType as ButtonColor;
                }

                console.error(`Invalid button type: ${cta.buttonType}`);
                return ButtonColor.TERTIARY; // Sane fallback. It might even be right.
            };

            state.totalPanels = action.payload.totalPanels;
            state.panelList = action.payload.panels;

            state.ctaList = action.payload.ctas.map((cta) => {
                const actualButtonType = getActualButtonType(cta);

                return {
                    ...cta,
                    buttonType: actualButtonType,
                };
            });
        });
    },
    initialState: onboardingInitialState,
    name: 'onboarding',
    reducers: {},
});

const selectOnboardingState = ({ onboarding }: RootState): OnboardingSlice => onboarding;

export const selectPanelList = createSelector(selectOnboardingState, ({ panelList }) => panelList);

export const selectTotalPanels = createSelector(selectOnboardingState, ({ totalPanels }) => totalPanels);

export const selectCTAList = createSelector(selectOnboardingState, ({ ctaList }) => ctaList);

export default onboardingSlice.reducer;
