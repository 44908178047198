import Cookies from 'js-cookie';

import { AccessTokenStates, setAccessTokensFromPersistentState } from '../store/slices/verification-slice';
import { sharedAPI } from '../utils/services/api';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/widget/widget';
import { useAppSelector } from './use-app-selector';
import { getSdkParams } from '../store/slices/sdk-slice';

interface PersistenData extends AccessTokenStates {
    partnerId: string;
    sessionId: string;
}

export function usePersistentAccessTokens() {
    const dispatch = useDispatch<AppDispatch>();
    const sdkParams = useAppSelector(getSdkParams);

    const setPersistentAccessTokens = async (tokenStates: PersistenData) => {
        const cookieLifetime = 0.33; // value is in days

        const setCookiesPromise = new Promise((resolve) => {
            Cookies.set('accessTokenGlobal', tokenStates.accessTokenGlobal, { expires: cookieLifetime });
            Cookies.set('accessTokenGlobalVerified', String(tokenStates.accessTokenGlobalVerified), {
                expires: cookieLifetime,
            });
            Cookies.set('superUserManualLogIn', String(tokenStates.superUserManualLogIn), { expires: cookieLifetime });
            Cookies.set('accessTokenGlobalSetAt', tokenStates.accessTokenGlobalSetAt), { expires: cookieLifetime };
            Cookies.set('partnerId', tokenStates.partnerId, { expires: cookieLifetime });
            Cookies.set('sessionId', tokenStates.sessionId, { expires: cookieLifetime });

            sharedAPI.token = tokenStates.accessTokenGlobal;

            resolve(null);
        });

        await setCookiesPromise;
    };

    const clearPersistentAccessTokens = () => {
        Cookies.remove('accessTokenGlobal');
        Cookies.remove('accessTokenGlobalVerified');
        Cookies.remove('superUserManualLogIn');
        Cookies.remove('accessTokenGlobalSetAt');
        Cookies.remove('partnerId');
        Cookies.remove('sessionId');

        const cacheKey = `${sdkParams?.session_id}_widget_partner_settings`;
        const offerCacheKey = `${sdkParams?.session_id}_homepage_offers`;

        sessionStorage.removeItem(cacheKey);
        sessionStorage.removeItem(offerCacheKey);

        dispatch(
            setAccessTokensFromPersistentState({
                accessTokenGlobal: '',
                accessTokenGlobalVerified: false,
                superUserManualLogIn: false,
                accessTokenGlobalSetAt: '',
            }),
        );

        sharedAPI.token = null;
    };

    return [
        {
            setPersistentAccessTokens,
            clearPersistentAccessTokens,
        },
    ];
}

export default usePersistentAccessTokens;
