import React, { Dispatch, useMemo } from 'react';
import { Button } from '../../buttons';
import {
    InfiniteScrollState,
    InfiniteScrollStateAction,
    InfiniteScrollStateActionNames,
} from '../infinite-scroll-reducer';

import './paging-component.scss';

interface PagingComponentProps<GenericObject> {
    firstPage: number;
    localState: InfiniteScrollState<GenericObject>;
    dispatchLocalState: Dispatch<InfiniteScrollStateAction<GenericObject>>;
}

export function PagingComponent<GenericObject>({
    firstPage,
    localState,
    dispatchLocalState,
}: PagingComponentProps<GenericObject>): React.ReactElement {
    const jumpToPage = (pageNumber: number): void => {
        dispatchLocalState({
            type: InfiniteScrollStateActionNames.SET_FIRST_PAGE,
            firstPageNumber: pageNumber,
        });
    };

    const lastButtonPage = useMemo(() => {
        let result = firstPage + 9;

        if (result > localState.pageCount) {
            result = localState.pageCount;
        }

        return result;
    }, [firstPage, localState.pageCount]);

    const previousPage = useMemo(() => {
        let result = firstPage - 10;

        if (result <= 0) {
            result = 1;
        }

        return result;
    }, [firstPage, localState.pageCount]);

    const nextPage = useMemo(() => {
        let result = lastButtonPage + 10;

        if (result > localState.pageCount) {
            result = localState.pageCount;
        }

        return result;
    }, [firstPage, localState.pageCount]);

    const renderPageButtons = () => {
        const buttons = [];

        for (let pageNumber = firstPage; pageNumber <= lastButtonPage; pageNumber++) {
            buttons.push(
                <Button
                    key={pageNumber}
                    className="paging-list__button"
                    testId={`paging-list-button-${pageNumber}`}
                    onClick={() => jumpToPage(pageNumber)}
                    text={pageNumber.toString()}
                ></Button>,
            );
        }

        return buttons;
    };

    if (localState.isError || localState.isEmpty || localState.pageCount === 0) {
        return null;
    }

    return (
        <div className="paging-list" data-testid="paging-list">
            <Button
                className="paging-list__button"
                testId="paging-list-button-first-page"
                onClick={() => jumpToPage(1)}
                icon="fa-regular fa-chevrons-left"
            ></Button>
            <Button
                className="paging-list__button"
                testId="paging-list-button-previous-page"
                onClick={() => jumpToPage(previousPage)}
                icon="fa-regular fa-chevron-left"
            ></Button>
            {renderPageButtons()}
            <Button
                className="paging-list__button"
                testId="paging-list-button-previous-page"
                onClick={() => jumpToPage(nextPage)}
                icon="fa-regular fa-chevron-right"
            ></Button>
            <Button
                className="paging-list__button"
                testId="paging-list-button-first-page"
                onClick={() => jumpToPage(localState.pageCount)}
                icon="fa-regular fa-chevrons-right"
            ></Button>
        </div>
    );
}
