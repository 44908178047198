import React from 'react';
import { Button, ButtonColor, ButtonSize } from '../../common';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';

import './empty-content.scss';

interface EmptyContentProps {
    title: string;
    text?: string;
    imgUrl: string;
    imgAltText?: string;
    hasButton?: boolean;
    buttonText?: string;
    onClick?: () => void;
    trackingType?: ButtonTrackingTypes;
}

export const EmptyContent: React.FC<EmptyContentProps> = ({
    title,
    text,
    imgUrl,
    imgAltText = '',
    hasButton,
    buttonText,
    onClick,
    trackingType,
}): React.ReactElement => {
    return (
        <div className="empty-content">
            <img src={imgUrl} className="empty-content__image" alt={imgAltText} />

            <h2 className="empty-content__title">{title}</h2>

            <p>{text}</p>

            {hasButton && (
                <Button
                    ariaLabel={buttonText}
                    color={ButtonColor.PRIMARY}
                    isInline={true}
                    size={ButtonSize.MEDIUM}
                    onClick={onClick}
                    testId="find-button"
                    text={buttonText}
                    trackingType={trackingType}
                />
            )}
        </div>
    );
};
