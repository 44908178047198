import { ConditionRuleList } from './condition';
import { RuleStepList } from './rule-step';
import { TargetRuleList } from './target';

export interface RuleSetDefinition {
    autofillRuleId: string;
    url: string;
    checkoutUrlPartial: string;
    status: RuleStatus;
    ruleset: RuleSet;
    createDate: string;
    updateDate: string;
}

export enum RuleStatus {
    DEPRECATED = 'deprecated',
    LIVE = 'live',
    TESTING = 'testing',
}

export interface RuleSet {
    elements: TargetRuleList;
    conditions: ConditionRuleList;
    steps: RuleStepList;
}

export type RuleSetList = {
    [domainName: string]: RuleSetDefinition[];
};
