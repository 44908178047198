import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../widget/widget';
import { EnvironmentNames } from '../../utils/helpers/environment';

export enum ApplicationModule {
    ACCOUNTS = 'accounts',
    ADMIN = 'admin-portal',
    CASHBACK_BROWSER_EXTENSION = 'cashback-browser-extension',
    BROWSER_EXTENSION = 'browser-extension',
    CLAIM = 'claim',
    PARTNER_PORTAL = 'partner-portal',
    REWARDS = 'rewards',
    STORE_FRONT = 'store-front',
    WIDGET = 'widget-v2',
}

export enum AuthenticationModalView {
    ACCOUNT_LINK_REQUEST = 'accountLinkRequest',
    FORGOT_PASSWORD = 'forgotPassword',
    LOGIN = 'login',
    SIGN_UP = 'signup',
    VERIFICATION_REQUIRED = 'verificationRequired',
    VERIFICATION_SENT = 'verificationSent',
}

export enum ClickableHTMLTypes {
    ARTICLE = 'article',
    DIV = 'div',
    LI = 'li',
    SECTION = 'section',
}

export enum RoundImageSizes {
    ONE = '1',
    TWO = '2',
    THREE = '3',
    FOUR = '4',
    FIVE = '5',
    SIX = '6',
    SEVEN = '7',
    EIGHT = '8',
    FULL_WIDTH = 'full-width',
    CUSTOM = 'custom',
}

export enum faqCategory {
    CASHBACK_LOYALTY = 'cashback-loyalty',
    OFFERS = 'offers',
    REWARDS = 'rewards',
    CASHBACK = 'cashback',
    BONUS = 'bonus',
    BROWSER_EXTENSION = 'browser-extension',
}

export enum TypographicSizes {
    ONE = '1',
    TWO = '2',
}

export enum NavBarPageTitleLabels {
    CASHBACK = 'Cashback',
    EXPLORE = 'Explore',
    FAQ = 'FAQ',
    FOR_YOU = 'For you',
    HOME = 'Home',
    WALLET = 'Wallet',
}

// Define a type for the slice state
export interface CommonState {
    activeFilter?: string;
    appModule: ApplicationModule;
    authModalView: AuthenticationModalView;
    brandingHeaderLogomarkUrl?: string;
    brandingOverride?: string;
    dashboardScrollPosition: number;
    faqCategory: faqCategory;
    hasBrandingHeaderTitle?: boolean;
    hasBrandingOverride: boolean;
    hasShownAutoOnboarding: boolean;
    isAuthenticationModalShowing: boolean;
    isCobranded: boolean;
    isDashboardHeaderHidden: boolean;
    isHidingPrizeout: boolean;
    isLocationSelectorOpen: boolean;
    isMiniWidgetLoader: boolean;
    isMobileLandscape: boolean;
    isMobilePortraitView: boolean;
    isOffersPanelVisible: boolean;
    isSmallIframeView: boolean;
    isTabletPortraitView: boolean;
    loading: boolean;
    mode: ApplicationMode;
}

export enum ApplicationMode {
    WIDGET = 'widget',
    GIFTING = 'gifting',
    RECEIVE_GIFT = 'receive-gift',
    MINI_WIDGET = 'mini-widget',
}

// Define the initial state using that type
export const commonInitialState: CommonState = {
    activeFilter: '',
    appModule: ApplicationModule.WIDGET,
    authModalView: AuthenticationModalView.SIGN_UP,
    brandingHeaderLogomarkUrl: '',
    brandingOverride: '',
    dashboardScrollPosition: 0,
    faqCategory: faqCategory.OFFERS,
    hasBrandingHeaderTitle: false,
    hasBrandingOverride: false,
    hasShownAutoOnboarding: false,
    isAuthenticationModalShowing: false,
    isCobranded: false,
    isDashboardHeaderHidden: false,
    isHidingPrizeout: false,
    isLocationSelectorOpen: false,
    isMiniWidgetLoader: false,
    isMobileLandscape: false,
    isMobilePortraitView: false,
    isOffersPanelVisible: false,
    isSmallIframeView: false,
    isTabletPortraitView: false,
    loading: false,
    mode: ApplicationMode.WIDGET,
};

export const commonSlice = createSlice({
    initialState: commonInitialState,
    name: 'common',
    reducers: {
        toggleIsLoading(state) {
            state.loading = !state.loading;
        },
        enableIsLoading(state) {
            state.loading = true;
        },
        disableIsLoading(state) {
            state.loading = false;
        },
        toggleIsLocationSelectorOpen(state) {
            state.isLocationSelectorOpen = !state.isLocationSelectorOpen;
        },
        setIsOffersPanelVisible(state, action: PayloadAction<boolean>) {
            state.isOffersPanelVisible = action.payload;
        },
        setIsTabletPortrait(state, action: PayloadAction<boolean>) {
            state.isTabletPortraitView = action.payload;
        },
        setIsMobilePortrait(state, action: PayloadAction<boolean>) {
            state.isMobilePortraitView = action.payload;
        },
        setIsMobileLandscape(state, action: PayloadAction<boolean>) {
            state.isMobileLandscape = action.payload;
        },
        setIsSmallIframeView(state, action: PayloadAction<boolean>) {
            state.isSmallIframeView = action.payload;
        },
        setIsCobranded(state, action: PayloadAction<boolean>) {
            state.isCobranded = action.payload;
        },
        setIsMiniWidgetLoader(state, action: PayloadAction<boolean>) {
            state.isMiniWidgetLoader = action.payload;
        },
        setIsAuthenticationModalShowing(state, action: PayloadAction<boolean>) {
            state.isAuthenticationModalShowing = action.payload;
        },
        setAuthenticationModalView(state, action: PayloadAction<AuthenticationModalView>) {
            state.authModalView = action.payload;
        },
        setDashboardScrollPosition(state, action: PayloadAction<number>) {
            state.dashboardScrollPosition = action.payload;
        },
        setWidgetType(state, action: PayloadAction<ApplicationMode>) {
            state.mode = action.payload;
        },
        setAppModule(state, action: PayloadAction<ApplicationModule>) {
            state.appModule = action.payload;
        },
        setIsDashboardHeaderHidden(state, action: PayloadAction<boolean>) {
            state.isDashboardHeaderHidden = action.payload;
        },
        setBrandingOverride(state, action: PayloadAction<string>) {
            if (action.payload != '') {
                state.hasBrandingOverride = true;
                state.brandingOverride = action.payload;
            } else {
                state.hasBrandingOverride = false;
                state.brandingOverride = '';
            }
        },
        setHasShownAutoOnboarding(state, action: PayloadAction<boolean>) {
            state.hasShownAutoOnboarding = action.payload;
        },
        setIsHidingPrizeout(state, action: PayloadAction<boolean>) {
            state.isHidingPrizeout = action.payload;
        },
        setActiveFilter(state, action: PayloadAction<string>) {
            state.activeFilter = action.payload;
        },
        setFaqCategory(state, action: PayloadAction<faqCategory>) {
            state.faqCategory = action.payload;
        },
        setBrandingHeaderLogomarkUrl(state, action: PayloadAction<string>) {
            state.brandingHeaderLogomarkUrl = action.payload;
        },
        setHasBrandingHeaderTitle(state, action: PayloadAction<boolean>) {
            state.hasBrandingHeaderTitle = action.payload;
        },
    },
});

export const {
    disableIsLoading,
    enableIsLoading,
    toggleIsLoading,
    toggleIsLocationSelectorOpen,
    setIsTabletPortrait,
    setIsMobilePortrait,
    setIsMobileLandscape,
    setIsSmallIframeView,
    setIsCobranded,
    setIsMiniWidgetLoader,
    setIsOffersPanelVisible,
    setIsAuthenticationModalShowing,
    setAuthenticationModalView,
    setDashboardScrollPosition,
    setWidgetType,
    setAppModule,
    setIsDashboardHeaderHidden,
    setBrandingOverride,
    setHasShownAutoOnboarding,
    setIsHidingPrizeout,
    setActiveFilter,
    setFaqCategory,
    setBrandingHeaderLogomarkUrl,
    setHasBrandingHeaderTitle,
} = commonSlice.actions;

const selectCommonState = ({ common }: RootState): CommonState => common;

export const selectLoading = createSelector(selectCommonState, ({ loading }) => loading);

export const selectDashboardScrollPosition = createSelector(
    selectCommonState,
    ({ dashboardScrollPosition }) => dashboardScrollPosition,
);

export const selectActiveFilter = createSelector(selectCommonState, ({ activeFilter }) => activeFilter);

export const selectFaqCategory = createSelector(selectCommonState, ({ faqCategory }) => faqCategory);

export const selectIsLocationSelectorOpen = createSelector(
    selectCommonState,
    ({ isLocationSelectorOpen }) => isLocationSelectorOpen,
);

export const selectIsOffersPanelVisible = createSelector(
    selectCommonState,
    ({ isOffersPanelVisible }) => isOffersPanelVisible,
);

export const selectIsTabletPortrait = createSelector(
    selectCommonState,
    ({ isTabletPortraitView }) => isTabletPortraitView,
);

export const selectIsMobilePortrait = createSelector(
    selectCommonState,
    ({ isMobilePortraitView }) => isMobilePortraitView,
);

export const selectIsMobileLandscape = createSelector(selectCommonState, ({ isMobileLandscape }) => isMobileLandscape);

export const selectIsSmallIframeView = createSelector(selectCommonState, ({ isSmallIframeView }) => isSmallIframeView);

export const selectIsCobranded = createSelector(selectCommonState, ({ isCobranded }) => isCobranded);

export const selectIsMiniWidgetLoader = createSelector(
    selectCommonState,
    ({ isMiniWidgetLoader }) => isMiniWidgetLoader,
);

export const selectIsAuthenticationModalShowing = createSelector(
    selectCommonState,
    (commonState) => commonState.isAuthenticationModalShowing,
);

export const selectAuthenticationModalView = createSelector(
    selectCommonState,
    (commonState) => commonState.authModalView,
);

export const selectIsAccounts = createSelector(
    selectCommonState,
    (commonState) => commonState.appModule === ApplicationModule.ACCOUNTS,
);

export const selectIsBrowserExtension = createSelector(
    selectCommonState,
    (commonState) => commonState.appModule === ApplicationModule.BROWSER_EXTENSION,
);

export const selectIsDTCModule = createSelector(
    selectIsAccounts,
    selectIsBrowserExtension,
    (isAccounts, isBrowserExtension) => isAccounts || isBrowserExtension,
);

export const selectIsDashboardHeaderHidden = createSelector(
    selectCommonState,
    (commonState) => commonState.isDashboardHeaderHidden,
);

export const selectHasShownAutoOnboarding = createSelector(
    selectCommonState,
    (commonState) => commonState.hasShownAutoOnboarding,
);

export const selectBrandingOverride = createSelector(selectCommonState, (commonState) => ({
    brandingOverride: commonState.brandingOverride,
    hasBrandingOverride: commonState.hasBrandingOverride,
}));

export const selectBrandingHeaderLogomarkUrl = createSelector(
    selectCommonState,
    (commonState) => commonState.brandingHeaderLogomarkUrl,
);

export const selectHasBrandingHeaderTitle = createSelector(
    selectCommonState,
    (commonState) => commonState.hasBrandingHeaderTitle,
);

export const selectShouldShowNonProdWarning = createSelector(() => {
    const isSandbox = process.env.ENV === EnvironmentNames.SANDBOX;
    const isCandidate = process.env.ENV === EnvironmentNames.CANDIDATE;

    const shouldShowWarning = isCandidate || isSandbox;

    return shouldShowWarning;
});

export const selectIsHidingPrizeout = createSelector(selectCommonState, (commonState) => commonState.isHidingPrizeout);

export default commonSlice.reducer;
