import React from 'react';
import { GenericModal } from '../generic-modal';
import { useAppSelector } from '../../../../hooks';
import { selectSweepstake } from '../../../../store/slices/sweepstakes-slice';
import { countWithLabel, formatDateTime } from '../../../../utils/helpers';

interface SweepstakesEntryProps {
    isOpen: boolean;
    onClose: () => void;
    sweepstakesId: string;
}
export const SweepstakesEntry: React.FC<SweepstakesEntryProps> = ({
    isOpen,
    onClose,
    sweepstakesId,
}): React.ReactElement => {
    const sweepstake = useAppSelector(selectSweepstake(sweepstakesId));
    const openSweepstakesLandingPage = () => window.open(sweepstake.landingPageUrl, '_blank');

    return (
        <GenericModal
            alertImage="https://assets.prizeout.com/widget/sweepstakes/sweepstakes-dots.png"
            ariaDescribedById="po-sweepstakes-description"
            ariaLabel="Prizeout Sweepstakes"
            hasExitButton={true}
            hasAlertImage={true}
            headline="Prizeout Sweepstakes"
            isOpen={isOpen}
            onClose={onClose}
            testId="po-sweepstakes-modal"
            primaryButtonText="Got it"
            primaryOnClick={onClose}
            secondaryButtonText="Learn More"
            secondaryOnClick={() => openSweepstakesLandingPage()}
            primaryTestId="po-sweepstakes-accept-button"
            secondaryTestId="po-sweepstakes-lp-button"
        >
            <p>
                You&apos;ve received your first entry! Keep track of entries by clicking into the menu (
                <i className="fa-kit fa-prizeout-menu"></i>).
            </p>
            <p id="po-sweepstakes-description">
                {`Sweepstakes ends on ${formatDateTime(
                    sweepstake.endDateUTC,
                )}. 1 entry per gift card purchased. Max of ${countWithLabel(sweepstake.eligibility.maxEntriesPerDay, {
                    singular: 'entry',
                    plural: 'entries',
                })} per day.`}
            </p>
        </GenericModal>
    );
};
