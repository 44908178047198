import React from 'react';
import { BonusTag, GiftCardImage, GiftCardImageView, RoundImage } from '../../../../../../components/common';
import { CurrentOfferDisplay } from '../offer-gift-card';
import { OfferName } from '../common/offer-name';
import { OfferGiftCardOverlay } from '../common/offer-gift-card-overlay';
import { RoundImageSizes } from '../../../../../../store/slices/common-slice';
import { useAppSelector } from '../../../../../../hooks';
import { selectIsMiniWidget } from '../../../../../../store/slices/sdk-slice';

import './brand-offer-display.scss';

interface BrandOfferDisplayProps {
    data: CurrentOfferDisplay;
}

export const BrandOfferDisplay: React.FC<BrandOfferDisplayProps> = ({ data }): React.ReactElement => {
    const isMiniWidget = useAppSelector(selectIsMiniWidget);

    return (
        <>
            <OfferGiftCardOverlay
                giftCardImage={
                    <GiftCardImage
                        imgUrl={data.hero_image_url}
                        altText={`${data.name} gift card`}
                        view={GiftCardImageView.HERO}
                    />
                }
            >
                <RoundImage
                    imageUrl={data.logomark_url}
                    imageAltText={`${data.name} logomark`}
                    size={RoundImageSizes.ONE}
                />
            </OfferGiftCardOverlay>

            <OfferName name={data.name} rows={1} isBold />

            {data.offer_value > 0 && (
                <BonusTag
                    type={data.offer_type}
                    value={data.offer_value}
                    currency={data.currency}
                    offerType={data.display_offer_type}
                    hideText={isMiniWidget && true}
                />
            )}
        </>
    );
};
