import React from 'react';
import SwiperCore, { A11y, Navigation, Scrollbar, Mousewheel } from 'swiper';
import {
    BonusTag,
    Button,
    ButtonColor,
    ButtonSize,
    ButtonType,
    GiftCardImage,
    GiftCardImageView,
    RoundImage,
} from '../../../../../../components/common';
import { RoundImageSizes, selectIsMobileLandscape } from '../../../../../../store/slices/common-slice';
import { CurrentOfferDisplay } from '../offer-gift-card';
import { OfferName } from '../common/offer-name';
import { BigBrandCarousel } from '../../big-brand/big-brand-carousel';
import { useAppSelector } from '../../../../../../hooks';

import './detailed-big-brand-offer-display.scss';

SwiperCore.use([A11y, Navigation, Scrollbar, Mousewheel]);

interface DetailedBigBrandOfferDisplayProps {
    data: CurrentOfferDisplay;
    supportingImages?: string[];
    description: string;
}

export const DetailedBigBrandOfferDisplay: React.FC<DetailedBigBrandOfferDisplayProps> = ({
    data,
    supportingImages,
    description,
}): React.ReactElement => {
    SwiperCore.use([A11y, Navigation, Scrollbar, Mousewheel]);

    const shouldRenderCarousel = supportingImages.length > 1;
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);

    return (
        <>
            <div className="detailed-big-brand-offer-display__info">
                <div className="detailed-big-brand-offer-display__branding">
                    <RoundImage
                        imageUrl={data.logomark_url}
                        imageAltText={`${data.name} logomark`}
                        size={isMobileLandscape ? RoundImageSizes.FIVE : RoundImageSizes.SIX}
                    />
                    <div className="detailed-big-brand-offer-display__details">
                        <OfferName name={data.name} rows={1} isBold />
                        {data.offer_value > 0 && (
                            <BonusTag
                                type={data.offer_type}
                                value={data.offer_value}
                                currency={data.currency}
                                offerType={data.display_offer_type}
                            />
                        )}
                    </div>
                </div>

                {description.length > 1 && !isMobileLandscape && (
                    <p className="detailed-big-brand-offer-display__description">{description}</p>
                )}

                {!isMobileLandscape && (
                    <Button
                        className="detailed-big-brand-offer-display__button"
                        ariaLabel="Get this deal"
                        text="Get this deal"
                        color={ButtonColor.PRIMARY}
                        size={ButtonSize.MINI}
                        type={ButtonType.BUTTON}
                    />
                )}
            </div>

            {shouldRenderCarousel && <BigBrandCarousel name={data.name} supportingImages={supportingImages} />}
            {!shouldRenderCarousel && (
                <GiftCardImage
                    imgUrl={data.hero_image_url || data.image_url}
                    altText={`${data.name} gift card`}
                    view={GiftCardImageView.HERO}
                />
            )}
        </>
    );
};
