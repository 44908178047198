import React from 'react';
import { OfferName } from '../../../modules/widget/components/offers/offer-gift-card/common/offer-name';
import { ClickableCard } from '../cards';
import { OnboardingModal } from '../modals/onboarding-modal';
import { useOnboardingStateManager } from '../../../hooks';

import './help-icon.scss';

export const HelpIcon: React.FC = ({}): React.ReactElement => {
    const { isOpen, closeOnboardingModal, openOnboardingModal } = useOnboardingStateManager();

    return (
        <>
            <ClickableCard className="help-icon" onClick={openOnboardingModal}>
                <div className="help-icon__icon-wrapper">
                    <i className="help-icon__icon fa-kit fa-prizeout-nav-help"></i>
                </div>

                <OfferName name="How it works" rows={1} isBold />
            </ClickableCard>

            <OnboardingModal testId="onboarding-modal-enabled" isOpen={isOpen} onClose={closeOnboardingModal} />
        </>
    );
};
