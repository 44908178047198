import { useEffect, EffectCallback, DependencyList, useRef } from 'react';

export const useUpdateEffect = (callback: EffectCallback, dependencies?: DependencyList) => {
    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }

        return callback();
    }, dependencies);
};
