import React from 'react';

export function useRefAssigner<GenericElement = HTMLElement>(
    parentRef: React.RefCallback<GenericElement> | React.MutableRefObject<GenericElement>,
    localRef?: React.MutableRefObject<GenericElement>,
) {
    // https://react-hook-form.com/faqs#Howtosharerefusage
    const refAssigner = (element: GenericElement) => {
        if (parentRef) {
            if (typeof parentRef === 'function') {
                parentRef(element);
            } else {
                parentRef.current = element;
            }
        }

        if (localRef) {
            localRef.current = element;
        }
    };

    return refAssigner;
}
