import React, { forwardRef } from 'react';
import { Button } from './button';
import { ButtonColor, ButtonIconPosition, ButtonSize } from './types';
import ClassNames from 'classnames';
import './update-button.scss';

interface UpdateButtonProps {
    ariaLabel?: string;
    className?: string;
    label?: string;
    hidden?: boolean;
    onClick?: () => void;
}

export const UpdateButton = forwardRef<HTMLButtonElement, UpdateButtonProps>(
    (
        { ariaLabel, className, label, hidden, onClick },
        ref: React.RefCallback<HTMLButtonElement> | React.MutableRefObject<HTMLButtonElement>,
    ): React.ReactElement => {
        const classes: string = ClassNames('update-button', className, {
            'update-button--hidden': hidden,
        });

        label = label || 'Update';
        ariaLabel = ariaLabel || label;

        return (
            <Button
                ref={ref}
                ariaLabel={ariaLabel}
                className={classes}
                color={ButtonColor.SECONDARY}
                size={ButtonSize.MINI}
                icon="fak fa-prizeout-refresh"
                iconPosition={ButtonIconPosition.LEFT}
                isInline={true}
                text={label}
                onClick={onClick}
            />
        );
    },
);
