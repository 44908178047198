import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { StatusNotification } from '../../ui-widgets';
import { toggleIsLoading } from '../../../../store/slices/common-slice';
import { AppDispatch } from '../../../../store/widget/widget';
import {
    renewExpiredVerification,
    selectActiveAccountVerificationEmail,
    selectActiveVerificationId,
} from '../../../../store/slices/verification-slice';
import { useAppSelector } from '../../../../hooks';
import { ButtonTrackingTypes } from '../../../../utils/services/tracking';
import { ErrorModal } from '..';

interface EmailUnverifiedProps {
    closeModal: () => void;
}

export const EmailUnverified: React.FC<EmailUnverifiedProps> = ({ closeModal }): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const [isErrorOnProcessing, setIsErrorOnProcessing] = useState(false);
    const [isVerificationRenewed, setIsVerificationRenewed] = useState(false);
    const activeVerificationEmail = useAppSelector(selectActiveAccountVerificationEmail);
    const verificationId = useAppSelector(selectActiveVerificationId);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const triggerRenewExpiredVerification = async () => {
        try {
            setIsButtonDisabled(true);
            dispatch(toggleIsLoading());
            await dispatch(renewExpiredVerification({ id: verificationId })).unwrap();
            setIsVerificationRenewed(true);
        } catch (e) {
            setIsErrorOnProcessing(true);
        } finally {
            dispatch(toggleIsLoading());
            setIsButtonDisabled(false);
        }
    };

    return (
        <>
            <StatusNotification
                ariaDescribedById="verificationRequired-modal-description"
                ariaLabelledById="verificationRequired-modal-headline"
                headline={
                    isVerificationRenewed ? 'Your email verification has been resent!' : 'Your email is not verified'
                }
                imageAltText=""
                imageUrl="https://assets.prizeout.com/widget/verification/not-verified.jpg"
                isButtonDisabled={isButtonDisabled}
                message={`Check your inbox and verify ${activeVerificationEmail} to continue. Don't have access to this email? <a href="mailto:support@prizeout.com">Contact Support</a>`}
                primaryButtonText="Got it"
                primaryOnClick={() => closeModal()}
                trackingTypePrimary={ButtonTrackingTypes.ACCOUNT_VERIFICATION_RENEW_BUTTON_CLICK}
                secondaryButtonText="Resend verification"
                secondaryOnClick={() => triggerRenewExpiredVerification()}
                trackingTypeSecondary={ButtonTrackingTypes.ACCOUNT_CANCEL_VERIFICATION_RENEW_BUTTON_CLICK}
            />

            <ErrorModal
                isOpen={isErrorOnProcessing}
                onClose={() => setIsErrorOnProcessing(false)}
                headline="An error has occurred"
                message="Please try again."
            />
        </>
    );
};
