import React from 'react';

import './logo.scss';

interface LogoProps {
    preventLogoSwitch?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ preventLogoSwitch }): React.ReactElement => {
    const imgSrc = 'https://assets.prizeout.com/branding/po-logo-full-blue.svg';
    const mobileImgSrc = 'https://assets.prizeout.com/branding/po-logo-small.svg';

    return (
        <>
            <picture>
                <source
                    data-testid="mobile-logo"
                    srcSet={preventLogoSwitch ? imgSrc : mobileImgSrc}
                    media="(max-width: 640px)"
                />
                <img data-testid="logo" className="logo" src={imgSrc} alt="Prizeout logo" />
            </picture>
        </>
    );
};
