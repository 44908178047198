import React from 'react';
import Classnames from 'classnames';

import './offer-gift-card-overlay.scss';

interface OfferGiftCardOverlayProps {
    giftCardImage: React.ReactNode;
    hasGradient?: boolean;
}

export const OfferGiftCardOverlay: React.FC<OfferGiftCardOverlayProps> = ({
    children,
    giftCardImage,
    hasGradient,
}): React.ReactElement => {
    const classes: string = Classnames('offer-gift-card-overlay__background', {
        'offer-gift-card-overlay__background--gradient': hasGradient,
    });

    return (
        <div data-testid="offer-gift-card-overlay" className="offer-gift-card-overlay">
            {giftCardImage}
            <div data-testid="offer-gift-card-overlay-background" className={classes}></div>
            <div className="offer-gift-card-overlay__content z-index-offer-gift-card-overlay">{children}</div>
        </div>
    );
};
