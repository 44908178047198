import React, { useEffect, useState } from 'react';
import Classnames from 'classnames';
import { useAppSelector, useLoadOfferDetail } from '../../../hooks';
import {
    selectIsCategoryFilterEnabled,
    selectIsSearchEnabled,
    selectIsSearchV2Enabled,
    selectPrizeoutUserSession,
} from '../../../store/slices/partner-config-slice';
import {
    PrizeoutOffer,
    PrizeoutOfferMini,
    getAllOffers,
    selectOffers,
    selectOffersData,
    selectSearchResultsDisplay,
} from '../../../store/slices/offers-slice';
import { disableIsLoading, enableIsLoading, selectIsMobilePortrait } from '../../../store/slices/common-slice';
import { InlineModuleLayout } from '../../../modules/common/inline-module-layout/inline-module-layout';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/widget/widget';
import { setActiveOffer, setCheckoutPanelOpen } from '../../../store/slices/checkout-slice';
import SearchWidget from './search-widget';
import { offerListSearchConfig } from './config';
import { FilterButton } from './filter';
import { OfferSortToggle } from '../../../modules/widget/components/dashboard/category-dashboard/offer-sort-toggle';
import Skeleton from 'react-loading-skeleton';
import { SearchTrackingVariationType, TrackingService } from '../../../utils/services/tracking';
import { useLocation } from 'react-router-dom';
import { getSdkParams } from '../../../store/slices/sdk-slice';

import './search-filter-layout.scss';

interface SearchFilterLayoutProps {
    toggleSort?: boolean;
}

export const SearchFilterLayout: React.FC<SearchFilterLayoutProps> = ({ toggleSort }): React.ReactElement => {
    const isSearchEnabled = useAppSelector(selectIsSearchEnabled);
    const isSearchV2Enabled = useAppSelector(selectIsSearchV2Enabled);
    const isCategoryFilterEnabled = useAppSelector(selectIsCategoryFilterEnabled);
    const isSearchResultsFullScreen = useAppSelector(selectSearchResultsDisplay);
    const isMobilePortrait = useAppSelector(selectIsMobilePortrait);
    const offers = useAppSelector(selectOffers);
    const offersSearchV2 = useAppSelector(selectOffersData);
    const prizeoutSessionId = useAppSelector(selectPrizeoutUserSession);
    const location = useLocation();
    const trackingService = TrackingService.getTrackingService();
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const sdkParams = useAppSelector(getSdkParams);
    const { buildOfferDetailLoader } = useLoadOfferDetail();

    useEffect(() => {
        if (isSearchV2Enabled) {
            setIsLoading(true);
            try {
                dispatch(
                    getAllOffers({
                        prizeoutSessionId,
                        sessionId: sdkParams?.session_id,
                    }),
                );
            } catch (e) {
                setIsError(true);
            }
        }
    }, []);

    useEffect(() => {
        if (offersSearchV2?.length > 0) {
            setIsLoading(false);
        }
    }, [offersSearchV2]);

    const offerDetailLoader = buildOfferDetailLoader((offer) => {
        dispatch(enableIsLoading());
        dispatch(setActiveOffer(offer));
        dispatch(setCheckoutPanelOpen());
        dispatch(disableIsLoading());
    });

    const classes = Classnames('search-filter-layout', {
        'search-filter-layout--full-screen z-index-full-screen-overlay': isMobilePortrait && isSearchResultsFullScreen,
    });

    const openCheckoutPanel = (offer: PrizeoutOffer) => {
        dispatch(setCheckoutPanelOpen());
        dispatch(setActiveOffer(offer));
    };

    const openCheckoutPanelFromMiniOffer = async (offer: PrizeoutOfferMini) => {
        await offerDetailLoader({
            offerId: offer.offer_id,
        });
        trackingService.trackSearchItemSelected(
            offer.offer_id,
            offer.name,
            location.pathname,
            SearchTrackingVariationType.BRAND,
        );
    };

    const renderSearchWidget = () => {
        if (isSearchEnabled) {
            return (
                <SearchWidget
                    searchItems={offers.flatMap((offer) => offer.data)}
                    searchConfig={offerListSearchConfig}
                    onClick={(offer) => openCheckoutPanel(offer as unknown as PrizeoutOffer)}
                    name="offers"
                />
            );
        }
    };

    const renderSearchV2Widget = () => {
        if (isSearchV2Enabled) {
            return (
                <SearchWidget
                    isFullWidth
                    searchItems={offersSearchV2}
                    searchConfig={offerListSearchConfig}
                    onClick={(offer) => openCheckoutPanelFromMiniOffer(offer as unknown as PrizeoutOfferMini)}
                    name="offers"
                    testId="search-widget-v2"
                />
            );
        }
    };

    const renderFilterButton = () => {
        if (isCategoryFilterEnabled) {
            return <FilterButton />;
        }
    };

    const renderLayout = () => {
        if (isSearchResultsFullScreen) {
            return renderSearchWidget();
        }

        return (
            <InlineModuleLayout>
                {renderSearchWidget()}
                {renderFilterButton()}
            </InlineModuleLayout>
        );
    };

    const renderLayoutV2 = () => {
        if (isSearchResultsFullScreen) {
            return renderSearchV2Widget();
        }

        return (
            <>
                {!isLoading && !isError && (
                    <InlineModuleLayout isFullWidth isTwoColumn={toggleSort}>
                        {renderSearchV2Widget()}
                        {toggleSort && <OfferSortToggle />}
                    </InlineModuleLayout>
                )}
                {isLoading && !isError && (
                    <div className="preloader" data-testid="search-widget-v2-loader">
                        <Skeleton height={45} inline={true} />
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {(isSearchEnabled || isCategoryFilterEnabled) && <div className={classes}>{renderLayout()}</div>}
            {isSearchV2Enabled && <div className={classes}>{renderLayoutV2()}</div>}
        </>
    );
};
