import React, { useState, useEffect } from 'react';
import Classnames from 'classnames';

import './toggle-switch.scss';

interface ToggleSwitchProps {
    active?: boolean;
    id: string;
    isDisabled?: boolean;
    onChange?: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    active = false,
    children,
    id,
    isDisabled = false,
    onChange,
}): React.ReactElement => {
    const [isChecked, setIsChecked] = useState(active);
    const classes = Classnames('toggle-switch', { 'toggle-switch--disabled': isDisabled });

    useEffect(() => {
        setIsChecked(active);
    }, [active]);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
        onChange && onChange();
    };

    return (
        <label htmlFor={id} className={classes}>
            {children && <div>{children}</div>}
            <input
                aria-checked={isChecked}
                aria-disabled={isDisabled}
                checked={isChecked}
                className="toggle-switch__input"
                data-testid="toggle-switch-input"
                id={id}
                onChange={handleOnChange}
                role="switch"
                type="checkbox"
            />
        </label>
    );
};
