import { PaginatedData } from '../../../../utils';
import { PageManagerPageType } from './page-manager';

export interface PagedRow<RowType> {
    row: RowType;
    pageNumber: number;
    pageIndex: number; // Index within page
}

export type RowIteratorCallback<RowType, ReturnType> = (
    row: PagedRow<RowType>,
    rowNumber: number,
) => PagedRow<ReturnType>;
export type RowFilterCallback<RowType> = (row: PagedRow<RowType>, rowNumber: number) => boolean;

export class PageManagerBase<RowType> {
    protected pages: PageManagerPageType<RowType>[] = [];

    private _changeHash: string = '';
    private _pageLength: number = 0;
    private _rowLength: number = 0;
    private _startingPage: number = 1;
    private _perPage: number = 0;
    private _totalPages: number = 0;

    constructor() {}

    // Number of pages actually in the manager
    get pageLength() {
        return this._pageLength;
    }

    protected set pageLength(newLength: number) {
        this._pageLength = newLength;
        this.setHash();
    }

    // Number of rows in the manager with startingPage accounted for
    get length() {
        return this._rowLength;
    }

    protected set length(newLength: number) {
        this._rowLength = newLength;
        this.setHash();
    }

    // First page to return in map/filter/getAllRows
    get startingPage() {
        return this._startingPage;
    }

    set startingPage(newPage: number) {
        this._startingPage = newPage <= this.totalPages || this.totalPages === 0 ? newPage : this.totalPages;
        this.length = this.getAllRows().length;
        this.setHash();
    }

    // Number of rows per page as specified by the server
    get perPage() {
        return this._perPage;
    }

    protected set perPage(perPage: number) {
        if (this._perPage !== 0 && perPage !== 0) {
            return;
        }

        this._perPage = perPage;
    }

    // Total number of expected pages as specified by the server
    get totalPages() {
        return this._totalPages;
    }

    protected set totalPages(totalPages: number) {
        this._totalPages = totalPages;
        this.setHash();
    }

    // Pages left to load from where we are
    get pagesLeftToLoad() {
        return this.totalPages - this.pageLength;
    }

    // A simple hash meant to track changes to the pages/rows/etc
    get changeHash() {
        return this._changeHash;
    }

    protected set changeHash(newHash: string) {
        this._changeHash = newHash;
    }

    // This hash does not need to be unique. It just
    // needs to change if the pageLength or rowLength change.
    private setHash() {
        const hashSeg = (value: number) => {
            return value.toString(16).padStart(4, '0');
        };

        // A tiebreaker is needed to be sure trivial changes are caught
        // when they should be.  A derivative of the epoch should be unique enough
        //
        // Ten milliseconds should be fast enough.
        const tieBreaker = Math.trunc(Date.now() / 10) % 1000;

        this.changeHash = `${hashSeg(this.startingPage)}_${hashSeg(this.pageLength)}_${hashSeg(
            this.totalPages,
        )}_${hashSeg(this.length)}_${hashSeg(tieBreaker)}`;
    }

    protected updateLength() {
        this.pageLength = this.pages.length - 1; // account for index 0.

        if (this.pageLength < 0) {
            this.pageLength = 0;
        }

        this.length = this.getAllRows().length;
    }

    protected validateNewPage(page: PaginatedData<RowType[]>, pageCount: number): number {
        const pageNumber = page.pagination.currentPage;
        this.perPage = page.pagination.perPage;
        this.totalPages = Math.ceil(page.pagination.total / page.pagination.perPage);

        if (pageNumber > this.totalPages) {
            return -1;
        }

        if (pageCount === 0 || this.startingPage > page.pagination.currentPage) {
            this.startingPage = page.pagination.currentPage;
        }

        return pageNumber;
    }

    protected pageHasData(page: PaginatedData<RowType[]>): boolean {
        return page?.data && page.data.length > 0;
    }

    protected mapPageRows<ReturnType>(
        callback: RowIteratorCallback<RowType, ReturnType>,
        rowIndex: () => number,
        page: PaginatedData<RowType[]>,
    ): PagedRow<ReturnType>[] {
        const data = page?.data || [];
        const pageNumber = page.pagination.currentPage;

        const pageRows = data.map((row, pageIndex) => {
            const rowData: PagedRow<RowType> = {
                row,
                pageIndex,
                pageNumber,
            };

            return callback(rowData, rowIndex());
        });

        return pageRows;
    }

    protected filterPageRows(
        callback: RowFilterCallback<RowType>,
        page: PaginatedData<RowType[]>,
        resultPages: PagedRow<RowType>[],
    ): void {
        const data = page?.data || [];
        const pageNumber = page.pagination.currentPage;

        for (let pageIndex: number = 0; pageIndex < data.length; pageIndex++) {
            const row = data[pageIndex];
            const rowData: PagedRow<RowType> = {
                row,
                pageIndex,
                pageNumber,
            };

            if (callback(rowData, resultPages.length)) {
                resultPages.push(rowData);
            }
        }
    }

    canAddPage(_page: PageManagerPageType<RowType>) {
        throw new Error('canAddPage must be overriden');
    }

    addPage(_page: PageManagerPageType<RowType>): void {
        throw new Error('addPage must be overriden');
    }

    getPage(_pageNumber: number): PageManagerPageType<RowType> {
        throw new Error('getPage must be overriden');
    }

    clearAllPages(): void {
        // Order of operations matters here.
        this.pages = [];
        this.updateLength();
        this.perPage = 0;
        this.totalPages = 0;
        this.changeHash = '';
    }

    map<ReturnType>(_callback: RowIteratorCallback<RowType, ReturnType>): PagedRow<ReturnType>[] {
        throw new Error('map must be overriden');
    }

    filter(_callback: RowFilterCallback<RowType>): PagedRow<RowType>[] {
        throw new Error('filter must be override');
    }

    protected getAllRows(): PagedRow<RowType>[] {
        return this.map((row) => row);
    }
}
