import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './skeleton-loader-element-circle.scss';

interface SkeletonLoaderElementCircleProps {
    height?: number; // Flexible based on design, number in pixels
}

export const SkeletonLoaderElementCircle: React.FC<SkeletonLoaderElementCircleProps> = ({
    height = 44,
}): React.ReactElement => {
    return (
        <div data-testid="skeleton-loader-element-circle" className="skeleton-loader-element-circle">
            <Skeleton
                height={height}
                width={height}
                circle
                inline
                containerTestId="skeleton-loader-element-circle-container"
            />
        </div>
    );
};
