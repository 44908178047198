import React from 'react';
import Classnames from 'classnames';

import './text-label.scss';

interface TextLabelProps {
    text: string;
    isBold?: boolean;
    isHeading?: boolean;
    className?: string;
    hasEllipsis?: boolean;
}

export const TextLabel: React.FC<TextLabelProps> = ({
    text,
    isBold,
    isHeading,
    className,
    hasEllipsis = true,
}): React.ReactElement => {
    const classes = Classnames(
        'text-label',
        { 'text-label--bold': isBold },
        { 'text-label--heading': isHeading },
        { 'text-label--ellipsis': hasEllipsis },
        className,
    );

    const renderText = () => {
        if (isHeading) {
            return (
                <h3 data-testid="text-label-heading" className={classes}>
                    {text}
                </h3>
            );
        } else {
            return (
                <p data-testid="text-label" className={classes}>
                    {text}
                </p>
            );
        }
    };

    return <>{renderText()}</>;
};
