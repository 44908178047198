import React from 'react';
import { AlertCallout, AlertCalloutMessageTypes, GenericModal } from '../../common';

interface ErrorModalProps {
    buttonText?: string;
    headline?: string;
    isOpen: boolean;
    message: string;
    onClose: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
    buttonText,
    headline,
    isOpen,
    message,
    onClose,
}): React.ReactElement => {
    const defaultButtonText = 'OK';
    const defaultHeadline = 'Oops! Something went wrong...';

    return (
        <GenericModal
            ariaDescribedById="aria-error-description"
            ariaLabelledById="aria-error-headline"
            ariaLabel="Show error message"
            headline={headline || defaultHeadline}
            isOpen={isOpen}
            onClose={onClose}
            portalClassName="error-modal"
            primaryButtonText={buttonText || defaultButtonText}
            primaryOnClick={onClose}
            testId="error-modal"
        >
            <AlertCallout
                hasBorder
                ariaDescribedById="aria-error-description"
                message={message}
                type={AlertCalloutMessageTypes.ERROR}
            />
        </GenericModal>
    );
};
