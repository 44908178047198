import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sharedAPI } from '../../utils/services/api';
import type { RootState } from '../widget/widget';
import {
    PostCheckoutOffers,
    PrizeoutOffer,
    PrizeoutOfferCustomDenomType,
    PrizeoutOfferValueOptions,
    selectDefaultCard,
} from './offers-slice';
import { uuid_v4 } from '../../utils';

// Define a type for the slice state
export interface CheckoutSlice {
    activeOffer?: PrizeoutOffer;
    activeCard?: PrizeoutOfferValueOptions;
    activeCheckoutValueId?: string;
    cashoutHistory: CashoutHistory;
    checkedOutCard?: CheckoutOfferResponse;
    currentCustomDenomType?: PrizeoutOfferCustomDenomType;
    customDenomDisplayCost?: number;
    customDenomDisplayValue?: number;
    customDenomDisplayAmountEarned?: number; // amount in bonus or cashback
    successfulCheckoutCard?: PrizeoutGiftCard;
    isCheckoutPanelOpen: boolean;
    loading: boolean;
    view: CheckoutViewEnum;
    shouldShowPostCheckoutOffers?: boolean;
    onboardingInfoShown: boolean;
}

export type CashoutHistory = {
    successes: CheckoutOfferResponse[];
    failures: CheckoutOfferResponse[];
};

export type PrizeoutGiftCard = {
    offer: PrizeoutOffer;
    card: PrizeoutOfferValueOptions;
};

type CheckoutOfferRequest = {
    cashout_type: string;
    category_id: number;
    checkoutValueId: string;
    cost_in_cents: number;
    country_code: string;
    email: string;
    offer_in_cents: number;
    payment_id?: string;
    prizeout_session_id: string;
    value_in_cents: number;
};

type GiftCardObject = {
    id: string;
    email: string;
    cost_in_cents: number;
    converted_cost_in_cents?: number;
    country_code: string;
    name: string;
    offer_in_cents: number;
    value_in_cents: number;
    currency_code: string;
    image_url: string;
    provider_name: string;
    provider_id: string;
};

type CheckoutSweepstakes = {
    showSweepstakesConfirmationModal: boolean;
    sweepstakesId: string;
};

export type CheckoutOfferResponse = {
    brand_id: string;
    claim_code: string;
    claim_url: string;
    request_id: string;
    giftcard_id: string;
    firstname: string;
    lastname: string;
    email: string;
    sold_out: boolean;
    user_limit_hit: boolean;
    remaining_conversions?: number;
    giftcard: GiftCardObject;
    error?: boolean;
    message?: string;
    httpStatus: number;
    held_for_review: boolean;
    sweepstakes?: CheckoutSweepstakes[];
    postCheckout?: PostCheckoutOffers;
};

export enum CheckoutViewEnum {
    CHECKOUT = 'checkout',
    CHECKOUT_BRAND_INFO = 'checkout-brand-info',
    CHECKOUT_CASHBACK_CONFIRM = 'checkout-cashback-confirm',
    CHECKOUT_REWARDS_CONFIRM = 'checkout-rewards-confirm',
    CHECKOUT_CONFIRMATION = 'checkout-confirmation',
    CHECKOUT_REWARDS_CONFIRMATION = 'checkout-rewards-confirmation',
}

// Define the initial state using that type
export const checkoutInitialState: CheckoutSlice = {
    cashoutHistory: {
        successes: [],
        failures: [],
    },
    isCheckoutPanelOpen: false,
    loading: false,
    view: CheckoutViewEnum.CHECKOUT,
    onboardingInfoShown: false,
};

export const checkoutOffer = createAsyncThunk(
    'offers/checkout',
    async (
        {
            cashout_type,
            category_id,
            checkoutValueId,
            cost_in_cents,
            country_code,
            email,
            offer_in_cents,
            payment_id,
            prizeout_session_id,
            value_in_cents,
        }: CheckoutOfferRequest,
        { rejectWithValue, signal },
    ) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    cashout_type,
                    category_id,
                    checkoutValueId,
                    cost_in_cents,
                    country_code,
                    email,
                    giftcard_request_id: uuid_v4(),
                    offer_in_cents,
                    payment_id,
                    prizeout_session_id,
                    value_in_cents,
                },
                endpoint: '/v2/checkout',
                method: 'POST',
                signal: signal,
            });
            if (results.data?.error) {
                return rejectWithValue(results.data);
            }
            return {
                ...results.data,
                httpStatus: results.status,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const checkoutSlice = createSlice({
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(checkoutOffer.fulfilled, (state, action: PayloadAction<CheckoutOfferResponse>) => {
            state.loading = false;
            state.checkedOutCard = action.payload;
        });

        builder.addCase(checkoutOffer.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(checkoutOffer.pending, (state) => {
            state.loading = true;
        });
    },
    initialState: checkoutInitialState,
    name: 'checkout',
    reducers: {
        resetCheckoutPanel(state) {
            state.isCheckoutPanelOpen = false;
            state.view = CheckoutViewEnum.CHECKOUT;
            state.activeOffer = null;
        },
        resetCheckoutSlice() {
            return checkoutInitialState;
        },
        resetCustomDenoms(state) {
            state.customDenomDisplayAmountEarned = null;
            state.customDenomDisplayCost = null;
            state.customDenomDisplayValue = null;
        },
        setActiveCard(state, action: PayloadAction<PrizeoutOfferValueOptions>) {
            state.activeCard = action.payload;
        },
        setCurrentCustomDenomType(state, action: PayloadAction<PrizeoutOfferCustomDenomType>) {
            state.currentCustomDenomType = action.payload;
        },
        setCustomDenomDisplayCost(state, action: PayloadAction<number>) {
            state.customDenomDisplayCost = action?.payload;
        },
        setCustomDenomDisplayValue(state, action: PayloadAction<number>) {
            state.customDenomDisplayValue = action?.payload;
        },
        setCustomDenomDisplayAmountEarned(state, action: PayloadAction<number>) {
            state.customDenomDisplayAmountEarned = action?.payload;
        },
        setActiveOffer(state, action: PayloadAction<PrizeoutOffer>) {
            state.activeOffer = action?.payload;
            state.activeCard = action?.payload?.giftcard_list[0];
            state.activeCheckoutValueId = state.activeCard?.checkout_value_id;
        },
        setCheckoutFailure(state, action: PayloadAction<CheckoutOfferResponse>) {
            state.cashoutHistory.failures.push(action.payload);
        },
        setCheckoutPanelClosed(state) {
            state.isCheckoutPanelOpen = false;
            state.view = CheckoutViewEnum.CHECKOUT;
        },
        setCheckoutPanelOpen(state) {
            state.isCheckoutPanelOpen = true;
        },
        setCheckoutSuccess(state, action: PayloadAction<CheckoutOfferResponse>) {
            state.cashoutHistory.successes.push(action.payload);
        },
        setCheckoutView(state, action: PayloadAction<CheckoutViewEnum>) {
            state.view = action.payload;
        },
        setOnboardingInfoShown(state, action: PayloadAction<boolean>) {
            state.onboardingInfoShown = action.payload;
        },
        setShouldShowPostCheckoutOffers(state, action: PayloadAction<boolean>) {
            state.shouldShowPostCheckoutOffers = action.payload;
        },
        setSuccessfulCheckoutCard(state, action: PayloadAction<PrizeoutGiftCard>) {
            state.successfulCheckoutCard = action.payload;
        },
        toggleIsLoading(state) {
            state.loading = !state.loading;
        },
    },
});

export const {
    resetCheckoutPanel,
    resetCheckoutSlice,
    resetCustomDenoms,
    setActiveCard,
    setCurrentCustomDenomType,
    setCustomDenomDisplayCost,
    setCustomDenomDisplayValue,
    setCustomDenomDisplayAmountEarned,
    setActiveOffer,
    setCheckoutFailure,
    setCheckoutPanelClosed,
    setCheckoutPanelOpen,
    setCheckoutSuccess,
    setCheckoutView,
    setOnboardingInfoShown,
    setShouldShowPostCheckoutOffers,
    setSuccessfulCheckoutCard,
    toggleIsLoading,
} = checkoutSlice.actions;

const selectCheckoutState = ({ checkout }: RootState): CheckoutSlice => checkout;

export const selectSuccessfulCheckoutCard = createSelector(
    selectCheckoutState,
    (checkout) => checkout?.successfulCheckoutCard,
);

export const selectCashoutHistory = createSelector(selectCheckoutState, (checkout) => checkout.cashoutHistory);

export const selectCurrentSelectedOffer = createSelector(selectCheckoutState, (checkout) => {
    if (checkout.activeOffer) {
        return checkout.activeOffer;
    }
});

export const selectCurrentSelectedCard = createSelector(selectCheckoutState, (checkout) => {
    if (checkout.activeCard) {
        return checkout.activeCard;
    }
});

export const selectActiveCard = createSelector(
    selectCurrentSelectedCard,
    selectDefaultCard,
    (selectedCard, defaultCard) => selectedCard || defaultCard,
);

export const selectActiveCheckoutValueId = createSelector(
    selectCheckoutState,
    ({ activeCheckoutValueId }) => activeCheckoutValueId,
);

export const selectCheckoutView = createSelector(selectCheckoutState, ({ view }) => view);

export const selectIsCheckoutConfirmationOpen = createSelector(
    selectCheckoutState,
    ({ view }) => view === CheckoutViewEnum.CHECKOUT_CONFIRMATION,
);

export const selectIsCheckoutPanelOpen = createSelector(
    selectCheckoutState,
    ({ isCheckoutPanelOpen }) => isCheckoutPanelOpen,
);

export const selectLoading = createSelector(selectCheckoutState, ({ loading }) => loading);

export const selectCheckedOutCard = createSelector(selectCheckoutState, ({ checkedOutCard }) => checkedOutCard);

export const selectShouldShowPostCheckoutOffers = createSelector(
    selectCheckoutState,
    ({ shouldShowPostCheckoutOffers }) => shouldShowPostCheckoutOffers,
);

export const selectCustomDenomDisplayCost = createSelector(
    selectCheckoutState,
    ({ customDenomDisplayCost }) => customDenomDisplayCost,
);

export const selectCustomDenomDisplayValue = createSelector(
    selectCheckoutState,
    ({ customDenomDisplayValue }) => customDenomDisplayValue,
);

export const selectCustomDenomDisplayAmountEarned = createSelector(
    selectCheckoutState,
    ({ customDenomDisplayAmountEarned }) => customDenomDisplayAmountEarned,
);

export const selectCurrentCustomDenomType = createSelector(
    selectCheckoutState,
    ({ currentCustomDenomType }) => currentCustomDenomType,
);

export const selectIsCostCustomDenom = createSelector(
    selectCheckoutState,
    ({ currentCustomDenomType }) => currentCustomDenomType === PrizeoutOfferCustomDenomType.COST,
);

export const selectIsValueCustomDenom = createSelector(
    selectCheckoutState,
    ({ currentCustomDenomType }) => currentCustomDenomType === PrizeoutOfferCustomDenomType.VALUE,
);

export const selectIsDualCostOrValueCustomDenom = createSelector(
    selectCheckoutState,
    ({ currentCustomDenomType }) => currentCustomDenomType === PrizeoutOfferCustomDenomType.DUAL,
);

export const selectIsDualCostOnlyCustomDenom = createSelector(
    selectCheckoutState,
    ({ currentCustomDenomType }) => currentCustomDenomType === PrizeoutOfferCustomDenomType.DUAL_COST_ONLY,
);

export const selectIsDualValueOnlyCustomDenom = createSelector(
    selectCheckoutState,
    ({ currentCustomDenomType }) => currentCustomDenomType === PrizeoutOfferCustomDenomType.DUAL_VALUE_ONLY,
);

export const selectIsCustomDenomDualInputs = createSelector(
    selectIsDualCostOrValueCustomDenom,
    selectIsDualCostOnlyCustomDenom,
    selectIsDualValueOnlyCustomDenom,
    (isDualCostOrValue: boolean, isDualCostOnly: boolean, isDualValueOnly: boolean) => {
        return isDualCostOrValue || isDualCostOnly || isDualValueOnly;
    },
);

export const selectOnboardingInfoShown = createSelector(
    selectCheckoutState,
    ({ onboardingInfoShown }) => onboardingInfoShown,
);

export default checkoutSlice.reducer;
