export enum LoggingSubject {
    ALWAYS_SHOWN = 'always-shown',
    AUTHENTICATION_MANAGER = 'authentication-manager',
    CONTENT_SCRIPT_MGMT = 'content-script-mgmt',
    INJECTION_MANAGER = 'injection-manager',
    INJECTION_MANAGER_RULES = 'injection-manager-rules',
    JWT_STATUS = 'jwt-status',
    MESSAGING = 'messaging',
    NAVIGATION_MONITOR_SETUP = 'navigation-monitor-setup',
    RULESET_PROCESSING = 'ruleset-processing',
}

export class LoggingManager {
    private static instance: LoggingManager = null;
    private static isInstantiating = false;
    static readonly storageKey = 'LoggingSubjectWhitelist';

    private subjectWhitelist: LoggingSubject[] = [LoggingSubject.ALWAYS_SHOWN];

    static getInstance() {
        if (this.instance) {
            this.instance.loadList(); // Keep the list up to date.
            return this.instance;
        }

        this.isInstantiating = true;
        this.instance = new this();
        this.instance.loadList();

        return this.instance;
    }

    constructor() {
        if (!LoggingManager.isInstantiating) {
            throw new Error('Direct instantiation not allowed, use getInstance');
        }

        LoggingManager.isInstantiating = false;
    }

    private normalizeList() {
        this.subjectWhitelist = this.subjectWhitelist ?? [];

        if (!this.subjectWhitelist.includes(LoggingSubject.ALWAYS_SHOWN)) {
            this.subjectWhitelist.push(LoggingSubject.ALWAYS_SHOWN);
        }

        this.subjectWhitelist.sort();
        return this.subjectWhitelist;
    }

    private async loadList(): Promise<void> {
        let loadedList: string;

        try {
            loadedList = localStorage.getItem(LoggingManager.storageKey);
        } catch {
            loadedList = null;
        }

        loadedList = loadedList || `[${LoggingSubject.ALWAYS_SHOWN}, ${LoggingSubject.RULESET_PROCESSING}]`;

        try {
            this.subjectWhitelist = JSON.parse(loadedList);
        } catch {
            this.subjectWhitelist = [LoggingSubject.ALWAYS_SHOWN];
        }

        this.normalizeList();
        this.saveList();
    }

    private saveList(): void {
        const storageValue = JSON.stringify(this.subjectWhitelist);

        try {
            localStorage.setItem(LoggingManager.storageKey, storageValue);
        } catch {
            return;
        }
    }

    setList(newList: LoggingSubject[]): void {
        this.subjectWhitelist = newList;
        this.normalizeList();
        this.saveList();
    }

    isSubjectEnabled(subject: LoggingSubject): boolean {
        return this.subjectWhitelist.includes(subject);
    }

    toggleSubject(subject: LoggingSubject): void {
        if (subject === LoggingSubject.ALWAYS_SHOWN) {
            return;
        }

        const workingList = [...this.subjectWhitelist];

        if (workingList.includes(subject)) {
            this.subjectWhitelist = workingList.filter((filterSubject) => {
                return subject !== filterSubject;
            });
        } else {
            this.subjectWhitelist.push(subject);
        }

        this.normalizeList();
        this.saveList();
    }

    getList(): LoggingSubject[] {
        return [...this.subjectWhitelist];
    }

    writeLog(subject: LoggingSubject, ...rest: any[]): void {
        if (subject === LoggingSubject.ALWAYS_SHOWN || this.isSubjectEnabled(subject)) {
            // eslint-disable-next-line no-console
            console.log('PRIZEOUT: ', subject, ...rest);
        }
    }
}

export const testLog = (subject: LoggingSubject, ...rest: any[]): void => {
    LoggingManager.getInstance().writeLog(subject, ...rest);
};
