import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { basePortalQuery } from '../common';
import { firebaseSignInRequest, firebaseSignInResponse, PortalEventGroups } from './admin-portal-types';

export const portalAuthenticationApiSlice = createApi({
    reducerPath: 'adminPortalAuthenticationApi',
    baseQuery: basePortalQuery,
    tagTypes: [PortalEventGroups.PortalLogin],
    endpoints: (builder) => ({
        loginAdmin: builder.mutation<firebaseSignInResponse, firebaseSignInRequest>({
            query: (credentials) => ({
                url: '/admin/logIn',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: [PortalEventGroups.PortalLogin],
        }),
    }),
});

export const { useLoginAdminMutation } = portalAuthenticationApiSlice;
