import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { auctionApi } from '../../utils/services/auction-api';
import { sharedAPI } from '../../utils/services/api';
import type { RootState } from '../widget/widget';
import { uuid_v4 } from '../../utils';
import { ApplicationModule } from './common-slice';

// Define a type for the slice state
export interface SidebarSlice {
    giftCards?: GiftCards;
    isOpen: boolean;
    loading: boolean;
    profile?: Profile;
    points?: Points;
    view: SidebarViewEnum;
}

export enum SidebarViewEnum {
    DEFAULT = 'default',
    PROFILE = 'profile',
    REWARDS = 'my-points',
    ABOUT = 'about',
    WALLET = 'my-gift-cards',
    GIFT_CARD = 'gift-card',
    GIFT_CARD_INSTRUCTIONS = 'gift-card-instructions',
    GIFT_CARD_TERMS = 'gift-card-terms',
    UPDATE_PASSWORD = 'change-your-password',
    LEVEL_LIST = 'level-list',
    GIFT_CARD_REWARD = 'reward-gift-card',
    CHECKOUT_REWARD = 'reward-checkout-confirmation',
    UPDATE_EMAIL = 'change-email-address',
    PREFERENCES = 'preferences',
}

// Define the initial state using that type
export const sidebarInitialState: SidebarSlice = {
    isOpen: false,
    loading: false,
    points: {
        balance: 0,
        levelsGiftCards: [],
        pending: 0,
    },
    view: SidebarViewEnum.DEFAULT,
};

type Points = {
    activeListLevel?: string;
    activeLevelGiftCard?: LevelsGiftCard;
    balance: number;
    levels?: Level[];
    levelsGiftCards: LevelsGiftCard[];
    max_level_completed?: boolean;
    nextLevel?: number;
    pending: number;
};

export type LevelsGiftCard = {
    checkout_value_id: string;
    currency: string;
    image_url: string;
    name: string;
    value_in_cents: string;
};

export type Level = {
    value: number;
    missing: number;
    unlocked: boolean;
    pct: number;
    pending_pct: number;
    used?: boolean;
};

type GiftCardListRequest = {
    status: string;
    page: number;
    perpage: number;
    country_code: string;
};

type GiftCardDetailRequest = {
    requestId: string;
};

type GiftCardListResponse = {
    data: GiftCard[];
    status: string;
    pagination: Pagination;
};

type GiftCardDetail = {
    providerName: string;
    giftCardName: string;
    imageUrl: string;
    valueInCents: number;
    currencyCode: string;
    storeUrl?: string;
    expirationDate?: string;
    code?: {
        value: string;
        show: boolean;
    };
    pin?: {
        value: string;
        show: boolean;
    };
    redemption_instructions?: {
        value: string;
        show: boolean;
    };
    prizeoutRedemptionInstructions?: {
        value: string;
        show: boolean;
    };
    qr_code?: {
        value: string;
        show: boolean;
    };
    giftCardTerms?: string;
    redirectUrl?: string;
    barcodeUrl?: string;
    barcodeValue?: string;
    redirect_url?: string;
    claim_url?: string;
    claim_code?: string;
};

type SelectedGiftCard = {
    giftcard?: GiftCard;
    status?: string;
};

type GiftCards = {
    available?: GiftCardListState;
    pending?: GiftCardListState;
    detail?: GiftCardDetail;
    selected?: SelectedGiftCard;
};

type GiftCardListState = {
    giftcards: GiftCard[];
    pagination: Pagination;
};

export type Pagination = {
    current: number;
    pages: number;
    perpage: number;
    total: number;
};

export type GiftCard = {
    cashout_date: string;
    claim_link: string;
    create_date: string;
    currency_code: string;
    giftcard_id: string;
    giftcard_request_id: string;
    image_url: string;
    name: string;
    status: string;
    value_in_cents: number;
};

type Profile = {
    emailPreference?: DeliveryPreference;
    user: SanitizedUserData;
};

export interface DeliveryPreference {
    email: string;
    account_id: string;
    create_date: string;
    delivery_preference_email_id?: string;
}

export type SanitizedUserData = {
    create_date: string;
    date_of_birth?: string;
    email?: string;
    firstname: string;
    gender: string;
    lastname: string;
    super_user_id: string;
    year_of_birth?: string;
};

type GetPointsResponse = Points;

interface GetProfileResponse extends SanitizedUserData {
    emailPreference: DeliveryPreference;
}

type ChangePasswordRequest = {
    currentPassword: string;
    newPassword: string;
};

type AddSecondaryEmailRequest = {
    email: string;
};

type EmailTokenRequest = {
    super_user_email_id: string;
};

type VerifyTokenRequest = {
    super_user_email_id: string;
    token: string;
};

type pointsDataRequest = {
    currencyCode: string;
};
type levelsDataRequest = {
    currencyCode: string;
};

type GetAuctionsLevelsRequest = {
    prizeoutSessionId: string;
};

type CheckoutPointsGiftCardRequest = {
    checkoutValueId: string;
    value_in_cents: string;
    currencyCode: string;
    email: string;
    prizeout_session_id: string;
};

export const checkoutPointsGiftCard = createAsyncThunk(
    'sidebar/checkoutPointsGiftCard',
    async (
        { checkoutValueId, value_in_cents, currencyCode, email, prizeout_session_id }: CheckoutPointsGiftCardRequest,
        { rejectWithValue, signal },
    ) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    giftcard_request_id: uuid_v4(),
                    checkoutValueId,
                    value_in_cents,
                    currencyCode,
                    email,
                    prizeout_session_id,
                },
                endpoint: '/points/checkout',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const updatePreferredEmail = createAsyncThunk(
    'sidebar/updatePreferredEmail',
    async ({ email }: { email: string }, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    email: email,
                },
                endpoint: `/account/poAccount/preferredEmail`,
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const getLevelsAuctionData = createAsyncThunk(
    'sidebar/getAuctionLevels',
    async ({ prizeoutSessionId }: GetAuctionsLevelsRequest, { rejectWithValue, signal }) => {
        try {
            const results = await auctionApi({
                data: {
                    prizeout_session_id: prizeoutSessionId,
                },
                endpoint: '/v2/offers/points',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const getProfileInfo = createAsyncThunk('sidebar/getProfile', async ({}, { rejectWithValue, signal }) => {
    try {
        const results = await sharedAPI.request({
            data: {},
            endpoint: '/account/profile',
            method: 'GET',
            signal: signal,
        });
        return {
            ...results.data,
        };
    } catch (e) {
        rejectWithValue(e.results.data);
    }
});

export const requestEmailConfirmationToken = createAsyncThunk(
    'sidebar/requestEmailConfirmationToken',
    async ({ super_user_email_id }: EmailTokenRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: { super_user_email_id },
                endpoint: '/account/email/sendEmailConfirmationToken',
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const requestEmailVerificationToken = createAsyncThunk(
    'sidebar/requestEmailConfirmationToken',
    async ({ super_user_email_id }: EmailTokenRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: { super_user_email_id },
                endpoint: '/account/email/sendEmailVerificationToken',
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const loadGiftCardList = createAsyncThunk(
    'sidebar/loadGiftCardList',
    async ({ page, perpage, status, country_code }: GiftCardListRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    page,
                    perpage,
                    status,
                    country_code,
                },
                endpoint: '/giftcards/list',
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
                status,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const loadGiftCardDetails = createAsyncThunk(
    'sidebar/loadGiftCardDetails',
    async ({ requestId }: GiftCardDetailRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: { requestId },
                endpoint: '/giftcards/details',
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
                status,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const changePassword = createAsyncThunk(
    'sidebar/changePassword',
    async ({ currentPassword, newPassword }: ChangePasswordRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    currentPassword,
                    newPassword,
                },
                endpoint: '/updatePasswordUsingCurrentPassword',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const addSecondaryEmail = createAsyncThunk(
    'sidebar/addSecondaryEmail',
    async ({ email }: AddSecondaryEmailRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    email,
                },
                endpoint: '/account/email/addSecondaryEmail',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const verifyEmailConfirmationToken = createAsyncThunk(
    'sidebar/verifyEmailConfirmationToken',
    async ({ super_user_email_id, token }: VerifyTokenRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    super_user_email_id,
                    token,
                },
                endpoint: '/account/email/verifyEmailConfirmationToken',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const promoteSecondaryToPrimary = createAsyncThunk(
    'sidebar/promoteSecondaryToPrimary',
    async ({ super_user_email_id }: EmailTokenRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    super_user_email_id,
                },
                endpoint: '/account/email/promoteSecondaryToPrimary',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const verifyEmailVerificationToken = createAsyncThunk(
    'sidebar/verifyEmailVerificationToken',
    async ({ super_user_email_id, token }: VerifyTokenRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    super_user_email_id,
                    token,
                },
                endpoint: '/account/email/verifyEmailVerificationToken',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const loadPointsData = createAsyncThunk(
    'sidebar/loadPointsData',
    async ({ currencyCode }: pointsDataRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    currencyCode: currencyCode,
                },
                endpoint: '/account/points',
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const loadLevelsData = createAsyncThunk(
    'sidebar/loadLevelsData',
    async ({ currencyCode }: levelsDataRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    currencyCode: currencyCode,
                },
                endpoint: '/account/levels',
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

// client-side deduplication to avoid duplicates in our array in case the same page of data is loaded more than once by mistake.
const mergeGiftcardsDeduplicated = function (originalData: GiftCard[], newData: GiftCard[]) {
    const existingIds = originalData.map((gc) => gc.giftcard_request_id);
    newData.map(function (gc) {
        if (!existingIds.includes(gc.giftcard_request_id)) {
            originalData.push(gc);
        }
    });
    return originalData;
};

export const sidebarSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getProfileInfo.fulfilled, (state, action: PayloadAction<GetProfileResponse>) => {
            state.profile = {
                ...state.profile,
                emailPreference: action.payload.emailPreference,
                user: {
                    create_date: action.payload.create_date,
                    date_of_birth: action.payload.date_of_birth,
                    firstname: action.payload.firstname,
                    gender: action.payload.gender,
                    lastname: action.payload.lastname,
                    super_user_id: action.payload.super_user_id,
                    year_of_birth: action.payload.year_of_birth,
                },
            };
        });

        builder.addCase(loadGiftCardDetails.fulfilled, (state, action: PayloadAction<GiftCardDetail>) => {
            state.giftCards.detail = action.payload;
        });

        builder.addCase(loadPointsData.fulfilled, (state, action: PayloadAction<GetPointsResponse>) => {
            state.points = {
                ...state.points,
                balance: action.payload.balance,
                pending: action.payload.pending,
            };
        });

        builder.addCase(getLevelsAuctionData.fulfilled, (state, action: PayloadAction<LevelsGiftCard[]>) => {
            const arr = [];
            for (const i in action.payload) {
                arr.push(action.payload[i]);
            }

            state.points = {
                ...state.points,
                levelsGiftCards: arr,
            };
        });

        builder.addCase(loadLevelsData.fulfilled, (state, action: PayloadAction<GetPointsResponse>) => {
            state.points = {
                ...state.points,
                levels: action.payload.levels,
                nextLevel: action.payload.nextLevel,
                max_level_completed: action.payload.max_level_completed,
            };
        });

        builder.addCase(loadGiftCardList.fulfilled, (state, action: PayloadAction<GiftCardListResponse>) => {
            let mergedList: GiftCard[];

            if (action.payload.status == 'active') {
                if (
                    state.giftCards &&
                    state.giftCards.available &&
                    state.giftCards.available.giftcards &&
                    action.payload.pagination.current !== 1
                ) {
                    mergedList = mergeGiftcardsDeduplicated(state.giftCards.available.giftcards, action.payload.data);
                } else {
                    mergedList = action.payload.data;
                }
                state.giftCards = {
                    ...state.giftCards,
                    available: {
                        giftcards: mergedList,
                        pagination: action.payload.pagination,
                    },
                };
            } else if (action.payload.status == 'pending') {
                if (
                    state.giftCards &&
                    state.giftCards.pending &&
                    state.giftCards.pending.giftcards &&
                    action.payload.pagination.current !== 1
                ) {
                    mergedList = mergeGiftcardsDeduplicated(state.giftCards.pending.giftcards, action.payload.data);
                } else {
                    mergedList = action.payload.data;
                }

                state.giftCards = {
                    ...state.giftCards,
                    pending: {
                        giftcards: mergedList,
                        pagination: action.payload.pagination,
                    },
                };
            }
        });
    },
    initialState: sidebarInitialState,
    name: 'sidebar',
    reducers: {
        setActiveLevel(state, action: PayloadAction<string>) {
            state.points.activeListLevel = action.payload;
        },
        setActiveLevelGiftCard(state, action: PayloadAction<LevelsGiftCard>) {
            state.points.activeLevelGiftCard = action.payload;
        },
        setSelectedGiftcard(state, action: PayloadAction<SelectedGiftCard>) {
            state.giftCards.selected = {
                giftcard: action.payload.giftcard,
                status: action.payload.status,
            };
        },
        setSidebarView(state, action: PayloadAction<SidebarViewEnum>) {
            state.view = action.payload;
        },
        toggleIsSidebarOpen(state) {
            state.isOpen = !state.isOpen;
        },
        toggleIsLoading(state) {
            state.loading = !state.loading;
        },
        clearGiftcardListCache(state) {
            state.giftCards = {};
        },
    },
});

export const {
    setActiveLevel,
    setActiveLevelGiftCard,
    setSelectedGiftcard,
    setSidebarView,
    toggleIsSidebarOpen,
    toggleIsLoading,
    clearGiftcardListCache,
} = sidebarSlice.actions;

const selectSidebarState = ({ sidebar }: RootState): SidebarSlice => sidebar;

export const selectLoading = createSelector(selectSidebarState, ({ loading }) => loading);

export const selectSidebarView = createSelector(selectSidebarState, ({ view }) => view);

export const selectBalance = createSelector(selectSidebarState, ({ points: { balance } }) => balance);

export const selectPendingBalance = createSelector(selectSidebarState, ({ points: { pending } }) => pending);

export const selectNextLevel = createSelector(selectSidebarState, ({ points: { nextLevel } }) => nextLevel);

export const selectLevels = createSelector(selectSidebarState, ({ points: { levels } }) => levels);

export const selectActiveLevelList = createSelector(selectSidebarState, ({ points }) => points?.activeListLevel);

export const selectActiveLevelGiftCard = createSelector(selectSidebarState, ({ points }) => {
    if (points?.activeLevelGiftCard) {
        return points?.activeLevelGiftCard;
    }
});

export const selectLevelGiftCardsAvailable = createSelector(
    selectSidebarState,
    ({ points: { levelsGiftCards } }) => levelsGiftCards,
);

export const selectGiftCardDetailData = createSelector(selectSidebarState, ({ giftCards }) => {
    if (giftCards?.detail) {
        return giftCards?.detail;
    }
});

export const selectSelectedGiftCard = createSelector(selectSidebarState, ({ giftCards }) => {
    if (giftCards?.selected) {
        return giftCards?.selected;
    }
});

export const selectProfileName = createSelector(selectSidebarState, ({ profile }) => {
    if (profile) {
        const { firstname, lastname } = profile?.user;
        return `${firstname} ${lastname}`;
    }
});

export const selectProfileSuperUserId = createSelector(selectSidebarState, ({ profile }) => {
    if (profile) {
        return profile.user.super_user_id;
    }
});

export const selectAvailableGiftCards = createSelector(selectSidebarState, ({ giftCards }) => {
    if (giftCards && giftCards.available) {
        const { giftcards } = giftCards.available;
        return giftcards;
    }
});

export const selectPendingGiftCards = createSelector(selectSidebarState, ({ giftCards }) => {
    if (giftCards && giftCards.pending) {
        const { giftcards } = giftCards.pending;
        return giftcards;
    }
});

export const selectAvailableGiftCardsPagination = createSelector(selectSidebarState, ({ giftCards }) => {
    if (giftCards && giftCards.available) {
        const { pagination } = giftCards.available;
        return pagination;
    }
});

export const selectPendingGiftCardsPagination = createSelector(selectSidebarState, ({ giftCards }) => {
    if (giftCards && giftCards.pending) {
        const { pagination } = giftCards.pending;
        return pagination;
    }
});

export const selectPreferredEmail = createSelector(selectSidebarState, ({ profile }) => {
    if (profile?.emailPreference) {
        const { email } = profile.emailPreference;
        return email;
    } else {
        return '';
    }
});

export const selectPreferredDeliveryPref = createSelector(selectSidebarState, ({ profile }) => {
    if (profile) {
        return profile.emailPreference;
    }
});

export const selectSidebarIsOpen = createSelector(selectSidebarState, ({ isOpen }) => isOpen);

export const selectAccountEmail = createSelector(selectSidebarState, ({ profile }) => {
    if (profile) {
        return profile.emailPreference?.email;
    }
});

export const selectShouldShowButtonEmptyWallet = ({ common: { appModule } }: RootState): boolean => {
    return appModule !== ApplicationModule.ACCOUNTS;
};

export default sidebarSlice.reducer;
