export const uuid_v4 = () => {
    const cryptoObj = window.crypto || (window as any).msCrypto; // For browser environments
    if (cryptoObj && cryptoObj.getRandomValues) {
        const buffer = new Uint8Array(16);
        cryptoObj.getRandomValues(buffer);

        // Set version to 4
        buffer[6] = (buffer[6] & 0x0f) | 0x40;
        // Set variant to RFC4122
        buffer[8] = (buffer[8] & 0x3f) | 0x80;

        return (
            padHex(buffer[0]) +
            padHex(buffer[1]) +
            padHex(buffer[2]) +
            padHex(buffer[3]) +
            '-' +
            padHex(buffer[4]) +
            padHex(buffer[5]) +
            '-' +
            padHex(buffer[6]) +
            padHex(buffer[7]) +
            '-' +
            padHex(buffer[8]) +
            padHex(buffer[9]) +
            '-' +
            padHex(buffer[10]) +
            padHex(buffer[11]) +
            padHex(buffer[12]) +
            padHex(buffer[13]) +
            padHex(buffer[14]) +
            padHex(buffer[15])
        );
    } else {
        // Fallback for environments without crypto.getRandomValues
        console.error('crypto.getRandomValues not supported. Unable to generate UUID.');
        return '';
    }
};

const padHex = (value: number) => {
    return value.toString(16).padStart(2, '0');
};
