import React, { useState } from 'react';
import Classnames from 'classnames';
import { Button } from './button';
import { ButtonType } from './types';
import { useUpdateEffect } from '../../../hooks';

import './plus-minus-toggle-button.scss';

interface PlusMinusToggleButtonProps {
    isDisabled?: boolean; // Set to true if there is a parent that handles the click
    isPlusShowing?: boolean;
    onClick?: () => void;
}

export const PlusMinusToggleButton: React.FC<PlusMinusToggleButtonProps> = ({
    isDisabled,
    isPlusShowing = true,
    onClick,
}): React.ReactElement => {
    const [showPlus, setShowPlus] = useState(isPlusShowing);

    const classes = Classnames('plus-minus-toggle-button__line', {
        'plus-minus-toggle-button__line--collapse': !showPlus,
    });

    const onClickHandler = () => {
        if (onClick) {
            togglePlusShowing();
            onClick();
        }
    };

    const togglePlusShowing = () => {
        setShowPlus(!showPlus);
    };

    // Need this to update in the case the button is wrapped in a parent accordion that opens/closes on its click event
    useUpdateEffect(() => {
        togglePlusShowing();
    }, [isPlusShowing]);

    return (
        <Button
            ariaLabel="Toggle open close button"
            className="plus-minus-toggle-button"
            onClick={() => onClickHandler()}
            isElement={true}
            type={ButtonType.BUTTON}
            testId="plus-minus-toggle-button"
            isDisabled={isDisabled}
        >
            <span data-testid="plus-minus-toggle-button-vertical-line" className={classes}></span>
            <span data-testid="plus-minus-toggle-button-horizontal-line" className={classes}></span>
        </Button>
    );
};
