import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { PaginatedData } from '../../../utils';
import { basePortalQuery } from '../common';
import {
    PortalEventGroups,
    AdminDashboardUsersQuery,
    AdminDashboardUser,
    AddAdminDashboardUserQuery,
    LinkAdminDashboardUserQuery,
    GenericSuccessResponse,
    AdminDashboardPartner,
    AdminDashboardPartnersQuery,
    SwitchDashboardUserLoginTypeQuery,
    SendPasswordResetOTAQuery,
    generatePasswordResetOTAQuery,
    generatePasswordResetOTAResponse,
    AddAdminDashboardPartnerQuery,
    dashboardUserPartnerAccountListQuery,
    dashboardUserPartnerAccount,
    CreateSandboxEnvQuery,
    UpdatePartnerCategoryQuery,
    UpdatePartnerProductVersionQuery,
    updatePartnerProdAllowedQuery,
    AdminDashboardRequest,
    AdminDashboardRequestsQuery,
    getDashboardRequestEmailListQuery,
    AdminDashboardRequestEmailMetadata,
    EmailEventMetadata,
    UserSession,
    AdminDashboardRequestCallbackMetadata,
    RequestCallbackDetails,
    resendGiftCardRequestEmailQuery,
    resendGiftCardRequestEmailResponse,
    GiftCardClaimDetail,
} from './admin-portal-types';

export const portalApiSlice = createApi({
    reducerPath: 'adminPortalApi',
    baseQuery: basePortalQuery,
    tagTypes: [
        PortalEventGroups.PortalDashboardRequestsList,
        PortalEventGroups.PortalDashboardRequestEmailList,
        PortalEventGroups.PortalDashboardUsersList,
        PortalEventGroups.PortalDashboardPartnersList,
        PortalEventGroups.AddPortalDashboardUser,
        PortalEventGroups.LinkPortalDashboardUser,
        PortalEventGroups.SwitchDashboardUserLoginType,
        PortalEventGroups.SendPasswordResetOTA,
        PortalEventGroups.generatePasswordResetOTA,
        PortalEventGroups.AddPortalDashboardPartner,
        PortalEventGroups.CreateSandboxEnv,
        PortalEventGroups.UpdatePartnerCategory,
        PortalEventGroups.UpdatePartnerProductVersion,
        PortalEventGroups.PortalDashboardRequestEmailEventsList,
        PortalEventGroups.PortalDashboardRequestSessionsList,
        PortalEventGroups.resendGiftCardRequestEmail,
        PortalEventGroups.PortalDashboardGiftCardClaimDetails,
        PortalEventGroups.PortalDashboardGiftCardRequestDetails,
        PortalEventGroups.processRequestBooking,
        PortalEventGroups.cancelRequestWithProvider,
        PortalEventGroups.changeRequestStatusToCancelled,
        PortalEventGroups.changeRequestStatusToExchanged,
        PortalEventGroups.stopRequestClaim,
        PortalEventGroups.PortalDashboardRequestsCallbacks,
        PortalEventGroups.PortalDashboardRequestsCallbackDetails,
    ],
    endpoints: (builder) => ({
        getDashboardRequestsList: builder.query<PaginatedData<AdminDashboardRequest[]>, AdminDashboardRequestsQuery>({
            query: (args: AdminDashboardRequestsQuery) => ({
                url: '/admin/inventory/giftcard/requests',
                params: args,
            }),
            providesTags: [PortalEventGroups.PortalDashboardRequestsList],
        }),
        getGiftCardClaimDetails: builder.query<GiftCardClaimDetail, string>({
            query: (giftcardRequestId: string) => ({
                url: `/admin/inventory/giftcard/request/${giftcardRequestId}/claim/details`,
                method: 'GET',
            }),
            extraOptions: { track: false },
            providesTags: [PortalEventGroups.PortalDashboardGiftCardClaimDetails],
        }),
        getGiftCardRequestDetails: builder.query<AdminDashboardRequest, string>({
            query: (giftcardRequestId: string) => ({
                url: `/admin/inventory/giftcard/request/${giftcardRequestId}/details`,
                method: 'GET',
            }),
            extraOptions: { track: false },
            providesTags: [PortalEventGroups.PortalDashboardGiftCardRequestDetails],
        }),

        processRequestBooking: builder.mutation<GiftCardClaimDetail, string>({
            query: (giftCardRequestId: string) => ({
                url: `/admin/inventory/giftcard/request/${giftCardRequestId}/booking/process`,
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.processRequestBooking],
        }),
        cancelRequestWithProvider: builder.mutation<GiftCardClaimDetail, string>({
            query: (giftCardRequestId: string) => ({
                url: `/admin/inventory/giftcard/request/${giftCardRequestId}/actions/cancel`,
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.cancelRequestWithProvider],
        }),
        changeRequestStatusToCancelled: builder.mutation<GiftCardClaimDetail, string>({
            query: (giftCardRequestId: string) => ({
                url: `/admin/inventory/giftcard/request/${giftCardRequestId}/status/cancel`,
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.changeRequestStatusToCancelled],
        }),
        changeRequestStatusToExchanged: builder.mutation<GiftCardClaimDetail, string>({
            query: (giftCardRequestId: string) => ({
                url: `/admin/inventory/giftcard/request/${giftCardRequestId}/actions/exchange`,
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.changeRequestStatusToExchanged],
        }),
        stopRequestClaim: builder.mutation<GenericSuccessResponse, string>({
            query: (giftCardRequestId: string) => ({
                url: `/admin/inventory/giftcard/request/${giftCardRequestId}/claim/stop`,
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.stopRequestClaim],
        }),

        getDashboardRequestEmailList: builder.query<
            AdminDashboardRequestEmailMetadata[],
            getDashboardRequestEmailListQuery
        >({
            query: (args: getDashboardRequestEmailListQuery) => ({
                url: '/admin/emails/related',
                method: 'GET',
                params: args,
            }),
            extraOptions: { track: false },
            providesTags: [PortalEventGroups.PortalDashboardRequestEmailList],
        }),
        resendGiftCardRequestEmail: builder.mutation<
            resendGiftCardRequestEmailResponse,
            resendGiftCardRequestEmailQuery
        >({
            query: (args: resendGiftCardRequestEmailQuery) => ({
                url: '/admin/emails/resend',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.resendGiftCardRequestEmail],
        }),
        getDashboardRequestEmailEventsList: builder.query<EmailEventMetadata[], getDashboardRequestEmailListQuery>({
            query: (args: getDashboardRequestEmailListQuery) => ({
                url: '/admin/emails/related/events',
                method: 'GET',
                params: args,
            }),
            extraOptions: { track: false },
            providesTags: [PortalEventGroups.PortalDashboardRequestEmailEventsList],
        }),
        getDashboardRequestSessionsList: builder.query<UserSession[], string>({
            query: (userId: string) => ({
                url: `/admin/prizeoutUser/${userId}/sessions`,
                method: 'GET',
            }),
            extraOptions: { track: false },
            providesTags: [PortalEventGroups.PortalDashboardRequestSessionsList],
        }),
        getGiftCardRequestCallbacks: builder.query<AdminDashboardRequestCallbackMetadata[], string>({
            query: (requestId: string) => ({
                url: '/admin/callbacks',
                method: 'GET',
                params: {
                    requestId,
                },
            }),
            extraOptions: { track: false },
            providesTags: [PortalEventGroups.PortalDashboardRequestsCallbacks],
        }),
        getGiftCardRequestCallbackDetails: builder.query<RequestCallbackDetails[], string>({
            query: (requestId: string) => ({
                url: '/admin/callbacks/full',
                method: 'GET',
                params: {
                    requestId,
                },
            }),
            extraOptions: { track: false },
            providesTags: [PortalEventGroups.PortalDashboardRequestsCallbackDetails],
        }),
        getDashboardUsersList: builder.query<PaginatedData<AdminDashboardUser[]>, AdminDashboardUsersQuery>({
            query: (args: AdminDashboardUsersQuery) => ({
                url: '/admin/dashboardUser/list',
                params: args,
            }),
            providesTags: [PortalEventGroups.PortalDashboardUsersList],
        }),
        addDashboardUser: builder.mutation<GenericSuccessResponse, AddAdminDashboardUserQuery>({
            query: (args: AddAdminDashboardUserQuery) => ({
                url: '/admin/dashboardUser',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.AddPortalDashboardUser],
        }),
        linkDashboardUser: builder.mutation<GenericSuccessResponse, LinkAdminDashboardUserQuery>({
            query: (args: LinkAdminDashboardUserQuery) => ({
                url: '/admin/dashboardUser/partnerLink',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.LinkPortalDashboardUser],
        }),
        getDashboardPartnersList: builder.query<PaginatedData<AdminDashboardPartner[]>, AdminDashboardPartnersQuery>({
            query: (args: AdminDashboardPartnersQuery) => ({
                url: '/admin/partnerList',
                params: args,
            }),
            providesTags: [PortalEventGroups.PortalDashboardPartnersList],
        }),
        switchDashboardUserLoginType: builder.mutation<AdminDashboardUser, SwitchDashboardUserLoginTypeQuery>({
            query: (args: SwitchDashboardUserLoginTypeQuery) => ({
                url: '/admin/dashboardUser/loginType',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.SwitchDashboardUserLoginType],
        }),
        SendPasswordResetOTA: builder.mutation<GenericSuccessResponse, SendPasswordResetOTAQuery>({
            query: (args: SendPasswordResetOTAQuery) => ({
                url: '/admin/dashboardUser/sendPasswordResetOTA',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.SendPasswordResetOTA],
        }),
        generatePasswordResetOTA: builder.mutation<generatePasswordResetOTAResponse, generatePasswordResetOTAQuery>({
            query: (args: generatePasswordResetOTAQuery) => ({
                url: '/admin/dashboardUser/generatePasswordResetOTA',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.generatePasswordResetOTA],
        }),
        // Partners the user has access to + perms
        getDashboardUserAccountList: builder.query<
            PaginatedData<dashboardUserPartnerAccount[]>,
            dashboardUserPartnerAccountListQuery
        >({
            query: (args: dashboardUserPartnerAccountListQuery) => ({
                url: '/admin/dashboardUser/accountList',
                params: args,
            }),
        }),
        addDashboardPartner: builder.mutation<GenericSuccessResponse, AddAdminDashboardPartnerQuery>({
            query: (args: AddAdminDashboardPartnerQuery) => ({
                url: '/admin/partner/masterAccount',
                method: 'POST',
                body: args,
            }),
        }),
        createSandboxEnv: builder.mutation<GenericSuccessResponse, CreateSandboxEnvQuery>({
            query: (args: CreateSandboxEnvQuery) => ({
                url: '/admin/partner/sandbox/createSandboxEnv',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.CreateSandboxEnv],
        }),
        updatePartnerCategory: builder.mutation<AdminDashboardPartner, UpdatePartnerCategoryQuery>({
            query: (args: UpdatePartnerCategoryQuery) => ({
                url: '/admin/partner/configs/partnerCategory',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.UpdatePartnerCategory],
        }),
        updatePartnerProductVersion: builder.mutation<AdminDashboardPartner, UpdatePartnerProductVersionQuery>({
            query: (args: UpdatePartnerProductVersionQuery) => ({
                url: '/admin/partner/configs/productVersion',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.UpdatePartnerProductVersion],
        }),
        updatePartnerProdAllowed: builder.mutation<AdminDashboardPartner, updatePartnerProdAllowedQuery>({
            query: (args: updatePartnerProdAllowedQuery) => ({
                url: '/admin/partner/configs/prodAllowed',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.UpdatePartnerProductVersion],
        }),
    }),
});

export const {
    useProcessRequestBookingMutation,
    useCancelRequestWithProviderMutation,
    useChangeRequestStatusToCancelledMutation,
    useChangeRequestStatusToExchangedMutation,
    useStopRequestClaimMutation,
    useGetDashboardRequestsListQuery,
    useLazyGetDashboardRequestEmailListQuery,
    useLazyGetDashboardRequestEmailEventsListQuery,
    useLazyGetDashboardRequestSessionsListQuery,
    useResendGiftCardRequestEmailMutation,
    useLazyGetGiftCardClaimDetailsQuery,
    useLazyGetGiftCardRequestDetailsQuery,
    useLazyGetGiftCardRequestCallbackDetailsQuery,
    useGetDashboardUsersListQuery,
    useAddDashboardUserMutation,
    useLinkDashboardUserMutation,
    useGetDashboardPartnersListQuery,
    useSendPasswordResetOTAMutation,
    useGetDashboardUserAccountListQuery,
    useGeneratePasswordResetOTAMutation,
    useSwitchDashboardUserLoginTypeMutation,
    useAddDashboardPartnerMutation,
    useCreateSandboxEnvMutation,
    useUpdatePartnerCategoryMutation,
    useUpdatePartnerProductVersionMutation,
    useUpdatePartnerProdAllowedMutation,
} = portalApiSlice;
