import React, { useState } from 'react';
import Classnames from 'classnames';
import { GenericModal } from '.';
import {
    PartnerUserBankAccount,
    selectCurrentBankAccount,
    selectUserBankAccounts,
    setSelectedUserBankAccount,
} from '../../../store/slices/partner-config-slice';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/widget/widget';
import { GiftingTrackingTypes, CashbackTransferTrackingTypes, TrackingService } from '../../../utils/services/tracking';
import { selectGiftingCheckoutValueId } from '../../../store/slices/gifting-slice';
import { selectIsGiftingMode } from '../../../store/slices/sdk-slice';
import { CurrencyFormatter, PaymentSecureTag } from '../ui-widgets';
import { convertCentsToDollars } from '../../../utils';
import { RadioButtonListLayout, RadioButtonListItem } from '../radio-button';

import './bank-account-selector-modal.scss';

interface BankAccountSelectorModalProps {
    isOpen: boolean;
    onClose: () => void;
    shouldShowBalance?: boolean;
}

export const BankAccountSelectorModal: React.FC<BankAccountSelectorModalProps> = ({
    isOpen,
    onClose,
    shouldShowBalance = true,
}): React.ReactElement => {
    const bankAccountOptions = useAppSelector(selectUserBankAccounts);
    const dispatch = useDispatch<AppDispatch>();
    const currentBankAccount = useAppSelector(selectCurrentBankAccount);
    const [selectedBankAccount, setSelectedBankAccount] = useState(currentBankAccount);
    const isGiftingMode = useAppSelector(selectIsGiftingMode);
    const trackingService = TrackingService.getTrackingService();
    const giftingCheckoutValueId = useAppSelector(selectGiftingCheckoutValueId);

    const closeAndResetBankAccountSelectorModal = () => {
        setSelectedBankAccount(currentBankAccount);
        onClose();
    };

    const radioWrapperClassNames = Classnames('bank-account-selector-modal__radio-wrapper', {
        'bank-account-selector-modal__radio-wrapper--with-balance': shouldShowBalance,
    });

    const setBankAccount = async () => {
        if (isGiftingMode) {
            trackingService.trackGiftingEvent(GiftingTrackingTypes.CONFIRM_ACCOUNT_SELECTION, {
                checkout_flow_id: giftingCheckoutValueId,
            });
        }
        trackingService.trackCashbackTransferAction(CashbackTransferTrackingTypes.SELECT_ACCOUNT_CONFIRM_CASHBACK, {
            host_account_id: selectedBankAccount.id,
            balance: selectedBankAccount.balance,
        });

        dispatch(setSelectedUserBankAccount(selectedBankAccount));
        onClose();
    };

    const setSelectedAccountById = (bankAccountId: string) => {
        const selectedAccount = bankAccountOptions.find(({ id }) => id === bankAccountId);
        if (selectedAccount) {
            setSelectedBankAccount(selectedAccount);
        }
    };

    const renderRadioContent = (bankAccount: PartnerUserBankAccount) => {
        return (
            <div className={radioWrapperClassNames}>
                <p className="bank-account-selector-modal__account">
                    {bankAccount.name} ****{bankAccount.last4}
                </p>
                {shouldShowBalance && (
                    <p data-testid="bank-account-selector-modal-balance">
                        <CurrencyFormatter
                            value={parseFloat(convertCentsToDollars(bankAccount.balance))}
                            decimals={true}
                            inline={true}
                        />
                    </p>
                )}
            </div>
        );
    };

    return (
        <>
            <GenericModal
                ariaLabelledById="bank-account-selector-headline"
                ariaLabel="Select a bank account"
                customClassName="bank-account-selector-modal"
                hasExitButton={true}
                headline="Select an account"
                isOpen={isOpen}
                onClose={closeAndResetBankAccountSelectorModal}
                primaryButtonText="Select account"
                primaryOnClick={setBankAccount}
                primaryTestId="bank-account-selector-done"
                subheadline="This is the account that will be used."
                testId="bank-account-selector-modal"
            >
                <div className="bank-account-selector-modal__content">
                    <PaymentSecureTag />
                    <RadioButtonListLayout>
                        {bankAccountOptions?.map((bankAccount: PartnerUserBankAccount) => (
                            <li key={bankAccount.id}>
                                <RadioButtonListItem
                                    isChecked={selectedBankAccount ? bankAccount.id === selectedBankAccount.id : false}
                                    onChange={(event) => setSelectedAccountById(event.target.value)}
                                    name="bank-account-selector"
                                    value={bankAccount.id}
                                    ichildren={renderRadioContent(bankAccount)}
                                    title={bankAccount.name}
                                    id={`testid-${bankAccount.id}`}
                                />
                            </li>
                        ))}
                    </RadioButtonListLayout>
                </div>
            </GenericModal>
        </>
    );
};
