import React from 'react';
import Classnames from 'classnames';
import { ClickableCard } from '../cards';
import './alert-callout.scss';

export enum AlertCalloutMessageTypes {
    ERROR = 'error',
    REWARDS = 'rewards',
    CASHBACK = 'cashback',
    SUCCESS = 'success',
    WARNING = 'warning',
    INFO = 'info',
}
interface AlertCalloutProps {
    className?: string;
    hasBorder?: boolean;
    ariaDescribedById?: string; // Only need this if displayed inside a modal
    isInline?: boolean;
    header?: string;
    message: string;
    onClick?: () => void;
    supportingBoldText?: string;
    testId?: string;
    type?: AlertCalloutMessageTypes;
    disableTracking?: boolean;
}

export const AlertCallout: React.FC<AlertCalloutProps> = ({
    className,
    hasBorder,
    ariaDescribedById,
    isInline,
    header,
    message,
    supportingBoldText,
    testId,
    type,
    onClick,
    disableTracking = false,
}): React.ReactElement => {
    const classes: string = Classnames(
        `alert-callout`,
        `alert-callout--${type}`,
        { 'alert-callout--border': hasBorder },
        { 'alert-callout--inline': isInline },
        { 'alert-callout--clickable': onClick },
        className,
    );

    const defaultTestId = 'alert-callout';

    let iconClassList;

    switch (type) {
        case AlertCalloutMessageTypes.ERROR:
        case AlertCalloutMessageTypes.WARNING:
            iconClassList = 'fal fa-exclamation-circle';
            break;

        case AlertCalloutMessageTypes.SUCCESS:
            iconClassList = 'fak fa-prizeout-check-solid';
            break;

        case AlertCalloutMessageTypes.CASHBACK:
            iconClassList = 'fak fa-prizeout-bonus-tag-percentage';
            break;

        case AlertCalloutMessageTypes.REWARDS:
            iconClassList = 'fak fa-prizeout-star-solid';
            break;

        case AlertCalloutMessageTypes.INFO:
            iconClassList = 'fak fa-prizeout-info';
            break;
        default:
            iconClassList = 'fal fa-exclamation-circle';
    }

    return (
        <ClickableCard
            cardName="alert-callout-card"
            onClick={onClick}
            isDisabled={!onClick}
            testId="alert-callout-clickable-card"
            disableTracking={disableTracking}
        >
            <div data-testid={testId || defaultTestId} className={classes} role="alert" aria-atomic="true">
                <div className="alert-callout__icon">
                    <i className={iconClassList}></i>
                </div>
                <div className="alert-callout__message">
                    {header && <strong>{header}</strong>}
                    <p id={ariaDescribedById}>{message}</p>
                    {supportingBoldText && (
                        <p>
                            <b>{supportingBoldText}</b>
                        </p>
                    )}
                </div>

                {onClick && <i className="fak fa-prizeout-forward-arrow alert-callout__arrow"></i>}
            </div>
        </ClickableCard>
    );
};
