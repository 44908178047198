import React from 'react';
import { displayOfferType, PrizeoutOfferValueOptions } from '../../../../store/slices/offers-slice';
import { convertCentsToDollars, formatDate, fixDecimals } from '../../../../utils/helpers';
import CurrencyFormatter from '../../../common/ui-widgets/currency-formatter';
import {
    selectWithdrawalTerm,
    selectGiftCardValueTerm,
    selectBalanceCurrencyCopy,
} from '../../../../store/slices/partner-config-slice';
import { useAppSelector } from '../../../../hooks';
import Classnames from 'classnames';
import { ToolTip, ToolTipTriggerTypes } from '../../../common';

import './calculation.scss';

interface CalculationProps {
    activeGiftCardOption: PrizeoutOfferValueOptions | null;
    calculationCurrency?: string;
    className?: string;
    isSummary?: boolean;
    offerType?: displayOfferType;
}

export const Calculation: React.FC<CalculationProps> = ({
    activeGiftCardOption,
    calculationCurrency,
    className,
    isSummary,
    offerType,
}): React.ReactElement => {
    const withdrawalTerm = useAppSelector(selectWithdrawalTerm);
    const valueTerm = useAppSelector(selectGiftCardValueTerm);
    const showBonus = activeGiftCardOption?.display_bonus > 0;
    const showPercentageBonus = showBonus && !activeGiftCardOption?.display_monetary_bonus;
    const shouldShowExpirationDate = activeGiftCardOption?.expires;
    const expirationDate = activeGiftCardOption?.expiration_date;
    const totalClass = Classnames('subheading', { 'text-highlight font-bold': !showBonus });
    const balanceCurrencyCopy = useAppSelector(selectBalanceCurrencyCopy);
    const classes = Classnames('calculation', className);

    const renderUserDiscount = () => {
        if (activeGiftCardOption.display_monetary_bonus) {
            return (
                <CurrencyFormatter
                    inline={true}
                    value={activeGiftCardOption.display_monetary_bonus}
                    currencyOverride={calculationCurrency}
                />
            );
        }

        const activeDisplayBonus = activeGiftCardOption.display_bonus;
        const shouldShowDecimals = !Number.isInteger(activeDisplayBonus);
        const signPrefix = offerType === displayOfferType.CASHBACK ? '' : '+';
        return signPrefix + fixDecimals(activeDisplayBonus, shouldShowDecimals ? 2 : 0) + '%';
    };

    const renderExpirationDetails = () => {
        if (expirationDate) {
            return `This card expires on ${formatDate(expirationDate)}`;
        } else {
            return `View the expiration date on the gift card details page`;
        }
    };

    const renderCostRow = () => (
        <div className="calculation__row">
            <div className="calculation__column">
                <p className="subheading">{withdrawalTerm}</p>
            </div>
            <div className="calculation__column">
                {activeGiftCardOption.currency_converted_cost_in_cents && (
                    <p className="subheading">
                        (
                        <CurrencyFormatter
                            inline={true}
                            decimals={true}
                            value={parseFloat(
                                convertCentsToDollars(activeGiftCardOption.currency_converted_cost_in_cents),
                            )}
                            currencyOverride={activeGiftCardOption.currency_conversion_code}
                        />
                        )
                    </p>
                )}
                <p className="subheading">
                    <CurrencyFormatter
                        inline={true}
                        decimals={true}
                        value={parseFloat(convertCentsToDollars(activeGiftCardOption.cost_in_cents))}
                        currencyOverride={calculationCurrency}
                        currencySymbolOverride={
                            activeGiftCardOption.currency_converted_cost_in_cents ? null : balanceCurrencyCopy
                        }
                    />
                </p>
            </div>
        </div>
    );

    const renderValueRow = () => (
        <div className="calculation__row">
            <div className="calculation__column">
                <p className={totalClass}>{valueTerm}</p>
            </div>
            <div className="calculation__column">
                <p className={totalClass}>
                    <CurrencyFormatter
                        inline={true}
                        decimals={true}
                        value={parseFloat(convertCentsToDollars(activeGiftCardOption.value_in_cents))}
                        currencyOverride={calculationCurrency}
                    />
                </p>
            </div>
        </div>
    );

    const renderDiscountRow = () => {
        if (activeGiftCardOption.value_in_cents - activeGiftCardOption.cost_in_cents === 0) {
            return;
        }
        const label: string = offerType === displayOfferType.CASHBACK ? 'Instant cashback' : 'Prizeout bonus';
        const tooltip: string =
            offerType === displayOfferType.CASHBACK
                ? 'This is the extra money given instantly on purchase'
                : 'This is the extra money you get with Prizeout';
        return (
            <>
                <div className="calculation__row">
                    <div className="calculation__column">
                        <div>
                            <p className="subheading">
                                <strong className="text-highlight">
                                    {label} {showPercentageBonus && `(${renderUserDiscount()})`}
                                </strong>
                            </p>
                            {!isSummary && (
                                <ToolTip
                                    content={tooltip}
                                    tipTrigger={ToolTipTriggerTypes.CLICK}
                                    modalHeadline={label}
                                />
                            )}
                        </div>
                    </div>
                    <div className="calculation__column">
                        <p className="subheading">
                            <strong className="text-highlight">
                                <CurrencyFormatter
                                    inline={true}
                                    decimals={true}
                                    value={parseFloat(
                                        convertCentsToDollars(
                                            activeGiftCardOption.value_in_cents - activeGiftCardOption.cost_in_cents,
                                        ),
                                    )}
                                    currencyOverride={calculationCurrency}
                                />
                            </strong>
                        </p>
                    </div>
                </div>
                {isSummary && <p>{tooltip}</p>}
            </>
        );
    };

    return (
        <section className={classes}>
            {activeGiftCardOption && (
                <>
                    {isSummary && <>{renderDiscountRow()}</>}
                    {!isSummary && (
                        <>
                            {offerType === displayOfferType.CASHBACK && (
                                <>
                                    {renderValueRow()}
                                    {renderDiscountRow()}
                                    {renderCostRow()}
                                </>
                            )}
                            {offerType !== displayOfferType.CASHBACK && (
                                <>
                                    {renderCostRow()}
                                    {renderDiscountRow()}
                                    {renderValueRow()}
                                </>
                            )}
                            {shouldShowExpirationDate && (
                                <small className="calculation__expiration">{renderExpirationDetails()}</small>
                            )}
                        </>
                    )}
                </>
            )}
        </section>
    );
};
