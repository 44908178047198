import { LoggingSubject, testLog } from '../../../../../../utils/logging/test-logging';
import {
    DOMListenerResult,
    changeInput,
    clickElement,
    delayedPromise,
    isElementValid,
    listenForMutation,
    listenOnFirstChangeEvent,
    waitForElement,
} from '../dom/dom-helpers';
import {
    CheckInputValueHandlerArgs,
    ClickElementHandlerArgs,
    FailHandlerArgs,
    StepActionHandlerList,
    TypeTextHandlerArgs,
    WaitForElementHandlerArgs,
    WaitHandlerArgs,
    WaitUntilConditionHandlerArgs,
} from './types';
import { StepActionName, SymbolTable, WaitResultAction } from '../types';
import { evalConditionExpression, getConditionTarget } from './condition-helpers';

export const DEFAULT_CONDITION_WAIT_INTERVAL = 200;
export const DEFAULT_CONDITION_WAIT_RETRIES = 10;

const maybeGetTargetElement = (symbolTable: SymbolTable, targetName: string): HTMLElement => {
    const accessor = symbolTable.targets[targetName];

    if (!accessor) {
        return null;
    }

    return accessor() as HTMLElement;
};

export const actionHandlers: StepActionHandlerList = {
    [StepActionName.CHECK_INPUT_VALUE]: async ({
        symbolTable,
        target,
        text,
        waitCheck,
    }: CheckInputValueHandlerArgs) => {
        if (!target) {
            throw new Error(`${StepActionName.CHECK_INPUT_VALUE} requires a target`);
        }

        if (!text) {
            throw new Error(`${StepActionName.CHECK_INPUT_VALUE} requires a text value`);
        }

        const { interval = DEFAULT_CONDITION_WAIT_INTERVAL, retries = DEFAULT_CONDITION_WAIT_RETRIES } =
            waitCheck || {};

        testLog(LoggingSubject.RULESET_PROCESSING, 'StepActionName.CHECK_INPUT_VALUE', target, text);

        let retry = retries;

        while (retry > 0) {
            const targetElement = maybeGetTargetElement(symbolTable, target) as HTMLInputElement | HTMLTextAreaElement;

            if (targetElement) {
                await listenOnFirstChangeEvent(targetElement, undefined, interval);
            } else {
                await delayedPromise(interval);
            }

            if (isElementValid(targetElement as HTMLInputElement | HTMLTextAreaElement, text)) {
                return;
            }

            retry--;
        }

        throw new Error(`Target "${target}" is not valid or does not match "${text}"`);
    },
    [StepActionName.CLICK_ELEMENT]: async ({ symbolTable, target }: ClickElementHandlerArgs) => {
        if (!target) {
            throw new Error(`${StepActionName.CLICK_ELEMENT} requires a target`);
        }

        testLog(LoggingSubject.RULESET_PROCESSING, 'StepActionName.CLICK_ELEMENT', target);
        const targetElement = maybeGetTargetElement(symbolTable, target);

        if (!targetElement) {
            throw new Error(`Target "${target}" not found`);
        }

        await clickElement(targetElement);

        return;
    },
    [StepActionName.FAIL]: async ({ message }: FailHandlerArgs) => {
        testLog(LoggingSubject.RULESET_PROCESSING, 'StepActionName.TYPE_TEXT', message);
        throw new Error(message);
    },
    [StepActionName.TYPE_TEXT]: async ({ symbolTable, target, text }: TypeTextHandlerArgs) => {
        if (!target) {
            throw new Error(`${StepActionName.TYPE_TEXT} requires a target`);
        }

        if (!text) {
            throw new Error(`${StepActionName.TYPE_TEXT} requires a text value`);
        }

        testLog(LoggingSubject.RULESET_PROCESSING, 'StepActionName.TYPE_TEXT', target, text);
        const targetElement = maybeGetTargetElement(symbolTable, target) as HTMLInputElement | HTMLTextAreaElement;

        if (!targetElement || !['input', 'textarea'].includes(targetElement.tagName.toLowerCase())) {
            throw new Error(`Target "${target}" not found`);
        }

        await changeInput(targetElement, text);

        return;
    },
    [StepActionName.WAIT]: async ({ duration }: WaitHandlerArgs) => {
        if (!duration) {
            throw new Error(`${StepActionName.WAIT} requires a duration`);
        }

        testLog(LoggingSubject.RULESET_PROCESSING, 'StepActionName.WAIT');
        await delayedPromise(duration);
        return;
    },
    [StepActionName.WAIT_FOR_ELEMENT]: async ({ symbolTable, target }: WaitForElementHandlerArgs) => {
        if (!target) {
            throw new Error(`${StepActionName.WAIT_FOR_ELEMENT} requires a target`);
        }

        testLog(LoggingSubject.RULESET_PROCESSING, 'StepActionName.WAIT_FOR_ELEMENT', target);
        const targetElement = await waitForElement(() => {
            return maybeGetTargetElement(symbolTable, target) as HTMLInputElement | HTMLTextAreaElement;
        });

        if (!targetElement) {
            throw new Error(`Target "${target}" not found`);
        }

        return;
    },
    [StepActionName.WAIT_UNTIL_CONDITION]: async ({
        symbolTable,
        target,
        condition,
        message,
        mutateTarget,
        onTrue,
        interval = DEFAULT_CONDITION_WAIT_INTERVAL,
        retries,
    }: WaitUntilConditionHandlerArgs) => {
        if (!condition) {
            throw new Error(`${StepActionName.WAIT_UNTIL_CONDITION} requires a condition`);
        }

        if (!onTrue) {
            throw new Error(`${StepActionName.WAIT_UNTIL_CONDITION} requires onTrue`);
        }

        testLog(
            LoggingSubject.RULESET_PROCESSING,
            'StepActionName.WAIT_UNTIL_CONDITION',
            condition,
            interval,
            message,
            mutateTarget,
            onTrue,
            retries,
            target,
        );

        target = target || getConditionTarget(symbolTable, condition) || Object.keys(symbolTable.targets)[0];
        mutateTarget = mutateTarget || target;
        message = message || `Failed on waiting for "${condition}"`;

        let retry = retries;

        while (retry > 0) {
            const targetElement = maybeGetTargetElement(symbolTable, target) as HTMLInputElement | HTMLTextAreaElement;
            const changePromise = targetElement
                ? listenOnFirstChangeEvent(targetElement, undefined, interval)
                : delayedPromise(interval);

            const mutateTargetElement = maybeGetTargetElement(symbolTable, mutateTarget);
            const mutatePromise = mutateTargetElement
                ? listenForMutation(mutateTargetElement, interval)
                : delayedPromise(interval);

            await Promise.any([changePromise, mutatePromise]).then((resolution: DOMListenerResult) => {
                return resolution;
            });

            const result = evalConditionExpression(symbolTable, condition);

            if (result) {
                if (onTrue === WaitResultAction.FAIL) {
                    throw new Error(message);
                }

                return;
            }

            retry--;
        }

        if (onTrue === WaitResultAction.FAIL) {
            return;
        }

        throw new Error(message);
    },
};
