export const isInputElement = (element: Element): element is HTMLInputElement => {
    return element instanceof HTMLInputElement;
};

export const isElement = (element: Element | Document): element is Element => {
    return element instanceof Element;
};

export const isHTMLElement = (element: Element | EventTarget): element is HTMLElement => {
    return element instanceof HTMLElement;
};

export const isNumber = (value: unknown): value is number => {
    // Check that value is defined.  Do not use `!==` to be sure it matches both null and undefined
    return value != null && typeof value === 'number';
};

export const isString = (value: unknown): value is string => {
    // Check that value is defined.  Do not use `!==` to be sure it matches both null and undefined
    return value != null && typeof value === 'string';
};

export const isNotDefined = (value: unknown): boolean => {
    return value === null || value === undefined;
};
