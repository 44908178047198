import { ConditionName, SymbolTable } from '../types';

const parseConditionExpression = (conditionExpression: string): [ConditionName, boolean] => {
    const parsedCondition = conditionExpression.split(/\s+/);
    const conditionName = parsedCondition.pop();
    const negate = parsedCondition.length ? parsedCondition[0] === 'not' : false;

    return [conditionName, negate];
};

export const evalConditionExpression = (symbolTable: SymbolTable, conditionExpression: string) => {
    const [conditionName, negate] = parseConditionExpression(conditionExpression);
    const condition = symbolTable.conditions[conditionName];

    if (!condition) {
        throw new Error(`Condition "${conditionExpression}" does not exist"`);
    }

    return condition() ? !negate : negate;
};

export const getConditionTarget = (symbolTable: SymbolTable, conditionExpression: string): string => {
    const [conditionName] = parseConditionExpression(conditionExpression);
    const ruleCondition = symbolTable.ruleset.conditions[conditionName];
    return ruleCondition.target;
};
