import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector, usePersistentAccessTokens } from '../../../hooks';
import { selectIsAccounts, toggleIsLoading } from '../../../store/slices/common-slice';
import { selectUserPrizeoutSession } from '../../../store/slices/partner-config-slice';
import { resetSDKParams } from '../../../store/slices/sdk-slice';
import { authenticateWidgetUser, selectAccessTokenGlobalSetAt } from '../../../store/slices/verification-slice';
import { AppDispatch } from '../../../store/widget/widget';
import { asyncRetry } from '../../../utils/helpers';
import SDKMessenger from '../../../utils/services/sdk';
import { ConfirmationModal } from '../../common/modals';
import { TrackingService } from '../../../utils/services/tracking';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../utils/helpers/routing-helpers';

const TokenExpirationWarning: React.FC = (): React.ReactElement => {
    const [isTokenWarningModalShowing, setIsTokenWarningModalShowing] = useState(false);
    const oneHourJWTLimitInMS = 1000 * 60 * 60;
    const dispatch = useDispatch<AppDispatch>();
    const trackingService = TrackingService.getTrackingService();
    const sdkMessenger = SDKMessenger.getSDKMessengerService();
    const sessionId = useAppSelector(selectUserPrizeoutSession);
    const [isRefreshingToken, setIsRefreshingToken] = useState(false);
    const tokenSetTime = Number(useAppSelector(selectAccessTokenGlobalSetAt));
    const isAccounts = useAppSelector(selectIsAccounts);
    const [{ clearPersistentAccessTokens }] = usePersistentAccessTokens();
    const navigate = useNavigate();

    useEffect(() => {
        const thirtyMinuteInterval = 1000 * 60 * 30;
        const authenticationInterval = setInterval(() => {
            const now = new Date().getTime();
            const tokenLifeTimeInMS = now - tokenSetTime;

            if (
                !!tokenSetTime &&
                !isRefreshingToken &&
                !isTokenWarningModalShowing &&
                tokenLifeTimeInMS > oneHourJWTLimitInMS
            ) {
                setIsTokenWarningModalShowing(true);
            }
        }, thirtyMinuteInterval);
        return () => clearInterval(authenticationInterval);
    }, []);

    const refreshJWT = async () => {
        dispatch(toggleIsLoading());
        setIsRefreshingToken(true);
        await asyncRetry(() => dispatch(authenticateWidgetUser({ sessionId: sessionId })).unwrap());
        setIsTokenWarningModalShowing(false);
        setIsRefreshingToken(false);
        dispatch(toggleIsLoading());
    };

    const exitPrizeout = async () => {
        dispatch(toggleIsLoading());
        await trackingService.trackHomeClickExitPrizeout('/Session-Expiration-Modal');

        if (isAccounts) {
            clearPersistentAccessTokens();
            navigate(routes.DTC_HOME);
        } else {
            dispatch(resetSDKParams());
            setIsTokenWarningModalShowing(false);
            sdkMessenger.closePrizeout();
        }
    };

    return (
        <>
            <ConfirmationModal
                headline="Your session is about to expire"
                subheadline="Would you like to continue your session?"
                confirmButtonText="Continue"
                cancelButtonText="Exit"
                cancelOnClick={() => exitPrizeout()}
                confirmOnClick={() => refreshJWT()}
                isConfirmationModalOpen={isTokenWarningModalShowing}
            />
        </>
    );
};

export default TokenExpirationWarning;
