export enum OnboardingTagGroups {
    ONBOARDING = 'onboarding',
}

export interface OnboardingChecklistItem {
    itemTitle: string;
    cashbackAmount?: number;
    isCompleted: boolean;
    cta?: string;
}
