import { useDispatch } from 'react-redux';

import { AppDispatch } from '../store/widget/widget';
import { asyncRetry } from '../utils/helpers';
import { selectUserPrizeoutSession } from '../store/slices/partner-config-slice';
import { getSdkParams } from '../store/slices/sdk-slice';
import { useState } from 'react';
import { useAppSelector } from './use-app-selector';
import { getOffers, RecContext, selectOffers } from '../store/slices/offers-slice';
import { TrackingService } from '../utils/services/tracking';

export function useLoadOffers() {
    const dispatch = useDispatch<AppDispatch>();
    const offers = useAppSelector(selectOffers);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const sdkParams = useAppSelector(getSdkParams);
    const prizeoutSessionId = useAppSelector(selectUserPrizeoutSession);
    const trackingService = TrackingService.getTrackingService();

    const loadOffers = async (recContext: RecContext = RecContext.HOME_PAGE) => {
        if (offers?.length < 1) {
            try {
                setIsError(false);
                setIsLoading(true);
                const sessionId = sdkParams.session_id;

                await asyncRetry(() =>
                    dispatch(
                        getOffers({
                            prizeoutSessionId,
                            recContext,
                            sessionId,
                        }),
                    ).unwrap(),
                );
                const tracer = sessionStorage.getItem('tracer');
                trackingService.trackHomepageOffersComplete(tracer, recContext);
            } catch (e) {
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return {
        loadOffers,
        offers,
        isLoading,
        isError,
    };
}

export default useLoadOffers;
