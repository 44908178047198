import React from 'react';
import { TileMediaAsset } from './tile-media-asset';
import './tile-balance-summary.scss';

interface TileBalanceSummaryProps {
    text: string;
    value: number | string;
}

export const TileBalanceSummary: React.FC<TileBalanceSummaryProps> = ({ text, value }) => {
    return (
        <>
            <div className="tile-balance-summary">
                <div className="tile-balance-summary__text">
                    <p>{text}</p>
                    <TileMediaAsset icon="fa-kit fa-prizeout-forward-arrow" />
                </div>

                <p className="tile-balance-summary__value">{value}</p>
            </div>
        </>
    );
};
