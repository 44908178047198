import React from 'react';
import { routes } from '../../../utils';
import { NavBarButton } from './nav-bar-button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useOpenNavBarLink } from '../../../hooks';
import { selectOffersCurrentLocation } from '../../../store/slices/offers-slice';
import { useDispatch } from 'react-redux';
import { setActiveCategoryListItem } from '../../../store/slices/category-list-slice';
import { NavBarButtonIconNames } from '../../../store/slices/partner-config-slice';

interface MainNavBarButtonProps {
    label: string;
    icon: NavBarButtonIconNames;
    route: routes;
    child?: React.ReactNode;
    id?: number;
}

export const MainNavBarButton: React.FC<MainNavBarButtonProps> = ({ label, icon, route, child, id }) => {
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const offerCurrentLocation = useAppSelector(selectOffersCurrentLocation);
    const [openNavBar] = useOpenNavBarLink();

    const isSelected =
        label !== 'Explore'
            ? currentLocation.pathname === route ||
              (currentLocation.pathname.includes(route) && !currentLocation.pathname.includes('category'))
            : currentLocation.pathname === route || currentLocation.pathname.includes('category');

    const handleExploreClick = () => {
        dispatch(
            setActiveCategoryListItem({
                categoryId: id,
                categoryName: label,
            }),
        );
        navigate(`/dashboard/category/${id}/${offerCurrentLocation}`);
    };

    return (
        <li>
            <NavBarButton
                icon={icon}
                isSelected={isSelected}
                onClick={label !== 'Explore' ? () => openNavBar(icon, route) : handleExploreClick}
                label={label}
            >
                {child}
            </NavBarButton>
        </li>
    );
};
