enum prizeoutWebsiteRoutes {
    TERMS = 'terms',
    PRIVACY = 'privacy',
    REWARDS_TERMS = 'rewards-terms',
    GIFTING_TERMS = 'gifting-terms',
    CASHBACKPLUS_TERMS = 'cashbackplus/terms',
}

export const prizeoutWebsiteDomain = `https://${process.env.WEBSITE_DOMAIN || 'prizeout.com'}`;

export const prizeoutWebsiteConstants = {
    termsUrl: `${prizeoutWebsiteDomain}/${prizeoutWebsiteRoutes.TERMS}/`,
    privacyUrl: `${prizeoutWebsiteDomain}/${prizeoutWebsiteRoutes.PRIVACY}/`,
    rewardsTermsUrl: `${prizeoutWebsiteDomain}/${prizeoutWebsiteRoutes.REWARDS_TERMS}/`,
    giftingTermsUrl: `${prizeoutWebsiteDomain}/${prizeoutWebsiteRoutes.GIFTING_TERMS}/`,
    cashbackPlusTermsUrl: `${prizeoutWebsiteDomain}/${prizeoutWebsiteRoutes.CASHBACKPLUS_TERMS}/`,
    helpUrl: 'https://help.prizeout.com/hc/en-us',
    browserExtensionChromeDownloadUrl:
        'https://chromewebstore.google.com/detail/cashback+-pay-brought-to/oooebgndoagidpahamnebdaigajnkgfa?hl=en&authuser=0',
    browserExtensionIosDownloadUrl: 'https://apps.apple.com/us/app/cashback-pay/id6499554007',
};

export enum PrizeoutEnv {
    CANDIDATE = 'candidate',
    DEV = 'dev',
    PRODUCTION = 'prod',
    SANDBOX = 'sandbox',
}
