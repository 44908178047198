import React from 'react';
import Classnames from 'classnames';
import {
    Button,
    ButtonColor,
    ButtonSize,
    GenericTag,
    GenericTagColor,
    GenericTagSize,
} from '../../../../../components/common';

import './offers-header.scss';

interface OffersHeaderProps {
    buttonText?: string;
    enableButton?: boolean;
    onClick?: () => void;
    subtitle?: string;
    tag?: string;
    title: string;
}

export const OffersHeader: React.FC<OffersHeaderProps> = ({
    buttonText,
    enableButton,
    onClick,
    subtitle,
    tag,
    title,
}): React.ReactElement => {
    const classes: string = Classnames(`offers-header`, { 'offers-header--has-inline-elements': tag || enableButton });

    const text = buttonText || 'See all';

    return (
        <header className={classes}>
            <div className="offers-header__title">
                <div className="offers-header__container">
                    <h1>{title}</h1>
                    {tag && <GenericTag text={tag} color={GenericTagColor.GRAY} size={GenericTagSize.MEDIUM} />}
                </div>
                {enableButton && (
                    <Button
                        ariaLabel={text}
                        size={ButtonSize.MINI}
                        color={ButtonColor.SECONDARY}
                        isInline={true}
                        text={text}
                        onClick={onClick}
                        testId="see-all-button"
                    />
                )}
            </div>
            {subtitle && <h2 className="offers-header__subtitle">{subtitle}</h2>}
        </header>
    );
};
