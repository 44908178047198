import React, { useState } from 'react';
import { createDevTools } from '@redux-devtools/core';
import { LogMonitor } from '@redux-devtools/log-monitor';
import { useAppSelector } from '../../hooks';
import { selectIsMiniWidget } from '../../store/slices/sdk-slice';
import { EnvironmentNames } from './environment';

import './dev-tools.scss';

const DevTool = createDevTools(<LogMonitor />);

const WrappedDevTool = () => {
    const [isShowingDevTools, setIsShowingDevTools] = useState(false);
    const isProd = process.env.ENV === EnvironmentNames.PRODUCTION;
    const isSandbox = process.env.ENV === EnvironmentNames.SANDBOX;
    const toggleDevTools = () => {
        setIsShowingDevTools(!isShowingDevTools);
    };
    const isMiniWidget = useAppSelector(selectIsMiniWidget);

    const clearSessionStorageData = () => {
        sessionStorage.clear();
    };

    return (
        <>
            {!isProd && !isSandbox && !isMiniWidget && (
                <>
                    <div className="dev-tools-wrapper">
                        <ul>
                            <li>
                                <button className="dev-tools-btn" onClick={() => clearSessionStorageData()}>
                                    CLEAR SESSION DATA
                                </button>
                            </li>
                            <li>
                                <button className="dev-tools-btn" onClick={() => toggleDevTools()}>
                                    SHOW DEV TOOLS
                                </button>
                            </li>
                        </ul>
                    </div>
                    {isShowingDevTools && (
                        <div className="dev-tools-container">
                            <DevTool />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default WrappedDevTool;
