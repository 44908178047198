import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../widget/widget';
import { sharedAPI } from '../../utils/services/api';

export interface PreferencesSlice {
    catalog: FavoritesCatalog;
}

export const preferencesInitialState: PreferencesSlice = {
    catalog: {
        brands: [] as BrandFavorites[],
        categories: [] as CategoryFavorites[],
    },
};

export interface FavoritesCatalog {
    brands: BrandFavorites[];
    categories: CategoryFavorites[];
}

export interface BrandFavorites {
    brandId: string;
    brandName: string;
    logomarkUrl: string;
}

export interface CategoryFavorites {
    categoryId: string;
    categoryName: string;
    emoji: string;
}

export interface BrandFavoritesSelection {
    brandId: string;
    isActive: boolean;
}

export interface CategoryFavoritesSelection {
    categoryId: string;
    isActive: boolean;
}

export const getFavoritesCatalog = createAsyncThunk('preferences/catalog', async ({}, { rejectWithValue, signal }) => {
    try {
        const result = await sharedAPI.request({
            data: {},
            endpoint: '/preferences/catalog',
            method: 'GET',
            signal: signal,
        });
        return {
            ...result.data,
        };
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const postBrandFavorite = createAsyncThunk(
    'preferences/selection',
    async (brand: BrandFavoritesSelection, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    ...brand,
                },
                endpoint: '/preferences/selection',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const postCategoryFavorite = createAsyncThunk(
    'preferences/selection',
    async (category: CategoryFavoritesSelection, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    ...category,
                },
                endpoint: '/preferences/selection',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const preferencesSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getFavoritesCatalog.fulfilled, (state, action: PayloadAction<FavoritesCatalog>) => {
            state.catalog = action.payload;
        });
    },
    initialState: preferencesInitialState,
    name: 'preferences',
    reducers: {},
});

export const {} = preferencesSlice.actions;

const selectPreferenceState = ({ preferences }: RootState): PreferencesSlice => preferences;

export const selectBrandCatalog = createSelector(selectPreferenceState, ({ catalog: { brands } }) => brands);

export const selectCategoryCatalog = createSelector(selectPreferenceState, ({ catalog: { categories } }) => categories);

export default preferencesSlice.reducer;
