import {
    setIsMobileLandscape,
    setIsMobilePortrait,
    setIsSmallIframeView,
    setIsTabletPortrait,
} from '../../store/slices/common-slice';
import { AppDispatch } from '../../store/widget/widget';

export const windowResizeHandler: (
    dispatch: AppDispatch,
    isTabletPortraitView: boolean,
    isMobileLandscape: boolean,
    isMobilePortraitView: boolean,
    isSmallIframeView: boolean,
) => void = (
    dispatch: AppDispatch,
    isTabletPortraitView: boolean,
    isMobileLandscape: boolean,
    isMobilePortraitView: boolean,
    isSmallIframeView: boolean,
) => {
    const isCurrentWindowTabletPortraitBreakpoint = window.innerWidth <= 834;
    const isCurrentWindowMobileLandscapeBreakpoint = window.innerWidth <= 640;
    const isCurrentWindowMobilePortrait = window.innerWidth <= 480;
    const isCurrentWindowSmallIframeBreakpoint = window.innerWidth <= 330;

    if (isCurrentWindowTabletPortraitBreakpoint !== isTabletPortraitView) {
        dispatch(setIsTabletPortrait(isCurrentWindowTabletPortraitBreakpoint));
    }

    if (isCurrentWindowMobileLandscapeBreakpoint !== isMobileLandscape) {
        dispatch(setIsMobileLandscape(isCurrentWindowMobileLandscapeBreakpoint));
    }

    if (isCurrentWindowMobilePortrait !== isMobilePortraitView) {
        dispatch(setIsMobilePortrait(isCurrentWindowMobilePortrait));
    }

    if (isCurrentWindowSmallIframeBreakpoint !== isSmallIframeView) {
        dispatch(setIsSmallIframeView(isCurrentWindowSmallIframeBreakpoint));
    }
};
