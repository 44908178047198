import React, { forwardRef } from 'react';
import { Button } from './button';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';

import './forward-arrow-button.scss';

interface ForwardArrowButtonProps {
    onClick: () => void;
    trackingType?: ButtonTrackingTypes;
}

export const ForwardArrowButton = forwardRef<HTMLButtonElement, ForwardArrowButtonProps>(
    (
        { onClick, trackingType },
        ref: React.RefCallback<HTMLButtonElement> | React.MutableRefObject<HTMLButtonElement>,
    ): React.ReactElement => {
        return (
            <Button
                ref={ref}
                ariaLabel={'Go forward'}
                className={'forward-arrow-button'}
                icon={'fak fa-prizeout-forward-arrow'}
                isElement
                onClick={onClick}
                testId={'forward-arrow-button'}
                trackingType={trackingType}
            />
        );
    },
);
