import React from 'react';
import { GenericModal } from './generic-modal';

import './transparent-modal.scss';

interface TransparentModalProps {
    ariaLabel: string; // This is a general description of the modal
    isOpen: boolean;
    onClose: () => void;
    testId?: string;
}

export const TransparentModal: React.FC<TransparentModalProps> = ({
    ariaLabel,
    children,
    isOpen,
    onClose,
    testId,
}): React.ReactElement => {
    return (
        <GenericModal
            ariaLabelledById="aria-transparent-modal-headline"
            ariaLabel={ariaLabel}
            hasExitButton={true}
            isOpen={isOpen}
            onClose={onClose}
            testId={testId}
            portalClassName="transparent-modal"
            customOverlayClassName="overlay--center-mobile"
        >
            {children}
        </GenericModal>
    );
};
