import React from 'react';
import Classnames from 'classnames';
import { ToolTip, ToolTipDirectionTypes } from '../../common';

import './status-icon-with-heading.scss';

export enum StatusIconType {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
    PENDING = 'pending',
}

interface StatusIconWithHeadingProps {
    text: string;
    status: StatusIconType;
    tooltip?: string;
}

export const StatusIconWithHeading: React.FC<StatusIconWithHeadingProps> = ({
    text,
    status,
    tooltip,
}): React.ReactElement => {
    const classes: string = Classnames('status-icon-with-heading', { [`status-icon-with-heading--${status}`]: status });

    let icon;

    switch (status) {
        case 'error':
        case 'warning':
            icon = 'fal fa-exclamation-circle';
            break;

        case 'success':
            icon = 'fak fa-prizeout-check-solid';
            break;
        case 'pending':
            icon = 'fak fa-prizeout-clock';
    }

    return (
        <div data-testid="status-icon-with-heading" className={classes}>
            <div className="status-icon-with-heading__icon">
                <i data-testid="status-icon-with-heading-icon" className={icon}></i>
            </div>

            <h1 className="status-icon-with-heading__heading">
                {text}
                {tooltip && (
                    <>
                        {' '}
                        <ToolTip content={tooltip} tipDirection={ToolTipDirectionTypes.DOWN} />
                    </>
                )}
            </h1>
        </div>
    );
};
