import React from 'react';
import Classnames from 'classnames';
import { GiftCardImage } from '../ui-widgets/gift-card-image';
import CurrencyFormatter from '../ui-widgets/currency-formatter';
import { constants } from '../../../utils/constants';
import { OfferName } from '../../../modules/widget/components/offers/offer-gift-card/common/offer-name';
import './gift-card.scss';

interface GiftCardProps {
    altText?: string;
    className?: string;
    currency?: string;
    decimals?: number;
    imgUrl?: string;
    isNameBold?: boolean;
    name: string;
    rows?: number;
    value?: number;
}

export const GiftCard: React.FC<GiftCardProps> = ({
    altText,
    className,
    currency,
    decimals,
    imgUrl,
    isNameBold,
    name,
    rows,
    value,
}): React.ReactElement => {
    const classes: string = Classnames('gift-card', className),
        imageUrl = imgUrl || constants.defaultGiftCardImageUrl,
        imageAltText = altText || 'Gift card';

    return (
        <div className={classes}>
            <GiftCardImage imgUrl={imageUrl} altText={imageAltText} />
            <div className="gift-card__row">
                <OfferName name={name} rows={rows} isBold={isNameBold} />
                {value && (
                    <p>
                        <CurrencyFormatter currencyOverride={currency} value={value} decimals={!!decimals} inline />
                    </p>
                )}
            </div>
        </div>
    );
};
