import { normalizeString } from './search-config-utils';

interface SearchListTransformOptions {
    pattern: string;
    ignoreCase?: boolean;
}

type TransformOperatorArgs<GenericObject> = [
    list: GenericObject[],
    options: SearchListTransformOptions,
    getKeyValue: (entry: GenericObject) => string,
    getTieBreakerValue?: (entry: GenericObject) => string,
];

type TransformOperator<GenericObject> = (
    ...args: TransformOperatorArgs<GenericObject>
) => TransformOperatorArgs<GenericObject>;

type SearchListTransformerArgs<GenericObject> = [list: GenericObject[], options: SearchListTransformOptions];

export type SearchListTransformer<GenericObject> = (
    ...args: SearchListTransformerArgs<GenericObject>
) => GenericObject[];

interface Seen {
    [K: string]: boolean;
}

export const dedupList = <GenericObject>(
    ...args: TransformOperatorArgs<GenericObject>
): TransformOperatorArgs<GenericObject> => {
    const [list, options, getKeyValue, getTieBreakerValue] = args;

    const seen: Seen = {};
    const newList = list.filter((entry) => {
        const compareValue = getKeyValue(entry);
        const tieBreakerValue = getTieBreakerValue(entry);
        const seenValue = compareValue + tieBreakerValue;

        if (seen[seenValue]) {
            return false;
        }

        seen[seenValue] = true;
        return true;
    });

    return [newList, options, getKeyValue];
};

const matchScore = (candidate: string, pattern: string, ignoreCase = false) => {
    const scoreIncrement = 10;
    let score = 0;

    const localCandidate = normalizeString(candidate, ignoreCase);
    const localPattern = normalizeString(pattern, ignoreCase);

    if (localCandidate.indexOf(localPattern) === 0) {
        score -= scoreIncrement;
    }

    if (localCandidate.indexOf(localPattern) === -1) {
        score += scoreIncrement;
    }

    return score;
};

export const sortList = <GenericObject>(
    ...args: TransformOperatorArgs<GenericObject>
): TransformOperatorArgs<GenericObject> => {
    const [list, options, getKeyValue] = args;

    const newList = [...list];
    const collator = new Intl.Collator();
    newList.sort((a: GenericObject, b: GenericObject): number => {
        const compareValueA = getKeyValue(a);
        const compareValueB = getKeyValue(b);

        const scoreA = matchScore(compareValueA, options.pattern, options.ignoreCase);
        const scoreB = matchScore(compareValueB, options.pattern, options.ignoreCase);
        return scoreA - scoreB + collator.compare(compareValueA, compareValueB);
    });

    return [newList, options, getKeyValue];
};

const buildTransformer = <GenericObject>(
    transformers: TransformOperator<GenericObject>[],
    getKeyValue: (entry: GenericObject) => string,
    getTieBreakerValue: (entry: GenericObject) => string,
): SearchListTransformer<GenericObject> => {
    const transformer = transformers.reverse().reduce((memo: any, transformer: TransformOperator<GenericObject>) => {
        if (memo) {
            return function (...args: SearchListTransformerArgs<GenericObject>): TransformOperatorArgs<GenericObject> {
                const [list, options] = args;
                return this(...transformer(list, options, getKeyValue, getTieBreakerValue));
            }.bind(memo);
        } else {
            return function (...args: SearchListTransformerArgs<GenericObject>): TransformOperatorArgs<GenericObject> {
                const [list, options] = args;
                return transformer(list, options, getKeyValue, getTieBreakerValue);
            }.bind(null);
        }
    }, null);

    return (list: GenericObject[], { pattern, ignoreCase = true }: SearchListTransformOptions): GenericObject[] => {
        const result = transformer(list, {
            pattern,
            ignoreCase,
        });
        return result[0];
    };
};

export const stringListTransformFactory = (
    transformers: TransformOperator<string>[],
): SearchListTransformer<string> => {
    const getKeyValue = (entry: string) => entry;
    const getTieBreakerValue = (entry: string) => entry;

    return buildTransformer(transformers, getKeyValue, getTieBreakerValue);
};

export const objectListTransformFactory = <GenericObject>(
    memberName: keyof GenericObject,
    transformers: TransformOperator<GenericObject>[],
    tieBreakerName?: keyof GenericObject,
): SearchListTransformer<GenericObject> => {
    const getKeyValue = (entry: GenericObject) => {
        return entry[memberName] as unknown as string;
    };

    tieBreakerName = tieBreakerName || memberName;
    const getTieBreakerValue = (entry: GenericObject) => {
        return entry[tieBreakerName] as unknown as string;
    };

    return buildTransformer(transformers, getKeyValue, getTieBreakerValue);
};
