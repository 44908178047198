import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export const isInputElement = (element: Element): element is HTMLInputElement => {
    return element instanceof HTMLInputElement;
};

export const isElement = (element: Element | Document): element is Element => {
    return element instanceof Element;
};

export const isHTMLElement = (element: Element | EventTarget): element is HTMLElement => {
    return element instanceof HTMLElement;
};

export const isNumber = (value: unknown): value is number => {
    // Check that value is defined.  Do not use `!==` to be sure it matches both null and undefined
    return value != null && typeof value === 'number';
};

export const isString = (value: unknown): value is string => {
    // Check that value is defined.  Do not use `!==` to be sure it matches both null and undefined
    return value != null && typeof value === 'string';
};

export const isNotDefined = (value: unknown): boolean => {
    return value === null || value === undefined;
};

export const isObject = (value: unknown): value is object => {
    return value != null && typeof value === 'object';
};

export const isSerializedError = (error: FetchBaseQueryError | SerializedError): error is SerializedError => {
    return error != null && Object.hasOwn(error, 'message');
};

export const isFetchBaseQueryError = (error: FetchBaseQueryError | SerializedError): error is FetchBaseQueryError => {
    return error != null && Object.hasOwn(error, 'status') && Object.hasOwn(error, 'data');
};

export const isFetchErrorData = (data: unknown): data is { message: string } => {
    return isObject(data) && Object.hasOwn(data, 'message');
};
