import React, { forwardRef, useEffect, useLayoutEffect, useReducer, useRef } from 'react';
import { useComponentId, useEffectQueue } from '../../../hooks';
import {
    ErrorState,
    ErrorStateManagerType,
    ErrorStateReducerActionNames,
    INITIAL_ERROR_STATE,
    errorStateReducer,
} from '../../../reducers';
import ClassNames from 'classnames';
import { getMessage, loadMessages } from '../../../utils/constants/messages/catalog';
import { useRefAssigner } from '../../../hooks/use-ref-assigner';
import './validated-input.scss';

// Message section
const MESSAGE_SECTION = 'uiInputs';

// Messages to be used when target.validationMessage is set to GENERIC_VALIDITY_MESSAGE.

export enum InputModeTypes {
    DECIMAL = 'decimal',
    EMAIL = 'email',
    NONE = 'none',
    NUMERIC = 'numeric',
    SEARCH = 'search',
    TEXT = 'text',
    TEL = 'tel',
    URL = 'url',
}

export enum InputTypes {
    EMAIL = 'email',
    FULL_NAME = 'full-name',
    NUMBER = 'number',
    PASSWORD = 'password',
    SEARCH = 'search',
    TEL = 'tel',
    TEXT = 'text',
}

interface OnCompletedArg {
    value: string;
    state: ErrorState;
}

export interface InputComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
    autoComplete?: string;
    className?: string;
    customValidation?: (arg0: HTMLInputElement) => string | null;
    errorStateManager?: ErrorStateManagerType;
    id?: string;
    inputMode?: InputModeTypes;
    invalidValueMessageName?: string;
    maxLength?: number;
    name?: string;
    onCancel?: (arg0: string) => void;
    onCompleted?: (arg0: OnCompletedArg) => void;
    pattern?: string;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    requiredMessageName?: string;
    showNativeMessages?: boolean;
    showValidation?: boolean;
    testId?: string;
    type?: InputTypes;
}

export const ValidatedInput = forwardRef(function ValidatedInput(
    {
        autoComplete,
        className,
        customValidation,
        errorStateManager = null,
        id,
        inputMode,
        invalidValueMessageName = 'inputIsInvalid',
        maxLength,
        name = 'validated',
        onCancel,
        onCompleted,
        pattern,
        placeholder,
        readOnly,
        required = false,
        requiredMessageName = 'inputIsRequired',
        showNativeMessages = false,
        showValidation = false,
        testId = 'validated-input-field',
        type = InputTypes.TEXT,
        ...rest
    }: InputComponentProps,
    ref: React.RefCallback<HTMLInputElement> | React.MutableRefObject<HTMLInputElement>,
): React.ReactElement {
    const validatedInputId = id || useComponentId('validatedInput');
    const validatedInputRef = useRef(null);

    const [errorState, dispatchErrorState] = errorStateManager || useReducer(errorStateReducer, INITIAL_ERROR_STATE);

    useLayoutEffect(() => {
        // WV2-2296 This load should be handled before we get here in most cases.
        //          Still, this is probably workable for the most part.
        loadMessages(MESSAGE_SECTION);
    }, []);

    useEffect(() => {
        validateInput();
    }, []);

    const [queueErrorStateCallback] = useEffectQueue([errorState]);

    const getValue = () => {
        return validatedInputRef.current ? validatedInputRef.current.value : '';
    };

    const getValidityMessage = (validity: ValidityState) => {
        // Only report that there is an error to the user. The only special
        // one is for a missing value on a required field.
        if (validity.valueMissing) {
            return getMessage(MESSAGE_SECTION, requiredMessageName, { name });
        }

        return getMessage(MESSAGE_SECTION, invalidValueMessageName);
    };

    const validateInput = () => {
        const target = validatedInputRef.current;

        if (!target) {
            return; // Either it hasn't been rendered yet, or it's in the process of being unmounted.
        }

        if (type === InputTypes.TEXT) {
            const value = inputMode === InputModeTypes.TEXT ? target.value?.trim() : target.value?.trimStart();
            target.value = value ? value : '';
        }

        dispatchErrorState({
            type: ErrorStateReducerActionNames.CLEAR,
        });

        const customMessage = customValidation?.call(null, target);

        if (customMessage) {
            target.setCustomValidity(customMessage);
        } else if (target.value.match(/\s$/)) {
            target.setCustomValidity('No trailing spaces are allowed');
        } else if (target.validationMessage === 'No trailing spaces are allowed') {
            target.setCustomValidity('');
        }

        target.checkValidity();
    };

    const handleInvalid = (evt: React.InvalidEvent<HTMLInputElement>) => {
        if (!showNativeMessages) {
            evt.preventDefault();
        }

        const target = validatedInputRef.current;
        if (!target) {
            return; // Either it hasn't been rendered yet, or it's in the process of being unmounted.
        }

        if (target.validity.valid) {
            return;
        }

        // Validity is set by the browser.
        // https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
        dispatchErrorState({
            type: ErrorStateReducerActionNames.SET,
            errorTarget: target,
            message: getValidityMessage(target.validity),
        });

        rest.onInvalid?.call(null, evt);
    };

    const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
        validateInput();
        queueErrorStateCallback(() => {
            rest?.onChange?.call(null, evt);
        });
    };

    const blurHandler = (evt: React.FocusEvent<HTMLInputElement>) => {
        validateInput();
        queueErrorStateCallback(() => {
            rest?.onBlur?.call(null, evt);
        });
    };

    const keyUpHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        validateInput();
        queueErrorStateCallback(() => {
            rest?.onKeyUp?.call(null, evt);
        });
    };

    const keyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        switch (evt.key) {
            case 'Enter':
                evt.preventDefault();
                validateInput();
                queueErrorStateCallback(([state]) => {
                    onCompleted?.call(null, {
                        value: getValue(),
                        state: state,
                    });
                });
                break;

            case 'Escape':
                evt.preventDefault();
                onCancel?.call(null, getValue());
                break;

            default:
                break;
        }

        rest?.onKeyDown?.call(null, evt);
    };

    const focusHandler = (evt: React.FocusEvent<HTMLInputElement>) => {
        rest?.onFocus?.call(null, evt);
    };

    const refAssigner = useRefAssigner(ref, validatedInputRef);

    const classes = ClassNames('validated-input', {
        'validated-input--show-validation': showValidation,
        [className]: className,
    });

    return (
        <input
            {...rest}
            aria-required={required}
            autoCapitalize="none"
            autoComplete={autoComplete}
            className={classes}
            data-testid={testId}
            disabled={readOnly}
            id={validatedInputId}
            inputMode={inputMode}
            maxLength={maxLength}
            name={name}
            onBlur={blurHandler}
            onChange={changeHandler}
            onFocus={focusHandler}
            onInvalid={handleInvalid}
            onKeyDown={keyDownHandler}
            onKeyUp={keyUpHandler}
            pattern={pattern}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={refAssigner}
            required={required}
            type={type}
        />
    );
});
