import React from 'react';
import { HowItWorksListItem, HowItWorksListItemTypes } from '../../features/how-it-works/how-it-works-list-item';
import { Button, ButtonColor, ButtonSize } from '../buttons';
import { useNumberSequence } from '../../../hooks';
import './tile-how-it-works.scss';

interface TileHowItWorksProps {
    title?: string;
    description?: string[];
    buttonLabel: string;
    onClick?: () => void;
    disabled?: boolean;
}

export const TileHowItWorks: React.FC<TileHowItWorksProps> = ({
    title,
    description,
    disabled,
    onClick,
    buttonLabel,
}) => {
    const bulletKeys = useNumberSequence();

    const descriptionFallBack = ['Browse digital gift cards', 'Select a brand', 'Purchase and get an instant bonus'];
    const descriptionList = description ? description : descriptionFallBack;
    const titleFallback = title ? title : 'How it works';
    return (
        <>
            <div className="tile-how-it-works">
                <section className="tile-how-it-works__content">
                    <h2 data-testid="tile-how-it-works-title">{titleFallback}</h2>

                    <ol>
                        {descriptionList.map((item, index) => {
                            return (
                                <HowItWorksListItem
                                    key={bulletKeys()}
                                    description={item}
                                    number={index + 1}
                                    displayType={HowItWorksListItemTypes.SOLID}
                                />
                            );
                        })}
                    </ol>
                </section>

                <section className="tile-how-it-works__button">
                    <Button
                        ariaLabel={buttonLabel}
                        text={buttonLabel}
                        size={ButtonSize.MINI}
                        data-testid="tile-how-it-works-cta"
                        color={ButtonColor.PRIMARY}
                        onClick={onClick}
                        disabled={disabled}
                    />
                </section>
            </div>
        </>
    );
};
