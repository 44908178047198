import { Dispatch } from 'react';

export enum ErrorStateReducerActionNames {
    SET = 'set',
    CLEAR = 'clear',
}

export interface ErrorState {
    isError: boolean;
    errorTarget: HTMLElement;
    message: string;
}

export const INITIAL_ERROR_STATE = {
    isError: false,
    errorTarget: null as HTMLElement,
    message: '',
};

export interface ErrorStateReducerAction {
    type: ErrorStateReducerActionNames;
    errorTarget?: HTMLElement;
    message?: string;
}

export type ErrorStateManagerType = [ErrorState, Dispatch<ErrorStateReducerAction>];

export const errorStateReducer = (state: ErrorState, action: ErrorStateReducerAction): ErrorState => {
    switch (action.type) {
        case ErrorStateReducerActionNames.SET:
            return {
                isError: true,
                errorTarget: action.errorTarget,
                message: action.message,
            };

        case ErrorStateReducerActionNames.CLEAR:
            return {
                isError: false,
                errorTarget: null,
                message: '',
            };

        default:
            return state;
    }
};
