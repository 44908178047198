import React, { useEffect, useState } from 'react';
import Classnames from 'classnames';
import { useAppSelector } from '../../../../../hooks';
import { selectCurrentSelectedOffer } from '../../../../../store/slices/checkout-slice';
import {
    displayOfferType,
    PrizeoutOffer,
    PrizeoutOfferSettings,
    PrizeoutOfferSettingsDisplayType,
} from '../../../../../store/slices/offers-slice';
import { selectIsOfferHighlightEnabled } from '../../../../../store/slices/partner-config-slice';
import { constants } from '../../../../../utils/constants';
import {
    BrandOfferDisplay,
    GiftCardOfferDisplay,
    HeroOfferDisplay,
    RoundImageOfferDisplay,
    BigBrandOfferDisplay,
    DetailedBigBrandOfferDisplay,
} from './offer-display';
import { selectIsMiniWidget } from '../../../../../store/slices/sdk-slice';
import { ClickableCard } from '../../../../../components/common/cards';
import { useParams } from 'react-router-dom';
import { BonusTagType } from '../../../../../components/common';
import { TrackingService } from '../../../../../utils/services/tracking';
import { selectIsMobileLandscape } from '../../../../../store/slices/common-slice';
import { CashbackOfferDisplay } from './offer-display/cashback-offer-display';
import { VerticalListOfferDisplay } from './offer-display/vertical-list-offer-display';

import './offer-gift-card.scss';

export type CurrentOfferDisplay = {
    display_type?: PrizeoutOfferSettingsDisplayType;
    display_offer_type?: displayOfferType;
    hero_image_url?: string;
    image_url?: string;
    logomark_url?: string;
    name: string;
    offer_type: BonusTagType;
    offer_value: number;
    currency: string;
    showDetailed?: boolean;
};

interface OfferGiftCardProps {
    offer: PrizeoutOffer;
    onSelectOffer: () => void;
    settings?: PrizeoutOfferSettings;
    showDetailed?: boolean;
    description?: string;
}

const OfferGiftCardComponent: React.FC<OfferGiftCardProps> = ({
    offer,
    onSelectOffer,
    settings,
    showDetailed,
    description,
}): React.ReactElement => {
    const firstGiftCard = offer.giftcard_list[0];
    const offerType = firstGiftCard.display_monetary_bonus ? BonusTagType.MONETARY : BonusTagType.PERCENTAGE;
    const offerValue =
        offerType === BonusTagType.MONETARY ? firstGiftCard.display_monetary_bonus : firstGiftCard.display_bonus;
    const [isCurrentActiveOffer, setIsCurrentActiveOffer] = useState(false);
    const activeOffer = useAppSelector(selectCurrentSelectedOffer);
    const isOfferHighlightEnabled = useAppSelector(selectIsOfferHighlightEnabled);
    const isMiniWidget = useAppSelector(selectIsMiniWidget);
    const displayType = settings?.display_type || PrizeoutOfferSettingsDisplayType.GIFT_CARD;
    const trackingService = TrackingService.getTrackingService();
    const { activeCategoryId }: any = useParams();
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);

    let classes: string = Classnames('offer-gift-card');

    if (displayType) {
        if (showDetailed) {
            classes += ` detailed-${displayType}-offer-display`;
        } else {
            classes += ` ${displayType}-offer-display`;
        }
        if (isOfferHighlightEnabled && !isMiniWidget && !isMobileLandscape) {
            classes += ' offer-gift-card--allow-selection';
            if (isCurrentActiveOffer) {
                classes += ' offer-gift-card--selected';
            }
        }
    }

    const selectOffer = () => {
        // change location of adset edit load complete
        trackingService.trackAdsetEditLoadComplete(
            'offer-widget',
            offer.giftcard_list[0].checkout_value_id,
            Number(activeCategoryId),
        );

        onSelectOffer();
    };

    useEffect(() => {
        setIsCurrentActiveOffer(JSON.stringify(activeOffer) === JSON.stringify(offer));
    }, [activeOffer]);

    const data = {
        display_type: displayType,
        display_offer_type: offer.display_offer_type,
        hero_image_url: offer.checkout_hero_url || offer.image_url || constants.defaultGiftCardImageUrl,
        image_url: offer.image_url || constants.defaultGiftCardImageUrl,
        logomark_url: offer.logomark_url || constants.defaultRoundImageUrl,
        name: offer.name,
        offer_type: offerType,
        offer_value: offerValue,
        currency: offer.currency_code,
    };

    const supportingImages = [data.hero_image_url || data.image_url].concat(offer.support_creative_list);

    const renderLayout = (display: string) => {
        switch (display) {
            case PrizeoutOfferSettingsDisplayType.BIG_BRAND:
                return showDetailed ? (
                    <DetailedBigBrandOfferDisplay
                        data={data}
                        supportingImages={supportingImages}
                        description={description}
                    />
                ) : (
                    <BigBrandOfferDisplay data={data} supportingImages={supportingImages} />
                );

            case PrizeoutOfferSettingsDisplayType.BRAND:
                return <BrandOfferDisplay data={data} />;

            case PrizeoutOfferSettingsDisplayType.CASHBACK:
                return <CashbackOfferDisplay data={data} />;

            case PrizeoutOfferSettingsDisplayType.GIFT_CARD:
                return <GiftCardOfferDisplay data={data} />;

            case PrizeoutOfferSettingsDisplayType.HERO:
                return <HeroOfferDisplay data={data} />;

            case PrizeoutOfferSettingsDisplayType.LOGOMARK:
                return <RoundImageOfferDisplay data={data} hideBonusText={isMiniWidget && true} />;

            case PrizeoutOfferSettingsDisplayType.VERTICAL_LIST_OFFER:
                return <VerticalListOfferDisplay data={data} />;

            default:
                return <GiftCardOfferDisplay data={data} />;
        }
    };

    return (
        <ClickableCard
            cardName="offer-gift-card"
            className={classes}
            onClick={selectOffer}
            data-testid={`offer-gift-card-${displayType}`}
            disableTracking={true}
        >
            {renderLayout(displayType)}
        </ClickableCard>
    );
};

export const OfferGiftCard = React.memo(OfferGiftCardComponent);
