import React from 'react';
import Classnames from 'classnames';

import './generic-tag.scss';

export enum GenericTagColor {
    PENDING = 'pending',
    PROCESSING = 'processing',
    ERROR = 'error',
    GRAY = 'gray',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
}

export enum GenericTagSize {
    MINI = 'mini',
    SMALL = 'small',
    MEDIUM = 'medium',
}
export enum GenericTagTheme {
    SOLID = 'solid',
    BORDER = 'border',
}

interface GenericTagProps {
    className?: string;
    color?: GenericTagColor;
    size?: GenericTagSize;
    theme?: GenericTagTheme;
    testId?: string;
    text: string;
}

export const GenericTag: React.FC<GenericTagProps> = ({
    className,
    color,
    size,
    theme = GenericTagTheme.SOLID,
    testId,
    text,
}): React.ReactElement => {
    const defaultTestId = 'generic-tag';
    const classes: string = Classnames(
        'generic-tag',
        { [`generic-tag--${color}`]: color },
        { [`generic-tag--${size}`]: size },
        { [`generic-tag--${theme}`]: theme },
        className,
    );

    return (
        <span className={classes} data-testid={testId || defaultTestId}>
            <p className="generic-tag__text">{text}</p>
        </span>
    );
};
