import React from 'react';
import { selectIsMobilePortrait } from '../../../store/slices/common-slice';
import { Button, ButtonColor, ButtonSize } from '../buttons';
import { useAppSelector } from '../../../hooks';

import './tile-testimonial-intro.scss';

interface TileTestimonialIntroProps {
    quote: string;
    name: string;
    image?: string;
    buttonLabel?: string;
    onClick?: () => void;
    disabled?: boolean;
}

export const TileTestimonialIntro: React.FC<TileTestimonialIntroProps> = ({
    quote,
    name,
    image,
    buttonLabel,
    onClick,
    disabled,
}) => {
    const isMobilePortrait = useAppSelector(selectIsMobilePortrait);
    const showTestimonialButton = isMobilePortrait && onClick && buttonLabel;
    return (
        <div className="tile-testimonial-intro">
            <section>
                <p className="tile-testimonial-intro__quote">{quote}</p>
            </section>
            <section className="tile-testimonial-intro__section">
                <div className="tile-testimonial-intro__profile">
                    {image && (
                        <img
                            data-testid="tile-testimonial-intro-image"
                            className="tile-testimonial-intro__image"
                            src={image}
                            alt=""
                        />
                    )}
                    <p className="tile-testimonial-intro__name">
                        <strong>{name}</strong>
                    </p>
                </div>

                {showTestimonialButton && (
                    <Button
                        ariaLabel={buttonLabel}
                        className="tile-testimonial-intro__cta"
                        disabled={disabled}
                        onClick={onClick}
                        size={ButtonSize.MINI}
                        text={buttonLabel}
                        color={ButtonColor.SECONDARY}
                    />
                )}
            </section>
        </div>
    );
};
