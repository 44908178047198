import React from 'react';
import { EmailUnverified } from './email-unverified';
import { VerificationSent } from './verification-sent';
import { GenericModal } from '../generic-modal';
import { AccountLinkRequest } from './account-link-request';
import { useAppSelector } from '../../../../hooks';
import {
    AuthenticationModalView,
    selectAuthenticationModalView,
    setAuthenticationModalView,
} from '../../../../store/slices/common-slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/widget/widget';
import { LogInResponse } from '../../../../store/slices/user-slice';
import { EmailCaptureFlowStateNames, selectEmailCaptureFlowState } from '../../../../store/slices/email-capture-slice';
import { selectForceEmailCaptureOnCheckout } from '../../../../store/slices/partner-config-slice';
import { SignUpResponse } from '../../../../store/slices/verification-slice';
import { useEmailCapturePost } from '../email-capture/hooks/use-email-capture-post';
import { LogInForm, RequestPasswordResetForm, SignUpForm } from '../../../forms';

interface AuthenticationModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const AuthenticationModal: React.FC<AuthenticationModalProps> = ({ isOpen, onClose }): React.ReactElement => {
    const view = useAppSelector(selectAuthenticationModalView);
    const dispatch = useDispatch<AppDispatch>();

    // This hook should be in the general hooks directory except that it is, actually, specialized.
    // The correct way to deal with this is to do the work for:
    // https://prizeout.atlassian.net/browse/WV2-2033
    const emailCapturePost = useEmailCapturePost();

    const emailCaptureFlowState = useAppSelector(selectEmailCaptureFlowState);
    const isEmailCaptureRequired = useAppSelector(selectForceEmailCaptureOnCheckout);

    const onSuccessfulLogIn = (response: LogInResponse) => {
        if (response.verifiedAuth && !response.needsToLink) {
            onClose();
        } else if (response.needsToLink) {
            dispatch(setAuthenticationModalView(AuthenticationModalView.ACCOUNT_LINK_REQUEST));
        } else if (!response.verifiedAuth) {
            dispatch(setAuthenticationModalView(AuthenticationModalView.VERIFICATION_REQUIRED));
        }
    };

    const onSuccessfulSignUp = async (response: SignUpResponse) => {
        if (isEmailCaptureRequired && emailCaptureFlowState !== EmailCaptureFlowStateNames.COMPLETED_CAPTURE_FLOW) {
            await emailCapturePost(response.email);
        }

        dispatch(setAuthenticationModalView(AuthenticationModalView.VERIFICATION_SENT));
    };

    return (
        <GenericModal
            ariaLabelledById={`${view}-modal-headline`}
            ariaDescribedById={`${view}-modal-description`}
            ariaLabel="Authentication modal"
            hasExitButton
            isOpen={isOpen}
            onClose={() => onClose()}
            testId="authentication-modal"
        >
            {
                {
                    accountLinkRequest: <AccountLinkRequest closeModal={() => onClose()} />,
                    login: (
                        <LogInForm
                            forgotPasswordOverride={() =>
                                dispatch(setAuthenticationModalView(AuthenticationModalView.FORGOT_PASSWORD))
                            }
                            onSuccess={(res) => onSuccessfulLogIn(res)}
                            signUpOverride={() => dispatch(setAuthenticationModalView(AuthenticationModalView.SIGN_UP))}
                        />
                    ),
                    signup: (
                        <SignUpForm
                            logInOverride={() => dispatch(setAuthenticationModalView(AuthenticationModalView.LOGIN))}
                            onSuccess={(res) => onSuccessfulSignUp(res)}
                        />
                    ),
                    forgotPassword: (
                        <RequestPasswordResetForm
                            logInOverride={() => dispatch(setAuthenticationModalView(AuthenticationModalView.LOGIN))}
                        />
                    ),
                    verificationRequired: <EmailUnverified closeModal={() => onClose()} />,
                    verificationSent: <VerificationSent closeModal={() => onClose()} />,
                }[view]
            }
        </GenericModal>
    );
};
