import React from 'react';
import { SDCOption, SDCOptionProps, SDCOptionWrapper } from '../select-dropdown/sdc-option/sdc-option-wrapper';
import './currency-selector-option.scss';

export interface CurrencySelectorData {
    flagCharacter: string;
    countryName: string;
}

export type CurrencySelectorOptions = SDCOption<CurrencySelectorData>;

const CurrencyOption: React.FC<SDCOptionProps<CurrencySelectorData>> = ({ option }): React.ReactElement => {
    return (
        <div className="currency-option">
            <p className="currency-option__flag">{option.data.flagCharacter}</p>
            <p className="currency-option__countryName">{option.data.countryName}</p>
        </div>
    );
};

export const CurrencySelectorOption = SDCOptionWrapper(CurrencyOption);
