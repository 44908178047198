import { useCallback, useEffect, useState } from 'react';
import { useObservedTrackerResult, TrackedHandlerList, QueryCallback, TrackedHandler, TrackedQuery } from './types';

export function useObservedTracker(observer: IntersectionObserver, topElement: Element): useObservedTrackerResult {
    const [trackedQueries, setTrackedQueries] = useState<TrackedQuery[]>([]);
    const [trackedHandlers, setTrackedHandlers] = useState<TrackedHandlerList>({});
    const [handlersNeedReset, setHandlersNeedReset] = useState<boolean>(false);

    const getHandlers = useCallback(() => {
        const result: TrackedHandlerList = {};

        if (observer && topElement) {
            trackedQueries.forEach((trackedQuery) => {
                result[trackedQuery.query] = new TrackedHandler(observer, topElement, trackedQuery);
            });
        }

        setTrackedHandlers(result);
    }, [trackedQueries]);

    const handleResetHandlers = useCallback(() => {
        Object.values(trackedHandlers).forEach((handler) => {
            handler.unobserve();
        });
        getHandlers();
    }, [trackedQueries]);

    useEffect(() => {
        handleResetHandlers();
    }, [trackedQueries]);

    useEffect(() => {
        handleResetHandlers();
        setHandlersNeedReset(false);
    }, [handlersNeedReset]);

    const resetHandlers = () => {
        setHandlersNeedReset(true);
    };

    const trackElement = (query: string, callback?: QueryCallback) => {
        // Attempting to call this from a rendering function in your component will
        // cause a "too many rerenders" error.
        setTrackedQueries((oldTracked) => {
            const newEntry: TrackedQuery = {
                query,
                callback,
            };

            const filteredList = oldTracked.filter((entry: TrackedQuery) => {
                return entry.query !== query;
            });

            return [...filteredList, newEntry];
        });
    };

    return {
        trackElement,
        resetHandlers,
        trackedHandlers,
    };
}
