import { useEffect } from 'react';
import { listOptIns, selectOptins } from '../store/slices/user-slice';
import { useAppSelector } from './use-app-selector';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/widget/widget';

export enum OptInEnum {
    REWARDS = 'rewards',
    TERMS_OF_SERVICE = 'terms_of_service',
    PRIVACY_POLICY = 'privacy_policy',
    COOKIE_POLICY = 'cookie_policy',
}

interface SanitizedOptIns {
    rewards?: boolean;
    terms_of_service?: boolean;
    privacy_policy?: boolean;
    cookie_policy?: boolean;
}

export function useOptIns() {
    const dispatch = useDispatch<AppDispatch>();
    const optIns = useAppSelector(selectOptins);

    useEffect(() => {
        (async () => {
            await dispatch(listOptIns());
        })();
    }, []);

    function hasOptIn(optInName: OptInEnum) {
        const sanitizedOptIns: SanitizedOptIns = {};

        for (let i = 0; i < optIns.length; i++) {
            const temp = optIns[i];
            sanitizedOptIns[temp.opt_in_type as OptInEnum] = temp.opt_in === 1;
        }

        return sanitizedOptIns[optInName];
    }

    return {
        optIns,
        hasOptIn,
    };
}

export default useOptIns;
