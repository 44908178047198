import React from 'react';
import { Button, ButtonColor, ButtonSize, ButtonType } from '../../buttons';
import { ButtonTrackingTypes } from '../../../../utils/services/tracking';

import './pills-list-layout.scss';

interface PillsListLayoutProps {
    ariaLabelledById: string;
    headline: string;
    primaryOnClick: () => void;
    primaryTestId?: string;
    secondaryOnClick?: () => void;
    secondaryTestId?: string;
    subheadline?: string;
}

export const PillsListLayout: React.FC<PillsListLayoutProps> = ({
    ariaLabelledById,
    children,
    headline,
    primaryOnClick,
    primaryTestId,
    secondaryOnClick,
    secondaryTestId,
    subheadline,
}): React.ReactElement => {
    const defaultPrimaryTestId = 'pills-list-primary';
    const defaultSecondaryTestId = 'pills-list-secondary';
    const defaultAriaLabelledById = 'pills-list-headline';
    return (
        <div className="pills-list-layout">
            <div className="pills-list-layout__content">
                <h1 id={ariaLabelledById || defaultAriaLabelledById}>{headline}</h1>
                {subheadline && <h2>{subheadline}</h2>}
                <ul className="pills-list-layout__list no-scrollbars">{children}</ul>
            </div>
            <div className="pills-list-layout__buttons">
                {secondaryOnClick && (
                    <Button
                        ariaLabel="Clear all selections"
                        className="pills-list-layout__button"
                        text="Clear all"
                        onClick={secondaryOnClick}
                        size={ButtonSize.MEDIUM}
                        color={ButtonColor.SECONDARY}
                        testId={secondaryTestId || defaultSecondaryTestId}
                        type={ButtonType.RESET}
                        trackingType={ButtonTrackingTypes.BUTTON_CLICK_CLEAR_PILLS_LIST}
                    />
                )}
                <Button
                    ariaLabel="Done with selection"
                    className="pills-list-layout__button"
                    text="Done"
                    onClick={primaryOnClick}
                    size={ButtonSize.MEDIUM}
                    color={ButtonColor.PRIMARY}
                    testId={primaryTestId || defaultPrimaryTestId}
                    trackingType={ButtonTrackingTypes.BUTTON_CLICK_CLOSE_PILLS_LIST}
                />
            </div>
        </div>
    );
};
