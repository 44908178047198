import React from 'react';
import { Button, SidebarArrow } from '../../../common';

import './sidebar-header.scss';

export enum SidebarHeaderCtaPosition {
    LEFT = 'left',
    RIGHT = 'right',
}
export enum SidebarHeaderCtaType {
    BACK = 'back',
    CLOSE = 'close',
}

export interface SidebarHeaderProps {
    onClick: () => void;
    title: string;
    testId?: string;
    ctaPosition?: SidebarHeaderCtaPosition;
    ctaType?: SidebarHeaderCtaType;
}

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
    onClick,
    title,
    testId = 'sidebar-header-heading',
    ctaPosition = SidebarHeaderCtaPosition.LEFT,
    ctaType = SidebarHeaderCtaType.BACK,
}): React.ReactElement => {
    return (
        <>
            <header className={`sidebar-header sidebar-header--arrow-${ctaPosition}`}>
                {ctaType == SidebarHeaderCtaType.BACK && <SidebarArrow onClick={onClick} />}
                {ctaType == SidebarHeaderCtaType.CLOSE && (
                    <Button
                        testId="sidebar-arrow"
                        ariaLabel="Close navigation"
                        icon="fa-kit fa-prizeout-close"
                        isElement={true}
                        onClick={onClick}
                        className="sidebar-header__close"
                    />
                )}
                <h2 data-testid={testId}>{title}</h2>
            </header>
        </>
    );
};
