import React, { ChangeEventHandler } from 'react';

import './radio-button-input.scss';

interface RadioButtonInputProps {
    id: string;
    isChecked: boolean;
    isDisabled?: boolean;
    name: string; // Should have same name for group
    onChange: ChangeEventHandler<HTMLInputElement>;
    register?: Record<string, unknown>;
    title: string; // Provide more info on hover
    value: string; // Data that gets sent on submit
}

export const RadioButtonInput: React.FC<RadioButtonInputProps> = ({
    id,
    isChecked,
    isDisabled,
    name,
    onChange,
    register,
    title,
    value,
}): React.ReactElement => {
    return (
        <div className="radio-button-input">
            <input
                checked={isChecked}
                className="radio-button-input__original"
                data-testid={`radio-button-input-${id}`}
                disabled={isDisabled}
                id={id}
                name={name}
                onChange={onChange}
                title={title}
                type="radio"
                value={value}
                {...register}
            />
            <span className="radio-button-input__imitation"></span>
        </div>
    );
};
