import React, { useState, useEffect, useRef } from 'react';
import Classnames from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { requestPasswordResetEmail } from '../../store/slices/user-slice';
import { selectAuthEmail } from '../../store/slices/partner-config-slice';
import { AppDispatch } from '../../store/widget/widget';
import { useAppSelector, useGoToRoute } from '../../hooks';
import { routes } from '../../utils/helpers/routing-helpers';
import { FormHeader, AlertCallout, ButtonColor, ButtonSize, ButtonType, Button } from '../common';
import { AlertCalloutMessageTypes } from '../common/ui-widgets/alert-callout';

import './request-password-email-form.scss';

interface RequestResetPWFormProps {
    logInOverride?: () => void;
}

export interface RequestPasswordResetFormRequest {
    email: string;
    recaptchaToken?: string;
}

export const RequestPasswordResetForm: React.FC<RequestResetPWFormProps> = ({ logInOverride }): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>(),
        [message, setMessage] = useState(''),
        [messageType, setMessageType] = useState(AlertCalloutMessageTypes.SUCCESS),
        [isLoading, setIsLoading] = useState(false),
        authEmail = useAppSelector(selectAuthEmail),
        classes: string = Classnames(`form__input-wrapper`, {
            'form__input-wrapper--error': messageType === AlertCalloutMessageTypes.ERROR,
        });

    const { goToRoute } = useGoToRoute();
    const recaptchaRef: any = useRef(null);

    useEffect(() => {
        document.title = 'Prizeout Password Reset';
        reset();
    }, []);

    useEffect(() => {
        setValue('email', authEmail);
    }, [authEmail]);

    const routeToLogin = () => {
        if (logInOverride) {
            logInOverride();
            return;
        }

        goToRoute(routes.DTC_HOME);
    };

    const { setValue, reset, register, handleSubmit } = useForm<RequestPasswordResetFormRequest>(),
        onSubmit: SubmitHandler<RequestPasswordResetFormRequest> = async (data) => {
            const { email } = data;

            await recaptchaRef.current.execute();

            try {
                setIsLoading(true);
                await dispatch(
                    requestPasswordResetEmail({
                        email,
                        recaptchaToken: recaptchaRef.current.getValue(),
                    }),
                ).unwrap();
                setMessageType(AlertCalloutMessageTypes.SUCCESS);
                setMessage('An email has been sent to you with a link to reset your password.');
            } catch (e) {
                setMessageType(AlertCalloutMessageTypes.ERROR);
                setMessage(e.message);
            } finally {
                setIsLoading(false);
            }
        };

    return (
        <>
            <FormHeader
                formName="forgotPassword-modal"
                headline="Forgot your password?"
                paragraph="No worries. We'll send you a link in your email in order to reset your password."
            />

            {message && <AlertCallout hasBorder message={message} type={messageType} className="mb-m" />}
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                {(message === '' || (message && messageType !== AlertCalloutMessageTypes.SUCCESS)) && (
                    <div className={classes}>
                        <input
                            {...register('email', {
                                required: {
                                    message: 'Email address required',
                                    value: true,
                                },
                            })}
                            aria-required="true"
                            type="email"
                            name="email"
                            id="email-request-password"
                            placeholder="Email address"
                            className="form__input"
                            defaultValue={authEmail}
                            autoComplete="email"
                        />
                        <label className="form__label" htmlFor="email-request-password">
                            Email address
                        </label>
                    </div>
                )}
                <small>
                    <Button
                        ariaLabel="I remember my password"
                        isLink
                        text="I remember my password"
                        onClick={() => routeToLogin()}
                    />
                </small>
                {(message === '' || (message && messageType !== AlertCalloutMessageTypes.SUCCESS)) && (
                    <div className="form__button-wrapper">
                        <Button
                            ariaLabel="Send reset email"
                            color={ButtonColor.PRIMARY}
                            size={ButtonSize.MEDIUM}
                            text="Send reset email"
                            type={ButtonType.SUBMIT}
                            isLoading={isLoading}
                        ></Button>
                    </div>
                )}
                <ReCAPTCHA
                    sitekey={process.env.INVISIBLE_RECAPTCHA_SITE_KEY || '6Ld_MoslAAAAAMwpQCRcHXzdBBlmjj92mvwdsv5j'}
                    size="invisible"
                    ref={recaptchaRef}
                />
            </form>
        </>
    );
};
