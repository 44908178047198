import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../../hooks';
import { Button, ButtonType } from '../buttons';
import ClassNames from 'classnames';
import './general-input.scss';

export interface GeneralInputControls {
    getValue: () => string;
    focus: () => void;
    blur: () => void;
    clearInput: () => void;
}

interface GeneralInputProps {
    name: string;
    placeholderText: string;
    icon?: string;
    shouldShowClear?: boolean;
    clearLabel?: string;
    initialValue?: string;
    onChange?: (arg0: string) => void;
    onCompleted?: (arg0: string) => void;
    onFocus?: (arg0: GeneralInputControls) => void;
    onClear?: (arg0: GeneralInputControls) => void;
    onMount?: (arg0: GeneralInputControls) => void;
    debounceDelay?: number;
}

export const GeneralInput: React.FC<GeneralInputProps> = ({
    placeholderText,
    name,
    icon,
    shouldShowClear = true,
    clearLabel = 'Clear',
    initialValue = '',
    onChange,
    onCompleted,
    onFocus,
    onClear,
    onMount,
    debounceDelay = 200,
}): React.ReactElement => {
    const inputRef = useRef<HTMLInputElement>();
    const [isClearShowing, setIsClearShowing] = useState(shouldShowClear && initialValue.length !== 0);
    const [controls, setControls] = useState<GeneralInputControls>({
        getValue: () => null,
        focus: () => null,
        blur: () => null,
        clearInput: () => null,
    });

    useEffect(() => {
        setControls({
            getValue,
            focus,
            blur,
            clearInput,
        });
    }, []);

    useEffect(() => {
        onMount?.call(null, controls);
    }, [controls]);

    useEffect(() => {
        if (inputRef.current && !inputRef.current.value) {
            setIsClearShowing(false);
        }
    }, [inputRef.current?.value]);

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const blur = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const getValue = () => {
        return inputRef.current ? inputRef.current.value : '';
    };

    const resetShowClear = () => {
        if (shouldShowClear) {
            setIsClearShowing(getValue().length !== 0);
        }
    };

    const [keyUpHandler] = useDebounce(() => {
        resetShowClear();
    }); // The default delay is probably fine in this case.

    const [changeHandler] = useDebounce(() => {
        resetShowClear();
        onChange?.call(null, getValue());
    }, debounceDelay);

    const completed = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        resetShowClear();
        if (evt.key === 'Enter') {
            evt.preventDefault();
            onCompleted?.call(null, getValue());
        }
    };

    const focused = () => {
        resetShowClear();
        onFocus?.call(null, controls);
    };

    const clearInput = () => {
        inputRef.current.value = '';
        resetShowClear();
        onClear?.call(null, controls);
        onChange?.call(null, getValue());
        focus();
    };

    const inputClasses = ClassNames('general-input__input', {
        'general-input__input--show-icon': !!icon,
    });

    return (
        <div className="general-input">
            {icon && (
                <div className="general-input__icon">
                    <i className={icon}></i>
                </div>
            )}
            <input
                ref={inputRef}
                className={inputClasses}
                name={name}
                defaultValue={initialValue}
                placeholder={placeholderText}
                onKeyUp={keyUpHandler}
                onChange={changeHandler}
                onKeyDown={completed}
                onFocus={focused}
                autoComplete="off"
            />
            {isClearShowing && (
                <Button
                    testId="clear-button"
                    className="general-input__clear"
                    ariaLabel={clearLabel}
                    isElement
                    onClick={clearInput}
                    type={ButtonType.RESET}
                />
            )}
        </div>
    );
};

export default GeneralInput;
