import { NavigationItemConfig } from '../../components/common/ui-widgets/navigation';
import { DashboardLayoutProp, isNotDefined } from '../types';

export const normalizeLayout = (
    layout: DashboardLayoutProp[],
    config: NavigationItemConfig[],
): NavigationItemConfig[] => {
    return config.reduce((newConfig, configItem) => {
        if (!configItem.id) {
            return [...newConfig, { ...configItem }];
        }

        if (isNotDefined(layout)) {
            return newConfig;
        }

        const layoutItem = layout.find((item) => item.tab === configItem.id);

        if (layoutItem) {
            // Copy everything to be sure we don't overwrite stuff.
            return [
                ...newConfig,
                {
                    ...configItem,
                },
            ];
        }

        return newConfig;
    }, []);
};
