import { BonusTagType } from '../components/common/ui-widgets/bonus-tag';
import { useMemo } from 'react';
import { PrizeoutOfferValueOptions } from '../store/slices/offers-slice';

export function useActiveCardBonusInfo(activeCard: PrizeoutOfferValueOptions) {
    const { bonusType, bonusValue } = useMemo(() => {
        const bonusType = activeCard?.display_monetary_bonus ? BonusTagType.MONETARY : BonusTagType.PERCENTAGE;
        const bonusValue =
            bonusType === BonusTagType.MONETARY ? activeCard?.display_monetary_bonus : activeCard?.display_bonus;

        return {
            bonusType,
            bonusValue,
        };
    }, [activeCard]);

    return {
        bonusType,
        bonusValue,
    };
}
