import React from 'react';
import ReactModal from 'react-modal';
import Classnames from 'classnames';

interface GenericModalWithViewsProps {
    ariaDescribedById?: string; // This ID needs to match the id on the children's content div or paragraph tag, don't include if no supporting copy. ie. aria-${formName}-description
    ariaLabelledById?: string; // This ID needs to match the id on the children's headline. ie. aria-${formName}-headline
    ariaLabel: string; // This is a general description of the modal
    customClassName?: string;
    customOverlayClassName?: string;
    isOpen: boolean;
    onClose: () => void;
    portalClassName?: string; // Class name of parent wrapper
    testId?: string;
}

export const GenericModalWithViews: React.FC<GenericModalWithViewsProps> = ({
    ariaDescribedById,
    ariaLabelledById,
    ariaLabel,
    children,
    customClassName,
    customOverlayClassName,
    isOpen,
    onClose,
    portalClassName = 'generic-modal-with-views',
    testId,
}): React.ReactElement => {
    const defaultTestId = 'generic-modal-with-views';
    const className: string = Classnames('modal z-index-modal-full', customClassName);
    const overlayClassName: string = Classnames('overlay z-index-overlay-full', customOverlayClassName);

    return (
        <ReactModal
            aria={{
                describedby: ariaDescribedById,
                labelledby: ariaLabelledById,
            }}
            ariaHideApp={false}
            bodyOpenClassName="modal-body-open"
            className={className}
            contentLabel={ariaLabel}
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            overlayClassName={overlayClassName}
            portalClassName={portalClassName}
            testId={testId || defaultTestId}
        >
            <>{children}</>
        </ReactModal>
    );
};
