import React from 'react';

interface RadioButtonListLayoutProps {
    caption?: string; // Use this if you need a caption to distinguish between radio groups
}

export const RadioButtonListLayout: React.FC<RadioButtonListLayoutProps> = ({
    caption,
    children,
}): React.ReactElement => {
    return (
        <fieldset data-testid="radio-button-list-layout" className="radio-button-list-layout">
            {caption && <legend className="mb-xs">{caption}</legend>}
            <ul className="no-scrollbars">{children}</ul>
        </fieldset>
    );
};
