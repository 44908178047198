import React, { forwardRef } from 'react';
import { Button } from './button';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';

import './back-arrow-button.scss';

interface BackArrowButtonProps {
    onClick: () => void;
    trackingType?: ButtonTrackingTypes;
}

export const BackArrowButton = forwardRef<HTMLButtonElement, BackArrowButtonProps>(
    (
        { onClick, trackingType },
        ref: React.RefCallback<HTMLButtonElement> | React.MutableRefObject<HTMLButtonElement>,
    ): React.ReactElement => {
        return (
            <Button
                ref={ref}
                ariaLabel={'Go back'}
                className={'back-arrow-button'}
                icon={'fak fa-prizeout-back-arrow'}
                isElement
                onClick={onClick}
                testId={'back-arrow-button'}
                trackingType={trackingType}
            />
        );
    },
);
