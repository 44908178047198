import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { AdminPortalRootState } from '../../admin-portal';
import { AdminDashboardPartner, AdminDashboardUser } from './admin-portal-types';

export interface AdminPortalState {
    selectedDashboardUser?: AdminDashboardUser;
    selectedDashboardPartner?: AdminDashboardPartner;
}

export const adminPortalInitialState: AdminPortalState = {
    selectedDashboardUser: null,
    selectedDashboardPartner: null,
};

export const adminPortalSlice = createSlice({
    initialState: adminPortalInitialState,
    name: 'adminPortal',
    reducers: {
        setDashboardUser(state, action: PayloadAction<AdminDashboardUser>) {
            state.selectedDashboardUser = action.payload;
        },
        setDashboardPartner(state, action: PayloadAction<AdminDashboardPartner>) {
            state.selectedDashboardPartner = action.payload;
        },
    },
});

export const { setDashboardUser, setDashboardPartner } = adminPortalSlice.actions;

export const selectAdminPortalState = ({ adminPortal }: AdminPortalRootState): AdminPortalState => adminPortal;

export const selectDashboardUser = createSelector(
    selectAdminPortalState,
    ({ selectedDashboardUser }) => selectedDashboardUser,
);
export const selectDashboardPartner = createSelector(
    selectAdminPortalState,
    ({ selectedDashboardPartner }) => selectedDashboardPartner,
);

export default adminPortalSlice.reducer;
