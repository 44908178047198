import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactModal from 'react-modal';

import App from './components/app';
import { store } from './store/widget/widget';

import 'normalize.css';
import './index.scss';
import { ApplicationModule } from './store/slices/common-slice';
import { getApplicationModule } from './utils';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { partnerPortalStore } from './store/partner-portal/partner-portal';
import { adminPortalStore } from './store/admin-portal';
import { cpStore } from './store/claim-page';
import { sfStore } from './store/store-front';
TimeAgo.addDefaultLocale(en);

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [
                'localhost',
                'widget-v2.prizeout.com',
                'sandbox.widget-v2.prizeout.com',
                'sandbox.uk.widget-v2.prizeout.com',
                'candidate.widget-v2.prizeout.com',
                'dev.widget-v2.prizeout.com',
                'uk.widget-v2.prizeout.com',

                'dev-dtc-services.prizeout.com',
                'candidate-dtc-services.prizeout.com',
                'sandbox-dtc-services-7sdv3lbjfq-ue.a.run.app',
                'preprod-dtc-services.prizeout.com',
                'dtc-services.prizeout.com',

                'sandbox-uk-dtc-services-7sdv3lbjfq-ew.a.run.app',
                'prod-uk-dtc-services.prizeout.com',

                'us-central1-prizeout-non-prod.cloudfunctions.net/dev-widget-app-py',
                'us-east1-prizeout-non-prod.cloudfunctions.net/candidate-widget-app-py',
                'us-central1-prizeout.cloudfunctions.net/sandbox-widget-app-py',
                'us-east1-prizeout.cloudfunctions.net/prod-widget-app-py',

                'europe-west2-prizeout.cloudfunctions.net/sandbox-widget-app-py',
                'europe-west2-prizeout.cloudfunctions.net/prod-widget-app-py',
            ],
        }),
        new Sentry.Replay({
            blockAllMedia: false,
            maskAllInputs: false,
            maskAllText: true,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// Need to add this for accessibility, to hide other content from screen readers if modal is opened
ReactModal.setAppElement('#root');

const application = getApplicationModule();

const appStore = {
    [ApplicationModule.ACCOUNTS]: store,
    [ApplicationModule.ADMIN]: adminPortalStore,
    [ApplicationModule.BROWSER_EXTENSION]: store,
    [ApplicationModule.CLAIM]: cpStore,
    [ApplicationModule.PARTNER_PORTAL]: partnerPortalStore, // update to partner portal store...
    [ApplicationModule.REWARDS]: store,
    [ApplicationModule.STORE_FRONT]: sfStore,
    [ApplicationModule.WIDGET]: store,
}[application];

ReactDOM.render(
    <Provider store={appStore}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root'),
);
