import React, { useMemo } from 'react';
import {
    PrizeoutProductVersion,
    selectPrizeoutProductVersion,
    selectProductName,
} from '../../../store/slices/partner-config-slice';
import { useAppSelector } from '../../../hooks';
import { GenericModal } from './generic-modal';
import { CheckoutInfoStepTemplate } from '../../features/onboarding/checkout-info-step';
import { CheckoutInfoSteps } from '../../features/onboarding/checkout-info-steps';

interface CheckoutInfoModalProps {
    isOpen: boolean;
    testId?: string;
    onClose?: () => void;
}

export const CheckoutInfoModal: React.FC<CheckoutInfoModalProps> = ({
    isOpen,
    testId,
    onClose,
}): React.ReactElement => {
    const prizeoutProductVersion = useAppSelector(selectPrizeoutProductVersion);
    const partnerProductName = useAppSelector(selectProductName);

    const headlineText: string = useMemo(() => {
        if (prizeoutProductVersion === PrizeoutProductVersion.STANDARD) {
            return 'How does Prizeout work?';
        }

        return `How does ${partnerProductName} work?`;
    }, [prizeoutProductVersion, partnerProductName]);

    const lineItems: CheckoutInfoStepTemplate[] = useMemo(() => {
        const partnerName =
            prizeoutProductVersion === PrizeoutProductVersion.STANDARD ? 'Prizeout' : partnerProductName;

        const templateLines: CheckoutInfoStepTemplate[] = [
            {
                [PrizeoutProductVersion.STANDARD]: true,
                [PrizeoutProductVersion.BONUS]: true,
                [PrizeoutProductVersion.CASHBACK]: true,
                [PrizeoutProductVersion.REWARDS]: true,
                icon: 'fa-sharp fa-light fa-circle-dollar',
                text: `Choose your gift card amount.`,
            },
            {
                [PrizeoutProductVersion.STANDARD]: false,
                [PrizeoutProductVersion.BONUS]: true,
                [PrizeoutProductVersion.CASHBACK]: true,
                [PrizeoutProductVersion.REWARDS]: true,
                icon: 'fa-sharp fa-light fa-building-columns',
                text: `Pay with your account.`,
            },
            {
                [PrizeoutProductVersion.STANDARD]: true,
                [PrizeoutProductVersion.BONUS]: true,
                [PrizeoutProductVersion.CASHBACK]: false,
                [PrizeoutProductVersion.REWARDS]: true,
                icon: 'fa-light fa-chart-mixed-up-circle-dollar',
                text: `Get bonus value instantly on your purchase`,
            },
            {
                [PrizeoutProductVersion.STANDARD]: true,
                [PrizeoutProductVersion.BONUS]: true,
                [PrizeoutProductVersion.CASHBACK]: true,
                [PrizeoutProductVersion.REWARDS]: true,
                icon: 'fa-light fa-gift-card',
                text: `Access your digital gift card from your email or your ${partnerName} account.`,
            },
            {
                [PrizeoutProductVersion.STANDARD]: false,
                [PrizeoutProductVersion.BONUS]: false,
                [PrizeoutProductVersion.CASHBACK]: true,
                [PrizeoutProductVersion.REWARDS]: true,
                icon: 'fa-light fa-chart-mixed-up-circle-dollar',
                text: `Get cashback instantly in your ${partnerName} account.`,
            },
        ];

        const actualText = templateLines.filter((lineItem) => {
            return lineItem[prizeoutProductVersion];
        });

        return actualText;
    }, [prizeoutProductVersion, partnerProductName]);

    return (
        <GenericModal
            testId={testId}
            ariaLabel="Checkout Info Modal"
            isOpen={isOpen}
            onClose={onClose}
            hasExitButton={true}
            headline={headlineText}
            portalClassName="checkout-info-modal"
            primaryButtonText="Got It"
            primaryOnClick={onClose}
        >
            <CheckoutInfoSteps steps={lineItems} />
        </GenericModal>
    );
};
