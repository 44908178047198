import { displayOfferType } from '../../store/slices/offers-slice';

// applying a correction for floating point arithmatic errors by rounding the third decimal
// this is to match the backend logic
const FLOATING_POINT_ROUNDING_CORRECTION_FACTOR = 3;

export interface CustomDenomNormalizedValues {
    costInCents: number;
    valueInCents: number;
    offerInCents: number;
}

export interface CustomInputAmounts {
    cost?: number;
    value?: number;
}

const hydrateCustomInputs = (customInputAmountInCents: CustomInputAmounts) => ({
    costInCents: customInputAmountInCents?.cost,
    valueInCents: customInputAmountInCents?.value,
});

const sanitizePercentageBonus = (percentageBonus: number) => (percentageBonus ? percentageBonus / 100 : 0);

const sanitizeCustomDenomNormalizedValues = (data: CustomDenomNormalizedValues): CustomDenomNormalizedValues => {
    return {
        ...data,
        costInCents: Math.floor(data.costInCents), // Lower the cost the nearest cent
        offerInCents: Math.ceil(data.offerInCents), // Increase the offer to the nearest cent
    };
};

const normalizeCashbackValues = (
    costInCents: number,
    valueInCents: number,
    monetaryBonus: number,
    percentageBonus: number,
): CustomDenomNormalizedValues => {
    const monetaryBonusInCents = monetaryBonus * 100;

    // If cashback, cost and value are equal
    if (valueInCents) {
        costInCents = valueInCents;
    } else {
        valueInCents = costInCents;
    }

    const offerInCents = monetaryBonus
        ? monetaryBonusInCents
        : parseFloat((costInCents * percentageBonus).toFixed(FLOATING_POINT_ROUNDING_CORRECTION_FACTOR));

    return sanitizeCustomDenomNormalizedValues({
        costInCents,
        valueInCents,
        offerInCents,
    });
};

const normalizeBonusValuesForMonetaryBonus = (
    costInCents: number,
    valueInCents: number,
    monetaryBonus: number,
): CustomDenomNormalizedValues => {
    const monetaryBonusInCents = monetaryBonus * 100;
    const offerInCents = monetaryBonusInCents;

    // If no valueInCents, set valueInCents based on monetaryBonus
    if (costInCents) {
        valueInCents = costInCents + monetaryBonusInCents;
    }

    costInCents = valueInCents - monetaryBonusInCents;

    return sanitizeCustomDenomNormalizedValues({
        costInCents,
        valueInCents,
        offerInCents,
    });
};

const normalizeBonusValuesForPercentageBonus = (
    costInCents: number,
    valueInCents: number,
    percentageBonus: number,
): CustomDenomNormalizedValues => {
    const discount = 1 - percentageBonus;

    // If no valueInCents, set valueInCents based on percentageBonus
    if (costInCents) {
        valueInCents = Math.ceil(
            parseFloat((costInCents / discount).toFixed(FLOATING_POINT_ROUNDING_CORRECTION_FACTOR)),
        );
    } else {
        costInCents = parseFloat((valueInCents * discount).toFixed(FLOATING_POINT_ROUNDING_CORRECTION_FACTOR)); // for the fi_bonus flow we are returning discounts
    }

    const offerInCents = valueInCents * percentageBonus;

    return sanitizeCustomDenomNormalizedValues({
        costInCents,
        valueInCents,
        offerInCents,
    });
};

const normalizeBonusValues = (
    costInCents: number,
    valueInCents: number,
    monetaryBonus: number,
    percentageBonus: number,
): CustomDenomNormalizedValues =>
    monetaryBonus
        ? normalizeBonusValuesForMonetaryBonus(costInCents, valueInCents, monetaryBonus)
        : normalizeBonusValuesForPercentageBonus(costInCents, valueInCents, percentageBonus);

/*
    WARNING:
        this does not work for currency conversions. This is intended for use with single currency type only!
        this function also rounds to be advantageous to the user
    params:
        offerType: displayOfferType ex. cashback vs bonus
        monetaryBonus: ($) fixed amount bonus
        percentageBonus: (%) percentage bonus
        customInputAmountInCents: the custom value or cost the user inputs
 */

export const normalizeCustomDenomValues = (
    offerType: displayOfferType,
    monetaryBonus: number,
    percentageBonus: number,
    customInputAmountInCents: CustomInputAmounts,
): CustomDenomNormalizedValues => {
    const { costInCents, valueInCents } = hydrateCustomInputs(customInputAmountInCents);
    percentageBonus = sanitizePercentageBonus(percentageBonus);

    return offerType == displayOfferType.CASHBACK
        ? normalizeCashbackValues(costInCents, valueInCents, monetaryBonus, percentageBonus)
        : normalizeBonusValues(costInCents, valueInCents, monetaryBonus, percentageBonus);
};
