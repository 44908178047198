import React from 'react';

import './form-header.scss';

interface FormHeaderProps {
    formName?: string; // Use kebab case. ie. log-in-form.. only need if using in modal
    headline?: string;
    paragraph?: string;
}

export const FormHeader: React.FC<FormHeaderProps> = ({ formName, headline, paragraph }): React.ReactElement => {
    const ariaLabelledById = `${formName}-headline`;
    const ariaDescribedById = `${formName}-description`;

    return (
        <header className="form-header">
            <h1 id={formName && ariaLabelledById} className="form-header__headline">
                {headline}
            </h1>
            {paragraph && (
                <p id={formName && ariaDescribedById} className="form-header__paragraph">
                    {paragraph}
                </p>
            )}
        </header>
    );
};
