import React, { forwardRef } from 'react';
import Classnames from 'classnames';
import { ButtonTrackingTypes, TrackingService } from '../../../utils/services/tracking';
import { ButtonColor, ButtonIconPosition, ButtonSize, ButtonType } from './types';

import { useRefAssigner } from '../../../hooks/use-ref-assigner';
import './button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    autoFocus?: boolean; // Should autoFocus, ie. first clickable item in modal
    ariaLabel?: string; // Only needed if button text is not obvious what the button is doing. This aria label will replace the button content in assistive technology for screenreaders
    className?: string;
    color?: ButtonColor;
    form?: string; // Only use if you need to pull a button out from a form. Needs to match form id. If this is enabled, must not have an onClick event.
    icon?: string;
    iconPosition?: ButtonIconPosition;
    id?: string;
    isDisabled?: boolean;
    isElement?: boolean;
    isFullWidth?: boolean;
    isInline?: boolean;
    isLink?: boolean; // Button that looks like a link, keep in mind all external links should use the ExternalLink component instead
    isLoading?: boolean;
    onClick?: () => void;
    size?: ButtonSize;
    testId?: string;
    text?: string;
    type?: ButtonType;
    trackingType?: ButtonTrackingTypes;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            autoFocus,
            ariaLabel,
            children,
            className,
            color,
            form,
            icon,
            iconPosition = ButtonIconPosition.RIGHT,
            id,
            isDisabled,
            isElement,
            isFullWidth,
            isInline,
            isLink,
            isLoading,
            onClick,
            size,
            testId,
            text,
            type = ButtonType.BUTTON,
            trackingType,
            ...rest
        },
        ref: React.RefCallback<HTMLButtonElement> | React.MutableRefObject<HTMLButtonElement>,
    ): React.ReactElement => {
        const hasTextWithIcon = text && icon;
        const textClasses: string = Classnames('button__text', { 'button__text--with-icon': hasTextWithIcon });
        const defaultTestId = 'button';
        const classes: string = Classnames(
            'button',
            { [`button--${color}`]: color },
            { [`button--${size}`]: size },
            { [`button--icon-position-${iconPosition}`]: hasTextWithIcon },
            { 'button--disabled': isDisabled },
            { 'button--element': isElement },
            { 'button--full-width': isFullWidth },
            { 'button--link': isLink },
            { 'button--inline': isInline },
            { 'button--loading': isLoading },
            className,
        );

        const trackingService = !!trackingType ? TrackingService.getTrackingService() : null;

        const refAssigner = useRefAssigner<HTMLButtonElement>(ref);

        const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();

            if (trackingService) {
                trackingService.trackButtonClick(trackingType, type, ariaLabel);
            }

            onClick?.call(null);
        };

        return (
            <button
                autoFocus={autoFocus}
                ref={refAssigner}
                data-testid={testId || defaultTestId}
                form={form}
                onClick={(e) => clickHandler(e)}
                id={id}
                type={type}
                className={classes}
                aria-label={ariaLabel}
                title={text}
                disabled={isDisabled || isLoading}
                tabIndex={rest?.tabIndex}
            >
                {isLoading && <i data-testid="button-loading" className="fas fa-spinner fa-spin"></i>}

                {!isLoading && (
                    <>
                        {text && <span className={textClasses}>{text}</span>}

                        {icon && <i className={icon}></i>}

                        {children}
                    </>
                )}
            </button>
        );
    },
);
