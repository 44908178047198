import React, { useMemo } from 'react';
import ClassNames from 'classnames';
import { countryConstants } from '../../../utils/constants/countries';
import { currencyConstants } from '../../../utils/constants/currencies';
import { SelectDropdown } from '../select-dropdown/select-dropdown';
import { SDCOption } from '../select-dropdown/sdc-option/sdc-option-wrapper';
import { CurrencySelectorOption, CurrencySelectorOptions } from './currency-selector-option';

interface CurrencySelectorProps {
    className?: string;
    defaultCurrency?: string;
    currencyFilter?: string[];
    onChange?: (arg: string) => void;
}

export const CurrencySelector: React.FC<CurrencySelectorProps> = ({
    className,
    defaultCurrency,
    currencyFilter,
    onChange,
}): React.ReactElement => {
    const classNames = ClassNames('currency-selector__selector', className);
    const options = useMemo<CurrencySelectorOptions[]>((): CurrencySelectorOptions[] => {
        let currencyList = Object.keys(currencyConstants.CountryCodeKeyedByCurrency);

        if (currencyFilter) {
            currencyList = currencyList.filter((currency) => {
                return currencyFilter.includes(currency);
            });
        }

        return currencyList.map((currency): CurrencySelectorOptions => {
            const countryCode = currencyConstants.CountryCodeKeyedByCurrency[currency];
            const flagCharacter = countryConstants.FlagsKeyedByCountryCode[countryCode];
            const countryName = countryConstants.LocationsKeyedByCountryCode[countryCode];

            const option: CurrencySelectorOptions = {
                value: currency,
                label: flagCharacter,
                selected: currency === defaultCurrency,
                data: {
                    flagCharacter: flagCharacter,
                    countryName: countryName,
                },
            };

            return option;
        });
    }, [currencyFilter]);

    const selectHandler = (option: SDCOption<null>) => {
        onChange?.call(null, option.value);
    };

    return (
        <div className="currency-selector">
            <SelectDropdown
                ariaLabel="Currency selector"
                testId="currency-selector-dropdown"
                className={classNames}
                onSelect={selectHandler}
                options={options}
                ListItemComponent={CurrencySelectorOption}
            />
        </div>
    );
};
