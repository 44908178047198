import React from 'react';

import './image-with-heading.scss';

interface ImageWithHeadingProps {
    imageAltText?: string;
    imageUrl: string;
    testId?: string;
    text: string;
}

export const ImageWithHeading: React.FC<ImageWithHeadingProps> = ({
    imageAltText = '',
    imageUrl,
    testId = 'image-with-heading',
    text,
}): React.ReactElement => {
    return (
        <div className="image-with-heading">
            <img data-testid={testId} src={imageUrl} alt={imageAltText} />
            <h1>{text}</h1>
        </div>
    );
};
