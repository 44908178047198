import React, { forwardRef } from 'react';
import Classnames from 'classnames';
import { Button } from './button';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';

import './close-button.scss';

export enum CloseButtonPosition {
    START = 'start',
    CENTER = 'center',
    END = 'end',
}

export enum CloseButtonSize {
    SMALL = 'small',
    DEFAULT = 'default',
}

interface CloseButtonProps {
    autoFocus?: boolean; // Should autoFocus, ie. first clickable item in modal
    className?: string;
    onClick: () => void;
    position?: CloseButtonPosition;
    trackingType?: ButtonTrackingTypes;
    size?: CloseButtonSize;
}

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
    (
        { autoFocus, className, onClick, position, trackingType, size = CloseButtonSize.DEFAULT },
        ref: React.RefCallback<HTMLButtonElement> | React.MutableRefObject<HTMLButtonElement>,
    ): React.ReactElement => {
        const defaultPosition = CloseButtonPosition.END;

        const classes: string = Classnames(
            'close-button',
            { [`close-button--${position || defaultPosition}`]: position || defaultPosition },
            { [`close-button--size-${size}`]: size },
            className,
        );

        return (
            <div className={classes}>
                <Button
                    autoFocus={autoFocus}
                    ref={ref}
                    ariaLabel="Close current view"
                    icon="far fa-times"
                    isElement
                    onClick={onClick}
                    testId="close-button"
                    trackingType={trackingType}
                />
            </div>
        );
    },
);
