import { useDispatch } from 'react-redux';
import { useAppSelector } from '../use-app-selector';
import { AppDispatch } from '../../store/widget/widget';
import {
    OfferSortType,
    PrizeoutOffer,
    selectCategoryOfferSort,
    setCategoryOfferSort,
} from '../../store/slices/offers-slice';
import { BonusTagType } from '../../components/common';

export interface OfferSortOption {
    label: string;
    heuristic: (list: PrizeoutOffer[]) => PrizeoutOffer[];
    type: OfferSortType;
}

export function useOfferSort(): {
    activeSortType: OfferSortType;
    sortList: (list: PrizeoutOffer[]) => PrizeoutOffer[];
    sortOptions: OfferSortOption[];
    setActiveSortType: (sortType: OfferSortType) => void;
} {
    const dispatch = useDispatch<AppDispatch>();

    const activeSortType = useAppSelector(selectCategoryOfferSort);

    const sortFn = (
        heuristic: (a: PrizeoutOffer, b: PrizeoutOffer) => number,
        list: PrizeoutOffer[],
    ): PrizeoutOffer[] => {
        const result = [...list];
        result.sort(heuristic);
        return result;
    };

    const sortOptions: OfferSortOption[] = [
        {
            label: 'Recommended (default)',
            heuristic: sortFn.bind(null, () => {
                return;
            }),
            type: OfferSortType.RECOMMENDED,
        },
        {
            label: 'Cashback',
            heuristic: sortFn.bind(null, (a: PrizeoutOffer, b: PrizeoutOffer) => {
                const offerTypeA = a.giftcard_list[0].display_monetary_bonus
                    ? BonusTagType.MONETARY
                    : BonusTagType.PERCENTAGE;
                const offerTypeB = b.giftcard_list[0].display_monetary_bonus
                    ? BonusTagType.MONETARY
                    : BonusTagType.PERCENTAGE;
                const keyA =
                        offerTypeA === BonusTagType.MONETARY
                            ? a.giftcard_list[0].display_monetary_bonus
                            : a.giftcard_list[0].display_bonus,
                    keyB =
                        offerTypeB === BonusTagType.MONETARY
                            ? b.giftcard_list[0].display_monetary_bonus
                            : b.giftcard_list[0].display_bonus;
                if (keyA < keyB) {
                    return 1;
                }
                if (keyA > keyB) {
                    return -1;
                }
                return 0;
            }),
            type: OfferSortType.BEST_DEALS,
        },
        {
            label: 'A-Z',
            heuristic: sortFn.bind(null, (a: PrizeoutOffer, b: PrizeoutOffer) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
            ),
            type: OfferSortType.A_Z,
        },
        {
            label: 'Z-A',
            heuristic: sortFn.bind(null, (a: PrizeoutOffer, b: PrizeoutOffer) =>
                a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1,
            ),
            type: OfferSortType.Z_A,
        },
    ];

    const sortList = (list: PrizeoutOffer[]) => {
        const sortTypeEntry = sortOptions.find((opt) => opt.type === activeSortType);
        return sortTypeEntry.heuristic(list);
    };

    const setActiveSortType = (newType: OfferSortType) => {
        dispatch(setCategoryOfferSort(newType));
    };

    return {
        activeSortType,
        setActiveSortType,
        sortList,
        sortOptions,
    };
}
