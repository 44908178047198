import Classnames from 'classnames';
import { DeepMap, FieldError } from 'react-hook-form';
import { ErrorStateManagerType } from '../../reducers';
import { CRMContentData } from '../../store/slices/crm-emulator';

export const determineClasses = <Type>(
    inputName: string,
    errors: DeepMap<Type, FieldError>,
    allowOverflow?: boolean,
): string => {
    const inputErrorExists = errors[inputName as keyof typeof errors];
    return Classnames(
        `form__input-wrapper`,
        { 'form__input-wrapper--error': inputErrorExists },
        { 'form__input-wrapper--allow-overflow': allowOverflow },
    );
};

export const submitFormOnEnter = (event: React.KeyboardEvent<HTMLInputElement>, handler: () => void) => {
    if (event.key == 'Enter') {
        event.preventDefault();
        handler();
    }
};

export const updateCTAList = (dataObject: CRMContentData, value: string, index: number): CRMContentData => ({
    ...dataObject,
    cta_list: dataObject.cta_list?.map((cta, i) =>
        i === index
            ? {
                  ...cta,
                  cta_label: value,
              }
            : cta,
    ),
});

export const updateTextList = (dataObject: CRMContentData, value: string, index: number): CRMContentData => ({
    ...dataObject,
    text_list: dataObject.text_list
        ? {
              ...dataObject.text_list,
              text: dataObject.text_list.text?.map((textItem, i) =>
                  i === index
                      ? {
                            ...textItem,
                            title: value,
                        }
                      : textItem,
              ),
          }
        : dataObject.text_list,
});

export const updateText = (dataObject: CRMContentData, value: string, index: number): CRMContentData => {
    const currentText = dataObject.text?.text;

    const newText = Array.isArray(currentText) ? currentText.map((line, i) => (i === index ? value : line)) : value;

    return {
        ...dataObject,
        text: {
            ...dataObject.text,
            text: newText,
        },
    };
};

export const updateCTA = (dataObject: CRMContentData, value: string): CRMContentData => {
    return {
        ...dataObject,
        cta: {
            ...dataObject.cta,
            cta_label: value,
        },
    };
};

export const updateObject = (
    dataObject: CRMContentData,
    value: string,
    field: keyof CRMContentData,
    index: number,
): CRMContentData => {
    const fieldData = dataObject[field];
    if (typeof fieldData !== 'object') {
        return dataObject;
    }

    const key = Object.keys(fieldData)[index];
    return {
        ...dataObject,
        [field]: {
            ...fieldData,
            [key]: value,
        },
    };
};

export const validateForEmptyString = (value: string) => {
    return !!value.trim();
};

type ValidationFunction = (value: string) => boolean;
export const validateWithMessage = (validationFn: ValidationFunction, errMsg: string) => {
    return (value: string) => {
        return validationFn(value) || errMsg;
    };
};

export const requireWithMessage = (validationFn: ValidationFunction, errMsg: string) => {
    return {
        required: {
            message: errMsg,
            value: true,
        },
        validate: validateWithMessage(validationFn, errMsg),
    };
};

export const clearWhiteSpace = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.trim();
};

export const verifyNativeValidation = (manager: ErrorStateManagerType) => {
    return () => {
        const [errorState] = manager;

        if (errorState.isError) {
            return errorState.message;
        }

        return true;
    };
};

export const getMessageFromError = (error: FieldError): string => {
    if (error.message) {
        return error.message;
    }

    if (error.ref instanceof HTMLInputElement) {
        if (error.ref.validationMessage) {
            return error.ref.validationMessage;
        }
    }

    return 'Unexpected validation error';
};
