// https://www.npmjs.com/package/react-transition-state

export enum TransitionStatus {
    PRE_ENTER = 'preEnter',
    ENTERING = 'entering',
    ENTERED = 'entered',
    PRE_EXIT = 'preExit',
    EXITING = 'exiting',
    EXITED = 'exited',
    UNMOUNTED = 'unmounted',
}

export const TRANSITION_STANDARD_DELAY = 250; // Need delay to be low to prevent gliches with checkout panel
