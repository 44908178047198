import currencyFormatter from 'currency-formatter';

export enum RoundingType {
    CEIL = 'ceil',
    FLOOR = 'floor',
}

const floor = (input: number): number => {
    return Math.floor(input);
};

const removeDecimals = (input: number): string => {
    return fixDecimals(input, 0);
};

const fixDecimals = (input: number, decimals?: number): string => {
    return input.toFixed(decimals || 0);
};

const progressPercent = (current: number, max: number) => {
    const progress = Math.max(0, Math.min(100, (current / max) * 100));

    return Math.round(progress);
};

const convertCentsToDollars = (v: number, roundingType?: RoundingType): string => {
    if (roundingType == RoundingType.CEIL) {
        return (Math.ceil(v) / 100).toString();
    }

    if (roundingType == RoundingType.FLOOR) {
        return (Math.floor(v) / 100).toString();
    }

    return (v / 100).toFixed(2);
};

const convertCentsToDollarsAsNumber = (v: number): number => Number(convertCentsToDollars(v));
const convertDollarsToCents = (v: number): number => v * 100;

const currencyDisplay = (
    currency: string,
    value: number,
    decimals = false,
    currencySymbolOverride?: string,
): string => {
    const { decimalDigits } = currencyFormatter.findCurrency(currency);

    let options = {
        currency: currency,
        minimumFractionDigits: decimals ? decimalDigits : 0,
        maximumFractionDigits: decimals ? decimalDigits : 0,
    };

    if (!currencySymbolOverride) {
        options = {
            ...options,
            ...{
                style: 'currency',
            },
        };
    }

    const numberFormat = new Intl.NumberFormat('en-US', options);

    const formatted = numberFormat.format(value);
    return currencySymbolOverride ? currencySymbolOverride + formatted : formatted;
};

export {
    floor,
    removeDecimals,
    fixDecimals,
    convertCentsToDollars,
    convertCentsToDollarsAsNumber,
    currencyDisplay,
    convertDollarsToCents,
    progressPercent,
};
