import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Navigation, Scrollbar, Mousewheel, Pagination } from 'swiper';
import { GiftCardImage, GiftCardImageView } from '../../../../../components/common';
import { useAppSelector } from '../../../../../hooks';
import { selectIsMobileLandscape } from '../../../../../store/slices/common-slice';

SwiperCore.use([A11y, Navigation, Scrollbar, Mousewheel, Pagination]);
interface BigBrandOfferDisplayProps {
    name: string;
    supportingImages: string[];
}

export const BigBrandCarousel: React.FC<BigBrandOfferDisplayProps> = ({
    name,
    supportingImages,
}): React.ReactElement => {
    SwiperCore.use([A11y, Navigation, Scrollbar, Mousewheel, Pagination]);
    const swiperId = 'swiper-big-brand-supporting-images';
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);

    return (
        <Swiper
            a11y={{ enabled: false }}
            centerInsufficientSlides={true}
            freeMode={false}
            grabCursor={true}
            key={swiperId}
            mousewheel={false}
            navigation={!isMobileLandscape}
            observer={true} // Enable to initialize when slides are visible
            observeParents={true} // Enable to initialize when slides are visible
            scrollbar={{ draggable: true }}
            spaceBetween={8}
            touchRatio={0.5}
            onSlideChange={() => window.event.stopImmediatePropagation()}
            pagination={{
                clickable: true,
            }}
        >
            {supportingImages &&
                supportingImages.map((url, idx) => (
                    <SwiperSlide key={`big-brand-image-${idx}`}>
                        <GiftCardImage
                            imgUrl={url}
                            altText={`${name} gift card`}
                            view={GiftCardImageView.HERO}
                            renderGradient
                        />
                    </SwiperSlide>
                ))}
        </Swiper>
    );
};
