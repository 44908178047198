import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../common';
import {
    ActivateAllRewardsResponse,
    GetRewardsListResponse,
    GetRewardsResponse,
    PostRewardsClaimResponse,
    PostUserRewardRequest,
    RewardsEventGroups,
    UserReward,
} from './rewards-types';

export const rewardsApiSlice = createApi({
    reducerPath: 'rewardsApi',
    baseQuery: baseQuery,
    tagTypes: [RewardsEventGroups.REWARDS],
    endpoints: (builder) => ({
        getRewards: builder.query<GetRewardsResponse, void>({
            query: () => '/rewards',
            providesTags: [RewardsEventGroups.REWARDS],
        }),
        getRewardsList: builder.query<GetRewardsListResponse, void>({
            query: () => '/rewards/v2',
            providesTags: [RewardsEventGroups.REWARDS],
        }),
        getClaimedRewards: builder.query<GetRewardsListResponse, void>({
            query: () => '/rewards/claimed',
            providesTags: [RewardsEventGroups.REWARDS],
        }),
        activateReward: builder.mutation<UserReward, PostUserRewardRequest>({
            query: (userRewardId) => {
                return {
                    url: '/rewards/activate',
                    method: 'POST',
                    body: userRewardId,
                };
            },
            invalidatesTags: [RewardsEventGroups.REWARDS],
        }),
        activateAllRewards: builder.mutation<ActivateAllRewardsResponse, void>({
            query: () => {
                return {
                    url: '/rewards/activateAll',
                    method: 'POST',
                };
            },
            invalidatesTags: [RewardsEventGroups.REWARDS],
        }),
        //to be Deprecated
        claimReward: builder.mutation<PostRewardsClaimResponse, PostUserRewardRequest>({
            query: (userRewardId) => ({
                url: `/rewards/claim`,
                method: 'POST',
                body: userRewardId,
            }),
            invalidatesTags: [RewardsEventGroups.REWARDS],
        }),
    }),
});

export const {
    useGetRewardsQuery,
    useGetRewardsListQuery,
    useGetClaimedRewardsQuery,
    useActivateRewardMutation,
    useActivateAllRewardsMutation,
    useClaimRewardMutation,
} = rewardsApiSlice;
