import React, { Fragment, useEffect, useState } from 'react';
import * as DOMPurify from 'dompurify';
import { ExternalLink, ExternalLinkDisplayTypes } from '../ui-widgets';
import { constants, uuid_v4 } from '../../../utils';
import { CheckboxTrackingTypes, TrackingService } from '../../../utils/services/tracking';
import './checkbox.scss';

interface CheckboxProps {
    content?: string;
    hasLegalTerms?: boolean; // Do not pass content, display terms/privacy instead
    id?: string;
    isRequired?: boolean;
    register?: Record<string, unknown>;
    trackingId?: string;
    trackingType?: CheckboxTrackingTypes;
    title: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
    content,
    hasLegalTerms,
    id,
    isRequired,
    trackingId,
    trackingType,
    register,
    title,
}): React.ReactElement => {
    const [isChecked, setIsChecked] = useState(false),
        elmId = id || `checkbox-${uuid_v4()}`,
        sanitizedContent = DOMPurify.sanitize(content, { ADD_ATTR: ['target'] });

    const trackingService = TrackingService.getTrackingService();

    const shouldSendTrackingEvent = isChecked && trackingId && trackingType;

    const determineContent = () => {
        if (hasLegalTerms) {
            return (
                <small>
                    Accept{' '}
                    <ExternalLink
                        displayType={ExternalLinkDisplayTypes.LINK}
                        ariaLabel="Prizeout Terms of Use"
                        label="Prizeout Terms of Use"
                        testId="checkbox-terms-link"
                        url={constants.prizeoutWebsiteRoutes.termsUrl}
                    />{' '}
                    and{' '}
                    <ExternalLink
                        displayType={ExternalLinkDisplayTypes.LINK}
                        ariaLabel="Privacy Policy"
                        label="Privacy Policy"
                        testId="checkbox-privacy-link"
                        url={constants.prizeoutWebsiteRoutes.privacyUrl}
                    />
                </small>
            );
        }

        return <small data-testid="checkbox-description" dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
    };

    const sendTrackingEvent = async () => {
        await trackingService.trackCheckboxOptIn(trackingType, trackingId);
    };

    useEffect(() => {
        if (shouldSendTrackingEvent) {
            sendTrackingEvent();
        }
    }, [shouldSendTrackingEvent]);

    return (
        <Fragment>
            <label data-testid="checkbox-label" className="checkbox" htmlFor={elmId}>
                <input
                    data-testid="checkbox-input"
                    id={elmId}
                    className="checkbox__input"
                    type="checkbox"
                    title={title}
                    onClick={() => setIsChecked(!isChecked)}
                    {...register}
                    aria-required={isRequired}
                    defaultChecked={isChecked}
                />
                <span
                    data-testid="checkbox-input-control"
                    className="checkbox__control"
                    aria-checked={isChecked}
                    aria-labelledby={elmId}
                >
                    {determineContent()}
                </span>
            </label>
        </Fragment>
    );
};
