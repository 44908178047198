import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../common';
import { CRMRecord, CRMTagGroups } from './crm-types';

export const crmApiSlice = createApi({
    reducerPath: 'crmApi',
    baseQuery,
    tagTypes: [CRMTagGroups.CRM],
    endpoints: (builder) => ({
        getCRMDataForAllPages: builder.query<CRMRecord, void>({
            query: () => '/crm',
            providesTags: [CRMTagGroups.CRM],
        }),
    }),
});

export const { useGetCRMDataForAllPagesQuery, useLazyGetCRMDataForAllPagesQuery } = crmApiSlice;
