import React, { forwardRef } from 'react';
import { Button } from './button';
import { ButtonColor, ButtonSize } from './types';
import './toast-button.scss';

interface ToastButtonProps {
    label: string;
    onClick?: () => void;
}

export const ToastButton = forwardRef<HTMLButtonElement, ToastButtonProps>(
    (
        { label, onClick },
        ref: React.RefCallback<HTMLButtonElement> | React.MutableRefObject<HTMLButtonElement>,
    ): React.ReactElement => {
        return (
            <Button
                ref={ref}
                ariaLabel={label}
                className="toast-button"
                testId="toast-button"
                color={ButtonColor.TERTIARY}
                size={ButtonSize.MINI}
                onClick={onClick}
                text={label}
            />
        );
    },
);
