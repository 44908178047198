import React from 'react';
import Classnames from 'classnames';

import './generic-module-layout.scss';

interface GenericModuleLayoutProps {
    noVerticalMargin?: boolean;
}

export const GenericModuleLayout: React.FC<GenericModuleLayoutProps> = ({
    children,
    noVerticalMargin,
}): React.ReactElement => {
    const classes: string = Classnames('generic-module-layout', {
        'generic-module-layout--no-vertical-margin': noVerticalMargin,
    });
    return (
        <div data-testid="generic-module-layout" className={classes}>
            {children}
        </div>
    );
};
