import React from 'react';
import './cashback-onboarding-step.scss';

interface CashbackOnboardingStepProps {
    title: string;
    description: string;
    children?: React.ReactNode;
}

export const CashbackOnboardingStep: React.FC<CashbackOnboardingStepProps> = ({
    title,
    description,
    children,
}): React.ReactElement => {
    return (
        <>
            <li>
                <div className="cashback-onboarding-step">
                    <div className="cashback-onboarding-step__tag">
                        <i className="cashback-onboarding-step__icon fa-kit fa-prizeout-tag-solid"></i>
                    </div>
                    <p className="cashback-onboarding-step__title">
                        <b>{title}</b>
                    </p>
                </div>
                {description && (
                    <div className="cashback-onboarding-step__description">
                        <p>{description}</p>
                    </div>
                )}
                <div className="cashback-onboarding-step__child-wrapper">{children}</div>
            </li>
        </>
    );
};
