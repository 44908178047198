import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { CBCERootState } from '../../cashback';

export interface CBCECommonState {
    isOfferError?: boolean;
    isFirstTimeUser?: boolean;
    isLoading?: boolean;
    isUserAuthenticated?: boolean;
    isLinking?: boolean;
    isMobileSafari?: boolean;
    isPinnedWindow?: boolean;
    isPopupWindow?: boolean;
}

export const cbceCommonInitialState: CBCECommonState = {
    isOfferError: false,
    isFirstTimeUser: true,
    isLoading: false,
    isUserAuthenticated: false,
    isLinking: false,
    isMobileSafari: false,
    isPinnedWindow: false,
    isPopupWindow: false,
};

export const cbceCommonSlice = createSlice({
    initialState: cbceCommonInitialState,
    name: 'cbceCommon',
    reducers: {
        setIsFirstTimeUser(state, action: PayloadAction<boolean>) {
            state.isFirstTimeUser = action.payload;
        },
        setIsUserAuthenticated(state, action: PayloadAction<boolean>) {
            state.isUserAuthenticated = action.payload;
        },
        setIsOfferError(state, action: PayloadAction<boolean>) {
            state.isOfferError = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setIsLinking(state, action: PayloadAction<boolean>) {
            state.isLinking = action.payload;
        },
        setIsMobileSafari(state, action: PayloadAction<boolean>) {
            state.isMobileSafari = action.payload;
        },
        setIsPopupWindow(state, action: PayloadAction<boolean>) {
            state.isPopupWindow = action.payload;
        },
        setIsPinnedWindow(state, action: PayloadAction<boolean>) {
            state.isPinnedWindow = action.payload;
        },
    },
});

export const {
    setIsFirstTimeUser,
    setIsUserAuthenticated,
    setIsOfferError,
    setIsLoading,
    setIsLinking,
    setIsMobileSafari,
    setIsPopupWindow,
    setIsPinnedWindow,
} = cbceCommonSlice.actions;

export const selectCBCECommonState = ({ cbceCommon }: CBCERootState): CBCECommonState => cbceCommon;

export const selectIsFirstTimeUser = createSelector(selectCBCECommonState, ({ isFirstTimeUser }) => isFirstTimeUser);

export const selectIsUserAuthenticated = createSelector(
    selectCBCECommonState,
    ({ isUserAuthenticated }) => isUserAuthenticated,
);

export const selectIsOfferError = createSelector(selectCBCECommonState, ({ isOfferError }) => isOfferError);

export const selectIsLoading = createSelector(selectCBCECommonState, ({ isLoading }) => isLoading);

export const selectIsLinking = createSelector(selectCBCECommonState, ({ isLinking }) => isLinking);

export const selectIsMobileSafari = createSelector(selectCBCECommonState, ({ isMobileSafari }) => isMobileSafari);

export const selectIsPopupWindow = createSelector(selectCBCECommonState, ({ isPopupWindow }) => isPopupWindow);

export const selectIsPinnedWindow = createSelector(selectCBCECommonState, ({ isPinnedWindow }) => isPinnedWindow);

export default cbceCommonSlice.reducer;
