import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import storeFrontReducer, { storeFrontInitialState } from '../slices/store-front-slice';
import { PrizeoutEnv } from '../../utils/constants/prizeout-website';
import { commonInitState, commonSlices } from '../common';

export const storeFrontSlices = {
    storeFront: storeFrontReducer,
};

const SFrontReducer = combineReducers({
    ...commonSlices,
    ...storeFrontSlices,
});

export const sfStore = configureStore({
    reducer: SFrontReducer,
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

export type SFRootState = ReturnType<typeof SFrontReducer>;

export type AppDispatch = typeof sfStore.dispatch & ThunkDispatch<SFRootState, null, AnyAction>;

export const SFInitialState: SFRootState = {
    ...commonInitState,
    storeFront: storeFrontInitialState,
};
