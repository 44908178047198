import { PaginatedData } from '../../../../utils';
import { PageManagerPageType } from './page-manager';
import { PagedRow, PageManagerBase, RowFilterCallback, RowIteratorCallback } from './page-manager-base';

export class PageManagerThunk<RowType> extends PageManagerBase<RowType> {
    constructor() {
        super();
        this.pages = [];
    }

    private isPaginatedData(page: PageManagerPageType<RowType>): page is PaginatedData<RowType[]> {
        return page != null && !page.hasOwnProperty('isLoading');
    }

    canAddPage(page: PageManagerPageType<RowType>) {
        if (this.isPaginatedData(page)) {
            return this.pageHasData(page);
        }

        return false;
    }

    addPage(page: PageManagerPageType<RowType>): void {
        if (this.isPaginatedData(page)) {
            const pageNumber = this.validateNewPage(page, this.pages.length);

            if (pageNumber !== -1) {
                this.pages[pageNumber] = page;
            }
        }

        this.updateLength();
    }

    getPage(pageNumber: number): PageManagerPageType<RowType> {
        return this.pages[pageNumber];
    }

    map<ReturnType>(callback: RowIteratorCallback<RowType, ReturnType>): PagedRow<ReturnType>[] {
        let rowIndex = 0;

        const rawPages = this.pages.slice(this.startingPage);

        return rawPages
            .map((page) => {
                if (!page) {
                    return [];
                }

                if (this.isPaginatedData(page)) {
                    return this.mapPageRows(callback, () => rowIndex++, page);
                }

                throw new Error('Unexpected type mismatch on mapping page');
            })
            .flat();
    }

    filter(callback: RowFilterCallback<RowType>): PagedRow<RowType>[] {
        const rawPages = this.pages.slice(this.startingPage);
        const resultPages: PagedRow<RowType>[] = [];

        for (const page of rawPages) {
            if (!page) {
                continue;
            }

            if (this.isPaginatedData(page)) {
                this.filterPageRows(callback, page, resultPages);
            } else {
                throw new Error('Unexpected type mismatch on filtering page');
            }
        }

        return resultPages;
    }
}
