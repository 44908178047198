import { SDCOption } from './sdc-option/sdc-option-wrapper';

export enum SelectDropdownStateActionNames {
    CLEAR_ACTIVE_ELEMENT = 'clear_active_element',
    CLEAR_SELECTED_OPTION = 'clear_selected_option',
    SET_ACTIVE_ELEMENT = 'set_active_element',
    SET_SELECTED_OPTION = 'set_selected_option',
    SET_SHOW_LIST = 'set_show_list',
    SET_SHOW_ON_TOP = 'set_show_on_top',
    TOGGLE_SHOW_LIST = 'toggle_show_list',
    UNSET_SHOW_LIST = 'unset_show_list',
    UNSET_SHOW_ON_TOP = 'unset_show_on_top',
}

export interface SelectDropdownState<GenericObject> {
    activeElement: HTMLLIElement;
    selectedOption: SDCOption<GenericObject>;
    showList: boolean;
    showOnTop: boolean;
}

export interface SelectDropdownStateAction<GenericObject> {
    type: SelectDropdownStateActionNames;
    payload?: {
        element?: HTMLLIElement;
        option?: SDCOption<GenericObject>;
    };
}

export function selectDropdownStateReducer<GenericObject>(
    state: SelectDropdownState<GenericObject>,
    action: SelectDropdownStateAction<GenericObject>,
): SelectDropdownState<GenericObject> {
    switch (action.type) {
        case SelectDropdownStateActionNames.CLEAR_ACTIVE_ELEMENT:
            return {
                ...state,
                activeElement: null,
            };

        case SelectDropdownStateActionNames.CLEAR_SELECTED_OPTION:
            return {
                ...state,
                selectedOption: null,
            };

        case SelectDropdownStateActionNames.SET_ACTIVE_ELEMENT:
            return {
                ...state,
                activeElement: action.payload.element,
            };

        case SelectDropdownStateActionNames.SET_SELECTED_OPTION:
            const newShowState = state.showList && !!action.payload.option ? false : state.showList;

            return {
                ...state,
                selectedOption: action.payload.option,
                showList: newShowState,
            };

        case SelectDropdownStateActionNames.SET_SHOW_LIST:
            return {
                ...state,
                showList: true,
                showOnTop: false,
            };

        case SelectDropdownStateActionNames.SET_SHOW_ON_TOP:
            return {
                ...state,
                showOnTop: true,
            };
        case SelectDropdownStateActionNames.TOGGLE_SHOW_LIST:
            return {
                ...state,
                showList: !state.showList,
            };

        case SelectDropdownStateActionNames.UNSET_SHOW_LIST:
            return {
                ...state,
                showList: false,
                showOnTop: false,
            };

        case SelectDropdownStateActionNames.UNSET_SHOW_ON_TOP:
            return {
                ...state,
                showOnTop: false,
            };

        default:
            console.error(`Unknown select dropdown reducer action '${action.type}'`);
            return state;
    }
}
