import React, { useEffect, useRef, useState } from 'react';
import { SDCOption, SDCOptionProps } from '../sdc-option/sdc-option-wrapper';
import { useNumberSequence } from '../../../../hooks';
import ClassNames from 'classnames';
import useDebounce from '../../../../hooks/use-debounce';
import './sdc-option-list.scss';

export interface SDCOptionListProps<GenericObject> {
    options: SDCOption<GenericObject>[];
    selectElement: HTMLElement;
    listId: string;
    ListItemComponent: React.ComponentType<SDCOptionProps<GenericObject>>;
    shown: boolean;
    shownOnTop: boolean;
    onSelect: (option: SDCOption<GenericObject>) => void;
    onChangeActiveElement: (optionElement: HTMLLIElement) => void;
    onClose: () => void;
}

export function SDCOptionList<GenericObject>({
    options,
    // selectElement will be needed for dynamic positioning.
    // https://prizeout.atlassian.net/browse/WV2-1914
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectElement,
    listId,
    ListItemComponent,
    shown,
    shownOnTop,
    onSelect,
    onChangeActiveElement,
    onClose,
}: SDCOptionListProps<GenericObject>): React.ReactElement {
    const [optionState, setOptionState] = useState(options);
    const listRef = useRef<HTMLUListElement>();
    const keySeq = useNumberSequence();

    useEffect(() => {
        setOptionState(options);
    }, [options]);

    useEffect(() => {
        if (!listRef.current) {
            return;
        }

        if (shown) {
            const optionElement: HTMLLIElement = listRef.current.querySelector('.sdc-option:first-child');
            focusOnOption(optionElement);
        }
    }, [shown]);

    const focusOnOption = (optionElement: HTMLLIElement) => {
        if (optionElement) {
            optionElement.focus();
            onChangeActiveElement(optionElement);
        }
    };
    const handleSelect = (option: SDCOption<GenericObject>) => {
        const newOptions = options.map((optionCandidate) => {
            if (optionCandidate.value.toString() === option.value.toString()) {
                return {
                    ...optionCandidate,
                    selected: true,
                };
            } else {
                return {
                    ...optionCandidate,
                    selected: false,
                };
            }
        });
        setOptionState(newOptions);
        onSelect?.call(null, option);
    };

    const handleNextOption = (listItem: HTMLLIElement) => {
        if (listItem.nextElementSibling) {
            const nextItem: HTMLLIElement = listItem.nextElementSibling as HTMLLIElement;
            focusOnOption(nextItem);
        }
    };

    const handlePrevOption = (listItem: HTMLLIElement) => {
        if (listItem.previousElementSibling) {
            const prevItem: HTMLLIElement = listItem.previousElementSibling as HTMLLIElement;
            focusOnOption(prevItem);
        }
    };

    // When changing from one option to another, this element gets a blur event, quickly
    // followed by a focus event.  That blur event should not cause the list to close.
    // But a blur event caused by something else, e.g. clicking outside the select, will
    // cause a blur event which is not followed by a focus event.  In that case we need
    // to close the list.  Setting up the event handler this way with a debounce allows
    // this work correctly and is reflected in the tests.
    const [handleFocus] = useDebounce<React.FocusEvent<HTMLUListElement>>((evt) => {
        if (evt.type === 'blur') {
            evt.preventDefault();
            evt.stopPropagation();

            // If the blur event fires before the click event, the list will be reopened when
            // the click event fires.  This is only a problem when a click is used to close the
            // list.  Keyboard events are not subject to this problem.
            const relatedTarget: HTMLElement = evt.relatedTarget as HTMLElement;

            if (!relatedTarget?.classList?.contains('select-dropdown__current--list-showing')) {
                onClose?.call(null);
            }
        }
    }, 100);

    const classes = ClassNames('sdc-option-list', {
        'sdc-option-list--shown z-index-sdc-option-list': shown,
        'sdc-option-list--top': shownOnTop,
    });

    return (
        <ul
            id={listId}
            className={classes}
            data-testid="sdc-option-list"
            ref={listRef}
            onFocus={handleFocus}
            onBlur={handleFocus}
            role="listbox"
            tabIndex={-1}
            aria-multiselectable={false}
        >
            {optionState.map((option) => {
                return (
                    <ListItemComponent
                        key={keySeq()}
                        option={option}
                        listId={listId}
                        onSelect={handleSelect}
                        nextOption={handleNextOption}
                        prevOption={handlePrevOption}
                        closeList={onClose}
                    />
                );
            })}
        </ul>
    );
}
