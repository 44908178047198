import { Hash } from '../types';

const CountryCodeKeyedByCurrency: Hash<string> = {
    AED: 'AE',
    BHD: 'BH',
    CAD: 'CA',
    EUR: 'IE',
    GBP: 'UK',
    JOD: 'JO',
    KWD: 'KW',
    OMR: 'OM',
    SAR: 'SA',
    USD: 'US',
};

export const currencyConstants = {
    CountryCodeKeyedByCurrency,
};
