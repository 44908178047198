import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/widget/widget';
import { selectBrandingOverride, setBrandingOverride } from '../store/slices/common-slice';
import { useAppSelector } from './use-app-selector';

export function useBrandingOverride(): [string, boolean] {
    const dispatch = useDispatch<AppDispatch>();
    const { brandingOverride, hasBrandingOverride } = useAppSelector(selectBrandingOverride);

    useLayoutEffect(() => {
        const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());

        if (!!queryParams.brandOverride) {
            dispatch(setBrandingOverride(queryParams.brandOverride));
        }
    }, []);

    return [brandingOverride, hasBrandingOverride];
}

export default useBrandingOverride;
