import React, { useEffect, useState } from 'react';
import { isHTMLElement } from '../../utils';
import { useObservedTracker } from './useObservedTracker';
import useDebounce from '../use-debounce';
import { getScrollPosition } from '../../utils/helpers/dom-helpers';
import { onListTopCallbackArgs, useIntersectionObserverResult } from './types';

/*
 Make using IntersectionObserver a little easier.

 https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

 Usage:


    const MyComponent: React.FC = (): React.ReactElement => {
        const handleObserverChange: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]): void => {
            if (!entries) {
                return;
            }

            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Element just came into view
                } else {
                    // Element just left view
                }
            });
        };

        const topRef = useRef(null);
    `   const summaryRef = useRef(null);
        const [summaryElement, setSummaryElement] = useState<Element>(null);
`       const [observer, setTopElement] = useIntersectionObserver(topRef, handleObserverChange, <options>);

        // This needs to run on every render
        useEffect(() => {
            setTopElement(topRef.current);
            setSummaryElement(summaryRef.current); // This part may vary.
        });

        // This is an example of how to handle this.  Details of how this works will vary.
        useEffect(() => {
            if (observer && summaryElement) {
                observer.observe(summaryElement);
            }
        }, [observer, summaryElement]);

        return <topElement ref={topRef} />;
    };
*/
export function useIntersectionObserver(
    topRef: React.MutableRefObject<Element>,
    callback: IntersectionObserverCallback,
    options = {},
    onListTopCallback: (args: onListTopCallbackArgs) => void = null,
): useIntersectionObserverResult {
    const [observer, setObserver] = useState<IntersectionObserver>(null);
    const [topElement, setTopElementState] = useState<Element>(topRef.current);
    const { trackElement, resetHandlers, trackedHandlers } = useObservedTracker(observer, topElement);

    const [debouncedOnListTopCallback] = onListTopCallback ? useDebounce(onListTopCallback) : [];

    const wheelHandler = (evt: WheelEvent) => {
        const scrollPosition = getScrollPosition(evt.currentTarget);

        if (evt.deltaY < -100) {
            const target = evt.currentTarget;

            if (isHTMLElement(target) && scrollPosition === 0) {
                debouncedOnListTopCallback?.call(null, {
                    evt,
                    resetHandlers,
                });
            }
        }
    };

    useEffect(() => {
        if (!topElement) {
            if (observer) {
                observer.disconnect();
            }

            setObserver(null);
            return;
        }

        const localObserver = new IntersectionObserver(callback, {
            ...options,
            root: topRef.current.parentElement,
        });

        setObserver(localObserver);

        if (onListTopCallback && isHTMLElement(topElement)) {
            topElement.addEventListener('wheel', wheelHandler, { passive: true });
        }

        return () => {
            if (onListTopCallback && isHTMLElement(topElement)) {
                topElement.removeEventListener('wheel', wheelHandler);
            }

            if (observer) {
                observer.disconnect();
            }

            setObserver(null);
        };
    }, [topElement]);

    const setTopElement = (element: Element) => {
        if (element.isSameNode(topElement)) {
            return;
        }

        setTopElementState(element);
    };

    return {
        observer,
        setTopElement,
        topElement,
        trackElement,
        resetHandlers,
        trackedHandlers,
    };
}
