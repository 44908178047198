import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { auctionApi } from '../../utils/services/auction-api';
import { sharedAPI } from '../../utils/services/api';
import type { RootState } from '../widget/widget';
import { GiftingType, PartnerUserBankAccount } from './partner-config-slice';
import { PrizeoutGiftCard } from './checkout-slice';
import { convertDollarsToCents } from '../../utils/helpers';

export enum GiftingStatusTypes {
    FAIL = 'fail',
    SENT = 'sent',
    PENDING = 'pending',
}

export enum GiftingSendTypes {
    PRIZEOUT = 'prizeout',
    GIFT_CARD = 'giftCard',
}

// Define a type for the slice state
export interface GiftingSlice {
    contacts: GiftingContact[];
    contactsLoaded: boolean;
    hasClosedOnboarding: boolean;
    sendType: GiftingSendTypes;
    giftCard: PrizeoutGiftCard;
    giftingCheckoutValueId: string;
    fundsAmount: number;
    message: string;
    giftingHistory: GiftingHistoryItem[];
    activeGiftingHistoryItem?: GiftingHistoryItem;
    activeContactInEdit?: GiftingContact;
    receivedGift?: {
        giftingSendId: string;
        giftType: GiftingType;
        status: GiftingStatusTypes;
        customMessage: string;
        expirationDate: string;
        logomark: string;
        checkoutDetails: any; //pending
        sender: {
            firstName: string;
            lastname: string;
        };
        showGiftingTerms: boolean;
    };
}
export interface GiftingHistoryItem {
    gifting_send_id: string;
    value_in_cents: number;
    currency_code: string;
    name?: string;
    logomark_url: string;
    gifting_send_timestamp: string;
    sent_to_name: string;
    is_prizeout_dollars?: boolean;
    display_status: GiftingStatusTypes;
}

export interface GiftingHistoryResponse {
    pagination: string;
    data: GiftingHistoryItem[];
}

export interface GiftingContact {
    giftingContactId: string;
    name: string;
    email: string;
    phone?: string;
    isSelected: boolean;
}

type GiftingContacts = GiftingContact[];

// Define the initial state using that type
export const giftingInitialState: GiftingSlice = {
    contacts: [],
    contactsLoaded: false,
    hasClosedOnboarding: false,
    sendType: null,
    giftCard: null,
    fundsAmount: 10,
    giftingCheckoutValueId: null,
    message: '',
    giftingHistory: [],
    activeGiftingHistoryItem: null,
};

type AddContactRequest = {
    name: string;
    email: string;
    phone?: string;
};

type SendGiftRequest = {
    recipients: GiftingContacts;
    message: string;
    giftCard?: PrizeoutGiftCard;
    fundsAmount?: number;
    giftingCheckoutValueId: string;
    bankAccount: PartnerUserBankAccount;
    prizeout_session_id: string;
    sendType: GiftingSendTypes;
};
interface UpdateContactRequest extends AddContactRequest {
    giftingContactId: string;
}

export interface DeleteContactResponse {
    gifting_contact_id: string;
}

export interface UpdateContactResponse {
    original_gifting_contact_id: string;
    giftingContactId: string;
    name: string;
    email: string;
    phone?: string;
}

export type SendGiftResponse = {
    giftingSendIds: string[];
};

export const addContact = createAsyncThunk(
    'gifting/contacts/add',
    async ({ name, email, phone }: AddContactRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    name,
                    email,
                    phone,
                },
                endpoint: '/gifting/contacts/add',
                method: 'POST',
                signal: signal,
            });
            return results.data;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const updateContact = createAsyncThunk(
    'gifting/contacts/update',
    async ({ giftingContactId, name, email, phone }: UpdateContactRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    gifting_contact_id: giftingContactId,
                    name,
                    email,
                    phone,
                },
                endpoint: '/gifting/contacts/update',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
                original_gifting_contact_id: giftingContactId,
            };
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const deleteContact = createAsyncThunk(
    'gifting/contacts/delete',
    async (gifting_contact_id: string, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    gifting_contact_id,
                },
                endpoint: '/gifting/contacts/delete',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
                gifting_contact_id,
            };
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const listContacts = createAsyncThunk('gifting/contacts', async ({}, { rejectWithValue, signal }) => {
    try {
        const results = await sharedAPI.request({
            data: {},
            endpoint: '/gifting/contacts',
            method: 'GET',
            signal: signal,
        });
        return results.data;
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

export const getGiftingOfferDetails = createAsyncThunk(
    'gifting/offerDetails',
    async ({}, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: '/gifting/offerDetails',
                method: 'GET',
                signal: signal,
            });
            return results.data;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const getGiftingHistory = createAsyncThunk('gifting_sends/list', async ({}, { rejectWithValue, signal }) => {
    try {
        const results = await sharedAPI.request({
            data: {},
            endpoint: '/gifting_sends/list',
            method: 'GET',
            signal: signal,
        });
        return results.data;
    } catch (e) {
        return rejectWithValue(e.response.data);
    }
});

export const sendGift = createAsyncThunk(
    'gifting/sends/add',
    async (
        {
            sendType,
            message,
            recipients,
            giftCard,
            fundsAmount,
            giftingCheckoutValueId,
            prizeout_session_id,
            bankAccount,
        }: SendGiftRequest,
        { rejectWithValue, signal },
    ) => {
        try {
            const giftingContactIds = recipients.map(({ giftingContactId }) => giftingContactId);
            const results = await sharedAPI.request({
                data: {
                    message,
                    giftingContactIds,
                    checkoutValueId: sendType === GiftingSendTypes.GIFT_CARD ? giftCard.card.checkout_value_id : null,
                    amountCents:
                        sendType === GiftingSendTypes.PRIZEOUT
                            ? convertDollarsToCents(fundsAmount)
                            : giftCard.card.cost_in_cents,
                    giftingCheckoutValueId,
                    paymentId: bankAccount.id,
                    prizeout_session_id,
                },
                endpoint: '/gifting/sends/add',
                method: 'POST',
                signal: signal,
            });
            return results.data;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

type GiftingOffersRequest = {
    prizeoutSessionId: string;
};

export const getGiftingOffers = createAsyncThunk(
    'offers/getGiftingOffers',
    async ({ prizeoutSessionId }: GiftingOffersRequest, { rejectWithValue, signal }) => {
        try {
            const results = await auctionApi({
                data: { prizeout_session_id: prizeoutSessionId },
                endpoint: '/v2/offers/gifting',
                signal: signal,
            });
            return results?.data?.checkout_value_id;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const giftingSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(listContacts.pending, (state) => {
            state.contactsLoaded = true;
        });

        builder.addCase(listContacts.fulfilled, (state, action: PayloadAction<GiftingContacts>) => {
            const arr = [];
            for (const i in action.payload) {
                arr.push(action.payload[i]);
            }
            // state.contactsLoaded = true;
            state.contacts = arr;
        });

        builder.addCase(addContact.fulfilled, (state, action: PayloadAction<GiftingContact>) => {
            state.contacts.push(action.payload);
        });

        builder.addCase(getGiftingOffers.fulfilled, (state, action: PayloadAction<string>) => {
            state.giftingCheckoutValueId = action.payload;
        });

        builder.addCase(getGiftingHistory.fulfilled, (state, action: PayloadAction<GiftingHistoryResponse>) => {
            state.giftingHistory = action.payload.data;
        });

        builder.addCase(updateContact.fulfilled, (state, action: PayloadAction<UpdateContactResponse>) => {
            // Remove the old contact
            state.contacts = state.contacts.filter(
                (contact) => contact.giftingContactId != action.payload.original_gifting_contact_id,
            );
            // Push the new one
            state.contacts.push({
                giftingContactId: action.payload.giftingContactId,
                name: action.payload.name,
                email: action.payload.email,
                phone: action.payload.phone,
                isSelected: false,
            });
        });

        builder.addCase(deleteContact.fulfilled, (state, action: PayloadAction<DeleteContactResponse>) => {
            state.contacts = state.contacts.filter(
                (contact) => contact.giftingContactId != action.payload.gifting_contact_id,
            );
        });
    },
    initialState: giftingInitialState,
    name: 'gifting',
    reducers: {
        toggleContact(state, action: PayloadAction<GiftingContact>) {
            const contact = state.contacts.find(
                ({ giftingContactId }) => giftingContactId === action.payload.giftingContactId,
            );
            contact.isSelected = !contact.isSelected;
        },
        toggleSendType(state, action: PayloadAction<GiftingSendTypes>) {
            state.sendType = action.payload;
        },
        setEmptyContacts(state) {
            state.contacts.forEach((contact) => {
                contact.isSelected = false;
            });
        },
        setFundsAmount(state, action: PayloadAction<string>) {
            state.fundsAmount = parseFloat(action.payload);
        },
        setGiftingGiftCard(state, action: PayloadAction<PrizeoutGiftCard>) {
            state.giftCard = action.payload;
        },
        setGiftingMessage(state, action: PayloadAction<string>) {
            state.message = action.payload;
        },
        setHasClosedOnboarding(state, action: PayloadAction<boolean>) {
            state.hasClosedOnboarding = action.payload;
        },
        setActiveHistoryItem(state, action: PayloadAction<GiftingHistoryItem>) {
            state.activeGiftingHistoryItem = action.payload;
        },
        setActiveContactInEdit(state, action: PayloadAction<GiftingContact>) {
            state.activeContactInEdit = action.payload;
        },
        clearActiveContactInEdit(state) {
            state.activeContactInEdit = null;
        },
        setMockGiftingData(state) {
            state.receivedGift = {
                giftingSendId: 'fooGiftingId',
                giftType: GiftingType.FUNDS,
                status: GiftingStatusTypes.SENT,
                customMessage: 'Here is a mock message for you.',
                expirationDate: '1/26/23',
                logomark: 'https://candidate.static.prizeout.com/campaign-creative/1659991358.2298813-1234.png',
                checkoutDetails: {}, //pending
                sender: {
                    firstName: 'John',
                    lastname: 'Slacker',
                },
                showGiftingTerms: true,
            };
        },
    },
});

export const {
    toggleContact,
    toggleSendType,
    setFundsAmount,
    setGiftingGiftCard,
    setHasClosedOnboarding,
    setActiveHistoryItem,
    setGiftingMessage,
    setEmptyContacts,
    setActiveContactInEdit,
    clearActiveContactInEdit,
    setMockGiftingData,
} = giftingSlice.actions;

const selectGiftingState = ({ gifting }: RootState): GiftingSlice => gifting;

export const selectContacts = createSelector(selectGiftingState, ({ contacts }) => contacts);

export const selectRecipients = createSelector(selectGiftingState, ({ contacts }) =>
    contacts.filter(({ isSelected }) => !!isSelected),
);

export const selectContactsLoaded = createSelector(selectGiftingState, ({ contactsLoaded }) => contactsLoaded);

export const selectSendType = createSelector(selectGiftingState, ({ sendType }) => sendType);

export const selectFundsAmount = createSelector(selectGiftingState, ({ fundsAmount }) => fundsAmount);

export const selectGiftingCheckoutValueId = createSelector(
    selectGiftingState,
    ({ giftingCheckoutValueId }) => giftingCheckoutValueId,
);

export const selectGiftingMessage = createSelector(selectGiftingState, ({ message }) => message);

export const selectGiftingGiftCard = createSelector(selectGiftingState, ({ giftCard }) => giftCard);

export const selectHasClosedOnboarding = createSelector(
    selectGiftingState,
    ({ hasClosedOnboarding }) => hasClosedOnboarding,
);

export const selectGiftingHistory = createSelector(selectGiftingState, ({ giftingHistory }) => giftingHistory);

export const selectActiveContactInEdit = createSelector(
    selectGiftingState,
    ({ activeContactInEdit }) => activeContactInEdit,
);

export const selectActiveGiftingHistoryItem = createSelector(
    selectGiftingState,
    ({ activeGiftingHistoryItem }) => activeGiftingHistoryItem,
);

export default giftingSlice.reducer;
