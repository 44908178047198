import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './skeleton-loader-element-gift-card.scss';

export const SkeletonLoaderElementGiftCard: React.FC = (): React.ReactElement => {
    return (
        <div data-testid="skeleton-loader-element-gift-card" className="skeleton-loader-element-gift-card">
            <Skeleton inline={true} />
        </div>
    );
};
