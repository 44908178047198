import { PrizeoutProductVersion } from '../partner-config-slice';

export enum PortalEventGroups {
    PortalLogin = 'admin-login',
    PortalDashboardUsersList = 'admin-dashboard-users-list',
    PortalDashboardPartnersList = 'admin-dashboard-partners-list',
    AddPortalDashboardUser = 'add-admin-dashboard-user',
    SwitchDashboardUserLoginType = 'switch-dashboard-user-login-type',
    SendPasswordResetOTA = 'send-password-reset-ota',
    generatePasswordResetOTA = 'generate-password-reset-ota',
    AddPortalDashboardPartner = 'add-admin-dashboard-partner',
    CreateSandboxEnv = 'create-sandbox-env',
}

export interface firebaseSignInRequest {
    idToken: string;
    displayName: string;
}
export interface firebaseSignInResponse {
    expirationDate: string;
}

export enum AdminPortalTab {
    REQUESTS = 'requests',
    PARTNERS = 'partners',
    DASHBOARD_USERS = 'dashboard_users',
    ADMIN_USERS = 'admin_users',
    MERCHANTS = 'merchants',
    INVENTORY = 'inventory',
    AGGREGATOR = 'aggregator',
    FUNDING = 'funding',
}

export enum AdminRoleTypes {
    ADMIN = 'admin',
    ENGINEERING_ADMIN = 'engineering',
    ENGINEERING = 'engineering',
    OPERATIONS_ADMIN = 'operations_admin',
    OPERATIONS = 'operations',
    SUPPORT = 'support',
    FINANCE = 'finance',
}

export interface PaginationResponse {
    total: number;
    lastPage: number;
    prevPage?: number;
    nextPage: number;
    perPage: number;
    currentPage: number;
}

export interface AdminDashboardUsersQuery {
    partnerId?: string;
    email?: string;
    isPoAdmin?: boolean;
    currentPage: number;
    perPage: number;
}

export enum DashboardUserAccountLoginType {
    PASSWORD = 'password',
    SSO = 'sso',
}

export interface GenericSuccessResponse {
    success: boolean;
}

// Users
export interface AddAdminDashboardUserQuery {
    loginType: DashboardUserAccountLoginType;
    isPoAdmin: boolean;
    email: string;
    name: string;
    partnerId?: string; // note you can create a dashboard user without specifing a partner id
    perms: AdminRoleTypes[];
}

export interface AdminDashboardUser {
    dashboardUserId: string;
    email: string;
    name: string;
    createDate: string;
    updateDate: string;
    title: string;
    accountLockedUntil?: Date;
    isPoAdmin: boolean;
    loginType: DashboardUserAccountLoginType;
}

// Partners
export enum PartnerStatus {
    LIVE = 'live',
    DEPRECATED = 'deprecated',
}

export enum PartnerCategory {
    CU = 'cu',
    GROE = 'groe',
    EWA = 'ewa',
    BANKING = 'banking',
    GAMING = 'gaming',
}

export interface AddAdminDashboardPartnerQuery {
    partnerId: string;
    partnerName: string;
    partnerCurrency: string;
    prizeoutProductVersion: PrizeoutProductVersion;
    category: PartnerCategory;
}

export interface AdminDashboardPartner {
    partnerId: string;
    partnerName: string;
    partnerNameRaw: string;
    partnerProductName?: string;
    prizeoutProductVersion: PrizeoutProductVersion;
    partnerCurrency: string;
    partnerCurrencySymbol: string;
    isAccountsEnabled?: boolean;
    ignorePrizeoutAccountEmail?: boolean;
    prodAllowed: boolean;
    showStorefrontUserDetails?: boolean;
    partnerOTPOverrides: {
        partnerOtpLoobackThresholdMinutes: number;
        partnerOtpExpirationThresholdMinutes: number;
        otpTypes: string[];
    };
    cashbackOverrides: {
        isBackToStoreEnabled: boolean;
        isTransferToBankEnabled: boolean;
        minBankTransferLimitCents: number;
        minLifetimeEarningThreshold: number;
    };
    emailHeader?: string;
    supportEmail?: string;
    partnerEmailDomain?: string;
    allowCustomUserEmails: boolean;
    withdrawalType: string;
    allowSandboxViaMasterAccount: boolean;
    isMaster: boolean;
    masterId: string;
    sandboxEnvName: string;
    dashboardAccountLoginType: DashboardUserAccountLoginType;
    category?: string;
    status?: PartnerStatus;
}

export interface AdminDashboardPartnersQuery {
    partnerId?: string;
    partnerName?: string;
    prizeoutProductVersion?: PrizeoutProductVersion;
    category?: PartnerCategory;
    prodAllowed?: boolean;
    status?: PartnerStatus;
    isMaster?: boolean; // FE should specify True for prod and false for sandbox
    perPage: number;
    currentPage: number;
}

export interface SwitchDashboardUserLoginTypeQuery {
    loginType: DashboardUserAccountLoginType;
    dashboardUserId: string;
}

export interface SendPasswordResetOTAQuery {
    email: string;
}

export interface generatePasswordResetOTAQuery {
    dashboardUserId: string;
}

export interface generatePasswordResetOTAResponse {
    url: string;
}

export interface dashboardUserPartnerAccountListQuery {
    dashboardUserId?: string;
    perPage: number;
    currentPage: number;
}

export interface dashboardUserPartnerAccount {
    accountListId: string;
    dashboardUserId: string;
    email: string;
    name: string;
    partnerId: string;
    accountType: string;
    accountName: string;
    createDate: string;
    updateDate: string;
    isMaster: boolean;
    masterId?: string;
    sandboxEnvName?: string;
    perms: string[];
}

export interface CreateSandboxEnvQuery {
    sandboxEnvName: string;
    newPartnerId: string;
    partnerId: string;
}
