import { currencyConstants as currencies } from './currencies';
import { countryConstants as countries } from './countries';
import { prizeoutWebsiteConstants as prizeoutWebsiteRoutes, prizeoutWebsiteDomain } from './prizeout-website';

export const constants = {
    currencies,
    countries,
    prizeoutWebsiteDomain,
    prizeoutWebsiteRoutes,
    defaultErrorImageUrl: 'https://assets.prizeout.com/widget/error/oh-no-taggy.svg',
    defaultGiftCardImageUrl: 'https://assets.prizeout.com/branding/Default-Gift-Card.png',
    defaultRoundImageUrl: 'https://assets.prizeout.com/branding/Default-Logomark.png',
    defaultQrCodeSize: 128,
    prizeoutLogomarkUrl: 'https://assets.prizeout.com/branding/po-logo-small.svg',
    prizeoutRoundLogomarkUrl: 'https://assets.prizeout.com/branding/po-logo-circle.svg',
    giftingImageUrl: 'https://assets.prizeout.com/widget/gifting/celebrate.png',
    giftingMessageCharLimit: 160,
    giftingFundsDenomList: [5, 10, 15, 25, 50, 75, 100, 150, 200, 250, 500],
    miniWidgetErrorImageUrl: 'https://assets.prizeout.com/widget/error/mini-widget-error.png',
    cashbackAdjustmentLogomarkUrl: 'https://assets.prizeout.com/widget/cashback/prizeout-adjustment-logomark.png',
    genericBankLogomarkUrl: 'https://assets.prizeout.com/widget/cashback/generic-bank-logomark.png',
    cbPlus: 'CashBack+',
    cbPlusPay: 'CashBack+\u00A0Pay', // Make sure it doesn't wrap
    cbPlusActions: 'CashBack+\u00A0Actions',
    cbPlusOffers: 'CashBack+\u00A0Offers',
};
