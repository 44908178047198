import React from 'react';
import Classnames from 'classnames';
import { useAppSelector } from '../../../hooks';
import { selectIsGiftingMode, selectIsMiniWidget } from '../../../store/slices/sdk-slice';
import { selectIsDashboardHeaderHidden, selectShouldShowNonProdWarning } from '../../../store/slices/common-slice';

import './non-prod-warning-banner.scss';

export const NonProdWarningBanner: React.FC = (): React.ReactElement => {
    const isMiniWidget = useAppSelector(selectIsMiniWidget);
    const isDashboardHeaderHidden = useAppSelector(selectIsDashboardHeaderHidden);
    const shouldShowNonProdWarningBanner = useAppSelector(selectShouldShowNonProdWarning);
    const isGiftingMode = useAppSelector(selectIsGiftingMode);

    const classes = Classnames('non-prod-warning-banner z-index-non-prod-warning-banner', {
        'non-prod-warning-banner--top': isDashboardHeaderHidden || isMiniWidget || isGiftingMode,
        'non-prod-warning-banner--right': isMiniWidget,
    });

    return (
        <>
            {shouldShowNonProdWarningBanner && (
                <article className={classes}>
                    <div className="non-prod-warning-banner__content">
                        <p className="non-prod-warning-banner__text">Test data ({process.env.ENV})</p>
                    </div>
                </article>
            )}
        </>
    );
};
