import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/widget/widget';
import { selectIsMobileLandscape, setDashboardScrollPosition } from '../store/slices/common-slice';
import { routes } from '../utils';
import { useAppSelector } from './use-app-selector';
import {
    SidebarViewEnum,
    selectSidebarIsOpen,
    setSidebarView,
    toggleIsSidebarOpen,
} from '../store/slices/sidebar-slice';
import { setCheckoutPanelClosed } from '../store/slices/checkout-slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavBarLocation, TrackingService } from '../utils/services/tracking';
import { NavBarButtonIconNames } from '../store/slices/partner-config-slice';

export function useOpenNavBarLink() {
    const trackingService = TrackingService.getTrackingService();
    const currentLocation = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);
    const navigate = useNavigate();
    const sidebarIsOpen = useAppSelector(selectSidebarIsOpen);

    const openNavBar = async (navBarButtonName: NavBarButtonIconNames, route: routes) => {
        // Reset the scroll to 0
        dispatch(setDashboardScrollPosition(0));

        if (sidebarIsOpen) {
            dispatch(setSidebarView(SidebarViewEnum.DEFAULT));
            dispatch(toggleIsSidebarOpen());
        }

        dispatch(setCheckoutPanelClosed());

        if (currentLocation.pathname !== route) {
            navigate(route);
        }

        await trackingService.trackNavBarClick(
            navBarButtonName,
            isMobileLandscape ? NavBarLocation.BOTTOM : NavBarLocation.HEADER,
        );
    };

    return [openNavBar];
}

export default useOpenNavBarLink;
