import React, { useEffect, useRef, useState } from 'react';
import Classnames from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { authenticateUser } from '../../store/slices/verification-slice';
import { selectAuthEmail } from '../../store/slices/partner-config-slice';
import { AppDispatch } from '../../store/widget/widget';
import { useAppSelector } from '../../hooks';
import { LogInResponse } from '../../store/slices/user-slice';
import { ButtonTrackingTypes } from '../../utils/services/tracking';
import { routes, submitFormOnEnter, validateForEmptyString, requireWithMessage } from '../../utils/helpers';
import {
    AlertCallout,
    AlertCalloutMessageTypes,
    Button,
    ButtonColor,
    ButtonSize,
    ButtonType,
    FormHeader,
} from '../common';

import './log-in-form.scss';

interface LogInFormProps {
    onSuccess: (response: LogInResponse) => void;
    forgotPasswordOverride?: () => void;
    signUpOverride?: () => void;
    isRecaptchaUsed?: boolean;
}

interface Inputs {
    email: string;
    password: string;
    recaptchaToken?: string;
    passwordconfirmation?: string;
}

export const LogInForm: React.FC<LogInFormProps> = ({
    forgotPasswordOverride,
    onSuccess,
    signUpOverride,
    isRecaptchaUsed = true,
}): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const authEmail = useAppSelector(selectAuthEmail);
    const recaptchaRef: any = useRef(null);

    const routeToSignUp = () => {
        if (signUpOverride) {
            signUpOverride();
            return;
        }

        navigate(routes.DTC_SIGN_UP);
    };

    const routeToPassword = () => {
        if (forgotPasswordOverride) {
            forgotPasswordOverride();
            return;
        }

        navigate(routes.FORGOT_PASSWORD);
    };

    const {
            register,
            handleSubmit,
            formState: { errors },
            reset,
        } = useForm<Inputs>(),
        onSubmit: SubmitHandler<Inputs> = async (data) => {
            const { email, password, passwordconfirmation } = data;

            try {
                setIsLoading(true);

                if (isRecaptchaUsed) {
                    await recaptchaRef.current.execute();
                } else if (passwordconfirmation != '') {
                    throw 'Invalid Form Submission';
                }

                const response = await dispatch(
                    authenticateUser({
                        email,
                        password,
                        recaptchaToken: isRecaptchaUsed ? recaptchaRef.current.getValue() : undefined,
                        passwordconfirmation: passwordconfirmation ?? '',
                    }),
                ).unwrap();

                if (response?.error) {
                    setMessage(response.message);
                    setIsLoading(false);
                } else {
                    setMessage('');
                    await onSuccess(response);
                }
            } catch (e) {
                if (e.message === 'Request failed with status code 429') {
                    setMessage('Too many requests. Try again later.');
                } else {
                    setMessage('Authentication has failed, please try again.');
                }
                setIsLoading(false);
            }
        };

    const determineClasses = (inputName: string) => {
        const inputErrorExists = errors[inputName as keyof typeof errors];

        return Classnames(`form__input-wrapper`, { 'form__input-wrapper--error': inputErrorExists || message });
    };

    useEffect(() => {
        reset();
    }, []);

    return (
        <>
            <FormHeader formName="login-modal" headline="Log in to your account" />

            {message && <AlertCallout message={message} type={AlertCalloutMessageTypes.ERROR} className="mb-m" />}

            <form className="form">
                <div className={determineClasses('email')}>
                    <input
                        {...register('email', {
                            required: {
                                message: 'Email address required',
                                value: true,
                            },
                            validate: validateForEmptyString,
                        })}
                        aria-required="true"
                        type="email"
                        name="email"
                        id="email-log-in"
                        placeholder="Email address"
                        className="form__input"
                        defaultValue={authEmail}
                        autoComplete="username"
                        onKeyDown={(event) => submitFormOnEnter(event, handleSubmit(onSubmit))}
                    />
                    <label className="form__label" htmlFor="email-log-in">
                        Email address
                    </label>
                </div>
                {errors['email'] && (
                    <AlertCallout message={errors['email'].message} type={AlertCalloutMessageTypes.ERROR} />
                )}
                <div className={determineClasses('password')}>
                    <input
                        {...register('password', requireWithMessage(validateForEmptyString, 'Password required'))}
                        aria-required="true"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        className="form__input"
                        autoComplete="current-password"
                        onKeyDown={(event) => submitFormOnEnter(event, handleSubmit(onSubmit))}
                    />
                    <label className="form__label" htmlFor="password">
                        Password
                    </label>
                </div>
                {errors['password'] && (
                    <AlertCallout message={errors['password'].message} type={AlertCalloutMessageTypes.ERROR} />
                )}

                {isRecaptchaUsed && (
                    <ReCAPTCHA
                        sitekey={process.env.INVISIBLE_RECAPTCHA_SITE_KEY || '6Ld_MoslAAAAAMwpQCRcHXzdBBlmjj92mvwdsv5j'}
                        size="invisible"
                        ref={recaptchaRef}
                    />
                )}

                {!isRecaptchaUsed && (
                    <input
                        {...register('passwordconfirmation', {
                            required: false,
                        })}
                        aria-required="true"
                        type="password"
                        name="passwordconfirmation"
                        id="passwordconfirmation"
                        placeholder="passwordconfirmation"
                        className="form__input--confirmation"
                        data-testid="passwordconfirmation"
                    />
                )}
            </form>
            <small>
                Forgot your password?
                <Button
                    ariaLabel="Reset password"
                    isLink
                    text="Reset password"
                    onClick={() => routeToPassword()}
                    trackingType={ButtonTrackingTypes.BUTTON_CLICK_FORGOT_PASSWORD}
                ></Button>
            </small>
            <div className="form__button-wrapper">
                <Button
                    ariaLabel="Log in"
                    color={ButtonColor.PRIMARY}
                    size={ButtonSize.MEDIUM}
                    text="Log in"
                    type={ButtonType.SUBMIT}
                    isLoading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    trackingType={ButtonTrackingTypes.BUTTON_CLICK_LOGIN}
                ></Button>
            </div>
            <small className="mt-s">
                Don&apos;t have an account?{' '}
                <Button
                    ariaLabel="Sign up"
                    isLink
                    text="Sign up"
                    onClick={() => routeToSignUp()}
                    trackingType={ButtonTrackingTypes.BUTTON_CLICK_SIGN_UP}
                ></Button>
            </small>
        </>
    );
};
