import { jwtDecode, JwtPayload } from 'jwt-decode';
import { LoggingSubject, testLog } from '../logging/test-logging';

// Accomodate potential extra field in our JWTs
interface CustomJwtPayload extends JwtPayload {
    expirationDate?: string;
}

const extractJwtExpiration = (token: string): number => {
    const decodedToken = jwtDecode<CustomJwtPayload>(token);
    const exp = decodedToken.exp * 1000;

    // expirationDate is an alternative way to express expiration in some of our JWTs
    // Use this value instead if it is sooner
    const altExpirationDateString = decodedToken.expirationDate;
    if (altExpirationDateString) {
        const altExp: number = new Date(altExpirationDateString).getTime();
        if (altExp < exp) {
            return altExp;
        }
    }

    return exp;
};

export const isJWTTokenExpired = (token: string): boolean => {
    const exp = extractJwtExpiration(token);
    const now = Date.now();

    testLog(
        LoggingSubject.JWT_STATUS,
        `isJWTokenExpired Token EXP: ${new Date(exp)} (${exp}), Now: ${new Date(now)} (${now})`,
    );

    return exp <= now;
};

export const jwtExpiration = (token?: string, defaultPeriod = 10 * 60 * 1000): [period: number, epoch: number] => {
    const now = Date.now();

    if (!token) {
        return [defaultPeriod, now + defaultPeriod];
    }

    const exp = extractJwtExpiration(token);

    /* never allow the period to be zero or less, otherwise make it as short as possible */
    const period = Math.min(Math.max(exp - now, 500), defaultPeriod);

    return [period, exp];
};

// This is entirely a diagnostic.  There are places we want to be able to see in the logs
// whether the token is expired, but we don't want to change the operations there in
// response, e.g., API calls.
export const jwtTokenExpirationLogging = (tokenType: string, token: string): void => {
    const tokenIsExpired = isJWTTokenExpired(token);
    testLog(LoggingSubject.JWT_STATUS, `"${tokenType}" is expired? ${tokenIsExpired}`);
    if (tokenIsExpired) {
        const decodedToken = jwtDecode(token);

        testLog(LoggingSubject.JWT_STATUS, `"${tokenType}"`, decodedToken);
    }
};
