import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import autoFillRulesReducer, { autoFillRulesInitialState } from '../slices/auto-fill-rules-slice';
import { cashbackApiSlice, cashbackReducer, cashbackInitialState } from '../slices/cashback';
import checkoutReducer, { checkoutInitialState } from '../slices/checkout-slice';
import emailCaptureReducer, { emailCaptureInitialState } from '../slices/email-capture-slice';
import giftingReducer, { giftingInitialState } from '../slices/gifting-slice';
import offerConfigReducer, { offersInitialState } from '../slices/offers-slice';
import otpSliceReducer, { otpInitialState } from '../slices/otp-slice';
import sidebarReducer, { sidebarInitialState } from '../slices/sidebar-slice';
import userReducer, { userInitialState } from '../slices/user-slice';
import verificationReducer, { verificationInitialState } from '../slices/verification-slice';
import { crmApiSlice } from '../slices/crm';
import cbceAuthReducer, {
    cbceAuthInitialState,
} from '../slices/cashback-chrome-plugin/cashback-chrome-plugin-auth-slice';
import cbceCommonReducer, {
    cbceCommonInitialState,
} from '../slices/cashback-chrome-plugin/cashback-chrome-plugin-common-slice';
import cbceOfferReducer, {
    cbceOfferInitialState,
} from '../slices/cashback-chrome-plugin/cashback-chrome-plugin-offer-slice';
import { commonInitState, commonSlices } from '../common';
import walletReducer, { walletInitialState } from '../slices/wallet-slice';

export const cbceSlices = {
    autoFillRules: autoFillRulesReducer,
    verification: verificationReducer,
    cashback: cashbackReducer,
    cbceAuthentication: cbceAuthReducer,
    cbceCommon: cbceCommonReducer,
    cbceOffer: cbceOfferReducer,
    checkout: checkoutReducer,
    gifting: giftingReducer,
    offers: offerConfigReducer,
    otp: otpSliceReducer,
    emailCapture: emailCaptureReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    wallet: walletReducer,
};

export const widgetAPIQuerySlices = {
    [cashbackApiSlice.reducerPath]: cashbackApiSlice.reducer,
    [crmApiSlice.reducerPath]: crmApiSlice.reducer,
};

const cbceReducer = combineReducers({
    ...widgetAPIQuerySlices,
    ...cbceSlices,
    ...commonSlices,
});

const cbcePersistConfig = {
    key: 'root',
    storage,
    blacklist: ['autoFillRules', 'cbceOffer'],
};

const cbcePersistedReducer = persistReducer(cbcePersistConfig, cbceReducer);

export const cbceStore = configureStore({
    reducer: cbcePersistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(cashbackApiSlice.middleware)
            .concat(crmApiSlice.middleware),
    devTools: true,
});

export const cbcePersistedStore = persistStore(cbceStore);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type CBCERootState = ReturnType<typeof cbceReducer>;

export type AppDispatch = typeof cbcePersistedStore.dispatch & ThunkDispatch<CBCERootState, null, AnyAction>;

// @ts-ignore
export const CBCEInitialState: CBCERootState = {
    ...commonInitState,
    autoFillRules: autoFillRulesInitialState,
    verification: verificationInitialState,
    cashback: cashbackInitialState,
    cbceAuthentication: cbceAuthInitialState,
    cbceCommon: cbceCommonInitialState,
    cbceOffer: cbceOfferInitialState,
    checkout: checkoutInitialState,
    gifting: giftingInitialState,
    offers: offersInitialState,
    otp: otpInitialState,
    emailCapture: emailCaptureInitialState,
    sidebar: sidebarInitialState,
    user: userInitialState,
    wallet: walletInitialState,
};
