import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/widget/widget';
import { selectIsCashbackEnabled } from '../store/slices/partner-config-slice';
import { useAppSelector } from './use-app-selector';
import { cashbackApiSlice, CashbackEventGroups } from '../store/slices/cashback';

export function useInvalidateCashbackMetrics() {
    const dispatch = useDispatch<AppDispatch>();
    const isCashbackEnabled = useAppSelector(selectIsCashbackEnabled);

    const invalidateCashbackMetrics = () => {
        if (isCashbackEnabled) {
            dispatch(cashbackApiSlice.util.invalidateTags([CashbackEventGroups.CASHBACK_SUMMARY_DATA]));
        }
    };

    return [invalidateCashbackMetrics];
}

export default useInvalidateCashbackMetrics;
