import React from 'react';
import classNames from 'classnames';

import './inline-module-layout.scss';

interface InlineModuleLayoutProps {
    isFullWidth?: boolean;
    isTwoColumn?: boolean;
}

export const InlineModuleLayout: React.FC<InlineModuleLayoutProps> = ({
    children,
    isFullWidth = false,
    isTwoColumn = false,
}): React.ReactElement => {
    const classes = classNames('inline-module-layout', {
        'inline-module-layout--full-width': isFullWidth,
        'inline-module-layout--two-columns': isTwoColumn,
    });

    return <div className={classes}>{children}</div>;
};
