import React, { forwardRef } from 'react';
import { Button } from '..';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';

import './sidebar-arrow.scss';

interface SidebarArrowProps {
    onClick: () => void;
    orientation?: string;
}

const arrowOrientationConfig = {
    right: {
        icon: 'far fa-chevron-right',
        ariaLabel: 'Move Forward',
        trackingType: ButtonTrackingTypes.BUTTON_CLICK_SIDEBAR_BACK_ARROW,
    },
    left: {
        icon: 'far fa-chevron-left',
        ariaLabel: 'Move Back',
        trackingType: ButtonTrackingTypes.BUTTON_CLICK_SIDEBAR_FORWARD_ARROW,
    },
};

export const SidebarArrow = forwardRef<HTMLButtonElement, SidebarArrowProps>(
    (
        { onClick, orientation },
        ref: React.RefCallback<HTMLButtonElement> | React.MutableRefObject<HTMLButtonElement>,
    ): React.ReactElement => {
        const orientationKey = orientation ?? 'left';
        const { icon, ariaLabel, trackingType } =
            arrowOrientationConfig[orientationKey as keyof typeof arrowOrientationConfig];

        return (
            <Button
                ref={ref}
                testId="sidebar-arrow"
                ariaLabel={ariaLabel}
                icon={icon}
                isElement={true}
                onClick={onClick}
                className="sidebar-arrow"
                trackingType={trackingType}
            />
        );
    },
);
