export enum EnvironmentNames {
    CANDIDATE = 'candidate',
    DEVELOPMENT = 'dev',
    PRE_PRODUCTION = 'preprod',
    PRODUCTION = 'prod',
    SANDBOX = 'sandbox',
}

export enum ClientDeviceCategory {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

export const isProduction = (environment = process.env.ENV) => {
    return environment === EnvironmentNames.PRODUCTION || environment === EnvironmentNames.SANDBOX;
};

export const getDeviceType: () => ClientDeviceCategory = () => {
    const userAgent = navigator.userAgent;
    const mobilePattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i;
    return mobilePattern.test(userAgent) ? ClientDeviceCategory.MOBILE : ClientDeviceCategory.DESKTOP;
};
