import { useDispatch } from 'react-redux';
import { setSidebarView, SidebarViewEnum } from '../store/slices/sidebar-slice';

import { AppDispatch } from '../store/widget/widget';

export function useSetSidebarView() {
    const dispatch = useDispatch<AppDispatch>();

    const setView = (view: SidebarViewEnum) => {
        dispatch(setSidebarView(view));
    };

    return [setView];
}

export default useSetSidebarView;
