import React from 'react';
import SwiperCore, { A11y, Navigation, Scrollbar, Mousewheel } from 'swiper';
import { BonusTag, GiftCardImage, GiftCardImageView, RoundImage } from '../../../../../../components/common';
import { RoundImageSizes } from '../../../../../../store/slices/common-slice';
import { CurrentOfferDisplay } from '../offer-gift-card';
import { OfferName } from '../common/offer-name';
import { BigBrandCarousel } from '../../big-brand/big-brand-carousel';

import './big-brand-offer-display.scss';

SwiperCore.use([A11y, Navigation, Scrollbar, Mousewheel]);
interface BigBrandOfferDisplayProps {
    data: CurrentOfferDisplay;
    supportingImages: string[];
}

export const BigBrandOfferDisplay: React.FC<BigBrandOfferDisplayProps> = ({
    data,
    supportingImages,
}): React.ReactElement => {
    SwiperCore.use([A11y, Navigation, Scrollbar, Mousewheel]);
    const shouldRenderCarousel = supportingImages.length > 1;

    return (
        <>
            {shouldRenderCarousel && <BigBrandCarousel name={data.name} supportingImages={supportingImages} />}
            {!shouldRenderCarousel && (
                <GiftCardImage
                    imgUrl={data.hero_image_url}
                    altText={`${data.name} gift card`}
                    view={GiftCardImageView.HERO}
                />
            )}

            <div className="big-brand-offer-display__info">
                <RoundImage
                    imageUrl={data.logomark_url}
                    imageAltText={`${data.name} logomark`}
                    size={RoundImageSizes.FIVE}
                />
                <div className="big-brand-offer-display__details">
                    <OfferName name={data.name} rows={1} isBold />
                    {data.offer_value > 0 && (
                        <BonusTag
                            type={data.offer_type}
                            value={data.offer_value}
                            currency={data.currency}
                            offerType={data.display_offer_type}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
