import React from 'react';
import './banner.scss';

interface BannerProps {
    text: string;
}

export const Banner: React.FC<BannerProps> = ({ text }): React.ReactElement => {
    return (
        <div className="banner">
            <h1>{text}</h1>
        </div>
    );
};
