import React from 'react';
import Classnames from 'classnames';

import './tile-media-asset.scss';

interface TileMediaAssetProps {
    imgSrc?: string;
    icon?: string;
    alt?: string;
}

export const TileMediaAsset: React.FC<TileMediaAssetProps> = ({ imgSrc, icon, alt }) => {
    const classes = Classnames('tile-media-asset', {
        'tile-media-asset--image': imgSrc,
        'tile-media-asset--icon': icon,
    });

    return (
        <>
            {imgSrc && !icon && (
                <img data-testid="media-asset-image" className={classes} src={imgSrc} alt={alt || ''} />
            )}
            {!imgSrc && icon && (
                <span className={classes}>
                    <i data-testid="media-asset-icon" className={icon}></i>
                </span>
            )}
        </>
    );
};
