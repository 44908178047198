import { DashboardUserAccountLoginType } from '../../store/slices/admin-portal';
import { DashboardUserPerm } from '../../store/slices/partner-portal';

export enum DashboardLayoutId {
    REWARDS = 'rewards',
    CASHBACK = 'cashback',
    FUNDING = 'funding',
    GIFT_CARD_TRANSACTIONS = 'gift_card_transactions',
    INTEGRATION = 'integration',
    PROFILE = 'profile',
    SUPPORT = 'support',
    TEAMS = 'teams',
    USERS = 'users',
}

export enum DashboardLayoutPerm {
    READ = 'read',
    WRITE = 'write',
}

export interface DashboardLayoutProp {
    tab: DashboardLayoutId;
    perm: DashboardLayoutPerm;
}

export interface SessionMetadata {
    authenticated: boolean;
    name: string;
    email: string;
    perms: DashboardUserPerm[];
    layout: DashboardLayoutProp[];
    expirationDate: string;
    selectedPartnerName: string;
    selectedPartnerId: string;
    environment: string;
    userLoginType: DashboardUserAccountLoginType;
}
