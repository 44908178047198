import React, { Suspense, lazy } from 'react';
import { ToastContainer } from 'react-toastify';
import { Loader } from './common';
import { ApplicationModule } from '../store/slices/common-slice';
import DevTools from '../utils/helpers/dev-tools';
import TokenExpirationWarning from './features/auth/token-expiration-warning';
import { VerificationMonitor } from './common/utils/verification-monitor';
import { getApplicationModule, isConsumerApplication } from '../utils';
import { ConsumerWindowListeners } from './common/utils/consumer-window-listeners';

import './app.scss';

const App: React.FC = (): React.ReactElement => {
    const application = getApplicationModule();
    const isConsumerApp = isConsumerApplication();

    const LazyAdminPortal = lazy(() => import('../modules/admin-portal'));
    const LazyClaim = lazy(() => import('../modules/claim'));
    const LazyDirectToConsumer = lazy(() => import('../modules/direct-to-customer'));
    const LazyPartnerPortal = lazy(() => import('../modules/partner-portal'));
    const LazyStoreFront = lazy(() => import('../modules/store-front'));
    const LazyWidget = lazy(() => import('../modules/widget'));

    return (
        <div className="application-wrapper">
            <div className="app-content-wrapper">
                <Suspense fallback={<Loader />}>
                    {
                        {
                            [ApplicationModule.ACCOUNTS]: <LazyDirectToConsumer />,
                            [ApplicationModule.ADMIN]: <LazyAdminPortal />,
                            [ApplicationModule.CLAIM]: <LazyClaim />,
                            [ApplicationModule.PARTNER_PORTAL]: <LazyPartnerPortal />,
                            [ApplicationModule.REWARDS]: <></>,
                            [ApplicationModule.STORE_FRONT]: <LazyStoreFront />,
                            [ApplicationModule.WIDGET]: <LazyWidget />,
                            [ApplicationModule.BROWSER_EXTENSION]: <></>, // this is only here to make ts happy
                        }[application]
                    }
                </Suspense>
            </div>
            {isConsumerApp && (
                <>
                    <VerificationMonitor />
                    <TokenExpirationWarning />
                    <ConsumerWindowListeners />
                </>
            )}
            <Loader />
            <DevTools />
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                closeButton={false}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme={'dark'}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default App;
