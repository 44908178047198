import React, { useState } from 'react';
import Classnames from 'classnames';
import { createDevTools } from '@redux-devtools/core';
import { LogMonitor } from '@redux-devtools/log-monitor';
import { useAppSelector } from '../../hooks';
import { selectIsMiniWidget } from '../../store/slices/sdk-slice';
import { EnvironmentNames } from './environment';
import { Button, ButtonColor, ButtonSize } from '../../components/common';
import { toast } from 'react-toastify';

import './dev-tools.scss';

const DevTool = createDevTools(<LogMonitor />);

const WrappedDevTool = () => {
    const isMiniWidget = useAppSelector(selectIsMiniWidget);

    const [isShowingDevTools, setIsShowingDevTools] = useState(false);
    const [isControlsMinimized, setIsControlsMinimized] = useState(true);

    const isProd = process.env.ENV === EnvironmentNames.PRODUCTION;
    const isSandbox = process.env.ENV === EnvironmentNames.SANDBOX;

    const classes = Classnames('z-index-loader dev-tools', {
        'dev-tools--is-minimized': isControlsMinimized,
    });

    const drawerClasses = Classnames('z-index-full-screen-overlay dev-tools dev-tools--drawer');

    const toggleDevTools = () => {
        setIsShowingDevTools(!isShowingDevTools);
    };

    const toggleControls = () => {
        setIsControlsMinimized(!isControlsMinimized);
    };

    const clearSessionStorageData = () => {
        sessionStorage.clear();
        toast.success('Session storage cleared');
    };

    const toggleIconLabel = isControlsMinimized ? 'plus' : 'minus';

    return (
        <>
            {!isProd && !isSandbox && !isMiniWidget && (
                <>
                    <div className={classes}>
                        <ul className="dev-tools__list">
                            <li>
                                <Button
                                    size={ButtonSize.MINI}
                                    color={ButtonColor.TERTIARY}
                                    isElement={true}
                                    onClick={toggleControls}
                                    icon={`far fa-${toggleIconLabel}`}
                                    className="dev-tools__controls-toggle"
                                />
                            </li>
                            <li>
                                <Button
                                    size={ButtonSize.MINI}
                                    color={ButtonColor.TERTIARY}
                                    text={`${isShowingDevTools ? 'Hide' : 'Show'} dev tools`}
                                    onClick={toggleDevTools}
                                />
                            </li>
                            <li>
                                <Button
                                    size={ButtonSize.MINI}
                                    color={ButtonColor.TERTIARY}
                                    text="Clear session data"
                                    onClick={clearSessionStorageData}
                                />
                            </li>
                        </ul>
                    </div>
                    {isShowingDevTools && (
                        <div className={drawerClasses}>
                            <DevTool />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default WrappedDevTool;
