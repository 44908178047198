import React from 'react';
import { CloseButton, CloseButtonPosition } from '../../common/buttons';
import { CRMTestimonial } from '../../../store/slices/crm';

import './cashback-onboarding-intro.scss';

interface CashbackOnboardingIntroProps {
    exitHandler?: () => void;
    data?: CRMTestimonial;
}

export const CashbackOnboardingIntro: React.FC<CashbackOnboardingIntroProps> = ({
    exitHandler,
    data,
}): React.ReactElement => {
    return (
        <section className="cashback-onboarding-intro">
            <CloseButton position={CloseButtonPosition.START} onClick={exitHandler} />
            {data?.quote && <p>{data.quote}</p>}
            <div className="cashback-onboarding-intro__author">
                {data?.nameAndTitle && (
                    <p>
                        <strong>{data.nameAndTitle}</strong>
                    </p>
                )}
                {data?.image && <img src={data.image} alt={data.nameAndTitle} />}
            </div>
        </section>
    );
};
