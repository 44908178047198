import React from 'react';
import { PrizeoutProductVersion } from '../../../store/slices/partner-config-slice';

export interface CheckoutInfoStepTemplate {
    [PrizeoutProductVersion.STANDARD]: boolean;
    [PrizeoutProductVersion.BONUS]: boolean;
    [PrizeoutProductVersion.CASHBACK]: boolean;
    [PrizeoutProductVersion.REWARDS]: boolean;
    icon: string;
    text: string;
}

interface CheckoutInfoStep {
    stepDefinition: CheckoutInfoStepTemplate;
}

import './checkout-info-step.scss';

export const CheckoutInfoStep: React.FC<CheckoutInfoStep> = ({ stepDefinition }): React.ReactElement => {
    return (
        <li className="checkout-info-step" data-testid="checkout-info-step">
            <div className="checkout-info-step__icon">
                <i className={stepDefinition.icon}></i>
            </div>
            <p className="checkout-info-step__text">{stepDefinition.text}</p>
        </li>
    );
};
