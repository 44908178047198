import React from 'react';
import { BackArrowButton, ExitButton } from '..';
import { SidebarToggle } from '../../features/sidebar/common';
import {
    PrizeoutProductVersion,
    selectHideExitButton,
    selectIsSingleWithdrawal,
    selectPrizeoutProductVersion,
} from '../../../store/slices/partner-config-slice';
import { CheckoutViewEnum, selectCheckoutView } from '../../../store/slices/checkout-slice';
import { selectIsMobileLandscape, selectIsMobilePortrait } from '../../../store/slices/common-slice';
import { useAppSelector, useGoToRoute } from '../../../hooks';
import { HeaderLayout } from './common/header-layout';
import { ProductBrandingHeader, UserBalanceAccountSelector } from '../ui-widgets';
import { selectIsGiftingMode } from '../../../store/slices/sdk-slice';
import { routes } from '../../../utils/helpers/routing-helpers';
import { HeaderEndItems } from './common/header-end-items';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';
import { LocationSelector } from '../../features/location-selector/location-selector';

export const Header: React.FC = (): React.ReactElement => {
    const hideExit = useAppSelector(selectHideExitButton);
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);
    const isSingleWithdrawal = useAppSelector(selectIsSingleWithdrawal);
    const activeView = useAppSelector(selectCheckoutView);
    const isCheckoutConfirmationView =
        activeView == CheckoutViewEnum.CHECKOUT_CONFIRMATION ||
        activeView == CheckoutViewEnum.CHECKOUT_REWARDS_CONFIRMATION;
    const hideNav = isSingleWithdrawal && isCheckoutConfirmationView;
    const isGiftingMode = useAppSelector(selectIsGiftingMode);
    const prizeoutProductVersion = useAppSelector(selectPrizeoutProductVersion);
    const { goToRoute } = useGoToRoute();
    const mobileView = useAppSelector(selectIsMobilePortrait);

    const shouldShowExitButton = !hideExit && !isGiftingMode;
    const shouldShowProductName = prizeoutProductVersion !== PrizeoutProductVersion.STANDARD;
    const shouldShowBrandingHeaderAsStartItem = mobileView && shouldShowProductName && hideExit;
    const shouldShowUserBalanceAccountSelector =
        !isMobileLandscape && prizeoutProductVersion == PrizeoutProductVersion.STANDARD;

    const determineStartItem = () => {
        return (
            <>
                {isGiftingMode && (
                    <BackArrowButton
                        onClick={() => goToRoute(routes.GIFTING)}
                        trackingType={ButtonTrackingTypes.GIFTING_HEADER_BACK_BUTTON_CLICK}
                    />
                )}

                {shouldShowExitButton && <ExitButton />}

                {shouldShowBrandingHeaderAsStartItem && <ProductBrandingHeader />}
            </>
        );
    };

    const determineEndItem = () => {
        if (hideNav) {
            return false;
        }

        return (
            <HeaderEndItems>
                <LocationSelector />
                {shouldShowUserBalanceAccountSelector && <UserBalanceAccountSelector />}
                {!isGiftingMode && <SidebarToggle />}
            </HeaderEndItems>
        );
    };

    return (
        <HeaderLayout
            startItem={determineStartItem()}
            endItem={determineEndItem()}
            hideCenteredBranding={shouldShowBrandingHeaderAsStartItem}
            showProductBrandingHeader={shouldShowProductName}
        />
    );
};
