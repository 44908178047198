import { NavigationItemConfig } from '../../components/common/ui-widgets/navigation';
import { DashboardLayoutProp } from '../types';
import { intersection } from 'lodash';

export const normalizeLayout = (
    layout: DashboardLayoutProp[],
    config: NavigationItemConfig[],
): NavigationItemConfig[] => {
    const layoutIds = layout?.map((item) => item.tab);
    const configIds = config?.map((item) => item.id);
    const validIds = intersection(layoutIds, configIds);
    const result = config.filter((item) => validIds.includes(item.id) || item.alwaysIncluded);

    return result;
};
