export enum LoadingStateActionNames {
    CLEAR_ERROR = 'clear_error',
    LOADING_DISABLED = 'loading_disabled',
    LOADING_ENABLED = 'loading_enabled',
    SET_ERROR = 'set_error',
}

export interface LoadingState {
    errorMessage?: string;
    isLoading: boolean;
    isError: boolean;
}

export interface LoadingStateAction {
    errorMessage?: string;
    type: LoadingStateActionNames;
}

export const INITIAL_LOADING_STATE: LoadingState = {
    isLoading: true,
    isError: false,
};

export function loadingStateReducer(state: LoadingState, action: LoadingStateAction) {
    switch (action.type) {
        case LoadingStateActionNames.CLEAR_ERROR:
            return {
                ...state,
                errorMessage: '',
                isError: false,
                isLoading: false,
            };

        case LoadingStateActionNames.LOADING_ENABLED:
            return {
                ...state,
                errorMessage: '',
                isError: false,
                isLoading: true,
            };

        case LoadingStateActionNames.LOADING_DISABLED:
            return {
                ...state,
                errorMessage: '',
                isError: false,
                isLoading: false,
            };

        case LoadingStateActionNames.SET_ERROR:
            return {
                isLoading: false,
                isError: true,
                errorMessage: action?.errorMessage,
            };

        default:
            console.error(`Unknown loading state reducer action: '${action}'`);
            return state;
    }
}
