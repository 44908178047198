import React, { useState } from 'react';
import Classnames from 'classnames';
import { UserBalance } from './user-balance';
import { ClickableCard } from '../cards';
import { selectIsMobileLandscape } from '../../../store/slices/common-slice';
import { useAppSelector } from '../../../hooks';
import { selectHideBalance, selectUserBankAccounts } from '../../../store/slices/partner-config-slice';
import { selectIsGiftingMode } from '../../../store/slices/sdk-slice';
import { GenericModuleLayout } from '../../../modules/common/generic-module-layout/generic-module-layout';
import { BankAccountSelectorModal } from '../modals/bank-account-selector-modal';
import { BankAccountSelector } from '../../features/bank-account-selector/bank-account-selector';

import './user-balance-account-selector.scss';

export const UserBalanceAccountSelector: React.FC = (): React.ReactElement => {
    const bankAccounts = useAppSelector(selectUserBankAccounts);
    const hasBankAccount = bankAccounts && bankAccounts.length >= 1;
    const isGiftingMode = useAppSelector(selectIsGiftingMode);
    const hideBalance = useAppSelector(selectHideBalance);
    const shouldShowUserBalance = !hideBalance || isGiftingMode;
    const shouldShowBankAccountSelector = hasBankAccount && !isGiftingMode;
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);
    const [isBankAccountSelectorModalOpen, setIsBankAccountSelectorModalOpen] = useState(false);

    const balanceTextLabel = 'Available balance';

    const classes = Classnames('user-balance-account-selector', {
        'user-balance-account-selector--two-columns': shouldShowBankAccountSelector,
        'user-balance-account-selector--mobile-view': isMobileLandscape,
        'user-balance-account-selector--account-only': !shouldShowUserBalance && shouldShowBankAccountSelector,
    });

    const openBankAccountSelectorModal = () => {
        setIsBankAccountSelectorModalOpen(true);
    };

    const closeBankAccountSelectorModal = () => {
        setIsBankAccountSelectorModalOpen(false);
    };

    const renderLayout = () => {
        return (
            <>
                <ClickableCard
                    testId="user-balance-account-selector-clickable"
                    cardName="user-balance-account-selector"
                    onClick={() => openBankAccountSelectorModal()}
                    isDisabled={!shouldShowBankAccountSelector}
                >
                    <article data-testid="user-balance-account-selector" className={classes}>
                        <div className="user-balance-account-selector__details">
                            <>
                                {shouldShowBankAccountSelector && <BankAccountSelector />}

                                {shouldShowUserBalance && (
                                    <>
                                        {!shouldShowBankAccountSelector && (
                                            <small className="user-balance-account-selector__text">
                                                {balanceTextLabel}
                                            </small>
                                        )}
                                        <UserBalance />
                                    </>
                                )}
                            </>
                        </div>
                        {shouldShowBankAccountSelector && (
                            <div className="user-balance-account-selector__icon-wrapper">
                                <i className="fa-solid fa-chevron-down"></i>
                            </div>
                        )}
                    </article>
                </ClickableCard>
            </>
        );
    };

    return (
        <>
            {(shouldShowUserBalance || shouldShowBankAccountSelector) && (
                <>
                    {isMobileLandscape && (
                        <GenericModuleLayout noVerticalMargin={true}>{renderLayout()}</GenericModuleLayout>
                    )}
                    {!isMobileLandscape && renderLayout()}
                </>
            )}

            <BankAccountSelectorModal isOpen={isBankAccountSelectorModalOpen} onClose={closeBankAccountSelectorModal} />
        </>
    );
};
