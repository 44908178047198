export enum MonthNames {
    JANUARY = 'January',
    FEBRUARY = 'February',
    MARCH = 'March',
    APRIL = 'April',
    MAY = 'May',
    JUNE = 'June',
    JULY = 'July',
    AUGUST = 'August',
    SEPTEMBER = 'September',
    OCTOBER = 'October',
    NOVEMBER = 'November',
}

export interface dateOptions {
    weekday?: 'narrow' | 'short' | 'long';
    era?: 'narrow' | 'short' | 'long';
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
    day?: 'numeric' | '2-digit';
    hour?: 'numeric' | '2-digit';
    minute?: 'numeric' | '2-digit';
    second?: 'numeric' | '2-digit';
    timeZoneName?: 'short' | 'long';

    // Time zone to express it in
    timeZone?: string;
    // Force 12-hour or 24-hour
    hour12?: boolean;

    // Rarely-used options
    hourCycle?: 'h11' | 'h12' | 'h23' | 'h24';
    formatMatcher?: 'basic' | 'best fit';
}

const dateFormatter = (
    date: Date,
    locale: string | Array<string> = 'en-US',
    options: dateOptions = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    },
): string => new Intl.DateTimeFormat(locale, options).format(date);

const timeFormatter = (
    date: Date,
    locale: string | Array<string> = 'en-US',
    options: dateOptions = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
): string => new Intl.DateTimeFormat(locale, options).format(date);

const formatDate = (
    date: Date | number | string,
    shouldDisplayTime = false,
    numericDate = false,
    numericMonth = false,
) => {
    const locale = window.navigator && window.navigator.language ? window.navigator.language : 'en-US';
    const workingDate = new Date(date);

    if (shouldDisplayTime) {
        return timeFormatter(workingDate, locale);
    } else if (numericDate) {
        return dateFormatter(workingDate, locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    } else if (numericMonth) {
        return dateFormatter(workingDate, locale, {
            year: '2-digit',
            month: 'numeric',
            day: '2-digit',
        });
    } else {
        return dateFormatter(workingDate, locale);
    }
};

const formatDateTime = (date: Date | number | string, numericDate = false) => {
    const formattedDate = formatDate(date, false, numericDate);
    const formattedTime = formatDate(date, true).toLowerCase();

    return `${formattedDate} at ${formattedTime}`;
};

const getISODate = (date: Date | number | string): string => {
    const workingDate = new Date(date);
    return workingDate.toISOString().split(/T/)[0];
};

export { dateFormatter, formatDate, formatDateTime, getISODate };
