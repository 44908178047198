import React from 'react';
import Classnames from 'classnames';
import { Button, ButtonType } from '../buttons';
import { NavBarButtonIconNames } from '../../../store/slices/partner-config-slice';

import './nav-bar-button.scss';

interface NavBarButtonProps {
    icon: NavBarButtonIconNames;
    label?: string;
    isSelected: boolean;
    onClick?: () => void;
}

export const NavBarButton: React.FC<NavBarButtonProps> = ({
    icon,
    label,
    isSelected,
    onClick,
    children,
}): React.ReactElement => {
    const classes = Classnames('nav-bar-button', {
        'nav-bar-button--is-selected': isSelected,
    });
    const iconName = isSelected ? `${icon}-selected` : icon;

    return (
        <Button
            ariaLabel={`Navigate to the ${label || icon} page`}
            className={classes}
            isElement={true}
            onClick={onClick}
            type={ButtonType.BUTTON}
            testId="nav-bar-button"
        >
            {children ? (
                children
            ) : (
                <span className="nav-bar-button__icon">
                    <i data-testid="nav-bar-button-icon" className={`fa-kit fa-prizeout-nav-${iconName}`}></i>
                </span>
            )}
            {label && <span className="nav-bar-button__label">{label}</span>}
        </Button>
    );
};
