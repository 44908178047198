import React from 'react';

import './copyright.scss';

interface CopyrightProps {
    testId?: string;
}

export const Copyright: React.FC<CopyrightProps> = ({ testId = 'copyright' }): React.ReactElement => {
    const currentYear = new Date().getFullYear();

    return (
        <p data-testid={testId} className="copyright">
            &copy; 2020-{currentYear}, Prizeout, Inc. All rights reserved.
        </p>
    );
};
