import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { PaginatedData } from '../../../utils';
import { basePortalQuery } from '../common';
import {
    PortalEventGroups,
    AdminDashboardUsersQuery,
    AdminDashboardUser,
    AddAdminDashboardUserQuery,
    GenericSuccessResponse,
    AdminDashboardPartner,
    AdminDashboardPartnersQuery,
} from './admin-portal-types';

export const portalApiSlice = createApi({
    reducerPath: 'adminPortalApi',
    baseQuery: basePortalQuery,
    tagTypes: [
        PortalEventGroups.PortalDashboardUsersList,
        PortalEventGroups.PortalDashboardPartnersList,
        PortalEventGroups.AddPortalDashboardUser,
    ],
    endpoints: (builder) => ({
        getDashboardUsersList: builder.query<PaginatedData<AdminDashboardUser[]>, AdminDashboardUsersQuery>({
            query: (args: AdminDashboardUsersQuery) => ({
                url: '/admin/dashboardUser/list',
                params: args,
            }),
            providesTags: [PortalEventGroups.PortalDashboardUsersList],
        }),
        addDashboardUser: builder.mutation<GenericSuccessResponse, AddAdminDashboardUserQuery>({
            query: (args: AddAdminDashboardUserQuery) => ({
                url: '/admin/dashboardUser',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.AddPortalDashboardUser],
        }),
        getDashboardPartnersList: builder.query<PaginatedData<AdminDashboardPartner[]>, AdminDashboardPartnersQuery>({
            query: (args: AdminDashboardPartnersQuery) => ({
                url: '/admin/partnerList',
                params: args,
            }),
            providesTags: [PortalEventGroups.PortalDashboardPartnersList],
        }),
    }),
});

export const { useGetDashboardUsersListQuery, useAddDashboardUserMutation, useGetDashboardPartnersListQuery } =
    portalApiSlice;
