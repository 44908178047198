import React from 'react';
import { TileMediaAsset } from './tile-media-asset';
import { RoundImageSizes } from '../../../store/slices/common-slice';
import { displayOfferType } from '../../../store/slices/offers-slice';
import { RoundImage } from '../ui-widgets';
import { OfferName } from '../../../modules/widget/components/offers/offer-gift-card/common/offer-name';
import { BonusTag, BonusTagType } from '../../common/ui-widgets/bonus-tag';

import './tile-weekly-deal.scss';

interface TileWeeklyDealProps {
    brand: string;
    value: string;
    offerType: displayOfferType;
    roundImageUrl: string;
    roundImageAlt?: string;
}

export const TileWeeklyDeal: React.FC<TileWeeklyDealProps> = ({
    brand,
    value,
    offerType,
    roundImageUrl,
    roundImageAlt,
}) => {
    const icon = 'fa-kit fa-prizeout-add';

    return (
        <>
            <div className="weekly-deal">
                <h2>An offer for you</h2>

                <div className="weekly-deal__offer-layout">
                    <div className="weekly-deal__logomark">
                        <RoundImage size={RoundImageSizes.SIX} imageUrl={roundImageUrl} imageAltText={roundImageAlt} />
                    </div>

                    <div className="weekly-deal__brand">
                        <OfferName rows={1} isBold={true} name={brand} />
                        <BonusTag type={BonusTagType.PERCENTAGE} value={parseFloat(value)} offerType={offerType} />
                    </div>

                    <div className="weekly-deal__icon">
                        <TileMediaAsset icon={icon} />
                    </div>
                </div>
            </div>
        </>
    );
};
