import { createSelector } from '@reduxjs/toolkit';
import { rewardsApiSlice } from './rewards-api-slice';
import { RootState } from '../../widget/widget';
import { RewardsCommonState } from './rewards-common-slice';

const selectGetRewardsResults = rewardsApiSlice.endpoints.getRewards.select();

const selectRewardsList = rewardsApiSlice.endpoints.getRewardsList.select();

const selectClaimedRewards = rewardsApiSlice.endpoints.getClaimedRewards.select();

export const selectGetRewardsIsLoading = createSelector(selectGetRewardsResults, ({ isLoading }) => isLoading);

export const selectGetRewards = createSelector(selectGetRewardsResults, ({ data }) => data);

export const selectAvailableActions = createSelector(selectGetRewards, (data) => data?.available);

export const selectFirstAvailablePartnerReward = createSelector(
    selectAvailableActions,
    (data) => data?.[0].partnerReward,
);

export const selectActiveActions = createSelector(selectGetRewards, (data) => data?.active);

export const selectCompletedActions = createSelector(selectGetRewards, (data) => data?.completed);

export const selectRewardsCommonState = ({ rewardsCommon }: RootState): RewardsCommonState => rewardsCommon;

export const selectCurrentConsumerReward = createSelector(
    selectRewardsCommonState,
    ({ currentConsumerReward }) => currentConsumerReward,
);

export const selectCurrentPartnerReward = createSelector(
    selectCurrentConsumerReward,
    ({ partnerReward }) => partnerReward,
);

export const selectCurrentUserReward = createSelector(selectCurrentConsumerReward, ({ userReward }) => userReward);

export const selectCurrentRewardCurrency = createSelector(
    selectCurrentUserReward,
    ({ payoutAmountCurrencyCode }) => payoutAmountCurrencyCode,
);

export const selectRewardsListData = createSelector(selectRewardsList, ({ data }) => data?.data);

export const selectClaimedRewardsData = createSelector(selectClaimedRewards, ({ data }) => data?.data);

export const selectRewardsListPagination = createSelector(selectRewardsList, ({ data }) => data?.pagination);

export const selectClaimedRewardsPagination = createSelector(selectClaimedRewards, ({ data }) => data?.pagination);
