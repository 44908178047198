import React from 'react';
import Classnames from 'classnames';
import { titleCaseText } from '../../../utils/helpers';

import './metadata.scss';

export enum MetadataTypes {
    TIMESTAMP = 'timestamp',
    USER = 'user',
    EMAIL = 'email',
    CLOCK = 'clock',
    BANK_ACCOUNT = 'bank-account',
}

interface MetadataProps {
    text: string;
    type: MetadataTypes;
}

export const Metadata: React.FC<MetadataProps> = ({ text, type }): React.ReactElement => {
    const classes: string = Classnames('metadata');

    let icon: string;

    switch (type) {
        case MetadataTypes.TIMESTAMP:
            icon = 'far fa-clock';
            break;

        case MetadataTypes.USER:
            icon = 'fak fa-prizeout-user';
            break;

        case MetadataTypes.EMAIL:
            icon = 'fak fa-prizeout-email';
            break;

        case MetadataTypes.CLOCK:
            icon = 'fak fa-prizeout-clock';
            break;

        case MetadataTypes.BANK_ACCOUNT:
            icon = 'fa-kit fa-bank-icon';
    }

    const renderText = () => {
        if (type === MetadataTypes.USER) {
            return titleCaseText(text);
        } else {
            return text;
        }
    };

    return (
        <div data-testid="metadata" className={classes}>
            <div className="metadata__icon">
                <i data-testid={`metadata-icon--${type}`} className={icon}></i>
            </div>

            <p>{renderText()}</p>
        </div>
    );
};
