import React from 'react';
import './clear-image-overlay.scss';

interface ClearImageOverlayProps {
    renderGradient?: boolean;
}

export const ClearImageOverlay: React.FC<ClearImageOverlayProps> = ({ renderGradient }): React.ReactElement => {
    return (
        <div className="clear-image-overlay">{renderGradient && <div className="clear-image-overlay__gradient" />}</div>
    );
};
