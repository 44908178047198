export const appendWhiteLabelStylesheet = (partnerId: string) => {
    const stylesheet = document.createElement('link');

    stylesheet.rel = 'stylesheet';
    stylesheet.href = `https://assets.prizeout.com/whitelabel/${partnerId}/themeOverride.css`;

    const existingOverride = document.querySelectorAll(`link[rel="stylesheet"][href="${stylesheet.href}"]`);

    if (existingOverride.length === 0) {
        document.head.after(stylesheet);
    }
};
