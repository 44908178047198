import React, { useRef, useState } from 'react';
import Classnames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { selectIsMobilePortrait } from '../../../store/slices/common-slice';
import { useAppSelector, useNumberSequence } from '../../../hooks';
import { GenericModal } from '../modals';
import { Button } from '../buttons';

import './tool-tip.scss';

export enum ToolTipTriggerTypes {
    CLICK = 'click',
    HOVER = 'hover',
}

export enum ToolTipDirectionTypes {
    UP = 'up',
    DOWN = 'down',
    TOP_RIGHT = 'top-right',
    TOP_LEFT = 'top-left',
    RIGHT_BOTTOM = 'right-bottom',
    RIGHT_MIDDLE = 'right-middle',
    RIGHT_TOP = 'right-top',
}

interface ToolTipProps {
    content: string;
    modalHeadline?: string; // This is the headline that displays in the modal, mobile view
    tipTrigger?: ToolTipTriggerTypes;
    tipDirection?: ToolTipDirectionTypes;
}

export const ToolTip: React.FC<ToolTipProps> = ({
    content,
    modalHeadline,
    tipTrigger = ToolTipTriggerTypes.HOVER,
    tipDirection = ToolTipDirectionTypes.UP,
}): React.ReactElement => {
    const getUniqueNumber = useNumberSequence();
    const isMobilePortraitView = useAppSelector(selectIsMobilePortrait);

    const [isOpen, setIsOpen] = useState(false);
    const [isShowing, setIsShowing] = useState(false);

    const toolTipContainerId = `tool-tip-container-${getUniqueNumber()}`;
    const toolTipRef = useRef(`tool-tip-${getUniqueNumber()}`);

    const isHoverTrigger = tipTrigger === ToolTipTriggerTypes.HOVER;

    const classes: string = Classnames('tool-tip', {
        'tool-tip--is-showing': isShowing,
        [`tool-tip--${tipDirection}`]: tipDirection,
    });

    const showToolTip = (): void => setIsShowing(true);

    const hideToolTip = (): void => setIsShowing(false);

    const toggleToolTip = (): void => setIsShowing(!isShowing);

    const openModal = (): void => setIsOpen(true);

    const closeModal = (): void => setIsOpen(false);

    const cancelClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        e.preventDefault();

        if (tipTrigger === ToolTipTriggerTypes.CLICK) {
            toggleToolTip();
        }
    };

    useHotkeys('esc', () => {
        if (document.activeElement === document.getElementById(toolTipContainerId)) {
            hideToolTip();
        }
    });

    useHotkeys('enter', () => {
        if (document.activeElement === document.getElementById(toolTipContainerId)) {
            showToolTip();
        }
    });

    return (
        <>
            {isMobilePortraitView ? (
                <div className="tool-tip" data-testid="tool-tip-mobile">
                    <Button
                        isElement={true}
                        icon="fak fa-prizeout-info"
                        onClick={openModal}
                        testId="tool-tip-mobile-button"
                    />
                    <GenericModal
                        ariaLabel={content}
                        headline={modalHeadline}
                        subheadline={content}
                        hasExitButton={true}
                        isOpen={isOpen}
                        onClose={closeModal}
                        primaryButtonText="Got it"
                        primaryOnClick={closeModal}
                        hasIcon={true}
                        iconClasses="fak fa-prizeout-info"
                        testId="tool-tip-modal"
                    />
                </div>
            ) : (
                <div
                    className={classes}
                    data-testid="tool-tip"
                    onClickCapture={cancelClick}
                    onBlur={() => hideToolTip()}
                    onFocus={isHoverTrigger ? showToolTip : undefined}
                    tabIndex={isShowing ? -1 : 0}
                    id={toolTipContainerId}
                >
                    <span
                        aria-describedby={isShowing ? toolTipRef.current : undefined}
                        className="tool-tip__trigger"
                        data-testid="tool-tip-trigger"
                        onMouseEnter={isHoverTrigger ? showToolTip : undefined}
                        onMouseLeave={isHoverTrigger ? hideToolTip : undefined}
                    >
                        <i data-testid="tool-tip-icon-trigger" className="fak fa-prizeout-info"></i>
                    </span>
                    <div
                        id={toolTipRef.current}
                        className="tool-tip__content"
                        role="tooltip"
                        aria-hidden={!isShowing}
                        data-testid="tool-tip-content"
                    >
                        <p>{content}</p>
                    </div>
                </div>
            )}
        </>
    );
};
