import React from 'react';
import Classnames from 'classnames';
import { Button, ButtonColor, ButtonSize } from '../buttons';

import './generic-card.scss';

interface GenericCardProps {
    hasBorder?: boolean;
    icon?: string;
    title: string;
    description: string;
    buttonText?: string;
    buttonSize?: ButtonSize;
    buttonColor?: ButtonColor;
    buttonIsInline?: boolean;
    onClick?: () => void;
}

export const GenericCard: React.FC<GenericCardProps> = ({
    hasBorder = true,
    icon,
    title,
    description,
    buttonText,
    buttonSize = ButtonSize.SMALL,
    buttonColor = ButtonColor.PRIMARY,
    buttonIsInline = true,
    onClick,
}): React.ReactElement => {
    const classes = Classnames('generic-card', { 'generic-card--has-border': hasBorder });

    return (
        <div className={classes}>
            {icon && (
                <div className="generic-card__icon">
                    <i data-testid="generic-card-icon" className={icon}></i>
                </div>
            )}

            <h2 data-testid="generic-card-title" className="generic-card__title">
                {title}
            </h2>
            <p data-testid="generic-card-description" className="generic-card__description">
                {description}
            </p>

            {buttonText && (
                <Button
                    ariaLabel={buttonText}
                    text={buttonText}
                    onClick={onClick}
                    color={buttonColor}
                    isInline={buttonIsInline}
                    size={buttonSize}
                />
            )}
        </div>
    );
};
