import React, { useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import {
    createVerification,
    getVerificationStatus,
    selectPendingVerifications,
    setAccessTokenGlobalVerified,
    setActiveAccountVerificationEmail,
    VerificationRecord,
    VERIFICATION_ACTION_TYPES,
} from '../../../store/slices/verification-slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/widget/widget';
import { toast } from 'react-toastify';
import { loadLevelsData, loadPointsData } from '../../../store/slices/sidebar-slice';
import { selectIsPointsEnabled, selectPartnerCurrency } from '../../../store/slices/partner-config-slice';
import { loadConnectedPartners } from '../../../store/slices/user-slice';
import { useAsyncLoader } from '../../../hooks/use-async-loader';

const SHORT_RETRY_WAIT = 2500; // 2.5 seconds.  The network is fine, although maybe a little slow.
const LONG_RETRY_WAIT = 180000; // 3 minutes. The network is likely down and we're waiting for it to recover.

export const VerificationMonitor: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const pendingVerifications = useAppSelector(selectPendingVerifications);
    const currencyCode = useAppSelector(selectPartnerCurrency);
    const isPointsEnabled = useAppSelector(selectIsPointsEnabled);

    const checkStatus = async (id: string) => {
        try {
            const status: VerificationRecord = await dispatch(getVerificationStatus({ id })).unwrap();

            if (
                status.type === VERIFICATION_ACTION_TYPES.ACCOUNT_CREATION ||
                status.type === VERIFICATION_ACTION_TYPES.UNKNOWN_EMAIL_ACCOUNT_CREATION
            ) {
                dispatch(setActiveAccountVerificationEmail(status.metadata?.superUserEmail));
            } else {
                dispatch(setActiveAccountVerificationEmail(status.metadata?.referenceEmail));
            }

            if (!status.isComplete && !status.isExpired && !status.isInvalid) {
                setTimeout(async () => await checkStatus(id), SHORT_RETRY_WAIT);
                return;
            }

            if (status.isComplete) {
                await handleCompletedVerification(status.type);
            }
        } catch (err) {
            console.error('verificationMonitor checkStatus fail', err);
            setTimeout(async () => await checkStatus(id), LONG_RETRY_WAIT);
        }
    };

    const handleCompletedVerification = async (type: VERIFICATION_ACTION_TYPES) => {
        switch (type) {
            case VERIFICATION_ACTION_TYPES.ACCOUNT_CREATION:
                toast.success('Account verified');
                await reloadDataOnVerification();
                break;
            case VERIFICATION_ACTION_TYPES.LINK_ACCOUNTS:
                toast.success('Account link verified');
                await reloadDataOnVerification();
                break;
            case VERIFICATION_ACTION_TYPES.UNKNOWN_EMAIL_ACCOUNT_CREATION:
                toast.success('Account verified');
                if (isPointsEnabled) {
                    await dispatch(loadPointsData({ currencyCode }));
                    await dispatch(loadLevelsData({ currencyCode }));
                }

                // create link verification
                await dispatch(
                    createVerification({
                        actionType: VERIFICATION_ACTION_TYPES.LINK_ACCOUNTS,
                    }),
                );
                break;
            default:
                break;
        }
    };

    const pointsDataLoader = useAsyncLoader({
        loader: loadPointsData,
        onLoadFail: (error) => {
            console.error('loadPointsData', error);
        },
    });

    const levelsDataLoader = useAsyncLoader({
        loader: loadLevelsData,
        onLoadFail: (error) => {
            console.error('loadLevelsData', error);
        },
    });

    const connectedPartnersLoader = useAsyncLoader({
        loader: loadConnectedPartners,
        onLoadFail: (error) => {
            console.error('loadConnectedPartners', error);
        },
    });

    const reloadDataOnVerification = async () => {
        try {
            await dispatch(setAccessTokenGlobalVerified(true));

            // Serial, retry (using asyncLoader hook).
            //
            // These calls must be serialized to accomodate an issue on the BE.  Once that
            // issue is corrected, it would be better if they were not serialized.
            if (isPointsEnabled) {
                await pointsDataLoader({ currencyCode });
                await levelsDataLoader({ currencyCode });
            }
            await connectedPartnersLoader();
        } catch (error) {
            console.error('reloadDataOnVerification failed', error);
        }
    };

    useEffect(() => {
        pendingVerifications.forEach((verification) => {
            if (verification.id && !verification.isComplete) {
                (async () => {
                    await checkStatus(verification.id);
                })();
            }
        });
    }, [JSON.stringify(pendingVerifications)]);

    return <></>;
};
