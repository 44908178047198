import React from 'react';
import Classnames from 'classnames';
import { useAppSelector } from '../../../hooks';
import { selectIsMiniWidget } from '../../../store/slices/sdk-slice';

import './powered-by-po.scss';

export const PoweredByPO: React.FC = (): React.ReactElement => {
    const isMiniWidget = useAppSelector(selectIsMiniWidget);
    const classes = Classnames('powered-by-po', { 'powered-by-po--mini': isMiniWidget });

    return (
        <img
            className={classes}
            data-testid="powered-by-po"
            src={`https://assets.prizeout.com/branding/powered-by-po.svg`}
            alt="Powered by Prizeout"
        />
    );
};
