import { PrizeoutProductVersion } from '../partner-config-slice';

export enum PortalEventGroups {
    PortalLogin = 'admin-login',
    PortalDashboardRequestsList = 'admin-dashboard-requests-list',
    PortalDashboardRequestEmailList = 'admin-dashboard-request-email-list',
    PortalDashboardRequestsCallbacks = 'admin-dashboard-requests-callbacks',
    PortalDashboardRequestsCallbackDetails = 'admin-dashboard-requests-callback-details',
    resendGiftCardRequestEmail = 'admin-dashboard-resend-giftcard-email',
    PortalDashboardRequestEmailEventsList = 'admin-dashboard-request-email-events-list',
    PortalDashboardRequestSessionsList = 'admin-dashboard-request-sessions-list',
    PortalDashboardGiftCardClaimDetails = 'admin-dashboard-giftcard-claim-details',
    PortalDashboardGiftCardRequestDetails = 'admin-dashboard-giftcard-request-details',
    PortalDashboardUsersList = 'admin-dashboard-users-list',
    PortalDashboardPartnersList = 'admin-dashboard-partners-list',
    AddPortalDashboardUser = 'add-admin-dashboard-user',
    LinkPortalDashboardUser = 'link-admin-dashboard-user',
    SwitchDashboardUserLoginType = 'switch-dashboard-user-login-type',
    SendPasswordResetOTA = 'send-password-reset-ota',
    generatePasswordResetOTA = 'generate-password-reset-ota',
    AddPortalDashboardPartner = 'add-admin-dashboard-partner',
    CreateSandboxEnv = 'create-sandbox-env',
    UpdatePartnerCategory = 'update-partner-category',
    UpdatePartnerProductVersion = 'update-partner-product-version',
    processRequestBooking = 'process-request-booking',
    cancelRequestWithProvider = 'cancel-request-with-provider',
    changeRequestStatusToCancelled = 'change-request-status-to-cancelled',
    changeRequestStatusToExchanged = 'change-request-status-to-exchanged',
    stopRequestClaim = 'stop-request-claim',
}

export interface firebaseSignInRequest {
    idToken: string;
    displayName: string;
}
export interface firebaseSignInResponse {
    expirationDate: string;
}

export enum AdminPortalTab {
    REQUESTS = 'requests',
    PARTNERS = 'partners',
    DASHBOARD_USERS = 'dashboard_users',
    ADMIN_USERS = 'admin_users',
    MERCHANTS = 'merchants',
    INVENTORY = 'inventory',
    AGGREGATOR = 'aggregator',
    FUNDING = 'funding',
}

export enum AdminRoleTypes {
    ADMIN = 'admin',
    ENGINEERING_ADMIN = 'engineering_admin',
    ENGINEERING = 'engineering',
    OPERATIONS_ADMIN = 'operations_admin',
    OPERATIONS = 'operations',
    SUPPORT = 'support',
    FINANCE = 'finance',
}

export interface PaginationResponse {
    total: number;
    lastPage: number;
    prevPage?: number;
    nextPage: number;
    perPage: number;
    currentPage: number;
}

export interface AdminDashboardUsersQuery {
    partnerId?: string;
    email?: string;
    isPoAdmin?: boolean;
    currentPage: number;
    perPage: number;
}

export enum DashboardUserAccountLoginType {
    PASSWORD = 'password',
    SSO = 'sso',
}

export interface GenericSuccessResponse {
    success: boolean;
}

// Requests
export enum PartnerRequestStatus {
    BALANCE_PENDING = 'balance_pending',
    BOOKING_PENDING = 'booking_pending',
    CANCELLED = 'cancelled',
    EXCHANGED = 'exchanged',
    FAIL = 'fail',
    PENDING = 'pending',
    PROCESSING = 'processing',
    QUEUED = 'queued',
    REJECTED = 'rejected',
    REQUESTED = 'requested',
    SILENT_FAIL = 'silent_fail',
    STARTED = 'started',
    SUCCESS = 'success',
}

export interface AdminDashboardRequestsQuery {
    userId?: string;
    email?: string; // This is a strict email match. Not fuzzy
    merchantId?: string;
    merchantName?: string;
    providerName?: string;
    partnerId?: string;
    partnerName?: string;
    status?: PartnerRequestStatus;
    giftcardRequestId?: string;
    sessionId?: string;
    perPage: number;
    currentPage: number;
}
export interface AdminDashboardRequestUserMeta {
    prizeoutUserId: string;
    partnerId: string;
    partnerUserId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    olbIds: string[];
    individualIds: string[];
    accountIds: string[];
    alternativePartnerUserId: string;
}
export interface AdminDashboardRequest {
    giftcardRequestId: string;
    recipientName?: string;
    recipientEmail?: string;
    senderEmail?: string;
    fraudName?: string;
    fraudSummary?: string;
    cashoutDate?: string;
    partnerRequestStatus: PartnerRequestStatus;
    merchantRequestStatus: string;
    giftcardValue: number;
    giftcardCost: number;
    offerInCents: number;
    adcontextType: string;
    checkoutContext: string;
    giftcardImage: string;
    merchantGiftcardCost: number;
    merchantCurrencyCode: string;
    merchantName: string;
    merchantId: string;
    partnerId: string;
    providerName: string;
    partnerUserId: string;
    prizeoutUserId: string;
    partnerCurrencyCode: string;
    partnerGiftcardCost: number;
    email: string;
    bookingId?: string;
    giftingSendId?: string;
    claimDate?: string;
    sessionId: string;
    firstName: string;
    lastName: string;
    partnerRequestStatusRaw: string;
    merchantRequestStatusRaw: string;
    failStep: string;
    partnerName: string;
    userMeta: AdminDashboardRequestUserMeta;
}

// Requests / Emails
export interface getDashboardRequestEmailListQuery {
    relatedId: string;
}
export interface resendGiftCardRequestEmailQuery {
    queueId: string;
}
export interface resendGiftCardRequestEmailResponse {
    queueId: string;
}
export interface AdminDashboardRequestEmailMetadata {
    queue_id: string;
    related_id: string;
    status: string;
    message_id: string;
    email: string;
    template_name: string;
    attempt: number;
    processed: number;
    deferred: number;
    delivered: number;
    open: number;
    click: number;
    bounce: number;
    dropped: number;
    spamreport: number;
    unsubscribe: number;
    group_unsubscribe: number;
    group_resubscribe: number;
    process_start: string;
    process_end: string;
}

export interface GiftCardClaimDetail {
    code: string;
    pin: string;
    store_url?: string;
    barcode_url?: string;
    redirect_url?: string;
    qr_code_url?: string;
    barcode_value: string;
    qr_code_value: string;
    expiration_date?: string;
    redemption_instructions?: string;
    redemption_instructions_v2?: string;
    terms_and_conditions?: string;
    token: string;
    claim_url: string;
}

// Requests / Fraud
export interface EmailEventDetails {
    email?: string;
    event?: string;
    ip?: string;
    response?: string;
    sg_event_id?: string;
    sg_message_id?: string;
    sg_template_id?: string;
    sg_template_name?: string;
    'smtp-id'?: string;
    timestamp?: number;
    tls?: number;
}

export interface IpMeta {
    vpnApiId?: string;
    ip?: string;
    isVpn?: boolean;
    isProxy?: boolean;
    isTor?: boolean;
    isRelay?: boolean;
    city?: string;
    region?: string;
    country?: string;
    continent?: string;
    regionCode?: string;
    countryCode?: string;
    lat?: string;
    lng?: string;
    timeZone?: string;
    localeCode?: string;
    metroCode?: string;
    isEu?: boolean;
    network?: string;
    asn?: string;
    aso?: string;
    createDate?: string;
    updateDate?: string;
}

export interface EmailEventMetadata {
    sendgrid_event_id?: string;
    create_date?: string;
    message_id?: string;
    email?: string;
    event?: string;
    details?: EmailEventDetails;
    single_send_id?: string;
    ip_meta?: IpMeta;
}

export interface UserSession {
    userSessionId?: string;
    userId?: string;
    region?: string;
    country?: string;
    ip?: string;
    partnerId?: string;
    lat?: number;
    lng?: number;
    geoCountry?: string;
    geoRegion?: string;
    geoCity?: string;
    balanceInCents?: number;
    createDate?: string;
    deviceFingerprint?: string;
    recContext?: string;
    successful_giftcard_request_ids?: string[];
    all_giftcard_request_ids?: string[];
}

export interface AdminDashboardRequestCallbackMetadata {
    partner_id: string;
    webhook_name: string;
    status: string;
    request_endpoint: string;
    execution_started: string;
    execution_ended: string;
}

export enum RequestCallbackStatus {
    STARTED = 'started',
    FAIL = 'fail',
    SUCCESS = 'success',
    NOT_FOUND = 'not-found',
}
export interface RequestCallbackDetails {
    partner_id: string;
    webhook_name: string;
    status: RequestCallbackStatus;
    request_endpoint: string;
    request_parameters?: any; // not a well definable contract
    body?: any; // not a well definable contract
    execution_started: string;
    execution_ended: string;
}

// Users
export interface LinkAdminDashboardUserQuery {
    email: string;
    partnerId: string;
    perms: AdminRoleTypes[];
}

export interface AddAdminDashboardUserQuery {
    loginType: DashboardUserAccountLoginType;
    isPoAdmin: boolean;
    email: string;
    name: string;
    partnerId?: string; // note you can create a dashboard user without specifing a partner id
    perms: AdminRoleTypes[];
}

export interface AdminDashboardUser {
    dashboardUserId: string;
    email: string;
    name: string;
    createDate: string;
    updateDate: string;
    title: string;
    accountLockedUntil?: Date;
    isPoAdmin: boolean;
    loginType: DashboardUserAccountLoginType;
}

// Partners
export enum PartnerStatus {
    LIVE = 'live',
    DEPRECATED = 'deprecated',
}

export enum PartnerCategory {
    CU = 'cu',
    GROE = 'groe',
    EWA = 'ewa',
    BANKING = 'banking',
    GAMING = 'gaming',
}

export interface AddAdminDashboardPartnerQuery {
    partnerId: string;
    partnerName: string;
    partnerCurrency: string;
    prizeoutProductVersion: PrizeoutProductVersion;
    category: PartnerCategory;
}

export interface AdminDashboardPartner {
    partnerId: string;
    partnerName: string;
    partnerNameRaw: string;
    partnerProductName?: string;
    prizeoutProductVersion: PrizeoutProductVersion;
    partnerCurrency: string;
    partnerCurrencySymbol: string;
    isAccountsEnabled?: boolean;
    ignorePrizeoutAccountEmail?: boolean;
    prodAllowed: boolean;
    showStorefrontUserDetails?: boolean;
    partnerOTPOverrides: {
        partnerOtpLoobackThresholdMinutes: number;
        partnerOtpExpirationThresholdMinutes: number;
        otpTypes: string[];
    };
    cashbackOverrides: {
        isBackToStoreEnabled: boolean;
        isTransferToBankEnabled: boolean;
        minBankTransferLimitCents: number;
        minLifetimeEarningThreshold: number;
    };
    emailHeader?: string;
    supportEmail?: string;
    partnerEmailDomain?: string;
    allowCustomUserEmails: boolean;
    withdrawalType: string;
    allowSandboxViaMasterAccount: boolean;
    isMaster: boolean;
    masterId: string;
    sandboxEnvName: string;
    dashboardAccountLoginType: DashboardUserAccountLoginType;
    category?: string;
    status?: PartnerStatus;
}

export interface AdminDashboardPartnersQuery {
    partnerId?: string;
    partnerName?: string;
    prizeoutProductVersion?: PrizeoutProductVersion;
    category?: PartnerCategory;
    prodAllowed?: boolean;
    status?: PartnerStatus;
    isMaster?: boolean; // FE should specify True for prod and false for sandbox
    perPage: number;
    currentPage: number;
}

export interface SwitchDashboardUserLoginTypeQuery {
    loginType: DashboardUserAccountLoginType;
    dashboardUserId: string;
}

export interface SendPasswordResetOTAQuery {
    email: string;
}

export interface generatePasswordResetOTAQuery {
    dashboardUserId: string;
}

export interface generatePasswordResetOTAResponse {
    url: string;
}

export interface dashboardUserPartnerAccountListQuery {
    dashboardUserId?: string;
    perPage: number;
    currentPage: number;
}

export interface dashboardUserPartnerAccount {
    accountListId: string;
    dashboardUserId: string;
    email: string;
    name: string;
    partnerId: string;
    accountType: string;
    accountName: string;
    createDate: string;
    updateDate: string;
    isMaster: boolean;
    masterId?: string;
    sandboxEnvName?: string;
    perms: string[];
}

export interface CreateSandboxEnvQuery {
    sandboxEnvName: string;
    newPartnerId: string;
    partnerId: string;
}

export interface UpdatePartnerCategoryQuery {
    partnerId: string;
    category: PartnerCategory;
}

export interface UpdatePartnerProductVersionQuery {
    partnerId: string;
    prizeoutProductVersion: PrizeoutProductVersion;
}

export interface updatePartnerProdAllowedQuery {
    partnerId: string;
    prodAllowed: boolean;
}
