// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/react-toastify/dist/ReactToastify.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast{background:var(--po-black);border-radius:var(--border-radius-primary);min-height:50px;padding:0 8px;font-family:var(--font-family-primary)}.Toastify__toast-container{padding:var(--space-primary)}`, "",{"version":3,"sources":["webpack://./src/utils/styles/vendor-overrides/toastify.scss"],"names":[],"mappings":"AAEA,iBACI,0BAAA,CACA,0CAAA,CACA,eAAA,CACA,aAAA,CACA,sCAAA,CAEA,2BACI,4BAAA","sourcesContent":["@import '~react-toastify/dist/ReactToastify.css';\n\n.Toastify__toast {\n    background: var(--po-black);\n    border-radius: var(--border-radius-primary);\n    min-height: 50px;\n    padding: 0 8px;\n    font-family: var(--font-family-primary);\n\n    &-container {\n        padding: var(--space-primary);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
