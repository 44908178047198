import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sharedAPI } from '../../../utils/services/api';
import { uuid_v4 } from '../../../utils';
import { PartnerSettings, PartnerUser } from '../partner-config-slice';
import { LinkTokenType } from '../verification-slice';
import { CBCERootState } from '../../cashback';

const DEFAULT_EXPIRATION_CHECK_INTERVAL = 10 * 60 * 1000; // Ten minutes.
export interface CBCEAuthState {
    userSession?: UserSessionData;
    extensionUserSession?: ExtensionUserSessionData;
    linkingData?: LinkingData;
}

export const cbceAuthInitialState: CBCEAuthState = {};

interface LinkRequestParams {
    linkToken: string;
}

interface UserSessionData {
    userToken: string;
    userTokenExpirationMillis: number; // number of millis until the user token is no longer valid,
    extensionAccessToken: string;
    extensionAccessTokenExpirationMillis: number;
    extensionAccessTokenExpirationDate: string;
}

export interface ExtensionUserSessionData {
    accessToken?: string;
    dateOfBirth: string;
    email: string;
    firstName: string;
    lastName: string;
    settings: PartnerSettings;
    superUserId: string;
    user?: PartnerUser;
}

export interface AuthenticationTokens {
    token?: string;
    accessToken?: string;
    userToken?: string;
}

export type LinkingData = {
    isAwaitingIosRelink?: boolean;
    turboLinkPreparingDate?: string;
    turboLinkDismissedDate?: string;
    turboLinkData?: TurboLinkData;
};

export type TurboLinkData = {
    linkToken: string;
    expirationDate: string;
    tokenType: LinkTokenType;
};

export type UserAndAccessTokenData = UserSessionData & {
    // isManuallyLoggedOut signifies user took action to manually log out (versus having never logged in ever)
    isManuallyLoggedOut?: boolean;
};

export type CreateSessionRequestResponse = ExtensionUserSessionData;

export const linkAuth = createAsyncThunk(
    'browserExtension/link',
    async ({ linkToken }: LinkRequestParams, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    linkToken,
                },
                endpoint: '/browserExtension/link',
                method: 'POST',
                signal: signal,
            });

            if (results?.status !== 200) {
                throw new Error('Failed to get token');
            }

            return results.data;
        } catch (e) {
            return rejectWithValue(e.response?.data || e.message);
        }
    },
);

export const createSession = createAsyncThunk(
    'browserExtension/sessionExchange',
    async (_, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    auction_uuid: uuid_v4(),
                },
                endpoint: '/browserExtension/sessionExchange',
                method: 'POST',
                signal: signal,
            });

            if (results?.status !== 200) {
                throw new Error('Failed to create session');
            }

            return results.data;
        } catch (e) {
            return rejectWithValue(e.response?.data || e.message);
        }
    },
);

export const logOffBrowserExtension = createAsyncThunk(
    'browserExtension/logOut',
    async (_, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: '/browserExtension/logOut',
                method: 'POST',
                signal: signal,
            });

            if (results?.status !== 200) {
                throw new Error('Failed to log off');
            }

            return results.data;
        } catch (e) {
            return rejectWithValue(e.response?.data || e.message);
        }
    },
);

export const cbcpAuthSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(linkAuth.fulfilled, (state, action: PayloadAction<UserAndAccessTokenData>) => {
            const userAndAccessTokenData: UserAndAccessTokenData = action.payload;
            state.userSession = userAndAccessTokenData;

            sharedAPI.browserExtensionTokens.userToken = action.payload.userToken;
            sharedAPI.browserExtensionTokens.accessToken = action.payload.extensionAccessToken;
        });

        builder.addCase(createSession.fulfilled, (state, action: PayloadAction<CreateSessionRequestResponse>) => {
            state.extensionUserSession = action.payload;

            sharedAPI.token = action.payload.accessToken;
        });
    },
    initialState: cbceAuthInitialState,
    name: 'cbceAuthentication',
    reducers: {
        resetUserSlice() {
            return cbceAuthInitialState;
        },
        setAccessTokenFromIosApp: (state, action: PayloadAction<UserAndAccessTokenData>) => {
            const userAndAccessTokenData: UserAndAccessTokenData = action.payload;
            state.userSession = userAndAccessTokenData;

            sharedAPI.browserExtensionTokens.userToken = action.payload.userToken;
            sharedAPI.browserExtensionTokens.accessToken = action.payload.extensionAccessToken;
        },
        setIsAwaitingIosRelink: (state, action: PayloadAction<boolean>) => {
            initiateLinkingData(state);
            state.linkingData.isAwaitingIosRelink = action.payload;
        },
        setTurboLinkPreparingDate: (state, action: PayloadAction<string>) => {
            initiateLinkingData(state);
            state.linkingData.turboLinkPreparingDate = action.payload;
        },
        setTurboLinkDismissedDate: (state, action: PayloadAction<string>) => {
            initiateLinkingData(state);
            state.linkingData.turboLinkDismissedDate = action.payload;
        },
        setTurboLinkData: (state, action: PayloadAction<TurboLinkData>) => {
            initiateLinkingData(state);
            state.linkingData.turboLinkData = action.payload;
        },
    },
});

const initiateLinkingData = (state: CBCEAuthState) => {
    if (!state?.linkingData) {
        state.linkingData = {};
    }
};

export const {
    resetUserSlice,
    setAccessTokenFromIosApp,
    setIsAwaitingIosRelink,
    setTurboLinkPreparingDate,
    setTurboLinkDismissedDate,
    setTurboLinkData,
} = cbcpAuthSlice.actions;

const selectCBCEAuthentication = ({ cbceAuthentication }: CBCERootState): CBCEAuthState => cbceAuthentication;

export const selectUserSession = createSelector(selectCBCEAuthentication, ({ userSession }) => userSession);

export const selectExtensionUserSession = createSelector(
    selectCBCEAuthentication,
    ({ extensionUserSession }) => extensionUserSession,
);

export const selectIsAwaitingIosRelink = createSelector(
    selectCBCEAuthentication,
    ({ linkingData }) => linkingData?.isAwaitingIosRelink,
);

export const selectTurboLinkPreparingDate = createSelector(
    selectCBCEAuthentication,
    ({ linkingData }) => linkingData?.turboLinkPreparingDate,
);

export const selectTurboLinkDismissedDate = createSelector(
    selectCBCEAuthentication,
    ({ linkingData }) => linkingData?.turboLinkDismissedDate,
);

export const selectTurboLinkData = createSelector(
    selectCBCEAuthentication,
    ({ linkingData }) => linkingData?.turboLinkData,
);

export const selectPartnerName = createSelector(
    selectCBCEAuthentication,
    ({ extensionUserSession }) => extensionUserSession?.settings?.display_name,
);

export const selectPartnerLogomark = createSelector(
    selectCBCEAuthentication,
    ({ extensionUserSession }) => extensionUserSession?.settings?.cobrand_logo,
);

export const selectExtensionPartnerId = createSelector(
    selectCBCEAuthentication,
    ({ extensionUserSession }) => extensionUserSession?.settings?.partner_id,
);

export const selectExtensionPrizeoutUserSessionId = createSelector(
    selectCBCEAuthentication,
    ({ extensionUserSession }) => extensionUserSession?.user?.prizeout_session_id,
);

export const selectUserName = createSelector(
    selectCBCEAuthentication,
    ({ extensionUserSession }) => `${extensionUserSession?.firstName || ''} ${extensionUserSession?.lastName || ''}`,
);

export const selectTotalGiftCards = createSelector(
    selectCBCEAuthentication,
    ({ extensionUserSession }) => extensionUserSession?.user?.giftcard_wallet_summary.total_cards,
);

export const selectTokens = createSelector(
    selectCBCEAuthentication,
    (authState): AuthenticationTokens => ({
        token: authState.extensionUserSession?.accessToken,
        accessToken: authState.userSession?.extensionAccessToken,
        userToken: authState.userSession?.userToken,
    }),
);

export const selectUserTokenExpirationMillis = createSelector(selectUserSession, (userSession) => {
    return userSession?.userTokenExpirationMillis || DEFAULT_EXPIRATION_CHECK_INTERVAL;
});

export const selectExtensionAccessExpirationMillis = createSelector(selectUserSession, (userSession) => {
    return userSession?.extensionAccessTokenExpirationMillis || DEFAULT_EXPIRATION_CHECK_INTERVAL;
});

export default cbcpAuthSlice.reducer;
