import React from 'react';
import { useAppSelector } from '../../../../hooks';
import { selectActiveOfferFilters } from '../../../../store/slices/offers-slice';
import { Pill } from '../common/pill';

import './filter-pill.scss';

interface FilterPillProps {
    clickHandler: () => void;
    name: string;
    testId?: string;
    unicode: string;
}

export const FilterPill: React.FC<FilterPillProps> = ({ clickHandler, name, testId, unicode }): React.ReactElement => {
    const activeFilterCategories = useAppSelector(selectActiveOfferFilters);
    const isActive = activeFilterCategories.filter((cat) => cat.category === name).length;
    const defaultTestId = `${name}-testId`;

    return (
        <Pill
            isActive={isActive}
            name={name}
            onClick={clickHandler}
            testId={testId || defaultTestId}
            unicode={unicode}
        />
    );
};
