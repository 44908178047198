import { Dispatch, useReducer } from 'react';
import {
    PagedListState,
    PagedListStateAction,
    pagedListStateReducer,
    INITIAL_PAGED_LIST_STATE,
} from './paged-list-reducer';

import { PageManagerThunk, PageManagerRtk } from './page-manager';

export type PagedListStateManager<RowType> = [PagedListState<RowType>, Dispatch<PagedListStateAction<RowType>>];

export function usePagedListState<RowType>(useRtk = false): PagedListStateManager<RowType> {
    const pageManager = useRtk ? new PageManagerRtk<RowType>() : new PageManagerThunk<RowType>();

    const [localState, dispatchLocalState] = useReducer(pagedListStateReducer, {
        ...(INITIAL_PAGED_LIST_STATE as PagedListState<RowType>),
        pageManager,
    });

    return [localState as PagedListState<RowType>, dispatchLocalState];
}
