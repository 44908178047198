import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../widget/widget';
import { constants } from '../../utils/constants';
import { sharedAPI } from '../../utils/services/api';

export enum displayOfferType {
    BONUS = 'bonus',
    CASHBACK = 'cashback',
}

export enum RecContext {
    HOME_PAGE = 'homepage',
    MINI = 'mini',
    REWARDS = 'rewards',
    SEARCH = 'search',
}

export enum PrizeoutOfferViewsType {
    BIG_BRAND_OFFERS = 'big-brand-offers',
    CASHBACK_OFFERS = 'horizontal-category-preview-offers',
    HORIZONTAL_OFFERS = 'horizontal-offers',
    MINI_WIDGET_OFFERS = 'mini-widget',
    VERTICAL_LIST_OFFERS = 'vertical-list-offers',
    VERTICAL_OFFERS = 'vertical-offers',
}

export enum PrizeoutOfferSettingsDisplayType {
    BIG_BRAND = 'big-brand',
    BRAND = 'brand',
    CASHBACK = 'cashback',
    GIFT_CARD = 'giftcard',
    HERO = 'hero',
    LOGOMARK = 'logomark',
    VERTICAL_LIST_OFFER = 'vertical-list-offer',
}

export enum PrizeoutOfferDenomType {
    CUSTOM_FIXED = 'custom_fixed',
    CUSTOM_INCREMENT = 'custom_increment',
    SINGLE_DENOM = 'single_denom',
}

export enum PrizeoutOfferCustomDenomType {
    VALUE = 'value', // 1 input, user specifies value
    COST = 'cost', // 1 input, user specifies cost
    DUAL = 'dual', // 2 inputs, user can specify value or cost
    DUAL_VALUE_ONLY = 'dual_value_only', // 2 inputs, user can only specify value
    DUAL_COST_ONLY = 'dual_cost_only', // 2 inputs, user can only specify cost
}

// Define a type for the slice state
export interface OffersState {
    activeOfferFilters?: PrizeoutCategory[];
    offers?: PrizeoutOffers;
    offersCurrentLocation?: string;
    offerCategories?: PrizeoutCategory[];
    offerSubCategories?: PrizeoutSubCategory[];
    isSearchResultsFullScreen?: boolean;
    categoryOfferMap: Record<string, OffersByCountryCode>;
    postCheckout?: PostCheckoutOffers;
    offersMetadata?: PrizeoutOfferMini[];
    categoryOfferSort: OfferSortType;
}

export enum OfferSortType {
    RECOMMENDED = 'recommended',
    BEST_DEALS = 'best-deals',
    A_Z = 'a_z',
    Z_A = 'z_a',
}

type OffersByCountryCode = Record<string, PrizeoutOffers>;

// Define the initial state
export const offersInitialState: OffersState = {
    activeOfferFilters: [],
    offers: [],
    offersCurrentLocation: '',
    offerCategories: [],
    offerSubCategories: [],
    isSearchResultsFullScreen: false,
    categoryOfferMap: {},
    categoryOfferSort: OfferSortType.RECOMMENDED,
};

export type PrizeoutOffers = PrizeoutOfferViews[];

// Store Locations
export type PrizeoutStoreLocationCoordinates = {
    lat: number;
    lng: number;
};
export type PrizeoutStoreLocationGeometry = {
    location: PrizeoutStoreLocationCoordinates;
};
export type PrizeoutStoreLocationPlace = {
    formatted_address: string;
    geometry: PrizeoutStoreLocationGeometry;
};
export type PrizeoutStoreLocation = {
    place_api_json: PrizeoutStoreLocationPlace;
};

export type PrizeoutOfferViews = {
    data: PrizeoutOffer[];
    settings?: PrizeoutOfferSettings;
    type: PrizeoutOfferViewsType;
};

type PrizeoutCategoryOfferViews = {
    categoryId: number;
    prizeoutOffers: PrizeoutOffers;
    countryCode: string;
};

export type PrizeoutOfferSettings = {
    display_type?: PrizeoutOfferSettingsDisplayType;
    subtitle?: string;
    tag?: string;
    title?: string;
    expand_all?: boolean;
    category_id?: string;
    should_load_offer_details?: boolean;
};

export type PrizeoutShopifyProduct = {
    image_url: string;
    product_title: string;
    product_cost: string;
    currency_code: string;
};

export interface PrizeoutOfferBase {
    name: string;
    offer_id?: string;
    merchant_id: string;
}

export type PrizeoutOfferFlavors = PrizeoutOffer | PrizeoutOfferMini;

export interface PrizeoutOffer extends PrizeoutOfferBase {
    category_tags?: PrizeoutCategory[];
    checkout_hero_url?: string;
    currency_code: string;
    description: string;
    display_offer_type?: displayOfferType;
    display_monetary_bonus?: number;
    display_prev_bonus?: number;
    display_bonus?: number;
    expiration_date?: string;
    giftcard_list: PrizeoutOfferValueOptions[];
    grouping_filter_list?: string[];
    image_url: string;
    in_store_only?: boolean;
    is_enabled: boolean;
    logomark_url?: string;
    name: string;
    redemption_methods?: RedemptionMethod[];
    shopifyProducts?: PrizeoutShopifyProduct[];
    stores?: PrizeoutStoreLocation[];
    subcategory_tags?: PrizeoutSubCategory[];
    support_creative_list?: string[];
    tag: string;
    giftCardTerms?: string;
}

export interface RedemptionMethod {
    title: RedemptionMethodTitle;
    icon: string;
}

export enum RedemptionMethodTitle {
    IN_APP = 'in-app',
    IN_STORE = 'in-store',
    ONLINE = 'online',
}

export interface PrizeoutOfferMini extends PrizeoutOfferBase {
    display_bonus: number;
    display_monetary_bonus: boolean;
    display_offer_type: displayOfferType;
    image_url: string;
    sort_order: number;
    product_categories: number[];
}

export type PrizeoutCategory = {
    category: string;
    unicode: string;
};

export type PrizeoutSubCategory = {
    name: string;
    parent_category?: string;
    unicode: string;
};

export type PrizeoutBrands = {
    name: string;
    supportingImage: string;
};

export type PrizeoutOfferValueOptions = {
    checkout_value_id: string;
    cost_in_cents: number;
    country_code?: string;
    currency_conversion_code?: string;
    currency_converted_cost_in_cents?: number;
    custom_denom_type?: PrizeoutOfferCustomDenomType;
    denom_type: PrizeoutOfferDenomType;
    display_bonus?: number;
    display_prev_bonus?: number;
    display_monetary_bonus?: number;
    expiration_date?: string;
    expires: boolean;
    increment_cents?: number;
    max_cost_in_cents?: number;
    max_value_in_cents?: number;
    min_cost_in_cents?: number;
    min_value_in_cents?: number;
    offer_in_cents?: number;
    value_in_cents: number;
};

export type PostCheckoutOffers = {
    countryCode: string;
    categoryId: number;
    offers: PrizeoutOfferViews;
};

export interface OffersRequest {
    location?: string;
    prizeoutSessionId: string;
    recContext?: RecContext;
    sessionId: string;
}

export interface OfferDetailRequest {
    offerId: string;
}

interface CategoryOffersRequest {
    categoryId: number;
    prizeoutSessionId: string;
    location: string;
}

export const getOffers = createAsyncThunk(
    'offers/getOffers',
    async ({ location, prizeoutSessionId, recContext, sessionId }: OffersRequest, { rejectWithValue, signal }) => {
        try {
            let results;
            const cacheKey = `${sessionId}_${recContext}_offers`;

            if (!!sessionStorage.getItem(cacheKey)) {
                results = JSON.parse(sessionStorage.getItem(cacheKey));
            } else {
                results = await sharedAPI.request({
                    data: {
                        countryCode: location,
                        recContext,
                        prizeoutSessionId,
                    },
                    endpoint: '/v2/auction/offers',
                    signal: signal,
                    method: 'GET',
                });
            }

            if (results?.data.error) {
                return rejectWithValue('Failed to load offers');
            }

            sessionStorage.setItem(cacheKey, JSON.stringify(results));

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getOffersMetadata = createAsyncThunk(
    'offers/getOffersMetadata',
    async ({ location, prizeoutSessionId }: OffersRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    country_code: location,
                    prizeout_session_id: prizeoutSessionId,
                },
                endpoint: '/v3/auction/offers',
                signal: signal,
                method: 'GET',
            });

            if (results?.data.error) {
                return rejectWithValue('Failed to load offers');
            }

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getOffersDetailsData = createAsyncThunk(
    'offers/getOfferDetailsData',
    async ({ offerId }: OfferDetailRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    offerId,
                },
                method: 'POST',
                endpoint: `/auction/offerDetails`,
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue('Failed to load offers');
            }

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getCategoryOffers = createAsyncThunk<PrizeoutCategoryOfferViews, CategoryOffersRequest>(
    'categories/getCategoryOffers',
    async ({ categoryId, prizeoutSessionId, location }: CategoryOffersRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    countryCode: location,
                    categoryId: categoryId,
                    prizeout_session_id: prizeoutSessionId,
                },
                method: 'POST',
                endpoint: '/categoryCarousel/offers',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.error);
            }

            const response: PrizeoutCategoryOfferViews = {
                categoryId,
                prizeoutOffers: results.data,
                countryCode: location,
            };

            return response;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const offersSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getOffersMetadata.fulfilled, (state, action: PayloadAction<PrizeoutOffers>) => {
            const offers: PrizeoutOfferMini[] = [];

            for (const i in action.payload[0]?.data) {
                offers.push(action.payload[0]?.data[i] as unknown as PrizeoutOfferMini);
            }

            state.offersMetadata = offers;
        });

        builder.addCase(getOffers.fulfilled, (state, action: PayloadAction<PrizeoutOffers>) => {
            const arr = [];
            for (const i in action.payload) {
                arr.push(action.payload[i]);
            }

            state.offers = arr;

            const catsArr: PrizeoutCategory[] = [];
            const subCatsArr: PrizeoutSubCategory[] = [];

            state.offersCurrentLocation =
                constants.currencies.CountryCodeKeyedByCurrency[arr[0]?.data[0].currency_code];

            state.offers.forEach((prizeoutOfferObj) => {
                prizeoutOfferObj.data.forEach((offer) => {
                    offer.category_tags.forEach((tag) => {
                        if (!catsArr.filter((cat) => cat.category === tag.category).length) {
                            catsArr.push(tag);
                        }
                    });
                    // can be re-enabled if we want to use sub-cat filtering
                    // offer.subcategory_tags.forEach((tag) => {
                    //     if (!subCatsArr.filter(cat => cat.name === tag.name).length) {
                    //         subCatsArr.push(tag);
                    //     }
                    // });
                });
            });

            state.offerCategories = catsArr;
            state.offerSubCategories = subCatsArr;
        });

        builder.addCase(getCategoryOffers.fulfilled, (state, action: PayloadAction<PrizeoutCategoryOfferViews>) => {
            const arr = [];

            for (const i in action.payload.prizeoutOffers) {
                arr.push(action.payload.prizeoutOffers[i]);
            }

            if (arr.length > 0) {
                if (!state.categoryOfferMap[action.payload.categoryId]) {
                    state.categoryOfferMap[action.payload.categoryId] = {};
                }
                state.categoryOfferMap[action.payload.categoryId][action.payload.countryCode] = arr;
            }
        });
        builder.addCase(getCategoryOffers.rejected, (state, action: any) => {
            if (action) {
                console.error(action.error ? action.error : action);
            } else {
                console.error('getCategoryOffers error', state);
            }
        });
    },
    initialState: offersInitialState,
    name: 'offers',
    reducers: {
        clearActiveOfferFilters(state) {
            state.activeOfferFilters = [];
        },
        setOfferLocation(state, action: PayloadAction<string>) {
            state.offersCurrentLocation = action.payload;
        },
        toggleActiveOfferFilter(state, action: PayloadAction<PrizeoutCategory>) {
            const categoryFilters = state.activeOfferFilters;
            if (!categoryFilters.filter((cat) => cat.category === action.payload.category).length) {
                state.activeOfferFilters = [...state.activeOfferFilters, action.payload];
            } else {
                const activeOfferFilters = [...state.activeOfferFilters];
                const idx = activeOfferFilters.findIndex((cat) => cat.category === action.payload.category);
                activeOfferFilters.splice(idx, 1);
                state.activeOfferFilters = [...activeOfferFilters];
            }
        },
        toggleIsSearchResultsFullScreen(state, action: PayloadAction<boolean>) {
            state.isSearchResultsFullScreen = action.payload;
        },
        setCategoryOfferSort(state, action: PayloadAction<OfferSortType>) {
            state.categoryOfferSort = action.payload;
        },
        setPostCheckoutOffers(state, action: PayloadAction<PostCheckoutOffers>) {
            state.postCheckout = action.payload;
        },
    },
});

export const {
    clearActiveOfferFilters,
    setOfferLocation,
    toggleActiveOfferFilter,
    toggleIsSearchResultsFullScreen,
    setCategoryOfferSort,
    setPostCheckoutOffers,
} = offersSlice.actions;

const selectOffersState = ({ offers }: RootState): OffersState => offers;

export const selectActiveOfferFilters = createSelector(
    selectOffersState,
    ({ activeOfferFilters }) => activeOfferFilters,
);

export const selectFirstOffer = createSelector(selectOffersState, ({ offers }) =>
    offers.length ? offers[0].data[0] : null,
);

export const selectFirstOfferFirstCard = createSelector(selectOffersState, ({ offers }) =>
    offers.length ? offers[0].data[0].giftcard_list[0] : null,
);

export const selectDefaultOffer = createSelector(selectFirstOffer, (firstOffer) => firstOffer);

export const selectDefaultCard = createSelector(selectFirstOfferFirstCard, (firstCard) => firstCard);

export const selectOffers = createSelector(selectOffersState, ({ offers }) => offers);

export const selectOffersMetadata = createSelector(selectOffersState, ({ offersMetadata }) => offersMetadata);

export const selectCategoryOfferMap = createSelector(selectOffersState, ({ categoryOfferMap }) => categoryOfferMap);

export const selectOfferCategories = createSelector(selectOffersState, ({ offerCategories }) => offerCategories);

export const selectOfferSubCategories = createSelector(
    selectOffersState,
    ({ offerSubCategories }) => offerSubCategories,
);

export const selectOffersCurrentLocation = createSelector(
    selectOffersState,
    ({ offersCurrentLocation }) => offersCurrentLocation,
);

export const selectFilteredOffers = createSelector(
    selectOffers,
    selectActiveOfferFilters,
    (offers, activeCategoryFilters) => {
        const filteredOfferMap: Map<string, PrizeoutOffer> = new Map();
        const activeCategoryFilterNames = activeCategoryFilters.map((cat) => cat.category);

        offers.forEach((offerSet) => {
            offerSet.data.forEach((offer) => {
                offer.category_tags.forEach((category) => {
                    // check if category is in active offer filters
                    if (activeCategoryFilterNames.indexOf(category.category) != -1) {
                        filteredOfferMap.set(offer.name, offer);
                    }
                });
            });
        });

        return Array.from(filteredOfferMap.values());
    },
);

export const selectSearchResultsDisplay = createSelector(
    selectOffersState,
    ({ isSearchResultsFullScreen }) => isSearchResultsFullScreen,
);

export const selectPostCheckoutOffers = createSelector(selectOffersState, ({ postCheckout }) => postCheckout);

export const selectCategoryOfferSort = createSelector(selectOffersState, ({ categoryOfferSort }) => categoryOfferSort);

export default offersSlice.reducer;
