import React from 'react';
import { BonusTag } from '../../../../../../components/common';
import { CurrentOfferDisplay } from '../offer-gift-card';
import { GiftCard } from '../../../../../../components/common/cards';
import { useAppSelector } from '../../../../../../hooks';
import { selectIsMiniWidget } from '../../../../../../store/slices/sdk-slice';

import './giftcard-offer-display.scss';

interface GiftCardOfferDisplayProps {
    data: CurrentOfferDisplay;
}

export const GiftCardOfferDisplay: React.FC<GiftCardOfferDisplayProps> = ({ data }): React.ReactElement => {
    const isMiniWidget = useAppSelector(selectIsMiniWidget);

    return (
        <>
            <GiftCard name={data.name} rows={1} imgUrl={data.image_url} altText={`${data.name} gift card`} isNameBold />

            {data.offer_value > 0 && (
                <BonusTag
                    type={data.offer_type}
                    value={data.offer_value}
                    currency={data.currency}
                    offerType={data.display_offer_type}
                    hideText={isMiniWidget && true}
                />
            )}
        </>
    );
};
