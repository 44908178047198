import React from 'react';
import Classnames from 'classnames';
import { selectSweepstake } from '../../../store/slices/sweepstakes-slice';
import { useAppSelector } from '../../../hooks';
import { ExternalLink, ExternalLinkButtonSizes, ExternalLinkDisplayTypes } from '../../common';
import { countWithLabel, formatDateTime } from '../../../utils';
import './entry-card.scss';

interface SweepstakesEntryCardProps {
    className?: string;
    testId?: string;
    sweepstakesId: string;
}

export const SweepstakesEntryCard: React.FC<SweepstakesEntryCardProps> = ({
    className,
    testId = 'entry-card',
    sweepstakesId,
}): React.ReactElement => {
    const sweepstake = useAppSelector(selectSweepstake(sweepstakesId));

    const currentDate = new Date();
    const sweepstakeEndDate = new Date(sweepstake?.endDateUTC);

    const hasSweepstakesEnded = currentDate > sweepstakeEndDate;

    const classes = Classnames('entry-card', className);

    const determineTextDetails = () => {
        if (hasSweepstakesEnded) {
            return (
                <p className="entry-card__text">
                    Entry period is now closed and we are currently validating entries. Winners will be selected at
                    random and prizes will be delivered via email.
                </p>
            );
        } else {
            return (
                <p className="entry-card__text">
                    {`1 entry per gift card purchased (max ${sweepstake.eligibility.maxEntriesPerDay} per day).`}
                    <br />
                    {`Ends ${formatDateTime(sweepstake.endDateUTC)}`}
                </p>
            );
        }
    };

    return (
        <div className={classes} data-testid={testId}>
            <h1>Prizeout Sweepstakes</h1>
            <div className="entry-card__total-entries">
                <i className="fak fa-prizeout-sweepstakes-dot"></i>{' '}
                <p>{`${countWithLabel(sweepstake.entries.totalActiveUserEntries, {
                    singular: 'entry',
                    plural: 'entries',
                })}`}</p>
            </div>
            {sweepstake.entries.totalPendingUserEntries > 0 && (
                <p className="entry-card__text">{`(${sweepstake.entries.totalPendingUserEntries} pending)`}</p>
            )}
            {determineTextDetails()}
            <ExternalLink
                buttonSize={ExternalLinkButtonSizes.MINI}
                displayType={ExternalLinkDisplayTypes.SECONDARY_BUTTON}
                isButtonInline={true}
                ariaLabel="Learn more"
                label="Learn more"
                testId="entry-learn-more-button"
                url={sweepstake.landingPageUrl}
            />
        </div>
    );
};
