import React from 'react';
import DOMPurify from 'dompurify';
import { Button, ButtonColor, ButtonSize } from '..';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';

import './status-notification.scss';

interface StatusNotificationProps {
    ariaDescribedById?: string; // Required if displayed in a modal
    ariaLabelledById?: string; // Required if displayed in a modal
    headline: string;
    imageAltText?: string;
    imageUrl?: string;
    isButtonDisabled?: boolean;
    message?: string;
    primaryButtonText?: string;
    primaryOnClick?: () => void;
    secondaryButtonText?: string;
    secondaryOnClick?: () => void;
    testId?: string;
    trackingTypePrimary?: ButtonTrackingTypes;
    trackingTypeSecondary?: ButtonTrackingTypes;
}

export const StatusNotification: React.FC<StatusNotificationProps> = ({
    ariaDescribedById,
    ariaLabelledById,
    children,
    headline,
    imageAltText,
    imageUrl,
    isButtonDisabled,
    message,
    primaryButtonText,
    primaryOnClick,
    secondaryButtonText,
    secondaryOnClick,
    testId,
    trackingTypePrimary,
    trackingTypeSecondary,
}): React.ReactElement => {
    const defaultTestId = 'status-notification';
    const defaultPrimaryAriaLabel = 'Primary action';
    const defaultSecondaryAriaLabel = 'Secondary action';
    const sanitizedMessage = DOMPurify.sanitize(message);

    return (
        <div className="status-notification">
            <>
                <div data-testid={testId || defaultTestId} className="status-notification__content">
                    {imageUrl && (
                        <div className="status-notification__image">
                            <img data-testid="status-notification-image" src={imageUrl} alt={imageAltText} />
                        </div>
                    )}
                    <header className="status-notification__header">
                        <h2 id={ariaLabelledById}>{headline}</h2>
                    </header>
                    {message && <p dangerouslySetInnerHTML={{ __html: sanitizedMessage }}></p>}
                    {children && <div id={ariaDescribedById}>{children}</div>}
                </div>

                {primaryButtonText && (
                    <div className="status-notification__buttons">
                        <Button
                            ariaLabel={primaryButtonText || defaultPrimaryAriaLabel}
                            color={ButtonColor.PRIMARY}
                            isDisabled={isButtonDisabled}
                            onClick={() => {
                                primaryOnClick();
                            }}
                            size={ButtonSize.MEDIUM}
                            text={primaryButtonText}
                            testId="action-primary-button"
                            trackingType={trackingTypePrimary}
                        />
                        {secondaryButtonText && (
                            <Button
                                ariaLabel={secondaryButtonText || defaultSecondaryAriaLabel}
                                color={ButtonColor.SECONDARY}
                                isDisabled={isButtonDisabled}
                                onClick={() => secondaryOnClick()}
                                size={ButtonSize.MEDIUM}
                                text={secondaryButtonText}
                                testId="action-secondary-button"
                                trackingType={trackingTypeSecondary}
                            />
                        )}
                    </div>
                )}
            </>
        </div>
    );
};
