import { useNavigate, useLocation } from 'react-router-dom';
import { RouteParameters, replaceRouteParams, routes } from '../utils/helpers/routing-helpers';

export function useGoToRoute(): {
    goToRoute: (routeName: routes, params?: RouteParameters) => void;
    currentPath: string;
} {
    const currentLocation = useLocation();
    const navigate = useNavigate();

    const goToRoute = (routeTemplate: routes, params?: RouteParameters): void => {
        const routeName = params ? replaceRouteParams(routeTemplate, params) : routeTemplate;

        if (currentLocation.pathname !== routeName) {
            navigate(routeName);
        }
    };

    const currentPath = currentLocation.pathname;

    return {
        goToRoute,
        currentPath,
    };
}

export default useGoToRoute;
