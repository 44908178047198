import React from 'react';

import './payment-secure-tag.scss';

export const PaymentSecureTag: React.FC = (): React.ReactElement => {
    return (
        <div data-testid="payment-secure-tag" className="payment-secure-tag">
            <span className="payment-secure-tag__icon-wrapper">
                <i
                    data-testid="payment-secure-tag-icon"
                    className="payment-secure-tag__icon fa-solid fa-shield-check"
                ></i>
            </span>
            <small className="payment-secure-tag__text">Payment secure</small>
        </div>
    );
};
