import { useEffect, useState } from 'react';
import { selectIsAccountsEnabled, selectPrizeoutAccountIsActive } from '../store/slices/partner-config-slice';
import { selectUser } from '../store/slices/user-slice';
import { selectAccessTokenGlobalVerified, selectSuperUserManualLogIn } from '../store/slices/verification-slice';
import { useAppSelector } from './use-app-selector';

export enum AuthenticationResult {
    AUTHENTICATION_CHECKING = 'authentication-checking',
    AUTHENTICATION_NEEDS_LINK = 'authentication-needs-link',
    AUTHENTICATION_NEEDS_LOGIN = 'authentication-needs-login',
    AUTHENTICATION_NEEDS_SIGN_UP = 'authentication-needs-sign-up',
    AUTHENTICATION_NEEDS_VERIFICATION = 'authentication-needs-verification',
    AUTHENTICATION_SUCCESS = 'authentication-success',
}

// Disable some typescript stuff for this line.  This is a bit of future proofing.
// Remove the disable comments when this gets filled out.

export interface useCheckAuthenticationOptions {}

export interface AuthenticationControls {
    isAccountsEnabled: boolean;
    isGlobalVerifiedLoggedIn: boolean;
    isManuallyLoggedIn: boolean;
    prizeoutAccountIsActive: boolean;
    userNeedsToLink: boolean;
}

export function useCheckAuthentication(
    _options?: useCheckAuthenticationOptions,
): [AuthenticationResult, AuthenticationControls] {
    // Disable some typescript stuff for this line.  This is a bit of future proofing.
    // Remove the disable comments when this gets filled out.

    _options = { ..._options };

    const [authenticationResult, setAuthenticationResult] = useState<AuthenticationResult>(
        AuthenticationResult.AUTHENTICATION_CHECKING,
    );

    // No Prizeout authentication needed, no signup for Prizeout allowed.
    const isAccountsEnabled = useAppSelector(selectIsAccountsEnabled);

    // Logged in, linked, verified.
    const isGlobalVerifiedLoggedIn = useAppSelector(selectAccessTokenGlobalVerified);

    // Logged in to your Prizeout account.  Possibly not linked or verified.
    const isManuallyLoggedIn: boolean = useAppSelector(selectSuperUserManualLogIn);

    // Successfully linked a partner account to a Prizeout account.
    const prizeoutAccountIsActive = useAppSelector(selectPrizeoutAccountIsActive);

    // The needToLink flag in the user tells if the Prizeout account needs to be linked to the partner account.
    const user = useAppSelector(selectUser);

    useEffect(() => {
        if (!isAccountsEnabled) {
            return;
        }

        checkStatus();
    }, [isGlobalVerifiedLoggedIn, isManuallyLoggedIn, prizeoutAccountIsActive, user]);

    function checkStatus() {
        if (isGlobalVerifiedLoggedIn) {
            setAuthenticationResult(AuthenticationResult.AUTHENTICATION_SUCCESS);
            return;
        }

        if (isManuallyLoggedIn) {
            setAuthenticationResult(
                user?.needsToLink
                    ? AuthenticationResult.AUTHENTICATION_NEEDS_LINK
                    : AuthenticationResult.AUTHENTICATION_NEEDS_VERIFICATION,
            );
            return;
        }

        if (prizeoutAccountIsActive) {
            setAuthenticationResult(AuthenticationResult.AUTHENTICATION_NEEDS_LOGIN);
            return;
        }

        setAuthenticationResult(AuthenticationResult.AUTHENTICATION_NEEDS_SIGN_UP);
    }

    if (authenticationResult === AuthenticationResult.AUTHENTICATION_CHECKING) {
        if (!isAccountsEnabled) {
            setAuthenticationResult(AuthenticationResult.AUTHENTICATION_SUCCESS);
        } else {
            checkStatus();
        }
    }

    return [
        authenticationResult,
        {
            isAccountsEnabled,
            isGlobalVerifiedLoggedIn,
            isManuallyLoggedIn,
            prizeoutAccountIsActive,
            userNeedsToLink: !!user?.needsToLink,
        },
    ];
}

export default useCheckAuthentication;
