import { DependencyList, useEffect, useRef } from 'react';

type WaitCallback = (dependencies: DependencyList) => void;

export function useEffectQueue(dependencies: DependencyList): [(arg: WaitCallback) => void, () => void] {
    const callbackQueue = useRef([] as WaitCallback[]);

    useEffect(() => {
        const doQueue = callbackQueue.current.splice(0);
        doQueue.forEach((cb) => cb(dependencies));
    }, dependencies);

    const queueEffectCallback = (callback: WaitCallback) => {
        callbackQueue.current.push(callback);
    };

    const clearEffectQueue = () => {
        callbackQueue.current.splice(0);
    };

    return [queueEffectCallback, clearEffectQueue];
}
