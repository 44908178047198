import React from 'react';
import { CheckoutInfoStep, CheckoutInfoStepTemplate } from './checkout-info-step';

interface CheckoutInfoStepsProps {
    steps: CheckoutInfoStepTemplate[];
}

import './checkout-info-steps.scss';
import { useNumberSequence } from '../../../hooks';

export const CheckoutInfoSteps: React.FC<CheckoutInfoStepsProps> = ({ steps }): React.ReactElement => {
    const keySequence = useNumberSequence();

    const renderStep = (stepDefinition: CheckoutInfoStepTemplate) => {
        return <CheckoutInfoStep key={keySequence()} stepDefinition={stepDefinition} />;
    };

    return <ul className="checkout-info-steps">{steps.map(renderStep)}</ul>;
};
