import React from 'react';
import { selectUserOptIns, selectIsCashbackPlusProduct } from '../../../store/slices/partner-config-slice';
import { useAppSelector, useNumberSequence } from '../../../hooks';
import { Copyright, ExternalLink, ExternalLinkDisplayTypes } from '../ui-widgets';
import { constants } from '../../../utils';

import './footer.scss';

export const Footer: React.FC = (): React.ReactElement => {
    const optIns = useAppSelector(selectUserOptIns);
    const isCashbackPlusProduct = useAppSelector(selectIsCashbackPlusProduct);
    const listKeys = useNumberSequence();

    const footerLinksData = [
        {
            url: constants.prizeoutWebsiteRoutes.cashbackPlusTermsUrl,
            text: `${constants.cbPlus} Loyalty Terms`,
            shouldShow: isCashbackPlusProduct,
        },
        {
            url: constants.prizeoutWebsiteRoutes.termsUrl,
            text: 'Prizeout Terms of Use',
            shouldShow: true,
        },
        {
            url: constants.prizeoutWebsiteRoutes.privacyUrl,
            text: 'Privacy Policy',
            shouldShow: true,
        },
        {
            url: `${constants.prizeoutWebsiteRoutes.privacyUrl}#privacy-information-for-california-residents`,
            text: 'California Privacy',
            shouldShow: optIns && optIns.ccpa,
        },
        {
            url: 'https://privacyportal.cookiepro.com/webform/20dcda57-44d2-4622-b07c-30902b02fbd4/9211ec70-941f-4529-9e43-027527ed5e2f',
            text: 'Do Not Sell or Share My Personal Information',
            shouldShow: optIns && optIns.ccpa,
        },
    ];

    const renderFooterLinks = footerLinksData.map((item) => {
        if (item.shouldShow) {
            return (
                <li key={listKeys()} className="footer__link">
                    <ExternalLink
                        ariaLabel={item.text}
                        displayType={ExternalLinkDisplayTypes.LINK}
                        label={item.text}
                        url={item.url}
                    />
                </li>
            );
        }
    });

    return (
        <footer className="footer">
            <Copyright />
            <ul className="footer__links">{renderFooterLinks}</ul>
        </footer>
    );
};
