import React from 'react';
import Classnames from 'classnames';

import './circle-with-icon.scss';

export enum CircleWithIconStatus {
    SUCCESS = 'success',
    WARNING = 'warning',
}

interface CircleWithIconProps {
    iconClasses: string;
    status: CircleWithIconStatus;
}

export const CircleWithIcon: React.FC<CircleWithIconProps> = ({ iconClasses, status }): React.ReactElement => {
    const classes = Classnames('circle-with-icon', { [`circle-with-icon--${status}`]: status });

    return (
        <div data-testid="circle-with-icon" className={classes}>
            <i className={iconClasses}></i>
        </div>
    );
};
