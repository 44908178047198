import React, { useEffect, useMemo, useState } from 'react';
import Classnames from 'classnames';
import { ClickableCard } from '../../cards';

import './copy-content-inline.scss';
import { isString } from '../../../../utils';

// This is essentially version of `CopyContent` that can be used inline in other
// components. At some point `CopyContent` should be reimplemented in terms of this
// component.
interface CopyContentInlineProps {
    content: string;
    onCopiedChange?: (copied: boolean) => void;
}

export const CopyContentInline: React.FC<CopyContentInlineProps> = ({
    content,
    onCopiedChange,
}): React.ReactElement => {
    const [contentCopied, setContentCopied] = useState(false);

    let copiedTimer: ReturnType<typeof setTimeout>;

    const checkContentCopiedTimer = () => {
        if (copiedTimer) {
            clearTimeout(copiedTimer);
        }
    };

    useEffect(() => {
        return () => {
            checkContentCopiedTimer();
        };
    }, []);

    const emptyContent: boolean = useMemo(() => {
        return !isString(content) || content.length === 0;
    }, [content]);

    const handleCopiedChange = (copied: boolean) => {
        setContentCopied(copied);
        onCopiedChange?.call(null, copied);
    };

    const copyContent = async () => {
        checkContentCopiedTimer();

        // To enable the navigator.clipboard API within an iframe, ensure the iframe includes
        // the attribute: allow='clipboard-read; clipboard-write'. This grants necessary permissions
        // for clipboard operations (reading and writing).
        try {
            await navigator.clipboard.writeText(content);
            handleCopiedChange(true);
        } catch (err) {
            console.error('Failed to copy content: ', err);
        }

        copiedTimer = setTimeout(() => {
            handleCopiedChange(false);
        }, 2500);
    };

    const classes = Classnames('copy-content-inline', { 'copy-content-inline--success': contentCopied });

    return (
        <div className={classes} data-testid="copy-content-inline">
            <ClickableCard
                className="copy-content-inline__value"
                onClick={copyContent}
                cardName={'copy-content-inline'}
                isDisabled={emptyContent}
            >
                <p>{content}</p>
                <span>
                    <i className="fa-kit fa-prizeout-copy" title="copy" data-testid="copy-icon"></i>
                </span>
            </ClickableCard>
        </div>
    );
};
