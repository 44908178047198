import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { basePortalQuery } from '../common';
import {
    ActivePartnerInfo,
    LoginPartnerRequest,
    PartnerAccount,
    PartnerInfo,
    PartnerPortalMutationResponse,
    PasswordResetRequest,
    PasswordSetRequest,
    PortalEventGroups,
    ActivePartnerAccount,
    PasswordResetViaCurrentPasswordRequest,
} from './partner-portal-types';

export const portalAuthenticationApiSlice = createApi({
    reducerPath: 'partnerPortalAuthenticationApi',
    baseQuery: basePortalQuery,
    tagTypes: [
        PortalEventGroups.PortalAccountInviteList,
        PortalEventGroups.PortalAccountList,
        PortalEventGroups.PortalLogin,
    ],
    endpoints: (builder) => ({
        getPartnerAccountInviteList: builder.query<PartnerAccount[], void>({
            query: () => 'partner/account/invite',
            providesTags: [PortalEventGroups.PortalAccountInviteList],
        }),
        getPartnerAccountList: builder.query<PartnerAccount[], void>({
            query: () => 'partner/account/list',
            providesTags: [PortalEventGroups.PortalAccountList],
        }),
        acceptPartnerAccountInvite: builder.mutation<PartnerPortalMutationResponse, ActivePartnerAccount>({
            query: (partnerData) => ({
                url: '/partner/account/invite/accept',
                method: 'POST',
                body: partnerData,
            }),
            invalidatesTags: [PortalEventGroups.PortalAccountInviteList, PortalEventGroups.PortalAccountList],
        }),
        rejectPartnerAccountInvite: builder.mutation<PartnerPortalMutationResponse, ActivePartnerAccount>({
            query: (partnerData) => ({
                url: '/partner/account/invite/reject',
                method: 'POST',
                body: partnerData,
            }),
            invalidatesTags: [PortalEventGroups.PortalAccountInviteList, PortalEventGroups.PortalAccountList],
        }),
        loginPartner: builder.mutation<PartnerInfo, LoginPartnerRequest>({
            query: (credentials) => ({
                url: '/partner/logIn',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: [PortalEventGroups.PortalLogin],
        }),
        loginPartnerWithUniversal: builder.mutation<PartnerInfo, undefined>({
            query: () => ({
                url: 'partner/universalLogIn',
                method: 'POST',
            }),
        }),
        setPartnerAccount: builder.mutation<ActivePartnerInfo, ActivePartnerAccount>({
            query: (partnerData) => ({
                url: '/partner/selectActive',
                method: 'POST',
                body: partnerData,
            }),
            invalidatesTags: [PortalEventGroups.PortalAccountList],
        }),
        logoutPartner: builder.mutation<void, void>({
            query: () => ({
                url: '/partner/logOut',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalLogin],
        }),
        passwordResetRequest: builder.mutation<void, PasswordResetRequest>({
            query: (resetRequest) => ({
                url: '/partner/password/resetRequest',
                method: 'POST',
                body: resetRequest,
            }),
        }),
        passwordSetRequest: builder.mutation<PartnerPortalMutationResponse, PasswordSetRequest>({
            query: (passwordSetRequest) => ({
                url: '/partner/password/set',
                method: 'POST',
                body: passwordSetRequest,
            }),
        }),
        passwordResetViaCurrentPassword: builder.mutation<
            PartnerPortalMutationResponse,
            PasswordResetViaCurrentPasswordRequest
        >({
            query: (payload) => ({
                url: '/partner/password/reset',
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetPartnerAccountInviteListQuery,
    useGetPartnerAccountListQuery,
    useAcceptPartnerAccountInviteMutation,
    useRejectPartnerAccountInviteMutation,
    useLoginPartnerMutation,
    useLoginPartnerWithUniversalMutation,
    useLogoutPartnerMutation,
    usePasswordResetRequestMutation,
    usePasswordSetRequestMutation,
    useSetPartnerAccountMutation,
    usePasswordResetViaCurrentPasswordMutation,
} = portalAuthenticationApiSlice;
