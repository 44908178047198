import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sharedAPI } from '../../utils/services/api';
import { SFRootState } from '../store-front';

// Define a type for the slice state
export interface StoreFrontState {
    auctionParams?: StoreFrontAuctionParams;
}

export interface StoreFrontAuctionParams {
    partner: {
        partnerId: string;
        prodAllowed: boolean;
    };
    sessionId: string;
    user: {
        balance: number;
        balanceCurrency: string;
        email: string;
        firstName: string;
        lastName: string;
        validEmail: boolean;
    };
}

export interface AuctionParams {
    partner_id: string;
    session_id: string;
    miniWidget?: boolean;
    user: {
        balance: number;
        balance_currency: string;
        email: string;
        first_name: string;
        last_name: string;
        prod_allowed: boolean;
        valid_email: boolean;
    };
}

// Define the initial state using that type
export const storeFrontInitialState: StoreFrontState = {};

interface PostAuthKeysReq {
    accessToken: string;
    passCode: string;
}

interface PostForgotAuthKeysReq {
    accessCode: string;
}

export const postAuthKeys = createAsyncThunk(
    'storefront/postAuthKeys',
    async ({ accessToken, passCode }: PostAuthKeysReq, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    accessToken,
                    passCode,
                },
                endpoint: '/storefront/login',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const postForgotAuthKeys = createAsyncThunk(
    'wallet/getWalletBrands',
    async ({ accessCode }: PostForgotAuthKeysReq, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    accessCode,
                },
                endpoint: '/store-front/access-reset',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const storeFrontSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(postAuthKeys.fulfilled, (state, action: PayloadAction<StoreFrontAuctionParams>) => {
            state.auctionParams = action.payload;
        });
    },
    initialState: storeFrontInitialState,
    name: 'storeFront',
    reducers: {
        resetAuctionParams(state) {
            state.auctionParams = null;
        },
    },
});

export const { resetAuctionParams } = storeFrontSlice.actions;

const selectStoreFrontState = ({ storeFront }: SFRootState): StoreFrontState => storeFront;

export const selectStoreFrontAuctionParams = createSelector(selectStoreFrontState, ({ auctionParams }) => ({
    partner_id: auctionParams.partner.partnerId,
    session_id: auctionParams.sessionId,
    miniWdget: false,
    user: {
        balance: auctionParams.user.balance,
        balance_currency: auctionParams.user.balanceCurrency,
        email: auctionParams.user.email,
        first_name: auctionParams.user.firstName,
        last_name: auctionParams.user.lastName,
        prod_allowed: auctionParams.partner.prodAllowed,
        valid_email: auctionParams.user.validEmail,
    },
}));

export default storeFrontSlice.reducer;
