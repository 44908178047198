export const asyncRetry = async (fn: () => unknown, retries = 3): Promise<unknown> => {
    let executionCount = 1;

    const execute = async (): Promise<unknown> => {
        try {
            return await fn();
        } catch (e) {
            if (e?.response?.status === 429) {
                throw e;
            }

            executionCount += 1;
            if (executionCount <= retries) {
                return await execute();
            }
            throw e;
        }
    };

    return await execute();
};
