import React from 'react';
import { GenericModal } from '../generic-modal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/widget/widget';
import { ButtonType, ExternalLink, ExternalLinkDisplayTypes } from '../../../common';
import { constants } from '../../../../utils/constants';
import { ButtonTrackingTypes, TrackingService } from '../../../../utils/services/tracking';
import { acceptOptins } from '../../../../store/slices/user-slice';

interface RewardsTermsProps {
    isOpen: boolean;
    onClose: () => void;
}
export const RewardsTerms: React.FC<RewardsTermsProps> = ({ isOpen, onClose }): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const trackingService = TrackingService.getTrackingService();

    const onAcceptTerms = async () => {
        trackingService.trackButtonClick(
            ButtonTrackingTypes.BUTTON_CLICK_ACCEPT_REWARDS_TERMS,
            ButtonType.BUTTON,
            'Accept rewards terms',
        );

        await dispatch(acceptOptins(['rewards']));
        onClose();
    };

    return (
        <GenericModal
            ariaLabelledById="po-rewards-headline"
            ariaDescribedById="po-rewards-description"
            ariaLabel="Prizeout Rewards Terms of Use"
            hasExitButton={true}
            headline="Prizeout Rewards Terms of Use"
            isOpen={isOpen}
            onClose={onClose}
            testId="po-rewards-tos-modal"
            primaryButtonText="Accept Terms"
            primaryOnClick={() => onAcceptTerms()}
            primaryTestId="po-rewards-accept-button"
        >
            <p id="po-rewards-description">
                By tapping &quot;Accept Terms&quot; below, you&apos;ll be enrolling in the Prizeout Rewards Program and
                you agree to the{' '}
                <ExternalLink
                    displayType={ExternalLinkDisplayTypes.LINK}
                    ariaLabel="Prizeout Rewards Terms of Use"
                    label="Prizeout Rewards Terms of Use"
                    url={constants.prizeoutWebsiteRoutes.rewardsTermsUrl}
                />
                .
            </p>
        </GenericModal>
    );
};
