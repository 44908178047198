import React, { useEffect, useState } from 'react';
import Classnames from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import qs from 'qs';
import { updatePasswordUsingVerification } from '../../store/slices/user-slice';
import { AppDispatch } from '../../store/widget/widget';
import {
    AlertCallout,
    AlertCalloutMessageTypes,
    Button,
    ButtonColor,
    ButtonSize,
    ButtonType,
    FormHeader,
} from '../../components/common';
import { routes } from '../../utils';
import './reset-password-form.scss';

interface Inputs {
    username: string;
    confirmPassword: string;
    password: string;
}

export const ResetPasswordForm: React.FC = (): React.ReactElement => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const dispatch = useDispatch<AppDispatch>(),
        navigate = useNavigate(),
        [message, setMessage] = useState(''),
        [isSuccess, setIsSuccess] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        classes: string = Classnames(`form__input-wrapper`, {
            'form__input-wrapper--error': message && !isSuccess,
        });

    const { register, handleSubmit } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setMessage('');
        const { password, confirmPassword } = data;
        if (password != confirmPassword) {
            setMessage('Passwords do not match.');
            setIsSuccess(false);
            return;
        }
        try {
            setIsLoading(true);
            await dispatch(
                updatePasswordUsingVerification({
                    password,
                    secretToken: queryParams['secretToken'] as string,
                    id: queryParams['id'] as string,
                }),
            ).unwrap();
            setMessage('Your password has updated successfully.');
            setIsSuccess(true);
        } catch (e) {
            setMessage(e.message);
            setIsSuccess(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!('id' in queryParams && queryParams['id'])) {
            navigate(routes.FORGOT_PASSWORD);
        }
    }, []);

    return (
        <>
            <FormHeader formName="reset-password-form" headline="Create your new password" />

            {message && !isSuccess && (
                <AlertCallout message={message} type={AlertCalloutMessageTypes.ERROR} className="mb-m" />
            )}

            {message && isSuccess && (
                <div>
                    <AlertCallout message={message} type={AlertCalloutMessageTypes.SUCCESS} className="mb-m" />
                    <p className="mb-m">
                        Return to <Link to="/">Log in</Link>
                    </p>
                </div>
            )}

            {!isSuccess && (
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <input
                        {...register('username')}
                        hidden={true}
                        type="email"
                        name="username"
                        autoComplete="username"
                    />
                    <div className={classes}>
                        <input
                            {...register('password', {
                                required: {
                                    message: 'Password required',
                                    value: true,
                                },
                            })}
                            aria-required="true"
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Create a new password"
                            className="form__input"
                            autoComplete="new-password"
                        />
                        <label className="form__label" htmlFor="password">
                            Create a new password
                        </label>
                    </div>
                    <div className={classes}>
                        <input
                            {...register('confirmPassword', {
                                required: {
                                    message: 'Confirmation password required',
                                    value: true,
                                },
                            })}
                            aria-required="true"
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirm your password"
                            className="form__input"
                            autoComplete="new-password"
                        />
                        <label className="form__label" htmlFor="confirmPassword">
                            Confirm your password
                        </label>
                    </div>
                    <div className="form__button-wrapper">
                        <Button
                            ariaLabel="Reset my password"
                            color={ButtonColor.PRIMARY}
                            size={ButtonSize.MEDIUM}
                            testId="reset-password-button"
                            text="Reset my password"
                            type={ButtonType.SUBMIT}
                            isLoading={isLoading}
                        ></Button>
                    </div>
                </form>
            )}
        </>
    );
};
