import React from 'react';
import { GiftCardImage } from '../../common';
import Classnames from 'classnames';

//Currently using older version of swiper until we figure out issues with later versions and jest ಠ_ಠ
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Navigation, Scrollbar, Mousewheel } from 'swiper';
import { useAppSelector } from '../../../hooks';
import { selectIsMobileLandscape } from '../../../store/slices/common-slice';
import { ClickableCard } from '../cards';

import './supporting-images.scss';

SwiperCore.use([A11y, Navigation, Scrollbar, Mousewheel]);

interface SupportingImagesProps {
    initialSlide?: number;
    isModal?: boolean;
    onClick?: (idx: number) => void;
    supportingImages: Array<string>;
}

export const SupportingImages: React.FC<SupportingImagesProps> = ({
    supportingImages,
    onClick,
    initialSlide,
    isModal,
}): React.ReactElement => {
    SwiperCore.use([A11y, Navigation, Scrollbar, Mousewheel]);
    const swiperId = 'swiper-supporting-images';
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);
    const isCarousel = supportingImages.length > 2 || (isMobileLandscape && isModal && supportingImages.length > 1);

    const selectCard = (idx: number) => {
        onClick(idx);
    };

    const renderGiftCardImage = (image: string, idx: number) => {
        return (
            <ClickableCard
                cardName="supporting-image"
                testId={`supporting-image-${idx}`}
                onClick={onClick && (() => selectCard(idx))}
                isDisabled={isModal}
                key={`supporting-image-${idx}`}
            >
                <GiftCardImage imgUrl={image} altText="" />
            </ClickableCard>
        );
    };

    const renderLayout = () => (
        <>
            {isCarousel && (
                <Swiper
                    a11y={{ enabled: false }}
                    breakpoints={{
                        641: {
                            slidesPerView: isModal ? 2.25 : 2.5,
                            spaceBetween: isModal ? 16 : 8,
                        },
                    }}
                    centerInsufficientSlides={true}
                    freeMode={true}
                    grabCursor={true}
                    initialSlide={initialSlide || 0}
                    key={swiperId}
                    mousewheel={false}
                    navigation={true}
                    observer={true} // Enable to initialize when slides are visible
                    observeParents={true} // Enable to initialize when slides are visible
                    scrollbar={true}
                    slidesOffsetBefore={16}
                    slidesOffsetAfter={20}
                    slidesPerView={isModal ? 1.25 : 2.5}
                    spaceBetween={8}
                    touchRatio={0.5}
                    touchStartPreventDefault={false}
                >
                    {supportingImages.map((image, idx) => (
                        <SwiperSlide key={`supporting-image-${idx}`}>{renderGiftCardImage(image, idx)}</SwiperSlide>
                    ))}
                </Swiper>
            )}
            {!isCarousel && supportingImages.map((image, idx) => renderGiftCardImage(image, idx))}
        </>
    );

    const wrapperClass = Classnames(
        'supporting-images',
        { 'supporting-images--grid': !isCarousel },
        { 'supporting-images--modal': isModal },
    );

    return (
        <>
            {supportingImages && (
                <div data-testid="supporting-images" className={wrapperClass}>
                    {renderLayout()}
                </div>
            )}
        </>
    );
};
