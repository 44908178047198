import _ from 'lodash';
import { useMemo } from 'react';

export function useComponentId(componentName: string): string {
    if (componentName.length <= 0) {
        throw new Error('Component name must be a non-zero length string');
    }

    // Without the useMemo, the id changes on each rerender.
    const id = useMemo(() => _.uniqueId(componentName), []);
    return id;
}

export default useComponentId;
