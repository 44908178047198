import React from 'react';
import Classnames from 'classnames';

import './overlay.scss';

interface OverlayProps {
    onClick?: () => void;
    overlayType?: string;
    zIndexType: string;
}

export const Overlay: React.FC<OverlayProps> = ({ onClick, overlayType, zIndexType, children }): React.ReactElement => {
    const classes: string = Classnames(
        `overlay`,
        { [`overlay--${overlayType}`]: overlayType },
        { [`z-index-${zIndexType}`]: zIndexType },
    );

    return (
        <div data-testid="overlay" className={classes} onClick={onClick}>
            {children}
        </div>
    );
};
