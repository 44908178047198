import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../store/widget/widget';
import { asyncRetry } from '../../../../../utils';
import { postCapturedEmail } from '../../../../../store/slices/email-capture-slice';

export function useEmailCapturePost(): (email: string) => any {
    const dispatch = useDispatch<AppDispatch>();

    const emailCapturePost = async (email: string) => {
        try {
            return await asyncRetry(() => {
                return dispatch(postCapturedEmail({ email })).unwrap();
            });
        } catch (e) {
            throw e; // propagate the error.
        }
    };

    return emailCapturePost;
}
