import React from 'react';
import { BackArrowButton } from '../buttons';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';
import './page-header.scss';

export interface PageHeaderProps {
    title?: string;
    onClick?: () => void;
    trackingType?: ButtonTrackingTypes;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, onClick, trackingType }): React.ReactElement => {
    return (
        <header data-testid="page-header" className="page-header">
            {onClick && <BackArrowButton onClick={onClick} trackingType={trackingType} />}
            {title && <h1 className="page-header__title">{title}</h1>}
        </header>
    );
};
