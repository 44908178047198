import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/widget/widget';
import { refreshUserBankAccount, selectIsAccountRefreshEnabled } from '../store/slices/partner-config-slice';
import { useAppSelector } from './use-app-selector';

export function useInvalidateAccountBalances() {
    const dispatch = useDispatch<AppDispatch>();
    const isAccountRefreshEnabled = useAppSelector(selectIsAccountRefreshEnabled);

    const invalidateAccountBalances = () => {
        if (isAccountRefreshEnabled) {
            dispatch(refreshUserBankAccount());
        }
    };

    return [invalidateAccountBalances];
}

export default useInvalidateAccountBalances;
