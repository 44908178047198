import React from 'react';
import Classnames from 'classnames';
import { DividerRow, DividerRowMode } from '../ui-widgets/divider-row';

import './button-row-layout.scss';

interface ButtonRowLayoutProps {
    isSticky?: boolean;
    hasTopDivider?: boolean;
    isResponsive?: boolean;
}

export const ButtonRowLayout: React.FC<ButtonRowLayoutProps> = ({
    children,
    isSticky,
    hasTopDivider,
    isResponsive,
}): React.ReactElement => {
    const classes = Classnames('button-row-layout', {
        'button-row-layout--is-sticky z-index-sticky-buttons': isSticky,
        'button-row-layout--has-top-divider': hasTopDivider,
    });
    const buttonClasses = Classnames('button-row-layout__buttons', {
        'button-row-layout__buttons--is-responsive': isResponsive,
    });

    return (
        <div data-testid="button-row-layout" className={classes}>
            {hasTopDivider && <DividerRow shouldExtendEdges={true} mode={DividerRowMode.THIN} />}
            <div className={buttonClasses}>{children}</div>
        </div>
    );
};
