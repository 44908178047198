import { Context, useContext, useEffect, useState } from 'react';
import { ControlContext, ControlContextContent, ControlFunction } from '../utils/types';

type ContextList = {
    [key in ControlContextId]?: Context<ControlContext<string>>;
};

const controlContextList: ContextList = {};

export enum ControlContextId {
    WALLET = 'wallet',
    CASHBACK = 'cashback',
    HELP = 'help',
}

export const useControlContextInit = <GenericKeyType extends string>(
    contextId: ControlContextId,
    context?: Context<ControlContext<GenericKeyType>>,
    overrides?: ControlContextContent<GenericKeyType>,
): ControlContext<GenericKeyType> => {
    const [content, setContent] = useState(overrides || ({} as unknown as ControlContextContent<GenericKeyType>));
    const setControl = (contentName: GenericKeyType, controlFunction: ControlFunction): void => {
        setContent((oldContent) => {
            return {
                ...oldContent,
                [contentName]: controlFunction,
            };
        });
    };
    const getControl = (contentName: GenericKeyType, defaultControl?: ControlFunction): ControlFunction => {
        return content[contentName] || defaultControl;
    };

    useEffect(() => {
        return () => {
            // Cleanup the context when it's no longer needed.
            controlContextList[contextId] = null;
        };
    }, []);

    const contextInit = {
        setControl,
        getControl,
        content,
    };

    controlContextList[contextId] = context;
    return contextInit;
};

export const useControlContext = <GenericKeyType extends string>(
    contextId: ControlContextId,
): ControlContext<GenericKeyType> => {
    return controlContextList[contextId] ? useContext(controlContextList[contextId]) : useControlContextInit(contextId);
};
