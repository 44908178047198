import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PagedListStateManager } from '../use-paged-list-state';
import { Button } from '../../buttons';
import { PagedListStateActionNames } from '../paged-list-reducer';
import { InputModeTypes, InputTypes, ValidatedInput } from '../../ui-inputs/validated-input';
import { useDebounce } from '../../../../hooks';
import { isHTMLElement, isInputElement } from '../../../../utils';
import ClassNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import './page-selector.scss';

interface PageSelectorProps<RowType> {
    pagingStateManager: PagedListStateManager<RowType>;
    centered?: boolean;
}

export function PageSelector<RowType>({
    pagingStateManager,
    centered = true,
}: PageSelectorProps<RowType>): React.ReactElement {
    const inputRef = useRef(null);
    const [pagingState, dispatchPagingState] = pagingStateManager;
    const [totalPages, setTotalPages] = useState(pagingState.pageManager.totalPages);

    useEffect(() => {
        if (pagingState.pageManagerHash) {
            setTotalPages(pagingState.pageManager.totalPages);
        }
    }, [pagingState.pageManagerHash]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = pagingState.firstPageNumber;
        }
    }, [pagingState.firstPageNumber]);

    const goToPage = (pageNumber: number): void => {
        dispatchPagingState({
            type: PagedListStateActionNames.SET_FIRST_PAGE,
            firstPageNumber: pageNumber,
        });
    };

    const [jumpToPage] = useDebounce(goToPage);

    const previousPage = useMemo(() => {
        let result = pagingState.firstPageNumber - 1;

        if (result <= 0) {
            result = 1;
        }

        return result;
    }, [pagingState.firstPageNumber]);

    const nextPage = useMemo(() => {
        let result = pagingState.firstPageNumber + 1;

        if (result > pagingState.pageManager.totalPages) {
            result = pagingState.pageManager.totalPages;
        }

        return result;
    }, [pagingState.firstPageNumber, pagingState.pageManager.totalPages]);

    const onInputBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
        if (evt.target.validity.valid) {
            goToPage(parseInt(evt.target.value));
        }
    };

    const [keyDownHandler] = useDebounce<React.KeyboardEvent<HTMLInputElement>>(
        (evt: React.KeyboardEvent<HTMLInputElement>) => {
            evt.preventDefault();

            if (isHTMLElement(evt.target) && isInputElement(evt.target)) {
                if (evt.target.validity.valid) {
                    if (evt.key === 'Enter') {
                        goToPage(parseInt(evt.target.value));
                    }
                }
            }
        },
    );

    const inputClassname = ClassNames('page-selector__current-page', {
        'page-selector__current-page--is-loading': pagingState.isLoading,
    });

    const renderSelector = () => {
        if (pagingState.isLoading && pagingState.pageManager.totalPages === 0) {
            return <Skeleton containerTestId="page-selector-skeleton" width="6rem" height="2rem" />;
        }

        if (pagingState.pageManager.totalPages === 0) {
            return null;
        }

        return (
            <>
                <Button
                    className="page-selector__button"
                    testId="page-selector-button-previous-page"
                    onClick={() => jumpToPage(previousPage)}
                    icon="fa-regular fa-chevron-left"
                    isDisabled={pagingState.isLoading || pagingState.firstPageNumber <= 1}
                />
                <ValidatedInput
                    ref={inputRef}
                    name="current-page"
                    className={inputClassname}
                    type={InputTypes.NUMBER}
                    inputMode={InputModeTypes.NUMERIC}
                    step={1}
                    min={1}
                    max={totalPages}
                    defaultValue={pagingState.firstPageNumber}
                    onBlur={onInputBlur}
                    onKeyDown={keyDownHandler}
                />
                <p className="page-selector__page-total">of {totalPages}</p>
                <Button
                    className="page-selector__button"
                    testId="page-selector-button-next-page"
                    onClick={() => jumpToPage(nextPage)}
                    icon="fa-regular fa-chevron-right"
                    isDisabled={pagingState.isLoading || pagingState.firstPageNumber >= totalPages}
                />
            </>
        );
    };

    const classnames = ClassNames('page-selector', {
        'page-selector--is-centered': centered,
    });

    return (
        <div className={classnames} data-testid="page-selector">
            {renderSelector()}
        </div>
    );
}
