import currencyFormatter from 'currency-formatter';

export function truncatedCurrencyFormatter(inputAmount: number, currencyCode: string) {
    let amount = Number(inputAmount) / 100;
    const abbreviations = ['', 'k', 'M', 'B'];
    let index = 0;

    while (amount >= 1000 && index < abbreviations.length - 1) {
        amount /= 1000;
        index++;
    }

    let formattedAmount;
    if (index === 0) {
        formattedAmount = amount.toFixed(2);
    } else {
        amount = Math.floor(amount * 10) / 10;
        formattedAmount = amount.toFixed(1);
    }

    const currency = currencyFormatter.findCurrency(currencyCode);
    const numberFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency.code,
        minimumFractionDigits: index === 0 ? 2 : 1,
        maximumFractionDigits: index === 0 ? 2 : 1,
    });

    return numberFormat.format(Number(formattedAmount)) + abbreviations[index];
}
