import { ConditionName, StepActionName, TargetName } from '../identifiers';

export type RuleStepList = RuleStep[];

export interface RuleStepBase {
    action: string;
    condition?: ConditionName;
}

export interface CheckInputValueRule extends RuleStepBase {
    action: StepActionName.CHECK_INPUT_VALUE;
    data: CheckInputValueData;
}

export interface ClickElementRule extends RuleStepBase {
    action: StepActionName.CLICK_ELEMENT;
    data: ClickElementData;
}

export interface FailRule extends RuleStepBase {
    action: StepActionName.FAIL;
    condition: ConditionName;
    data: FailData;
}

export interface TypeTextRule extends RuleStepBase {
    action: StepActionName.TYPE_TEXT;
    data: TypeTextData;
}

export interface WaitRule extends RuleStepBase {
    action: StepActionName.WAIT;
    data: WaitData;
}

export interface WaitForElementRule extends RuleStepBase {
    action: StepActionName.WAIT_FOR_ELEMENT;
    data: WaitForElementData;
}

export interface WaitUntilConditionRule extends RuleStepBase {
    action: StepActionName.WAIT_UNTIL_CONDITION;
    data: WaitUntilConditionData;
}

export type RuleStep =
    | CheckInputValueRule
    | ClickElementRule
    | FailRule
    | TypeTextRule
    | WaitRule
    | WaitForElementRule
    | WaitUntilConditionRule;

export enum WaitResultAction {
    FAIL = 'fail',
    CONTINUE = 'continue',
}

export interface CheckInputValueData {
    target: TargetName;
    text: string;
    waitCheck?: WaitCheckData;
}

export interface ClickElementData {
    target: TargetName;
}

export interface FailData {
    message: string;
}

export interface TypeTextData {
    target: TargetName;
    text: string;
}

export interface WaitData {
    duration: number;
}

export interface WaitForElementData {
    target: TargetName;
}

export interface WaitUntilConditionData {
    target?: TargetName;
    condition: ConditionName;
    message?: string;
    mutateTarget?: TargetName;
    onTrue: WaitResultAction;
    interval?: number;
    retries?: number;
}

export interface WaitCheckData {
    interval?: number;
    retries?: number;
}

export type StepData =
    | CheckInputValueData
    | ClickElementData
    | FailData
    | TypeTextData
    | WaitData
    | WaitForElementData
    | WaitUntilConditionData;
