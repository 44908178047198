export enum SearchStateActionNames {
    CLEAR_MATCHES = 'clear_matches',
    CLOSE_LIST = 'close-list',
    OPEN_LIST = 'open-list',
    SET_HAS_QUERY = 'set_has_query',
    SET_MATCHES = 'set_matches',
    UNSET_HAS_QUERY = 'unset_has_query',
}

export interface SearchState<GenericType> {
    matchingItems: GenericType[];
    hasQuery: boolean;
    canHaveResults: boolean;
    hasResults: boolean;
    isListOpen: boolean;
}

export interface SearchStateAction<GenericType> {
    type: SearchStateActionNames;
    payload?: {
        matchingItems: GenericType[];
    };
}

export function initializeSearchState<GenericType>() {
    return {
        matchingItems: [] as GenericType[],
        hasQuery: false,
        canHaveResults: false,
        hasResults: false,
        isListOpen: false,
    };
}

export const searchStateReducer = <GenericType>(
    state: SearchState<GenericType>,
    action: SearchStateAction<GenericType>,
) => {
    switch (action.type) {
        case SearchStateActionNames.CLEAR_MATCHES:
            return {
                ...state,
                canHaveResults: state.hasQuery,
                hasResults: false,
                matchingItems: [] as GenericType[],
            };

        case SearchStateActionNames.CLOSE_LIST:
            return {
                ...state,
                isListOpen: false,
            };

        case SearchStateActionNames.OPEN_LIST:
            return {
                ...state,
                isListOpen: true,
            };

        case SearchStateActionNames.SET_HAS_QUERY:
            return {
                ...state,
                hasQuery: true,
                canHaveResults: true,
                hasResults: state.matchingItems.length > 0,
            };

        case SearchStateActionNames.SET_MATCHES:
            return {
                ...state,
                canHaveResults: state.hasQuery,
                hasResults: action.payload.matchingItems.length > 0,
                matchingItems: action.payload.matchingItems,
            };

        case SearchStateActionNames.UNSET_HAS_QUERY:
            return {
                ...state,
                hasQuery: false,
                canHaveResults: false,
                hasResults: false,
                matchingItems: [] as GenericType[],
            };

        default:
            console.error(`Unknown searchState action ${action}`);
    }

    return state;
};
