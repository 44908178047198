import { useRef } from 'react';

export function useDebounce<GenericType>(
    callback: (arg0?: GenericType) => void,
    delay = 500,
): [(arg0?: GenericType) => void, () => boolean] {
    const handle = useRef(null);

    const reset = () => {
        if (handle.current) {
            clearTimeout(handle.current);
            handle.current = null;
            return true;
        }

        return false;
    };

    const start = (arg0?: GenericType) => {
        reset();

        handle.current = setTimeout(() => {
            handle.current = null;
            callback(arg0);
        }, delay);
    };

    return [start, reset];
}

export default useDebounce;
