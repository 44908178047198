import React from 'react';
import Classnames from 'classnames';
import { ClickableCard } from '../cards';
import './tile-clickable-card.scss';

interface TileClickableCardProps {
    contrastBackground?: boolean;
    isDisabled?: boolean;
    onClick: () => void;
    testId?: string;
}

export const TileClickableCard: React.FC<TileClickableCardProps> = ({
    children,
    contrastBackground,
    isDisabled,
    onClick,
    testId = 'tile-clickable-card',
}): React.ReactElement => {
    const classes = Classnames('tile-clickable-card', { 'tile-clickable-card--contrast': contrastBackground });

    return (
        <ClickableCard
            onClick={onClick}
            isDisabled={isDisabled}
            className={classes}
            testId={testId}
            disableTracking={true}
        >
            {children}
        </ClickableCard>
    );
};
