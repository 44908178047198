import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notation } from '../../components/common';
import type { RootState } from '../widget/widget';
import { sharedAPI } from '../../utils/services/api';

// Define a type for the slice state
export interface RewardActionsSlice {
    earnActions?: EarnAction[];
    howToEarn?: Notation[];
}

export interface EarnAction {
    title: string;
    description: string;
    icon: string;
}

// Define the initial state using that type
export const rewardActionsInitialState: RewardActionsSlice = {};

export const getHowToEarnActions = createAsyncThunk(
    'rewardActions/getHowToEarnActions',
    async (_, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/somewhere/giveme/earnactions`,
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getEarnActions = createAsyncThunk(
    'rewardActions/getEarnActions',
    async (_, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/somewhere/giveme/actions`,
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const rewardActionsSlice = createSlice({
    initialState: rewardActionsInitialState,
    name: 'rewardActions',
    extraReducers: (builder) => {
        builder.addCase(getHowToEarnActions.fulfilled, (state, action: PayloadAction<Notation[]>) => {
            state.howToEarn = action.payload;
        });
        builder.addCase(getEarnActions.fulfilled, (state, action: PayloadAction<EarnAction[]>) => {
            state.earnActions = action.payload;
        });
    },
    reducers: {
        resetSDKParams(_state) {
            _state = {};
        },
    },
});

export const { resetSDKParams } = rewardActionsSlice.actions;

const selectRewardState = ({ rewardActions }: RootState): RewardActionsSlice => rewardActions;

export const getRewardEarnActions = createSelector(selectRewardState, ({ earnActions }) => earnActions);

export const getHowToEarnACtions = createSelector(selectRewardState, ({ howToEarn }) => howToEarn);

export default rewardActionsSlice.reducer;
