import React from 'react';
import { Brand } from '../../../../../store/slices/wallet-slice';
import { constants } from '../../../../../utils/constants';
import { OfferName } from '../../../../../modules/widget/components/offers/offer-gift-card/common/offer-name';
import { RoundImage } from '../../../../common/ui-widgets';
import { RoundImageSizes } from '../../../../../store/slices/common-slice';
import { SearchResultPresenterProps } from './object-presenter-factory';

interface BrandPresenterProps extends SearchResultPresenterProps<Brand> {
    item: Brand;
}

export const BrandPresenter: React.FC<BrandPresenterProps> = ({ item }): React.ReactElement => {
    return (
        <div className="search-widget__brand-wrapper">
            <RoundImage
                imageUrl={item.logomarkUrl || constants.defaultRoundImageUrl}
                imageAltText={`${item.brandName} logo`}
                size={RoundImageSizes.THREE}
            />
            <OfferName rows={1} name={item.brandName} />
        </div>
    );
};
