import { AsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/widget/widget';
import { useEffect, useMemo } from 'react';

type KeyPairList = { [K: string]: unknown };
export type AsyncLoaderSuccessHandler<GenericFlags = KeyPairList> = (
    result: any,
    flags: GenericFlags,
    promise: Promise<any>,
) => void;

export type AsyncLoaderFailureHandler<GenericProps = KeyPairList, GenericFlags = KeyPairList> = (
    error: any,
    props: GenericProps,
    flags: GenericFlags,
    promise: Promise<any>,
) => void;

export type AsyncLoaderAbortHandler = () => void;

type LoadingDispatch<GenericProps, GenericFlags> = (props?: GenericProps, flags?: GenericFlags) => Promise<void>;

interface useAsyncLoaderProps<GenericProps, GenericFlags> {
    loader: AsyncThunk<any, GenericProps, any>;
    retryCount?: number;
    onLoadSuccess?: AsyncLoaderSuccessHandler<GenericFlags>;
    onLoadFail?: AsyncLoaderFailureHandler<GenericProps, GenericFlags>;
    onLoadAbort?: AsyncLoaderAbortHandler;
}

export function useAsyncLoader<GenericProps = KeyPairList, GenericFlags = KeyPairList>({
    loader,
    retryCount = 5,
    onLoadSuccess,
    onLoadFail,
    onLoadAbort,
}: useAsyncLoaderProps<GenericProps, GenericFlags>): LoadingDispatch<GenericProps, GenericFlags> {
    const controller = useMemo(() => {
        return new AbortController();
    }, []);

    const signal = useMemo(() => {
        return controller.signal;
    }, []);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        return () => {
            controller.abort('AsyncLoader unmounted');
        };
    }, []);

    const loadingDispatch: LoadingDispatch<GenericProps, GenericFlags> = (props, flags): Promise<any> => {
        let tryCount = 1;

        return new Promise(async (resolver, rejecter) => {
            while (tryCount <= retryCount) {
                try {
                    const tryPromise = dispatch(loader(props));

                    signal.addEventListener('abort', () => {
                        tryPromise.abort();
                    });

                    const result = await tryPromise.unwrap();
                    onLoadSuccess?.call(null, result, flags);
                    resolver(result);
                    break;
                } catch (err) {
                    if (err.name === 'AbortError') {
                        onLoadAbort?.call(null);
                        resolver(null);
                        break;
                    }

                    if (err.response?.status === 429) {
                        onLoadFail?.call(null, err, props, flags);
                        rejecter(err);
                        break;
                    }

                    if (tryCount < retryCount) {
                        tryCount++;
                        continue;
                    }

                    onLoadFail?.call(null, err, props, flags);
                    rejecter(err);
                    break;
                }
            }
        });
    };

    return loadingDispatch;
}
