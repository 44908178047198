import { isElement } from 'lodash';
import { delayedPromise, isHTMLElement } from '../../utils';

export enum IntersectionDirection {
    NONE = 'none',
    UP = 'up',
    DOWN = 'down',
}

export interface onListTopCallbackArgs {
    evt: WheelEvent;
    resetHandlers: () => void;
}

export type OnListTopCallback = (args: onListTopCallbackArgs) => void;

export interface useIntersectionObserverResult {
    observer: IntersectionObserver;
    setTopElement: (element: Element) => void;
    topElement: Element;
    trackElement: TrackElement;
    resetHandlers: () => void;
    trackedHandlers: TrackedHandlerList;
}

export type QueryCallback = (entry: IntersectionObserverEntry, tracking_id: string) => boolean;

export interface TrackedQuery {
    query: string;
    callback?: QueryCallback;
}

export class TrackedHandler {
    private observer: IntersectionObserver;
    private topElement: Element;
    private query: string;
    private callback: QueryCallback;
    private element: Element;
    private enabled: boolean;

    constructor(observer: IntersectionObserver, topElement: Element, trackedQuery: TrackedQuery) {
        this.observer = observer;
        this.topElement = topElement;
        this.query = trackedQuery.query;
        this.callback = trackedQuery.callback;
        this.enabled = true;

        this.findElement();
    }

    private async findElement() {
        do {
            this.element = this.topElement.querySelector(this.query);

            if (this.element) {
                break;
            }

            await delayedPromise(250);
        } while (this.enabled);

        if (this.element == null || !isElement(this.element)) {
            return; // either no element was found, or the element found is not observable
        }

        if (isHTMLElement(this.element)) {
            this.element.dataset.tracking_id = this.query;
        }

        this.observer.observe(this.element);
    }

    handler(observer: IntersectionObserver, entry: IntersectionObserverEntry) {
        if (
            observer === this.observer &&
            this.topElement.contains(this.element) &&
            this.element.isSameNode(entry.target)
        ) {
            const tracking_id = isHTMLElement(this.element) ? this.element.dataset.tracking_id : this.query;

            if (this.callback) {
                if (this.callback(entry, tracking_id)) {
                    this.unobserve();
                }
            }
        }

        return this;
    }

    unobserve() {
        if (this.observer && this.element) {
            this.observer.unobserve(this.element);
        }

        this.element = null;
        this.enabled = false;
        return this;
    }

    observe() {
        this.unobserve();
        this.enabled = true;
        this.findElement();
        return this;
    }
}

export type TrackedHandlerList = { [k: string]: TrackedHandler };

export type TrackElement = (query: string, callback?: QueryCallback) => void;

export interface useObservedTrackerResult {
    trackElement: TrackElement;
    resetHandlers: () => void;
    trackedHandlers: TrackedHandlerList;
}
