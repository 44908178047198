import { isEqual } from 'lodash';

// Use this for setting state where the newstate might trigger a rerender even though it's technically
// equal to the oldstate.  This can happen with some object states, for instance.  It should be as
// simple as:
//
//    setState(safeSetter(newValue));
//
export const safeSetter = <DataType = any>(newValue: DataType): ((oldData: DataType) => DataType) => {
    return (oldValue) => {
        if (isEqual(oldValue, newValue)) {
            return oldValue;
        }

        return newValue;
    };
};
