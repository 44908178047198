import React from 'react';
import { useAppSelector } from '../../../../hooks';
import {
    selectActiveOfferFilters,
    PrizeoutCategory,
    selectOfferCategories,
    toggleActiveOfferFilter,
} from '../../../../store/slices/offers-slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/widget/widget';
import { Pill } from '../common/pill';
import { GenericModuleLayout } from '../../../../modules/common/generic-module-layout/generic-module-layout';

import './filter-display.scss';

export const FilterDisplay: React.FC = (): React.ReactElement => {
    const availableCategories = useAppSelector(selectOfferCategories);
    const activeFilterCategories = useAppSelector(selectActiveOfferFilters);
    const dispatch = useDispatch<AppDispatch>();
    const toggleFilter = (category: PrizeoutCategory) => {
        dispatch(toggleActiveOfferFilter(category));
    };

    const renderCategoryList = (availableCategories: PrizeoutCategory[]) => {
        return availableCategories
            .filter((category) =>
                activeFilterCategories.find((activeCategory) => activeCategory.category === category.category),
            )
            .map(({ category, unicode }) => (
                <Pill
                    key={category}
                    isActive
                    isDisplayedOnDashboard
                    name={category}
                    onClose={() =>
                        toggleFilter({
                            category,
                            unicode,
                        })
                    }
                    testId={`${category}-testId`}
                    unicode={unicode}
                />
            ));
    };

    return (
        <>
            {activeFilterCategories.length > 0 && (
                <GenericModuleLayout noVerticalMargin={true}>
                    <div className="filter-display">
                        <ul className="filter-display__list no-scrollbars">
                            {renderCategoryList(availableCategories)}
                        </ul>
                    </div>
                </GenericModuleLayout>
            )}
        </>
    );
};
