import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../common';
import { CashbackEarningsTracker, EarningsEventGroups, EarningsInsight } from './earnings-types';

export const earningsApiSlice = createApi({
    reducerPath: 'earningsApi',
    baseQuery,
    tagTypes: [EarningsEventGroups.EARNINGS_CASHBACK, EarningsEventGroups.EARNINGS_INSIGHTS],
    endpoints: (builder) => ({
        getCashbackEarningsTracker: builder.query<CashbackEarningsTracker[], void>({
            query: () => 'cashback/earnings/tracker',
            providesTags: [EarningsEventGroups.EARNINGS_CASHBACK],
        }),
        getEarningsInsights: builder.query<EarningsInsight[], void>({
            query: () => 'cashback/earnings/insights',
            providesTags: [EarningsEventGroups.EARNINGS_CASHBACK],
        }),
    }),
});

export const { useGetCashbackEarningsTrackerQuery, useGetEarningsInsightsQuery } = earningsApiSlice;
