import React from 'react';
import { ButtonColor } from '../buttons';
import { GenericModal } from './generic-modal';

interface ConfirmationModalProps {
    cancelButtonText?: string;
    cancelColor?: ButtonColor;
    cancelOnClick: () => void;
    cancelIsLoading?: boolean;
    cancelIsDisabled?: boolean;
    cancelTestId?: string;
    confirmButtonText?: string;
    confirmColor?: ButtonColor;
    confirmOnClick: () => void;
    confirmIsLoading?: boolean;
    confirmIsDisabled?: boolean;
    confirmTestId?: string;
    headline: string;
    iconClasses?: string;
    isConfirmationModalOpen: boolean;
    subheadline?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    cancelButtonText = 'Cancel',
    cancelColor,
    cancelOnClick,
    cancelIsLoading,
    cancelIsDisabled,
    cancelTestId = 'confirmation-modal-cancel',
    confirmButtonText = 'Confirm',
    confirmColor,
    confirmIsLoading,
    confirmIsDisabled,
    confirmOnClick,
    confirmTestId = 'confirmation-modal-confirm',
    headline,
    iconClasses = `fak fa-prizeout-alert-circle`,
    isConfirmationModalOpen,
    subheadline,
}): React.ReactElement => {
    return (
        <>
            <GenericModal
                ariaDescribedById={subheadline && `confirmation-modal-description`}
                ariaLabelledById="confirmation-modal-headline"
                ariaLabel="Confirm or cancel a decision"
                hasExitButton={true}
                hasIcon={true}
                headline={headline}
                iconClasses={iconClasses}
                isOpen={isConfirmationModalOpen}
                onClose={cancelOnClick}
                portalClassName="confirmation-modal"
                primaryButtonText={confirmButtonText}
                primaryButtonColor={confirmColor}
                primaryOnClick={confirmOnClick}
                primaryTestId={confirmTestId}
                primaryIsDisabled={confirmIsDisabled}
                primaryIsLoading={confirmIsLoading}
                secondaryButtonText={cancelButtonText}
                secondaryButtonColor={cancelColor}
                secondaryIsDisabled={cancelIsDisabled}
                secondaryIsLoading={cancelIsLoading}
                secondaryOnClick={cancelOnClick}
                secondaryTestId={cancelTestId}
                subheadline={subheadline}
                testId="confirmation-modal"
            />
        </>
    );
};
