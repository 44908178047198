import React, { useEffect } from 'react';
import Classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/widget/widget';
import { PrizeoutOffer } from '../../../store/slices/offers-slice';
import { setActiveOffer, setCheckoutPanelOpen } from '../../../store/slices/checkout-slice';
import { selectIsMobileLandscape } from '../../../store/slices/common-slice';
import { OffersHeader } from '../../../modules/widget/components/offers/offers-header/offers-header';
import { SidebarArrow } from '../buttons';
import { useAppSelector } from '../../../hooks';
import { VerticalOffersDisplay } from '../../../modules/widget/components/offers/vertical-offers-display/vertical-offers-display';
import useTransition from 'react-transition-state';
import { TRANSITION_STANDARD_DELAY } from '../../../utils/types';
import { OffersHeaderModuleLayout } from '../../../modules/common/offers-header-module-layout/offers-header-module-layout';
import { GenericModuleLayout } from '../../../modules/common/generic-module-layout/generic-module-layout';
import './full-screen-offers-panel.scss';

interface FullScreenOffersPanelProps {
    headerInfo: {
        title: string;
        subtitle: string | null;
        tag: string | null;
    };
    isOpen: boolean;
    offers: PrizeoutOffer[];
    onClose: () => void;
}

export const FullScreenOffersPanel: React.FC<FullScreenOffersPanelProps> = ({
    headerInfo,
    isOpen,
    offers,
    onClose,
}): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);
    const [{ status }, toggleTransition] = useTransition({
        timeout: TRANSITION_STANDARD_DELAY,
        preEnter: true,
        preExit: true,
    });

    const classes: string = Classnames('full-screen-offers-panel z-index-full-panel', {
        [`full-screen-offers-panel--${status}`]: status,
    });

    const { title, subtitle, tag } = headerInfo;

    const offerClickHandler = (offer: PrizeoutOffer) => {
        dispatch(setActiveOffer(offer));

        if (isMobileLandscape) {
            dispatch(setCheckoutPanelOpen());
        }
    };

    useEffect(() => {
        toggleTransition(isOpen);
    }, [isOpen]);

    return (
        <>
            <div data-testid="full-screen-offers-panel" className={classes}>
                <OffersHeaderModuleLayout>
                    <div className="full-screen-offers-panel__header">
                        <SidebarArrow onClick={() => onClose()} />
                        <OffersHeader title={title} subtitle={subtitle} tag={tag} />
                    </div>
                </OffersHeaderModuleLayout>
                <GenericModuleLayout>
                    <VerticalOffersDisplay offers={offers} onClick={offerClickHandler} />
                </GenericModuleLayout>
            </div>
        </>
    );
};
