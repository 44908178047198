import React from 'react';
import { CashbackOnboardingStep } from './cashback-onboarding-step';
import { CashbackLogoMarks } from './cashback-logomarks';
import { useNumberSequence } from '../../../hooks';
import './cashback-onboarding-steps.scss';

export const CashbackOnboardingSteps: React.FC = (): React.ReactElement => {
    const stepsKey = useNumberSequence();
    const steps = [
        {
            title: 'Browse with confidence',
            description: 'Buy digital gift cards for your daily shopping',
            child: <CashbackLogoMarks />,
        },
        {
            title: 'Pay seamlessly',
            description: 'Your accounts are already linked',
        },
        {
            title: 'Get cashback',
            description: 'Transfer it to your account in seconds or use it to buy more digital gift cards',
        },
        {
            title: 'Use and repeat',
            description: 'Use your digital gift card online or in-store. Keep getting cashback!',
        },
    ];

    return (
        <>
            <ol className="cashback-onboarding-steps">
                {steps.map((step) => (
                    <CashbackOnboardingStep key={stepsKey()} title={step.title} description={step.description}>
                        {step.child}
                    </CashbackOnboardingStep>
                ))}
            </ol>
        </>
    );
};
