import React from 'react';
import { ToolTip, ToolTipDirectionTypes, ToolTipTriggerTypes } from '../../../../../components/common';

import './partner-portal-tool-tip.scss';

interface PartnerPortalToolTipProps {
    content: string;
    modalHeadline?: string;
    tipTrigger?: ToolTipTriggerTypes;
    tipDirection?: ToolTipDirectionTypes;
}

export const PartnerPortalToolTip: React.FC<PartnerPortalToolTipProps> = ({
    content,
    modalHeadline,
    tipTrigger = ToolTipTriggerTypes.HOVER,
    tipDirection = ToolTipDirectionTypes.UP,
}): React.ReactElement => {
    return (
        <div className="partner-portal-tool-tip">
            <ToolTip
                content={content}
                modalHeadline={modalHeadline}
                tipTrigger={tipTrigger}
                tipDirection={tipDirection}
            />
        </div>
    );
};
