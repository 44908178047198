import React from 'react';
import { uuid_v4 } from '../../../../utils';
import { Button, ExternalLink, ExternalLinkDisplayTypes } from '../../../common';

import './sidebar-navigation.scss';

export interface SidebarNavigationProps {
    externalLink?: string;
    icon: string;
    onClick?: () => void;
    text: string;
    visible: boolean;
}

export interface SidebarNavigationViewProps {
    navigation: SidebarNavigationProps[];
}

export const SidebarNavigation: React.FC<SidebarNavigationViewProps> = ({ navigation }): React.ReactElement => {
    const determineHTML = (nav: any, index: number) => {
        if (nav.externalLink) {
            return (
                <ExternalLink
                    ariaLabel={nav.text}
                    className="sidebar-navigation__link"
                    displayType={ExternalLinkDisplayTypes.LINK}
                    label={nav.text}
                    testId={`nav-button-${index}`}
                    url={nav.externalLink}
                />
            );
        } else {
            return (
                <Button
                    ariaLabel={nav.text}
                    className="sidebar-navigation__link"
                    isElement
                    onClick={() => nav.onClick()}
                    testId={`nav-button-${index}`}
                    text={nav.text}
                />
            );
        }
    };

    return (
        <nav id="sidebar-navigation" className="sidebar-navigation">
            <ul className="sidebar-navigation__list">
                {navigation &&
                    navigation.map((nav, index) => {
                        if (nav.visible) {
                            return (
                                <li key={uuid_v4()} className="sidebar-navigation__item">
                                    <span className="sidebar-navigation__icon">
                                        <i className={nav.icon}></i>
                                    </span>
                                    {determineHTML(nav, index)}
                                </li>
                            );
                        }
                    })}
            </ul>
        </nav>
    );
};
