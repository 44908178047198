import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { PartnerPortalRootState } from '../../partner-portal';

export interface PartnerPortalState {
    dashboardType: DashboardType;
    pageIsNotDashboard: boolean;
}

export enum DashboardType {
    PARTNER_REWARDS = 'partner-rewards',
    PARTNER_USERS = 'partner-users',
    PARTNER_MEMBER_PAYOUTS = 'partner-member-payouts',
    FUNDING_TRANSACTIONS = 'funding-transactions',
    FUNDING_PAYMENTS = 'funding-payments',
}

export const partnerPortalInitialState: PartnerPortalState = {
    dashboardType: DashboardType.PARTNER_REWARDS,
    pageIsNotDashboard: false,
};

export const partnerPortalSlice = createSlice({
    initialState: partnerPortalInitialState,
    name: 'partnerPortal',
    reducers: {
        setDashboardType(state, action: PayloadAction<DashboardType>) {
            state.dashboardType = action.payload;
        },
        setPageIsNotDashboard(state, action: PayloadAction<boolean>) {
            state.pageIsNotDashboard = action.payload;
        },
    },
});

export const { setDashboardType, setPageIsNotDashboard } = partnerPortalSlice.actions;

export const selectPartnerPortalState = ({ partnerPortal }: PartnerPortalRootState): PartnerPortalState =>
    partnerPortal;

export const selectDashboardType = createSelector(selectPartnerPortalState, ({ dashboardType }) => dashboardType);
export const selectPageIsNotDashboard = createSelector(
    selectPartnerPortalState,
    ({ pageIsNotDashboard }) => pageIsNotDashboard,
);

export default partnerPortalSlice.reducer;
