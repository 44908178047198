import React from 'react';
import Classnames from 'classnames';

import './alert-with-icon-circle.scss';

export enum AlertWithIconCircleTypes {
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
}

interface AlertWithIconCircleProps {
    status: AlertWithIconCircleTypes;
}

export const AlertWithIconCircle: React.FC<AlertWithIconCircleProps> = ({ status }): React.ReactElement => {
    const classes = Classnames('alert-with-icon-circle', {
        [`alert-with-icon-circle--${status}`]: status,
    });

    let iconClass: string;

    switch (status) {
        case AlertWithIconCircleTypes.ERROR:
            iconClass = 'fa-circle-exclamation';
            break;

        case AlertWithIconCircleTypes.SUCCESS:
            iconClass = 'fa-circle-check';
            break;

        case AlertWithIconCircleTypes.WARNING:
            iconClass = 'fa-circle-exclamation';
            break;

        default:
            iconClass = 'fa-circle-exclamation';
    }

    return (
        <div data-testid="alert-with-icon-circle" className={classes}>
            <i
                data-testid="alert-with-icon-circle-icon"
                className={`alert-with-icon-circle__icon fa-regular ${iconClass}`}
            ></i>
        </div>
    );
};
