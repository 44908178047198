import React from 'react';
import DOMPurify from 'dompurify';
import { determineValueContents } from '../../../utils/helpers';
import { getObjectCSS } from './text-or-html-helper';
import { Buffer } from 'buffer';

import './text-or-html.scss';

const OBJECT_STYLE = getObjectCSS();

interface TextOrHtmlProps {
    inputText: string;
}

export const TextOrHTML: React.FC<TextOrHtmlProps> = ({ inputText }): React.ReactElement => {
    const textType = determineValueContents(inputText);

    const htmlParts = [OBJECT_STYLE];
    switch (textType) {
        case 'string':
            htmlParts.push(`<p>${inputText}</p>`);
            break;

        case 'html':
            htmlParts.push(DOMPurify.sanitize(inputText));
            break;

        default:
            break;
    }

    const htmlBuffer = Buffer.from(htmlParts.join(' ').replace(/\n/g, ' '));
    const html = htmlBuffer.toString('base64');

    return (
        <object
            className="text-or-html-object"
            data-testid="partner-object"
            type="text/html"
            data={`data:text/html;base64,${html}`}
        >
            Sorry, something seems to have gone wrong
        </object>
    );
};
