export enum CheckoutActionNames {
    INITIALIZE = 'initialize',
    CLOSE_ERROR_MODAL = 'close-error-modal',
    HAS_INPUT_ERROR = 'has-input-error',
    OPEN_ERROR_MODAL = 'open-error-modal',
    START_CHECKOUT_LOADING = 'start-checkout-loading',
    START_GIFT_CARD_LOADING = 'start-gift-card-loading',
    START_PROCESSING_RULES_LOADING = 'start-processing-rules-loading',
    STOP_CHECKOUT_LOADING = 'stop-checkout-loading',
    STOP_GIFT_CARD_LOADING = 'stop-gift-card-loading',
    STOP_PROCESSING_RULES_LOADING = 'stop-processing-rules-loading',
}

export interface CheckoutAction {
    type: CheckoutActionNames;
    payload?: {
        hasInputError?: boolean;
        failHeadline?: string;
        failMessage?: string;
    };
}

export interface CheckoutState {
    hasInputError: boolean;
    isCheckoutLoading: boolean;
    isErrorModalOpen: boolean;
    isGiftCardLoading: boolean;
    isProcessingRulesLoading: boolean;
    shouldShowContent: boolean;
    failHeadline: string;
    failMessage: string;
}

export const checkoutInitialState: CheckoutState = {
    hasInputError: false,
    isCheckoutLoading: false,
    isErrorModalOpen: false,
    isGiftCardLoading: false,
    isProcessingRulesLoading: false,
    shouldShowContent: true,
    failHeadline: '',
    failMessage: '',
};

export function checkoutReducer(state: CheckoutState, action: CheckoutAction) {
    switch (action.type) {
        case CheckoutActionNames.INITIALIZE:
            return {
                ...state,
                hasInputError: false,
                isCheckoutLoading: false,
                isErrorModalOpen: false,
                isGiftCardLoading: false,
                isProcessingRulesLoading: false,
                shouldShowContent: true,
                failHeadline: '',
                failMessage: '',
            };

        case CheckoutActionNames.CLOSE_ERROR_MODAL:
            return {
                ...state,
                isCheckoutLoading: false,
                isErrorModalOpen: false,
                isGiftCardLoading: false,
                isProcessingRulesLoading: false,
                shouldShowContent: true,
                failHeadline: '',
                failMessage: '',
            };

        case CheckoutActionNames.HAS_INPUT_ERROR:
            return {
                ...state,
                hasInputError: action.payload?.hasInputError,
            };

        case CheckoutActionNames.OPEN_ERROR_MODAL:
            return {
                ...state,
                isCheckoutLoading: false,
                isErrorModalOpen: true,
                isGiftCardLoading: false,
                isProcessingRulesLoading: false,
                shouldShowContent: true,
                failHeadline: action.payload?.failHeadline,
                failMessage: action.payload?.failMessage,
            };

        case CheckoutActionNames.START_CHECKOUT_LOADING:
            return {
                ...state,
                isCheckoutLoading: true,
                isErrorModalOpen: false,
                isGiftCardLoading: false,
                isProcessingRulesLoading: false,
                shouldShowContent: false,
            };

        case CheckoutActionNames.START_GIFT_CARD_LOADING:
            return {
                ...state,
                isCheckoutLoading: false,
                isErrorModalOpen: false,
                isGiftCardLoading: true,
                isProcessingRulesLoading: false,
                shouldShowContent: false,
            };

        case CheckoutActionNames.START_PROCESSING_RULES_LOADING:
            return {
                ...state,
                isCheckoutLoading: false,
                isErrorModalOpen: false,
                isGiftCardLoading: false,
                isProcessingRulesLoading: true,
                shouldShowContent: false,
            };

        case CheckoutActionNames.STOP_CHECKOUT_LOADING:
            return {
                ...state,
                isCheckoutLoading: false,
                isErrorModalOpen: false,
                isGiftCardLoading: false,
                isProcessingRulesLoading: false,
                shouldShowContent: true,
            };

        case CheckoutActionNames.STOP_GIFT_CARD_LOADING:
            return {
                ...state,
                isCheckoutLoading: false,
                isErrorModalOpen: false,
                isGiftCardLoading: false,
                isProcessingRulesLoading: false,
                shouldShowContent: true,
            };

        case CheckoutActionNames.STOP_PROCESSING_RULES_LOADING:
            return {
                ...state,
                isCheckoutLoading: false,
                isErrorModalOpen: false,
                isGiftCardLoading: false,
                isProcessingRulesLoading: false,
                shouldShowContent: true,
            };
    }
}
