import { createSelector } from '@reduxjs/toolkit';
import { crmApiSlice } from './crm-api-slice';
import { CRMTemplateLayout } from './crm-types';

const selectCRMDataForAllPagesResult = crmApiSlice.endpoints.getCRMDataForAllPages.select();

export const selectCRMDataForAllPages = createSelector(selectCRMDataForAllPagesResult, ({ data }) => data);

export const selectIsBillboardCRMLayout = createSelector(
    selectCRMDataForAllPagesResult,
    ({ data }) =>
        data?.mini_widget?.full?.data?.layout === CRMTemplateLayout.BILLBOARD || CRMTemplateLayout.CLASSIC_BILLBOARD,
);
