import { routes } from '../../../utils';

export enum EarningsEventGroups {
    EARNINGS_CASHBACK = 'CashbackEarningsData',
    EARNINGS_INSIGHTS = 'InsightsEarningsData',
}

export interface CashbackEarningsTracker {
    actualSpendEarningsInCents: number;
    estimatedSpendEarningsInCents: number;
    actualActionEarningsInCents: number;
    estimatedActionEarningsInCents: number;
    earningCurrency: string;
    earningMonth: string;
}

export enum EarningsInsightImageType {
    CATEGORY = 'category',
    REWARD = 'reward',
    URL = 'url',
    FA = 'fa-icon',
    IMAGE = 'image',
}

export enum EarningsInsightCashbackType {
    VALUE = '$',
    PERCENT = '%',
}

export interface EarningsInsight {
    imageStr: string;
    imageType: EarningsInsightImageType;
    insightText?: string;
    insightType: string;
    selectMerchant?: string;
    ctaText?: string;
    ctaUrl?: routes;
    cashbackType?: EarningsInsightCashbackType;
    cashbackAmount?: string;
}
