import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../widget/widget';

export interface MessagesSlice {
    [messageName: string]: string;
}

export const messagesInitialState: MessagesSlice = {};

export interface ServerMessagePayload {
    messageName: string;
    messageText: string;
}

export const messagesSlice = createSlice({
    initialState: messagesInitialState,
    name: 'messages',
    reducers: {
        setServerMessage(state, action: PayloadAction<ServerMessagePayload>) {
            state[action.payload.messageName] = action.payload.messageText;
        },
        clearServerMessage(state, action: PayloadAction<string>) {
            state[action.payload] = null;
        },
    },
});

export const { setServerMessage, clearServerMessage } = messagesSlice.actions;

const selectMessagesState = ({ messages }: RootState): MessagesSlice => messages;

export const selectGetServerMessage = createSelector(selectMessagesState, (messages) => {
    return (messageName: string) => messages[messageName];
});

export default messagesSlice.reducer;
