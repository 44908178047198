import React from 'react';
import { SDCOptionProps, SDCOptionWrapper } from '../sdc-option-wrapper';

import './sdc-text-option.scss';

const TextOption: React.FC<SDCOptionProps<null>> = ({ option }): React.ReactElement => {
    return <p className="sdc-text-option">{option.label}</p>;
};

export const SDCTextOption = SDCOptionWrapper(TextOption);
