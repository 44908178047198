import { Dispatch, useReducer } from 'react';
import {
    InfiniteScrollState,
    InfiniteScrollStateAction,
    infiniteScrollStateReducer,
    INITIAL_INFINITE_SCROLL_STATE,
} from './infinite-scroll-reducer';

export type InfiniteScrollStateManager<GenericObject> = [
    InfiniteScrollState<GenericObject>,
    Dispatch<InfiniteScrollStateAction<GenericObject>>,
];

export function useInfiniteScrollState<GenericObject>(): InfiniteScrollStateManager<GenericObject> {
    const [localState, dispatchLocalState] = useReducer(
        infiniteScrollStateReducer,
        INITIAL_INFINITE_SCROLL_STATE as InfiniteScrollState<GenericObject>,
    );

    return [localState as InfiniteScrollState<GenericObject>, dispatchLocalState];
}
