import React, { useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { selectCurrentBankAccount } from '../../../store/slices/partner-config-slice';
import { DividerRowWithContent } from '../../common/ui-widgets';
import { selectIsGiftingMode } from '../../../store/slices/sdk-slice';
import { BankAccountSelectorModal } from '../../common/modals/bank-account-selector-modal';

import './bank-account-selector.scss';

export const BankAccountSelector: React.FC = (): React.ReactElement => {
    const currentBankAccount = useAppSelector(selectCurrentBankAccount);
    const isGiftingMode = useAppSelector(selectIsGiftingMode);

    const [isBankAccountSelectorModalOpen, setIsBankAccountSelectorModalOpen] = useState(false);

    const openBankAccountSelectorModal = () => {
        setIsBankAccountSelectorModalOpen(true);
    };

    const closeBankAccountSelectorModal = () => {
        setIsBankAccountSelectorModalOpen(false);
    };

    const renderLabel = () => {
        if (currentBankAccount?.masked_account_number) {
            return `${currentBankAccount.masked_account_number}`;
        }

        return `**${currentBankAccount?.last4}`;
    };

    const renderContent = () => {
        if (isGiftingMode) {
            return (
                <DividerRowWithContent
                    name="select-bank-account"
                    shouldExtendEdges={true}
                    onClick={() => openBankAccountSelectorModal()}
                >
                    <div className="grid grid--y-center grid--stretch-left">
                        {currentBankAccount ? (
                            <div className="bank-account-selector__labels">
                                <span className="bank-account-selector__label">{currentBankAccount?.name}</span>
                                <span className="bank-account-selector__label">{renderLabel()}</span>
                            </div>
                        ) : (
                            <span className="bank-account-selector__label bank-account-selector__label--secondary">
                                Select an account
                            </span>
                        )}
                        <i className="fak fa-prizeout-edit text-highlight"></i>
                    </div>
                </DividerRowWithContent>
            );
        }

        if (!currentBankAccount) {
            return <small>Select a bank account</small>;
        }

        return (
            <div className="bank-account-selector__labels">
                <small>{currentBankAccount.name}</small>
                <small>
                    {currentBankAccount?.masked_account_number
                        ? currentBankAccount.masked_account_number
                        : `**${currentBankAccount.last4}`}
                </small>
            </div>
        );
    };

    return (
        <>
            <div data-testid="bank-account-selector" className="bank-account-selector">
                {renderContent()}
            </div>

            <BankAccountSelectorModal isOpen={isBankAccountSelectorModalOpen} onClose={closeBankAccountSelectorModal} />
        </>
    );
};
