import React from 'react';
import Classnames from 'classnames';

import './supporting-text.scss';

interface SupportingTextProps {
    isCentered?: boolean;
    text: string;
}

export const SupportingText: React.FC<SupportingTextProps> = ({ isCentered, text }): React.ReactElement => {
    const classes: string = Classnames('supporting-text', { 'supporting-text--centered': isCentered });

    return (
        <p data-testid="supporting-text" className={classes}>
            {text}
        </p>
    );
};
