import React, { ReactNode, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CloseButton, CloseButtonPosition } from '../buttons';

import './pill-tabs.scss';

export interface PillTabsData {
    id: string;
    label: string;
    content: ReactNode;
}

interface PillTabsProps {
    tabs: PillTabsData[];
    closeOnClick?: () => void;
}

export const PillTabs: React.FC<PillTabsProps> = ({ tabs, closeOnClick }): React.ReactElement => {
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <div className="pill-tabs">
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <div className="pill-tabs__controls">
                    {closeOnClick && <CloseButton position={CloseButtonPosition.START} onClick={closeOnClick} />}
                    <TabList>
                        {tabs.map((tab) => (
                            <Tab data-testid={`tab-header-${tab.id}`} key={`tab-header-${tab.id}`}>
                                {tab.label}
                            </Tab>
                        ))}
                    </TabList>
                </div>

                {tabs.map((tab) => (
                    <TabPanel data-testid={`tab-content-${tab.id}`} key={`tab-content-${tab.id}`}>
                        {tab.content}
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    );
};
