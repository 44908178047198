import React from 'react';
import { TileArrowText } from './tile-arrow-text';
import { TileMediaAsset } from './tile-media-asset';
import './tile-no-cashback.scss';

interface TileNoCashbackProps {
    bannerText: string;
    img?: string;
    alt?: string;
    arrowText: string;
}

export const TileNoCashback: React.FC<TileNoCashbackProps> = ({ img, alt, arrowText, bannerText }) => {
    return (
        <div className="tile-no-cashback">
            <p className="tile-no-cashback__description">{bannerText}</p>
            <div className="tile-no-cashback__media-wrapper">
                <TileArrowText text={arrowText} />
                <TileMediaAsset imgSrc={img} alt={alt} />
            </div>
        </div>
    );
};
