import React from 'react';
import { AlertCallout, AlertCalloutMessageTypes, Button, ButtonColor, ButtonSize } from '../../../common';
import { ButtonTrackingTypes } from '../../../../utils/services/tracking';

import './inline-error.scss';

interface InlineErrorProps {
    title: string;
    text?: string;
    imgUrl?: string;
    imgAltText?: string;
    hasButton?: boolean;
    buttonText?: string;
    onClick?: () => void;
    trackingType?: ButtonTrackingTypes;
}

export const InlineError: React.FC<InlineErrorProps> = ({
    title,
    text,
    imgUrl = 'https://assets.prizeout.com/widget/error/auction-error.svg',
    imgAltText = '',
    hasButton,
    buttonText,
    onClick,
    trackingType,
}): React.ReactElement => {
    return (
        <div data-testid="inline-error" className="inline-error">
            <img src={imgUrl} className="inline-error__image" data-testid="inline-error-image" alt={imgAltText} />

            <h2 className="inline-error__title">{title}</h2>

            <AlertCallout hasBorder isInline message={text} type={AlertCalloutMessageTypes.ERROR} />

            {hasButton && (
                <Button
                    ariaLabel={buttonText}
                    color={ButtonColor.PRIMARY}
                    isInline={true}
                    size={ButtonSize.MEDIUM}
                    onClick={onClick}
                    testId="find-button"
                    text={buttonText}
                    trackingType={trackingType}
                />
            )}
        </div>
    );
};
