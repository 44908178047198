import React from 'react';
import Classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import './skeleton-loader-element-generic.scss';

export enum SkeletonLoaderWidth {
    ONE_QUARTER = 'quarter',
    ONE_THIRD = 'third',
    HALF = 'half',
    TWO_THIRDS = 'two-thirds',
    THREE_QUARTERS = 'three-quarters',
    FULL = 'full',
}

export enum SkeletonLoaderBorderRadius {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    PILL = 'pill',
    ZERO = 'zero',
}

interface SkeletonLoaderElementGenericProps {
    borderRadius?: SkeletonLoaderBorderRadius;
    height?: number; // Flexible based on design, number in pixels
    width?: SkeletonLoaderWidth;
}

export const SkeletonLoaderElementGeneric: React.FC<SkeletonLoaderElementGenericProps> = ({
    borderRadius = SkeletonLoaderBorderRadius.TERTIARY,
    height,
    width = SkeletonLoaderWidth.FULL,
}): React.ReactElement => {
    const classes = Classnames(
        'skeleton-loader-element-generic',
        { [`skeleton-loader-element-generic--width-${width}`]: width },
        { [`skeleton-loader-element-generic--border-radius-${borderRadius}`]: borderRadius },
    );

    return (
        <div data-testid="skeleton-loader-element-generic" className={classes}>
            <Skeleton height={height} inline={true} containerTestId="skeleton-loader-element-generic-container" />
        </div>
    );
};
