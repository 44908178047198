import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { sharedAPI } from '../../utils/services/api';
import { CBCERootState } from '../cashback';
import { selectCurrentDomain } from './cashback-chrome-plugin/cashback-chrome-plugin-offer-slice';
import {
    RuleProcessingStatus,
    RuleSet,
    RuleSetDefinition,
    RuleStatus,
} from '../../modules/cashback-browser-extension/content-scripts/auto-apply/rule-processing';
import { ClientDeviceCategory } from '../../utils';

export enum AutoFillRuleStepActions {
    CHECK_INPUT_VALUE = 'checkInputValue',
    CLICK_ELEMENT = 'clickElement',
    TYPE_TEXT = 'typeText',
    WAIT = 'wait',
    WAIT_FOR_ELEMENT = 'waitForElement',
}

export interface AutoFillRulesState {
    rulesetList: AutoFillRulesetList;
    ruleProcessingStatusList: RuleProcessingStatusList;
}

export const autoFillRulesInitialState: AutoFillRulesState = {
    rulesetList: {} as AutoFillRulesetList,
    ruleProcessingStatusList: {} as RuleProcessingStatusList,
};

export interface AutoFillRulesetList {
    [K: string]: RuleSetDefinition[];
}

interface RuleProcessingStatusList {
    [K: string]: RuleProcessingStatus;
}

interface AutoFillRulesetGetRequest {
    platform?: ClientDeviceCategory;
    url: string;
}

export interface AutoFillRulesetGetResult {
    url?: string;
    rulesetList?: RuleSetDefinition[];
}

interface AutoFillRulesetAddRequest {
    pageUrl: string;
    ruleset: RuleSet;
}

interface AutoFillRulesetDeleteRequest {
    autofillRuleId: string;
}

interface AutoFillSetResultAction {
    url: string;
    result: RuleProcessingStatus;
}

export const getAutoFillRuleset = createAsyncThunk(
    'browserExtension/getAutofillRuleset',
    async ({ platform, url }: AutoFillRulesetGetRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/browserExtension/autofillRules/${encodeURIComponent(url)}${
                    platform ? `?platform=${platform}` : ``
                }`,
                method: 'GET',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.message);
            }

            return {
                url: url,
                rulesetList: [...(results?.data || [])],
            } as AutoFillRulesetGetResult;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const addAutoFillRule = createAsyncThunk(
    'browserExtension/addAutofillRule',
    async (addRequest: AutoFillRulesetAddRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: addRequest,
                endpoint: '/browserExtension/autofillRules',
                method: 'POST',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.message);
            }

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const deleteAutoFillRule = createAsyncThunk(
    'browserExtension/deleteAutofillRule',
    async ({ autofillRuleId }: AutoFillRulesetDeleteRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/browserExtension/autofillRules/${autofillRuleId}`,
                method: 'DELETE',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.message);
            }

            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const autoFillRulesSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getAutoFillRuleset.fulfilled, (state, action: PayloadAction<AutoFillRulesetGetResult>) => {
            if (action.payload.url && action.payload.rulesetList) {
                state.rulesetList[action.payload.url] = action.payload.rulesetList;
            }
        });
        builder.addCase(addAutoFillRule.fulfilled, (state, action: PayloadAction<RuleSetDefinition>) => {
            if (state.rulesetList[action.payload.url]) {
                state.rulesetList[action.payload.url].push(action.payload);
            } else {
                state.rulesetList[action.payload.url] = [action.payload];
            }
        });
        builder.addCase(deleteAutoFillRule.fulfilled, (state, action: PayloadAction<RuleSetDefinition>) => {
            const deletedId = action.payload.autofillRuleId;
            const currentRules = state.rulesetList[action.payload.url];

            if (currentRules) {
                const newRules = currentRules.filter((rule) => {
                    return rule.autofillRuleId !== deletedId;
                });

                state.rulesetList[action.payload.url] = newRules;
            }
        });
    },
    initialState: autoFillRulesInitialState,
    name: 'auto-fill-rules',
    reducers: {
        setRuleProcessingStatus(state, action: PayloadAction<AutoFillSetResultAction>) {
            state.ruleProcessingStatusList[action.payload.url] = action.payload.result;
        },
        clearRuleProcessingState(state, action: PayloadAction<string>) {
            delete state.ruleProcessingStatusList[action.payload];
        },
    },
});

export const { setRuleProcessingStatus, clearRuleProcessingState } = autoFillRulesSlice.actions;

const selectAutoFillRulesState = ({ autoFillRules }: CBCERootState): AutoFillRulesState => autoFillRules;

const selectRulesetList = createSelector(selectAutoFillRulesState, ({ rulesetList }) => rulesetList);
export const selectRuleProcessingStatusList = createSelector(
    selectAutoFillRulesState,
    ({ ruleProcessingStatusList }) => ruleProcessingStatusList,
);

export const selectActiveRules = createSelector(
    selectCurrentDomain,
    selectRulesetList,
    (currentDomain, rulesetList) => {
        const result = rulesetList[currentDomain] || [];

        return result.filter((rule: RuleSetDefinition) => rule.status == RuleStatus.LIVE);
    },
);

export const selectRuleProcessingStatus = createSelector(
    selectCurrentDomain,
    selectRuleProcessingStatusList,
    (currentDomain, ruleProcessingStatusList) => ruleProcessingStatusList[currentDomain],
);

export default autoFillRulesSlice.reducer;
