import { getOffersDetailsData, OfferDetailRequest, PrizeoutOffer } from '../../store/slices/offers-slice';
import { useAsyncLoader } from '../use-async-loader';

export function useLoadOfferDetail() {
    const buildOfferDetailLoader = (onLoadSuccess: (offer: PrizeoutOffer) => void) => {
        const offersDetailLoader = useAsyncLoader<OfferDetailRequest>({
            loader: getOffersDetailsData,
            onLoadSuccess: (offer: PrizeoutOffer) => {
                onLoadSuccess(offer);
            },
        });

        return offersDetailLoader;
    };

    return {
        buildOfferDetailLoader,
    };
}

export default useLoadOfferDetail;
