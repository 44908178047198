import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { PaginatedData } from '../../../utils';
import { basePortalQuery } from '../common';
import {
    PortalEventGroups,
    AdminDashboardUsersQuery,
    AdminDashboardUser,
    AddAdminDashboardUserQuery,
    GenericSuccessResponse,
    AdminDashboardPartner,
    AdminDashboardPartnersQuery,
    SwitchDashboardUserLoginTypeQuery,
    SendPasswordResetOTAQuery,
    generatePasswordResetOTAQuery,
    generatePasswordResetOTAResponse,
    AddAdminDashboardPartnerQuery,
    dashboardUserPartnerAccountListQuery,
    dashboardUserPartnerAccount,
    CreateSandboxEnvQuery,
} from './admin-portal-types';

export const portalApiSlice = createApi({
    reducerPath: 'adminPortalApi',
    baseQuery: basePortalQuery,
    tagTypes: [
        PortalEventGroups.PortalDashboardUsersList,
        PortalEventGroups.PortalDashboardPartnersList,
        PortalEventGroups.AddPortalDashboardUser,
        PortalEventGroups.SwitchDashboardUserLoginType,
        PortalEventGroups.SendPasswordResetOTA,
        PortalEventGroups.generatePasswordResetOTA,
        PortalEventGroups.AddPortalDashboardPartner,
        PortalEventGroups.CreateSandboxEnv,
    ],
    endpoints: (builder) => ({
        getDashboardUsersList: builder.query<PaginatedData<AdminDashboardUser[]>, AdminDashboardUsersQuery>({
            query: (args: AdminDashboardUsersQuery) => ({
                url: '/admin/dashboardUser/list',
                params: args,
            }),
            providesTags: [PortalEventGroups.PortalDashboardUsersList],
        }),
        addDashboardUser: builder.mutation<GenericSuccessResponse, AddAdminDashboardUserQuery>({
            query: (args: AddAdminDashboardUserQuery) => ({
                url: '/admin/dashboardUser',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.AddPortalDashboardUser],
        }),
        getDashboardPartnersList: builder.query<PaginatedData<AdminDashboardPartner[]>, AdminDashboardPartnersQuery>({
            query: (args: AdminDashboardPartnersQuery) => ({
                url: '/admin/partnerList',
                params: args,
            }),
            providesTags: [PortalEventGroups.PortalDashboardPartnersList],
        }),
        switchDashboardUserLoginType: builder.mutation<AdminDashboardUser, SwitchDashboardUserLoginTypeQuery>({
            query: (args: SwitchDashboardUserLoginTypeQuery) => ({
                url: '/admin/dashboardUser/loginType',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.SwitchDashboardUserLoginType],
        }),
        SendPasswordResetOTA: builder.mutation<GenericSuccessResponse, SendPasswordResetOTAQuery>({
            query: (args: SendPasswordResetOTAQuery) => ({
                url: '/admin/dashboardUser/sendPasswordResetOTA',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.SendPasswordResetOTA],
        }),
        generatePasswordResetOTA: builder.mutation<generatePasswordResetOTAResponse, generatePasswordResetOTAQuery>({
            query: (args: generatePasswordResetOTAQuery) => ({
                url: '/admin/dashboardUser/generatePasswordResetOTA',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.generatePasswordResetOTA],
        }),
        // Partners the user has access to + perms
        getDashboardUserAccountList: builder.query<
            PaginatedData<dashboardUserPartnerAccount[]>,
            dashboardUserPartnerAccountListQuery
        >({
            query: (args: dashboardUserPartnerAccountListQuery) => ({
                url: '/admin/dashboardUser/accountList',
                params: args,
            }),
        }),
        addDashboardPartner: builder.mutation<GenericSuccessResponse, AddAdminDashboardPartnerQuery>({
            query: (args: AddAdminDashboardPartnerQuery) => ({
                url: '/admin/partner/masterAccount',
                method: 'POST',
                body: args,
            }),
        }),
        createSandboxEnv: builder.mutation<GenericSuccessResponse, CreateSandboxEnvQuery>({
            query: (args: CreateSandboxEnvQuery) => ({
                url: '/admin/partner/sandbox/createSandboxEnv',
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [PortalEventGroups.CreateSandboxEnv],
        }),
    }),
});

export const {
    useGetDashboardUsersListQuery,
    useAddDashboardUserMutation,
    useGetDashboardPartnersListQuery,
    useSendPasswordResetOTAMutation,
    useGetDashboardUserAccountListQuery,
    useGeneratePasswordResetOTAMutation,
    useSwitchDashboardUserLoginTypeMutation,
    useAddDashboardPartnerMutation,
    useCreateSandboxEnvMutation,
} = portalApiSlice;
