export enum CustomDenomsActionNames {
    INITIALIZE = 'initialize',
    UPDATE_AMOUNT_EARNED = 'update-amount-earned',
    USER_INPUTTED_COST = 'user-inputted-cost',
    USER_INPUTTED_VALUE = 'user-inputted-value',
    SET_FOCUSED_INPUT_COST = 'set-focused-input-cost',
    SET_FOCUSED_INPUT_VALUE = 'set-focused-input-value',
}

interface CustomDenomsAction {
    type: CustomDenomsActionNames;
    payload?: {
        currentAmountEarned?: string;
        currentDenom?: number;
    };
}

export interface CustomDenomsState {
    currentAmountEarned: string;
    currentDenom: number;
    isCostFocused: boolean;
    isValueFocused: boolean;
    shouldGetCustomDenomValuesViaCost: boolean;
    shouldGetCustomDenomValuesViaValue: boolean;
    shouldUpdateCostDisplay: boolean;
    shouldUpdateValueDisplay: boolean;
}

export const customDenomsInitialState: CustomDenomsState = {
    currentAmountEarned: '',
    currentDenom: null,
    isCostFocused: false,
    isValueFocused: false,
    shouldGetCustomDenomValuesViaCost: false,
    shouldGetCustomDenomValuesViaValue: false,
    shouldUpdateCostDisplay: false,
    shouldUpdateValueDisplay: false,
};

export function customDenomsReducer(state: CustomDenomsState, action: CustomDenomsAction) {
    switch (action.type) {
        case CustomDenomsActionNames.INITIALIZE:
            return {
                ...state,
                currentAmountEarned: null,
                currentDenom: null,
                isCostFocused: false,
                isValueFocused: false,
                shouldGetCustomDenomValuesViaCost: false,
                shouldGetCustomDenomValuesViaValue: false,
                shouldUpdateCostDisplay: false,
                shouldUpdateValueDisplay: false,
            };

        case CustomDenomsActionNames.UPDATE_AMOUNT_EARNED:
            return {
                ...state,
                currentAmountEarned: action.payload?.currentAmountEarned,
            };

        case CustomDenomsActionNames.USER_INPUTTED_COST:
            return {
                ...state,
                currentDenom: action.payload?.currentDenom,
                isCostFocused: true,
                isValueFocused: false,
                shouldGetCustomDenomValuesViaCost: true,
                shouldGetCustomDenomValuesViaValue: false,
                shouldUpdateCostDisplay: false,
                shouldUpdateValueDisplay: true,
            };

        case CustomDenomsActionNames.USER_INPUTTED_VALUE:
            return {
                ...state,
                currentDenom: action.payload?.currentDenom,
                isCostFocused: false,
                isValueFocused: true,
                shouldGetCustomDenomValuesViaCost: false,
                shouldGetCustomDenomValuesViaValue: true,
                shouldUpdateCostDisplay: true,
                shouldUpdateValueDisplay: false,
            };

        case CustomDenomsActionNames.SET_FOCUSED_INPUT_COST:
            return {
                ...state,
                isCostFocused: true,
                isValueFocused: false,
            };

        case CustomDenomsActionNames.SET_FOCUSED_INPUT_VALUE:
            return {
                ...state,
                isCostFocused: false,
                isValueFocused: true,
            };
    }
}
