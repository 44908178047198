import React from 'react';
import './tile-header.scss';

interface TileContainerHeaderProps {
    label: string;
}

export const TileContainerHeader: React.FC<TileContainerHeaderProps> = ({ label }) => {
    return (
        <header data-testid="tile-header" className="tile-header">
            <h1 className="tile-header__text">{label}</h1>
        </header>
    );
};
