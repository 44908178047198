import { Brand } from '../../../../store/slices/wallet-slice';
import { PrizeoutOffer, PrizeoutOfferMini } from '../../../../store/slices/offers-slice';
import {
    SearchResultPresenter,
    objectPresenterFactory,
    BrandPresenter,
    OfferPresenter,
    StringPresenter,
} from './presenters';
import {
    SearchListTransformer,
    dedupList,
    objectListTransformFactory,
    sortList,
    stringListTransformFactory,
} from './search-list-transforms';
import { SearchMatcher, objectMatchFactory, stringMatcher } from './search-matchers';

type SearchableOffers = PrizeoutOffer | PrizeoutOfferMini;

const brandListTransform = objectListTransformFactory<Brand>('brandName', [dedupList, sortList], 'brandId');
const offerListTransform = objectListTransformFactory<SearchableOffers>('name', [dedupList, sortList]);
const stringListTransform = stringListTransformFactory([dedupList, sortList]);

const brandMatcher = objectMatchFactory<Brand>('brandName');
const offerMatcher = objectMatchFactory<SearchableOffers>('name');

export interface SearchConfig<GenericObject> {
    matcher: SearchMatcher<GenericObject>;
    Presenter: SearchResultPresenter<GenericObject>;
    listTransform: SearchListTransformer<GenericObject>;
}

export const stringListSearchConfig: SearchConfig<string> = {
    matcher: stringMatcher,
    Presenter: StringPresenter,
    listTransform: stringListTransform,
};

export const objectListConfigFactory = <GenericObject>(
    memberName: keyof GenericObject,
    tieBreakerName?: keyof GenericObject,
): SearchConfig<GenericObject> => {
    return {
        matcher: objectMatchFactory<GenericObject>(memberName),
        Presenter: objectPresenterFactory<GenericObject>(memberName),
        listTransform: objectListTransformFactory<GenericObject>(memberName, [dedupList, sortList], tieBreakerName),
    };
};

export const objectListWithNameSearchConfig = objectListConfigFactory('name');

export const brandListSearchConfig: SearchConfig<Brand> = {
    matcher: brandMatcher,
    Presenter: BrandPresenter,
    listTransform: brandListTransform,
};

export const offerListSearchConfig: SearchConfig<SearchableOffers> = {
    matcher: offerMatcher,
    Presenter: OfferPresenter,
    listTransform: offerListTransform,
};
