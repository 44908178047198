import { useDispatch } from 'react-redux';

import { AppDispatch } from '../store/widget/widget';
import { resetCheckoutSlice } from '../store/slices/checkout-slice';

export function useResetCheckout() {
    const dispatch = useDispatch<AppDispatch>();

    const resetCheckout = () => {
        const results = dispatch(resetCheckoutSlice());
        return results;
    };

    return [resetCheckout];
}

export default useResetCheckout;
