import React from 'react';
import Classnames from 'classnames';

import './char-count.scss';

interface CharCountProps {
    refObject: React.MutableRefObject<HTMLTextAreaElement>;
    max: number;
}
export const CharCount: React.FC<CharCountProps> = ({ refObject, max }): React.ReactElement => {
    const charCount = () => {
        return refObject.current?.value?.length || 0;
    };

    const charCountClass = Classnames('char-count', {
        'char-count--limit-reached': charCount() > max,
    });

    return (
        <div className={charCountClass}>
            {charCount()}/{max}
        </div>
    );
};
