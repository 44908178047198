import React from 'react';

export interface SearchResultPresenterProps<GenericObject> {
    item: GenericObject;
}

export type SearchResultPresenter<GenericObject> = ({
    item,
}: SearchResultPresenterProps<GenericObject>) => React.ReactElement;

// MRiehle: Arrow funcitons with generics don't seem to compile in `.tsx` files.
export function objectPresenterFactory<GenericObject>(
    memberName: keyof GenericObject,
): SearchResultPresenter<GenericObject> {
    const Presenter: SearchResultPresenter<GenericObject> = ({ item }): React.ReactElement => {
        return <p>{item[memberName]}</p>;
    };

    return Presenter;
}

export const objectWithNamePresenter = objectPresenterFactory('name');
