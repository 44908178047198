import React from 'react';
import Classnames from 'classnames';
import './alert-highlight.scss';

interface AlertHighlightProps {
    className?: string;
    testId?: string;
    message: string;
}

export const AlertHighlight: React.FC<AlertHighlightProps> = ({ className, testId, message }): React.ReactElement => {
    const classes: string = Classnames(`alert-highlight`, className);

    const defaultTestId = 'alert-highlight';

    return (
        <div data-testid={testId || defaultTestId} className={classes}>
            <p className="alert-highlight__message">{message}</p>
        </div>
    );
};
