const trimText = (text: string, limit: number): string => {
    let trimmedText = text;
    if (text.length > limit) {
        trimmedText = `${text.substring(0, limit)}...`;
    }
    return trimmedText;
};

const determineValueContents = (item: string | Array<string>): string => {
    let type = '';
    const htmlRegex = /(<([^>]+)>)/i;

    if (Array.isArray(item)) {
        type = 'array';
    } else if (htmlRegex.test(item)) {
        type = 'html';
    } else {
        type = 'string';
    }

    return type;
};

const titleCaseText = (text: string) => {
    return text.replace(/(^\w{1})|(\s+\w{1})/g, (s) => s.toUpperCase());
};

const countWithLabel = (value: number, options: { plural: string; singular: string; zeroValue?: string }) => {
    const useOptions = {
        singular: options.singular || '',
        plural: options.plural || options.singular || '',
        zeroValue: options.zeroValue || options.plural || options.singular || '',
    };

    switch (value) {
        case 0:
            return `${value} ${useOptions.zeroValue}`;
        case 1:
            return `${value} ${useOptions.singular}`;
        default:
            return `${value} ${useOptions.plural}`;
    }
};

export interface SubstitutionValues {
    [parameterName: string]: string | number;
}

// MRiehle This does not handle plurals at all.
export const parameterSubstitution = (
    template: string,
    substitutionValues: SubstitutionValues = {} as SubstitutionValues,
) => {
    let result = template;

    if (!result) {
        return '';
    }

    for (const parameterName in substitutionValues) {
        result = result.replace(new RegExp(`%%${parameterName}%%`, 'g'), substitutionValues[parameterName].toString());
    }

    return result.replace(/%%.+%%/g, '**unknown**');
};

export const encodeDomain = (domain: string) => {
    return encodeURIComponent(domain).replace(/\./g, '%2E');
};

export { trimText, determineValueContents, titleCaseText, countWithLabel };
