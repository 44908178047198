export function useNumberSequence(): () => number {
    function sequence(i: number): () => number {
        let x = i;

        return () => {
            return x++;
        };
    }

    return sequence(0);
}

export function useStringSequence(prefix: string): () => string {
    function sequence(prefix: string): () => string {
        const pref = prefix;
        const nseq = useNumberSequence();

        return () => {
            const num = nseq().toString().padStart(2, '0');

            if (pref) {
                return pref + num;
            } else {
                return num;
            }
        };
    }

    return sequence(prefix);
}
