import { SubstitutionValues, parameterSubstitution } from '../../helpers';

interface MessageCatalog {
    [language: string]: LanguageCatalog;
}

interface LanguageCatalog {
    [sectionName: string]: MessageList;
}

interface MessageList {
    [messageName: string]: string;
}

export const messageCatalog = {} as MessageCatalog;
export const messageLanguage = 'en-US'; // MRiehle TODO: Use window.navigator.language.

export const loadMessages = async (sectionName: string): Promise<MessageList> => {
    if (!messageCatalog[messageLanguage]) {
        messageCatalog[messageLanguage] = {};
    }

    if (!messageCatalog[messageLanguage][sectionName]) {
        // https://github.com/webpack/webpack/issues/6680
        //
        // Dynamic loading still requires a constant for the load path.  This is
        // fixable with changes to webpack. For what I'm doing here, I don't want
        // to get bogged down in that fix.
        //
        // const sectionPath = sectionName.replace(/[A-Z]/g, (s) => `-${s.toLowerCase()}`);
        // const catalogPath = `/messages/${messageLanguage}/${sectionPath}`;
        // const sections = await import(catalogPath);
        // messageCatalog[messageLanguage][sectionName] = sections[sectionName];

        const sections = await import('./en-US/ui-inputs');
        messageCatalog['en-US'].uiInputs = sections.uiInputs;

        return sections.uiInputs;
    }

    return messageCatalog[messageLanguage][sectionName];
};

export const getMessage = (
    sectionName: string,
    messageName: string,
    substitutionValues?: SubstitutionValues,
): string => {
    const section = messageCatalog?.[messageLanguage]?.[sectionName];

    if (section?.[messageName]) {
        return parameterSubstitution(section[messageName], substitutionValues);
    } else {
        return `${messageName} (${JSON.stringify(substitutionValues)}) [${messageLanguage}:${sectionName}]`;
    }
};
