import React, { useEffect, useState } from 'react';
import { selectPartnerName, selectProductName } from '../../../store/slices/partner-config-slice';
import { useAppSelector } from '../../../hooks';
import { selectPartnerId } from '../../../store/slices/sdk-slice';
import { SkeletonLoaderElementCircle } from '../skeleton-loaders';
import { AppDispatch } from '../../../store/widget/widget';
import { useDispatch } from 'react-redux';
import {
    selectBrandingHeaderLogomarkUrl,
    selectHasBrandingHeaderTitle,
    setBrandingHeaderLogomarkUrl,
    setHasBrandingHeaderTitle,
} from '../../../store/slices/common-slice';

import './product-branding-header.scss';

export const ProductBrandingHeader: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();

    const productName = useAppSelector(selectProductName);
    const partnerId = useAppSelector(selectPartnerId);
    const partnerName = useAppSelector(selectPartnerName);
    const brandingHeaderLogomarkUrl = useAppSelector(selectBrandingHeaderLogomarkUrl);
    const hasBrandingHeaderTitle = useAppSelector(selectHasBrandingHeaderTitle);

    const [showProductName, setShowProductName] = useState(false);
    const [showSkeletonLoader, setShowSkeletonLoader] = useState(true);

    const getLogomarkUrl = `https://assets.prizeout.com/whitelabel/${partnerId}/brandLogo.png`;

    const setImageOnLoad = () => {
        setShowSkeletonLoader(false);

        if (brandingHeaderLogomarkUrl) {
            return;
        }

        dispatch(setBrandingHeaderLogomarkUrl(getLogomarkUrl));
    };

    const setFallbackOnError = () => {
        setShowSkeletonLoader(false);
        setShowProductName(true);

        if (hasBrandingHeaderTitle) {
            return;
        }

        dispatch(setHasBrandingHeaderTitle(true));
    };

    const determineLayout = () => {
        if (showProductName || hasBrandingHeaderTitle) {
            return (
                <>
                    <p data-testid="product-branding-header-name" className="product-branding-header__name">
                        {productName}
                    </p>
                </>
            );
        }

        return (
            <img
                data-testid="product-branding-header-image"
                alt={`${partnerName} logo`}
                className="product-branding-header__image"
                src={brandingHeaderLogomarkUrl || getLogomarkUrl}
                onLoad={setImageOnLoad}
                onError={setFallbackOnError}
            />
        );
    };

    useEffect(() => {
        if (brandingHeaderLogomarkUrl || hasBrandingHeaderTitle) {
            setShowSkeletonLoader(false);
        }
    }, [brandingHeaderLogomarkUrl, hasBrandingHeaderTitle]);

    return (
        <div className="product-branding-header">
            <>
                {showSkeletonLoader && <SkeletonLoaderElementCircle />}

                {determineLayout()}
            </>
        </div>
    );
};
