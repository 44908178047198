import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sharedAPI } from '../../utils/services/api';
import { RootState } from '../widget/widget';
import { SignUpRequest } from '../../components/forms';
import { LogInResponse, User } from './user-slice';
import { getPartnerConfig, PartnerConfigState } from './partner-config-slice';
import { ApplicationModule } from './common-slice';
import {
    createSession,
    CreateSessionRequestResponse,
} from './cashback-chrome-plugin/cashback-chrome-plugin-auth-slice';

// Define a type for the slice state
export interface VerificationState {
    activeAccountVerificationEmail?: string;
    authTokens: AuthTokens;
    currentVerificationFlow?: VerificationFlowState;
    pendingVerifications: VerificationRecord[];
    activeVerificationId?: string;
    extensionLinking: {
        linkToken: string;
        expirationDate: string;
    };
}

export enum VERIFICATION_ACTION_TYPES {
    ACCOUNT_CREATION = 'account-creation',
    LINK_ACCOUNTS = 'link-accounts',
    UNKNOWN_EMAIL_ACCOUNT_CREATION = 'unknown-email-account-creation',
}

interface VerificationFlowState {
    id: string;
    isComplete: boolean;
    isExpired: boolean;
}

// Define the initial state using that type
export const verificationInitialState: VerificationState = {
    authTokens: {
        accessTokenGlobal: '',
        accessTokenGlobalVerified: false,
        superUserManualLogIn: false,
        accessTokenGlobalSetAt: new Date().getTime().toString(),
    },
    activeVerificationId: '',
    pendingVerifications: [],
    extensionLinking: {
        linkToken: '',
        expirationDate: '',
    },
};

export interface AuthTokens {
    accessTokenGlobal: string;
    accessTokenGlobalVerified: boolean;
    superUserManualLogIn: boolean;
    accessTokenGlobalSetAt: string;
}

type CreateVerficationRequest = {
    actionPayload?: any;
    actionType: VERIFICATION_ACTION_TYPES;
};

export type VerificationRecord = {
    id: string;
    type: VERIFICATION_ACTION_TYPES;
    isComplete: boolean;
    isExpired: boolean;
    isInvalid: boolean;
    metadata?: {
        referenceEmail?: string;
        superUserEmail: string;
    };
};

type VerficationRequest = {
    id: string;
};

type VerificationCompletionRequest = {
    id: string;
    params?: any;
    secretToken: string;
};

export type SignUpResponse = {
    accessToken: string;
    email: string;
    verificationUUID: string;
    accountsPartnerId?: string;
    accountsSessionId?: string;
};

type AuthRequest = {
    email: string;
    password: string;
    recaptchaToken?: string;
    passwordconfirmation?: string;
};

type AuthWidgetRequest = {
    sessionId: string;
};

export interface AccessTokenStates {
    accessTokenGlobal: string;
    accessTokenGlobalVerified: boolean;
    superUserManualLogIn: boolean;
    accessTokenGlobalSetAt: string;
}

export enum LinkTokenType {
    LONG = 'long',
    SHORT = 'short',
}

interface GetBELinkingTokenRequest {
    tokenType?: LinkTokenType;
}

interface BELinkingTokenResponse {
    linkToken: string;
    expirationDate: string;
    tokenType: LinkTokenType;
}

export const getBELinkingToken = createAsyncThunk(
    'browserExtension/link',
    async ({ tokenType }: GetBELinkingTokenRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    tokenType,
                },
                endpoint: '/browserExtension/link',
                method: 'GET',
                signal: signal,
            });
            return {
                tokenType,
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const createVerification = createAsyncThunk(
    'verification/createVerificationStatus',
    async ({ actionPayload, actionType }: CreateVerficationRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    actionPayload,
                    actionType,
                },
                endpoint: `/verification/create`,
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const getVerificationStatus = createAsyncThunk(
    'verification/getVerificationStatus',
    async ({ id }: VerficationRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/verification/${id}/status`,
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const renewExpiredVerification = createAsyncThunk(
    'verification/renewVerification',
    async ({ id }: VerficationRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/verification/${id}/renew`,
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const completeVerification = createAsyncThunk(
    'verification/processVerification',
    async ({ id, secretToken, params }: VerificationCompletionRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    id,
                    secretToken,
                    params,
                },
                endpoint: `/verification/processVerification`,
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const signUpUser = createAsyncThunk(
    'user/signUpUser',
    async (userData: SignUpRequest, { rejectWithValue, signal }) => {
        const { email, firstName, lastName, optIn, password, recaptchaToken } = userData;
        try {
            const results = await sharedAPI.request({
                data: {
                    email,
                    firstName,
                    lastName,
                    optIn,
                    password,
                    recaptchaToken,
                },
                endpoint: '/signUp',
                method: 'POST',
                signal: signal,
            });
            return {
                email,
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const authenticateUser = createAsyncThunk(
    'user/authenticateUser',
    async ({ email, password, recaptchaToken, passwordconfirmation }: AuthRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    email,
                    password,
                    recaptchaToken,
                    passwordconfirmation,
                },
                endpoint: recaptchaToken ? '/v2/logIn' : '/v2/beLogIn',
                method: 'POST',
                signal: signal,
            });
            return {
                email,
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const authenticateWidgetUser = createAsyncThunk(
    'user/authenticateWidgetUser',
    async ({ sessionId }: AuthWidgetRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    sessionId,
                },
                endpoint: '/widget/auth',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            rejectWithValue(e.results.data);
        }
    },
);

export const verificationSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(renewExpiredVerification.fulfilled, (state, action: PayloadAction<VerificationRecord>) => {
            state.pendingVerifications = [...state.pendingVerifications, action.payload];
        });

        builder.addCase(getBELinkingToken.fulfilled, (state, action: PayloadAction<BELinkingTokenResponse>) => {
            if (action.payload.tokenType === LinkTokenType.SHORT) {
                state.extensionLinking = {
                    expirationDate: action.payload.expirationDate,
                    linkToken: action.payload.linkToken,
                };
            }
        });

        builder.addCase(getVerificationStatus.fulfilled, (state, action: PayloadAction<VerificationRecord>) => {
            const pendingVerifications = [...state.pendingVerifications].filter(({ id }) => id != action.payload.id);
            state.pendingVerifications = pendingVerifications;
        });

        builder.addCase(getVerificationStatus.rejected, (_state, action: any) => {
            console.error(action.error?.message || action.error?.name || action.error | action);
        });

        builder.addCase(completeVerification.fulfilled, (state, action: PayloadAction<VerificationRecord>) => {
            const { id, isComplete, isExpired } = action.payload;

            state.currentVerificationFlow = {
                id,
                isComplete,
                isExpired,
            };
        });

        builder.addCase(createVerification.fulfilled, (state, action: PayloadAction<VerificationRecord>) => {
            state.pendingVerifications = [...state.pendingVerifications, action.payload];
        });

        builder.addCase(authenticateUser.fulfilled, (state, action: PayloadAction<LogInResponse>) => {
            if (!action.payload.error) {
                state.authTokens = {
                    accessTokenGlobal: action.payload.accessToken,
                    accessTokenGlobalVerified: !!action.payload.verifiedAuth ? true : false,
                    superUserManualLogIn: true,
                    accessTokenGlobalSetAt: String(new Date().getTime()),
                };

                if (action.payload.activeAuthVerification) {
                    state.pendingVerifications = [...state.pendingVerifications, action.payload.activeAuthVerification];
                    if (action.payload.activeAuthVerification?.metadata) {
                        state.activeAccountVerificationEmail =
                            action.payload.activeAuthVerification?.metadata?.referenceEmail ||
                            action.payload.activeAuthVerification?.metadata?.superUserEmail;
                    } else if (action.payload.linkFromEmail) {
                        state.activeAccountVerificationEmail = action.payload.linkFromEmail;
                    } else {
                        state.activeAccountVerificationEmail = action.payload.email;
                    }
                    state.activeVerificationId = action.payload.activeAuthVerification.id;
                }

                sharedAPI.token = action.payload.accessToken;
            }
        });

        builder.addCase(signUpUser.fulfilled, (state, action: PayloadAction<SignUpResponse>) => {
            state.pendingVerifications = [
                ...state.pendingVerifications,
                {
                    id: action.payload.verificationUUID,
                    isComplete: false,
                    isExpired: false,
                    isInvalid: false,
                    type: VERIFICATION_ACTION_TYPES.LINK_ACCOUNTS,
                },
            ];

            state.activeAccountVerificationEmail = action.payload.email;
            state.activeVerificationId = action.payload.verificationUUID;

            state.authTokens = {
                accessTokenGlobal: action.payload.accessToken,
                accessTokenGlobalVerified: false,
                superUserManualLogIn: true,
                accessTokenGlobalSetAt: String(new Date().getTime()),
            };
            sharedAPI.token = action.payload.accessToken;
        });

        builder.addCase(authenticateWidgetUser.fulfilled, (state, action: PayloadAction<User>) => {
            state.authTokens = {
                accessTokenGlobal: action.payload.accessToken,
                accessTokenGlobalVerified: false,
                superUserManualLogIn: false,
                accessTokenGlobalSetAt: String(new Date().getTime()),
            };
            sharedAPI.token = action.payload.accessToken;
        });

        builder.addCase(getPartnerConfig.fulfilled, (state, action: PayloadAction<PartnerConfigState>) => {
            state.authTokens = {
                ...state.authTokens,
                accessTokenGlobal: action.payload.accessToken,
                accessTokenGlobalSetAt: String(new Date().getTime()),
            };
            if (action.payload.applicationModule !== ApplicationModule.ACCOUNTS) {
                state.authTokens = {
                    ...state.authTokens,
                    accessTokenGlobalVerified: false,
                    superUserManualLogIn: false,
                };
            }
            sharedAPI.token = action.payload.accessToken;
        });

        builder.addCase(createSession.fulfilled, (state, action: PayloadAction<CreateSessionRequestResponse>) => {
            state.authTokens.accessTokenGlobal = action.payload.accessToken;
        });
    },
    initialState: verificationInitialState,
    name: 'verification',
    reducers: {
        clearVerificationTokens(state) {
            state.authTokens = verificationInitialState.authTokens;
        },
        setAccessTokenGlobalVerified(state, action: PayloadAction<boolean>) {
            state.authTokens.accessTokenGlobalVerified = action.payload;
        },
        setActiveAccountVerificationEmail(state, action: PayloadAction<string>) {
            state.activeAccountVerificationEmail = action.payload;
        },
        resetVerificationSlice() {
            return verificationInitialState;
        },
        setAccessTokensFromPersistentState(state, action: PayloadAction<AccessTokenStates>) {
            state.authTokens = {
                accessTokenGlobal: action.payload.accessTokenGlobal,
                accessTokenGlobalVerified: action.payload.accessTokenGlobalVerified,
                superUserManualLogIn: action.payload.superUserManualLogIn,
                accessTokenGlobalSetAt: action.payload.accessTokenGlobalSetAt,
            };
        },
    },
});

export const {
    clearVerificationTokens,
    resetVerificationSlice,
    setAccessTokenGlobalVerified,
    setActiveAccountVerificationEmail,
    setAccessTokensFromPersistentState,
} = verificationSlice.actions;

const selectVerificationState = ({ verification }: RootState): VerificationState => verification;

export const selectCurrenVerificationFlow = createSelector(
    selectVerificationState,
    ({ currentVerificationFlow }) => currentVerificationFlow,
);

export const selectPendingVerifications = createSelector(
    selectVerificationState,
    ({ pendingVerifications }) => pendingVerifications,
);

export const selectAccessTokenGlobalVerified = createSelector(
    selectVerificationState,
    ({ authTokens: { accessTokenGlobalVerified } }) => accessTokenGlobalVerified,
);

export const selectAccessTokenGlobal = createSelector(
    selectVerificationState,
    ({ authTokens: { accessTokenGlobal } }) => accessTokenGlobal,
);

export const selectSuperUserManualLogIn = createSelector(
    selectVerificationState,
    ({ authTokens: { superUserManualLogIn } }) => superUserManualLogIn,
);

export const selectAccessTokenGlobalSetAt = createSelector(
    selectVerificationState,
    ({ authTokens: { accessTokenGlobalSetAt } }) => accessTokenGlobalSetAt,
);

export const selectActiveAccountVerificationEmail = createSelector(
    selectVerificationState,
    ({ activeAccountVerificationEmail }) => activeAccountVerificationEmail,
);

export const selectActiveVerificationId = createSelector(
    selectVerificationState,
    ({ activeVerificationId }) => activeVerificationId,
);

export const selectExtensionLinkingData = createSelector(
    selectVerificationState,
    ({ extensionLinking }) => extensionLinking,
);

export default verificationSlice.reducer;
