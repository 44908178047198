import React from 'react';
import { ClickableHTMLTypes } from '../../../store/slices/common-slice';
import Classnames from 'classnames';
import { ClickableCard } from '../cards';
import { DividerRowEdgeType } from './divider-row';

import './divider-row-with-content.scss';
import { ExternalLink, ExternalLinkDisplayTypes } from './external-link';

interface DividerRowWithContentProps {
    htmlElement?: ClickableHTMLTypes;
    onClick?: () => void;
    shouldExtendEdges?: boolean;
    edgeType?: DividerRowEdgeType;
    isDisabled?: boolean;
    className?: string;
    testId?: string;
    noBorder?: boolean;
    name?: string;
    disableTracking?: boolean;
    hasSmallPadding?: boolean;
    url?: string;
    ariaLabel?: string;
}

export const DividerRowWithContent: React.FC<DividerRowWithContentProps> = ({
    children,
    htmlElement,
    onClick,
    shouldExtendEdges,
    edgeType = DividerRowEdgeType.SPACE_PRIMARY,
    isDisabled,
    className,
    testId = 'clickable-divider-row-with-content',
    noBorder,
    name,
    disableTracking,
    hasSmallPadding,
    url,
    ariaLabel,
}): React.ReactElement => {
    const classes: string = Classnames(
        'divider-row-with-content',
        {
            'divider-row-with-content--extend-edges': shouldExtendEdges,
            'divider-row-with-content--no-border': noBorder,
            'divider-row-with-content--small-padding': hasSmallPadding,
            [`divider-row-with-content--${edgeType}`]: edgeType,
        },
        className,
    );

    const cardName = name ?? 'divider-row-with-content';

    return (
        <>
            {url ? (
                <ExternalLink
                    displayType={ExternalLinkDisplayTypes.CUSTOM}
                    url={url}
                    testId={testId}
                    className={className}
                    ariaLabel={ariaLabel}
                >
                    <div data-testid="divider-row-with-content-external-link" className={classes}>
                        {children}
                    </div>
                </ExternalLink>
            ) : (
                <ClickableCard
                    cardName={cardName}
                    testId={testId}
                    onClick={onClick}
                    htmlElement={htmlElement}
                    isDisabled={isDisabled}
                    disableTracking={disableTracking}
                >
                    <div data-testid="divider-row-with-content" className={classes}>
                        {children}
                    </div>
                </ClickableCard>
            )}
        </>
    );
};
