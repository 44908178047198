import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    AuthenticationModalView,
    setAuthenticationModalView,
    setIsAuthenticationModalShowing,
} from '../store/slices/common-slice';
import { AppDispatch } from '../store/widget/widget';
import useCheckAuthentication, {
    AuthenticationControls,
    AuthenticationResult,
    useCheckAuthenticationOptions,
} from './use-check-authentication';

type AuthenticationPrechecker<GenericViewEnum> = (payload: GenericViewEnum) => boolean;

export interface useAuthenticateWallOptions<GenericViewEnum> {
    authenticationCompletePrecheck?: AuthenticationPrechecker<GenericViewEnum>;
    authenticationCheckOptions?: useCheckAuthenticationOptions;
}

export interface useAuthenticateWallProps<GenericViewEnum> {
    authenticationDone: (payload: GenericViewEnum) => void;
    options?: useAuthenticateWallOptions<GenericViewEnum>;
}

export function useAuthenticateWall<GenericViewEnum>({
    authenticationDone,
    options,
}: useAuthenticateWallProps<GenericViewEnum>): {
    authenticateWall: (payload?: GenericViewEnum, overridePrecheck?: boolean) => void;
    authenticationControls: AuthenticationControls;
} {
    const configurationOptions: useAuthenticateWallOptions<GenericViewEnum> = {
        authenticationCompletePrecheck: () => true,
        authenticationCheckOptions: {},
        ...options,
    };

    const dispatch = useDispatch<AppDispatch>();

    const [authenticationResult, authenticationControls] = useCheckAuthentication();
    const [nextPayload, setNextPayload] = useState<GenericViewEnum>(null);

    useEffect(() => {
        if (authenticationResult === AuthenticationResult.AUTHENTICATION_SUCCESS && nextPayload) {
            handleAuthenticationCompletion(nextPayload);
        }
    }, [authenticationResult, nextPayload]);

    const handleAuthenticationCompletion = (payload: GenericViewEnum, overridePrecheck = false) => {
        if (overridePrecheck || configurationOptions.authenticationCompletePrecheck(payload as GenericViewEnum)) {
            authenticationDone(payload as GenericViewEnum);
        }
    };

    const showAuthenticationModal = (mode: AuthenticationModalView): void => {
        dispatch(setAuthenticationModalView(mode));
        dispatch(setIsAuthenticationModalShowing(true));
    };

    const authenticateWall = async (authenticationPayload?: GenericViewEnum, overridePrecheck = false) => {
        switch (authenticationResult) {
            case AuthenticationResult.AUTHENTICATION_NEEDS_LINK:
                return showAuthenticationModal(AuthenticationModalView.ACCOUNT_LINK_REQUEST);

            case AuthenticationResult.AUTHENTICATION_NEEDS_LOGIN:
                setNextPayload(authenticationPayload);
                return showAuthenticationModal(AuthenticationModalView.LOGIN);

            case AuthenticationResult.AUTHENTICATION_NEEDS_SIGN_UP:
                return showAuthenticationModal(AuthenticationModalView.SIGN_UP);

            case AuthenticationResult.AUTHENTICATION_NEEDS_VERIFICATION:
                return showAuthenticationModal(AuthenticationModalView.VERIFICATION_REQUIRED);

            case AuthenticationResult.AUTHENTICATION_SUCCESS:
                handleAuthenticationCompletion(authenticationPayload, overridePrecheck);
                return;

            case AuthenticationResult.AUTHENTICATION_CHECKING:
            default:
                return;
        }
    };

    return {
        authenticateWall,
        authenticationControls,
    };
}

export default useAuthenticateWall;
