import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import giftCardReducer, { giftCardInitialState } from '../slices/gift-card-slice';
import { PrizeoutEnv } from '../../utils/constants/prizeout-website';
import { commonInitState, commonSlices } from '../common';

export const claimPageSlices = {
    giftCard: giftCardReducer,
};

const claimReducer = combineReducers({
    ...commonSlices,
    ...claimPageSlices,
});

export const cpStore = configureStore({
    reducer: claimReducer,
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

export type CPRootState = ReturnType<typeof claimReducer>;

export type AppDispatch = typeof cpStore.dispatch & ThunkDispatch<CPRootState, null, AnyAction>;

export const CPInitialState: CPRootState = {
    ...commonInitState,
    giftCard: giftCardInitialState,
};
