import { normalizeString } from './search-config-utils';

export type SearchMatcher<GenericObject> = (arg0: GenericObject, arg1: string, matchCase?: boolean) => boolean;

// Some standard matchers for (probably) common cases.
export const stringMatcher: SearchMatcher<string> = (candidate: string, pattern: string, matchCase = false) => {
    const localCandidate = normalizeString(candidate);
    const localPattern = normalizeString(pattern);

    if (matchCase) {
        return localCandidate.includes(localPattern);
    } else {
        return localCandidate.toLowerCase().includes(localPattern.toLowerCase());
    }
};

// Generic object matcher factory.
export const objectMatchFactory = <GenericObject>(memberName: keyof GenericObject): SearchMatcher<GenericObject> => {
    const matcher: SearchMatcher<GenericObject> = (item: GenericObject, query: string, matchCase = false) => {
        const itemValue: string = item[memberName] as unknown as string;
        return stringMatcher(itemValue, query, matchCase);
    };

    return matcher;
};
