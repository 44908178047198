import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks';
import { selectSignUpEmail } from '../../../../store/slices/user-slice';
import { StatusNotification } from '../../ui-widgets';
import { ButtonTrackingTypes } from '../../../../utils/services/tracking';

interface VerificationSentProps {
    closeModal: () => void;
}

export const VerificationSent: React.FC<VerificationSentProps> = ({ closeModal }): React.ReactElement => {
    const email = useAppSelector(selectSignUpEmail);
    const [message, setMessage] = useState('A verification link was sent.');

    useEffect(() => {
        if (email) {
            setMessage('A verification link was sent to ' + email + '.');
        }
    }, [email]);

    return (
        <>
            <StatusNotification
                ariaDescribedById="verificationSent-modal-description"
                ariaLabelledById="verificationSent-modal-headline"
                headline="Almost done"
                imageAltText=""
                imageUrl="https://assets.prizeout.com/widget/verification/email-sent.png"
                message={message}
                primaryButtonText="Got it"
                primaryOnClick={() => closeModal()}
                trackingTypePrimary={ButtonTrackingTypes.ACCOUNT_VERIFICATION_SENT_BUTTON_CLICK}
            />
        </>
    );
};
