import React from 'react';
import './barcode.scss';

interface BarcodeProps {
    barcodeUrl: string;
    barcodeValue?: string;
}

export const Barcode: React.FC<BarcodeProps> = ({ barcodeUrl, barcodeValue }): React.ReactElement => {
    return (
        <div data-testid="barcode" className="barcode">
            <img src={barcodeUrl} alt={barcodeValue} />
            {barcodeValue && <p>{barcodeValue}</p>}
        </div>
    );
};
