import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uuid_v4 } from '../../utils';
import { RootState } from '../widget/widget';
import { SDKParams, selectIsMiniWidget } from './sdk-slice';

import { routes } from '../../utils/helpers/routing-helpers';
import { AuctionParams } from './store-front-slice';
import { selectCheckedOutCard, CheckoutOfferResponse } from './checkout-slice';
import { sharedAPI } from '../../utils/services/api';
import { OTPRequestResponse, renewOTPRequestID } from './otp-slice';
import { ApplicationModule } from './common-slice';
import {
    createSession,
    CreateSessionRequestResponse,
} from './cashback-chrome-plugin/cashback-chrome-plugin-auth-slice';

export enum CheckoutType {
    CASHBACK = 'cashback',
    REGULAR = 'regular',
}

export enum GiftingType {
    CARD = 'card',
    FUNDS = 'funds',
}

export enum MiniWidgetDisplayTypes {
    BILLBOARD = 'billboard',
    OFFERS = 'offers',
    TESTIMONIAL_TEXT_LIST_V1 = 'testimonial_text_list_v1',
    GIFT_CARD_CASHBACK_BALANCES_TEXT_LIST_V1 = 'gift_card_cashback_balances_text_list_v1',
    GIFT_CARD_BONUS_BALANCES_TEXT_LIST_V1 = 'gift_card_bonus_balances_text_list_v1',
    GIFT_CARD_CASHBACK_BALANCES_AND_OFFERS_V1 = 'gift_card_cashback_balances_and_offers_v1',
    GIFT_CARD_BONUS_BALANCES_AND_OFFERS_V1 = 'gift_card_bonus_balances_and_offers_v1',
    BONUS_BANNER_BONUS_BALANCE_GIFT_CARDS_OFFER = 'bonus_banner_bonus_balance_gift_cards_offer',
    CLASSIC_TESTIMONIAL = 'classic_testimonial',
    BONUS_BANNER_BONUS_BALANCE_GIFT_CARDS_BULLETS = 'bonus_banner_bonus_balance_gift_card_bullets',
    CASHBACK_BANNER_CASHBACK_BALANCE_GIFT_CARDS_BULLETS = 'cashback_banner_cashback_balance_gift_cards_bullets',
    CASHBACK_BANNER_CASHBACK_BALANCE_GIFT_CARDS_OFFER = 'cashback_banner_cashback_balance_gift_cards_offer',
    TOTAL_MEMBERS_CASHBACK = 'total_members_cashback',
    WELCOME_INSTRUCTIONS_BANNER = 'welcome_instructions_banner',
    CLASSIC_OFFERS = 'classic_offers',

    LUMIN_BILLBOARD = 'lumin_billboard',
    LUMIN_STACKED_CASHBACK_BALANCE_BACKGROUND_IMAGE_AND_TEXT = 'lumin_stacked_cashback_balance_background_image_and_text',
    LUMIN_STACKED_CASHBACK_BALANCE_BILLBOARD = 'lumin_stacked_cashback_balance_billboard',
    LUMIN_STACKED_CASHBACK_BALANCE_EARNING_INSIGHT = 'lumin_stacked_cashback_balance_earning_insight',
    LUMIN_STACKED_CASHBACK_BALANCE_EARNING_TRACKER = 'lumin_stacked_cashback_balance_earning_tracker',
    LUMIN_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_LARGE_HEADER_TEXT = 'lumin_stacked_cashback_balance_info_card_left_image_large_header_text',
    LUMIN_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_SMALL_HEADER_TEXT = 'lumin_stacked_cashback_balance_info_card_left_image_small_header_text',
    LUMIN_STACKED_CASHBACK_BALANCE_INFO_CARD_RIGHT_IMAGE_WRAPPED_LARGE_NUMBER = 'lumin_stacked_cashback_balance_info_card_right_image_wrapped_large_number',
    LUMIN_STACKED_CASHBACK_BALANCE_OFFER = 'lumin_stacked_cashback_balance_offer',
    LUMIN_STACKED_CASHBACK_BALANCE_REWARD = 'lumin_stacked_cashback_balance_reward',
    LUMIN_STACKED_FALLBACK = 'lumin_stacked_fallback',

    // Q2 Classic
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_EARNING_INSIGHT = 'q2_classic_stacked_cashback_balance_earning_insight',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_BACKGROUND_IMAGE_AND_TEXT = 'q2_classic_stacked_cashback_balance_background_image_and_text',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_INFO_CARD_RIGHT_IMAGE_WRAPPED_LARGE_NUMBER = 'q2_classic_stacked_cashback_balance_info_card_right_image_wrapped_large_number',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_SMALL_HEADER_TEXT = 'q2_classic_stacked_cashback_balance_info_card_left_image_small_header_text',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_LARGE_HEADER_TEXT = 'q2_classic_stacked_cashback_balance_info_card_left_image_large_header_text',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_BILLBOARD = 'q2_classic_stacked_cashback_balance_billboard',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_REWARD = 'q2_classic_stacked_cashback_balance_reward',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_OFFER = 'q2_classic_stacked_cashback_balance_offer',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_EARNING_TRACKER = 'q2_classic_stacked_cashback_balance_earning_tracker',
    Q2_CLASSIC_STACKED_REWARD_FALLBACK = 'q2_classic_stacked_reward_fallback',
    Q2_CLASSIC_STACKED_OFFER_FALLBACK = 'q2_classic_stacked_offer_fallback',
    Q2_CLASSIC_BILLBOARD = 'q2_classic_billboard',

    // Q2 Composable
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_BACKGROUND_IMAGE_AND_TEXT = 'q2_composable_stacked_cashback_balance_background_image_and_text',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_LARGE_HEADER_TEXT = 'q2_composable_stacked_cashback_balance_info_card_left_image_large_header_text',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_INFO_CARD_RIGHT_IMAGE_WRAPPED_LARGE_NUMBER = 'q2_composable_stacked_cashback_balance_info_card_right_image_wrapped_large_number',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_SMALL_HEADER_TEXT = 'q2_composable_stacked_cashback_balance_info_card_left_image_small_header_text',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_EARNING_INSIGHT = 'q2_composable_stacked_cashback_balance_earning_insight',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_OFFER = 'q2_composable_stacked_cashback_balance_offer',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_REWARD = 'q2_composable_stacked_cashback_balance_reward',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_BILLBOARD = 'q2_composable_stacked_cashback_balance_billboard',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_EARNING_TRACKER = 'q2_composable_stacked_cashback_balance_earning_tracker',
    Q2_COMPOSABLE_STACKED_OFFERS_FALLBACK = 'q2_composable_stacked_offers_fallback',
    Q2_COMPOSABLE_STACKED_REWARDS_FALLBACK = 'q2_composable_stacked_rewards_fallback',
    Q2_COMPOSABLE_STACKED_FALLBACK = 'q2_composable_stacked_fallback',
    Q2_COMPOSABLE_BILLBOARD = 'q2_composable_billboard',

    //Banno
    BANNO_STACKED_FALLBACK = 'banno_stacked_fallback',
    BANNO_STACKED_CASHBACK_BALANCE_REWARD = 'banno_stacked_cashback_balance_reward',
    BANNO_STACKED_CASHBACK_BALANCE_OFFER = 'banno_stacked_cashback_balance_offer',
    BANNO_STACKED_CASHBACK_BALANCE_INFO_CARD_RIGHT_IMAGE_WRAPPED_LARGE_NUMBER = 'banno_stacked_cashback_balance_info_card_right_image_wrapped_large_number',
    BANNO_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_SMALL_HEADER_TEXT = 'banno_stacked_cashback_balance_info_card_left_image_small_header_text',
    BANNO_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_LARGE_HEADER_TEXT = 'banno_stacked_cashback_balance_info_card_left_image_large_header_text',
    BANNO_STACKED_CASHBACK_BALANCE_EARNING_TRACKER = 'banno_stacked_cashback_balance_earning_tracker',
    BANNO_STACKED_CASHBACK_BALANCE_EARNING_INSIGHT = 'banno_stacked_cashback_balance_earning_insight',
    BANNO_STACKED_CASHBACK_BALANCE_BILLBOARD = 'banno_stacked_cashback_balance_billboard',
    BANNO_STACKED_CASHBACK_BALANCE_BACKGROUND_IMAGE_AND_TEXT = 'banno_stacked_cashback_balance_background_image_and_text',
    BANNO_BILLBOARD = 'banno_billboard',

    // Modals
    CLASSIC_MODAL_ICON_BULLETS = 'classic_modal_icon_bullets',
    TESTIMONIAL_MODAL_ICON_BULLETS = 'testimonial_modal_icon_bullets',
    CLASSIC_MODAL_CHECKLIST_IMAGE = 'classic_modal_checklist_image',
    CLASSIC_MODAL_PARAGRAPH_TEXT = 'classic_modal_paragraph_text',
    LEGAL_MODAL_PARAGRAPH_TEXT_DATA_SHARE_V1 = 'legal_modal_paragraph_text_data_share_v1',
    LEGAL_MODAL_CHECKLIST_IMAGE = 'legal_modal_checklist_image',
    LEGAL_MODAL_PARAGRAPH_TEXT = 'legal_modal_paragraph_text',
}

export enum CheckoutFlowTypes {
    STANDARD = 'standard',
    MULTI_PANEL = 'multi_panel',
}

export enum NavBarButtonIconNames {
    CASHBACK = 'cashback',
    CATEGORY_EXPLORE = 'category-explore',
    CON_CONTENT = 'content',
    CON_CUSTOMERS = 'customers',
    CON_TRANSACTIONS = 'transactions',
    CONSUMER = 'consumer',
    DEALS = 'deals',
    EXPLORE = 'explore',
    FOR_YOU = 'for-you',
    HELP = 'help',
    HOME = 'home',
    MERCHANTS = 'merchants',
    PARTNER = 'partner',
    PROFILE = 'profile',
    WALLET = 'wallet',
}

// Define a type for the slice state
export interface PartnerConfigState {
    settings: PartnerSettings;
    user?: PartnerUser;
    sdk_settings?: SDKParams;
    accessToken?: string;
    error?: string;
    applicationModule?: ApplicationModule;
}

export interface OptIns {
    gdpr?: boolean;
    ccpa?: boolean;
}

export interface NavBarOption {
    icon: NavBarButtonIconNames;
    label: string;
    metadata: {
        category_id: number;
    };
    route: routes;
}

export interface SweepstakeSettings {
    sweepstakes_id: string;
    show_sweepstakes_banner: boolean;
    show_sweepstakes_checkout_alert: boolean;
}

export interface CheckoutSettings {
    checkout_calculation_type: CheckoutType;
    is_reg_e_enabled: boolean;
    reg_e_type: CheckoutType;
    show_checkout_onboarding: boolean;
}

export interface GiftCardWalletSettings {
    show_total_bonus_earned: boolean;
}

export enum PrizeoutProductVersion {
    STANDARD = 'standard',
    BONUS = 'fi_bonus',
    CASHBACK = 'fi_cashback',
    REWARDS = 'fi_rewards',
}

export const partnerSettingsInitialState: PartnerSettings = {
    actual_close_first_cashout: false,
    always_show_cost_equal_balance: false,
    body_theme: false,
    nav: [] as NavBarOption[],
    checkout_button_cta: 'Select gift card',
    checkout_button_confirm_cta: 'Confirm',
    checkout_flow_version: CheckoutFlowTypes.STANDARD,
    checkout_settings: {
        checkout_calculation_type: CheckoutType.REGULAR,
        is_reg_e_enabled: false,
        reg_e_type: CheckoutType.REGULAR,
        show_checkout_onboarding: false,
    },
    cobrand_logo: '',
    consumer_onboarding_flow_id: '',
    country_code: '',
    custom_css: {},
    custom_css_mini: {},
    display_name: '',
    force_email_capture_on_checkout: false,
    force_partner_session_otp: false,
    giftcard_value_term: 'You get',
    gift_card_wallet_settings: {
        show_total_bonus_earned: false,
    },
    has_previous_gift_sends: false,
    header_theme: false,
    hide_balance: false,
    custom_filter_order: [],
    hide_exit: false,
    is_2fa_enabled: false,
    is_actions_enabled: false,
    is_accounts_enabled: false,
    is_cancel_request_enabled: false,
    is_cashback_enabled: false,
    is_category_filter_enabled: false,
    is_category_selection_enabled: false,
    is_category_sort_enabled: false,
    is_chrome_extension_enabled: false,
    is_crm_enabled: false,
    is_gifting_partner: false,
    is_offer_highlight_enabled: false,
    is_onboarding_enabled: false,
    is_opt_in_preference_page_enabled: false,
    is_points_enabled: false,
    is_profile_enabled: false,
    is_search_enabled: false,
    is_search_v2_enabled: false,
    is_secondary_email_enabled: false,
    is_share_gift_card_enabled: false,
    is_share_api_enabled: false,
    is_use_cashback_for_giftcards_enabled: false,
    is_cashback_terms_disclaimer_enabled: false,
    is_wallet_v2_enabled: false,
    logo: '',
    logo_mobile: '',
    mini_widget: {
        crm_copy: 'Get deals from brands you love.',
        header_title: 'Offers for you',
        is_my_gift_card_cta_enabled: false,
        is_powered_by_po_enabled: false,
    },
    on_close_first_cashout: false,
    partner_currency: 'USD',
    partner_product_name: 'Prizeout',
    partner_program_name: '',
    prizeout_product_version: PrizeoutProductVersion.STANDARD,
    prizeout_logo: '',
    show_cu_onboarding_banner: false,
    single_withdrawal_only: false,
    spend_redemption_title: 'Select redemption amount',
    withdrawal_term: 'Spend Amount',
};

// Define the initial state
export const partnerInitialState: PartnerConfigState = {
    settings: {
        ...partnerSettingsInitialState,
    },
};

export type PartnerSettings = {
    actual_close_first_cashout: boolean;
    always_show_cost_equal_balance: boolean;
    auction_version?: number;
    balance_currency_copy?: string;
    body_theme: boolean;
    nav: NavBarOption[];
    checkout_button_cta?: string;
    checkout_button_confirm_cta?: string;
    checkout_flow_version: CheckoutFlowTypes;
    checkout_settings: CheckoutSettings;
    cobrand_logo: string;
    consumer_onboarding_flow_id?: string;
    country_code: string;
    country_filter?: string[];
    custom_css?: Record<string, string>;
    custom_css_mini?: Record<string, string>;
    display_name: string;
    force_email_capture_on_checkout: boolean;
    force_partner_session_otp: boolean;
    giftcard_value_term: string;
    gift_card_wallet_settings: GiftCardWalletSettings;
    custom_filter_order?: string[];
    gift_type?: GiftingType;
    has_previous_gift_sends: boolean;
    has_successful_cashouts?: boolean;
    header_theme: boolean;
    hide_balance: boolean;
    hide_exit: boolean;
    is_2fa_enabled: boolean;
    is_actions_enabled?: boolean;
    is_accounts_enabled: boolean;
    is_cancel_request_enabled: boolean;
    is_cashback_enabled: boolean;
    is_category_filter_enabled: boolean;
    is_category_selection_enabled: boolean;
    is_category_sort_enabled: boolean;
    is_chrome_extension_enabled: boolean;
    is_crm_enabled: boolean;
    is_gifting_partner: boolean;
    is_offer_highlight_enabled: boolean;
    is_onboarding_enabled?: boolean;
    is_opt_in_preference_page_enabled?: boolean;
    is_points_enabled: boolean;
    is_profile_enabled: boolean;
    is_reg_e_enabled?: boolean;
    is_search_enabled: boolean;
    is_search_v2_enabled: boolean;
    is_secondary_email_enabled: boolean;
    is_share_gift_card_enabled: boolean;
    is_share_api_enabled: boolean;
    is_use_cashback_for_giftcards_enabled?: boolean;
    is_cashback_terms_disclaimer_enabled?: boolean;
    is_wallet_v2_enabled: boolean;
    logo: string;
    logo_mobile: string;
    mini_widget?: MiniWidgetSettings;
    on_close_first_cashout: boolean;
    partner_currency: string;
    partner_product_name: string;
    partner_program_name?: string;
    partner_session_otp_request_id?: string;
    partner_support_email?: string;
    prizeout_product_version: PrizeoutProductVersion;
    prizeout_logo: string;
    show_cu_onboarding_banner: boolean;
    single_withdrawal_only: boolean;
    spend_redemption_title: string;
    sweepstakes?: SweepstakeSettings[];
    testimonial?: {
        image: string;
        quote: string;
        nameAndTitle: string;
    };
    withdrawal_term: string;
};

type GiftcardWalletSummary = {
    active_total_value_cents: number;
    bonus_earned_cents: number;
    num_active_cards: number;
    num_archived_cards: number;
    num_pending_cards: number;
    total_cards: number;
};

export type PartnerUser = {
    account_active?: boolean;
    auth_email: string;
    balance: number;
    bank_accounts?: PartnerUserBankAccount[];
    cashout_email: string;
    giftcard_wallet_summary?: GiftcardWalletSummary;
    internal_accounts?: PartnerUserInternalAccounts;
    is_first_session?: boolean;
    location: PartnerUserLocation;
    open_my_gift_cards: boolean;
    open_to_route?: routes;
    prizeout_session_id: string;
    region: string;
    sdk: PartnerUserSDKSettings;
    selected_bank_account?: PartnerUserBankAccount;
    show_opt_ins: OptIns;
};

export type PartnerUserBankAccount = {
    id: string;
    name: string;
    last4: string;
    balance: number;
    currency: string;
    is_default_selected?: boolean;
    source?: string;
};

export type PartnerUserInternalAccounts = {
    cashback_account: PartnerUserBankAccount;
};

type PartnerUserSDKSettings = {
    balance: number;
    country: string;
    region: string;
};

type PartnerUserLocation = {
    city: string;
    country: string;
    lat: number;
    lng: number;
    region: string;
    ip?: string;
};

type MiniWidgetSettings = {
    crm_copy: string;
    header_title: string;
    is_my_gift_card_cta_enabled: boolean;
    is_powered_by_po_enabled: boolean;
};

interface MiniWidgetDisplayMetadata {
    id?: string;
}

export type MiniWidgetDisplay = {
    type: MiniWidgetDisplayTypes;
    metadata: MiniWidgetDisplayMetadata;
    layoutData?: LayoutData;
};

type LayoutData = iTestimonialTextListV1 | iGiftCardCashbackBalancesTextListV1 | iGiftCardBonusBalancesTextListV1;

interface TileTextList {
    style: string;
    text: string[];
}

interface TileCTA {
    cta_label: string;
    cta_url: routes;
}

export interface iTestimonialTextListV1 {
    cta: TileCTA;
    headline: string;
    testimonial: {
        image?: string;
        quote: string;
        nameAndTitle: string;
    };
    text_list: TileTextList;
}

export interface iGiftCardCashbackBalancesTextListV1 {
    cta: TileCTA;
    headline: string;
    text_list: TileTextList;
}
export interface iGiftCardBonusBalancesTextListV1 {
    cta: TileCTA;
    headline: string;
    text_list: TileTextList;
}

interface GetPartnerConfigParams {
    sdk_settings: SDKParams | AuctionParams;
    applicationModule: ApplicationModule;
}

export interface WalletSummaryResponse {
    activeTotalValueCents: number;
    bonusEarnedCents: number;
    numArchivedCards: number;
    numActiveCards: number;
    numPendingCards: number;
}

export const getPartnerConfig = createAsyncThunk(
    'partnerConfig/getConfig',
    async ({ sdk_settings, applicationModule }: GetPartnerConfigParams, { rejectWithValue, signal }) => {
        try {
            let results;

            const sessionId = sdk_settings.session_id;
            const moduleVariant = sdk_settings?.miniWidget ? 'mini_widget' : 'widget';
            const cacheKey = `${sessionId}_${moduleVariant}_partner_settings`;

            if (!!sessionStorage.getItem(cacheKey) || false) {
                results = JSON.parse(sessionStorage.getItem(cacheKey));
            } else {
                results = await sharedAPI.request({
                    data: {
                        auction_uuid: uuid_v4(),
                        ...sdk_settings,
                    },
                    endpoint: '/v2/session',
                    method: 'POST',
                    signal: signal,
                });
            }

            if (results?.data.error) {
                return rejectWithValue(results.data.message || 'Prizeout failed to load');
            }

            sessionStorage.setItem(cacheKey, JSON.stringify(results));

            return {
                applicationModule,
                ...results.data,
            };
        } catch (e) {
            console.error(e);
            return rejectWithValue(e);
        }
    },
);

export const getOTPSessionState = createAsyncThunk('otp/processOTP', async (_: never, { rejectWithValue, signal }) => {
    try {
        const results = await sharedAPI.request({
            data: {},
            endpoint: `/otp/session`,
            method: 'GET',
            signal: signal,
        });
        return {
            ...results.data,
        };
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const partnerSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getPartnerConfig.fulfilled, (state, action: PayloadAction<PartnerConfigState>) => {
            state.settings = {
                ...state.settings,
                ...action.payload.settings,
            };
            state.user = action.payload.user;

            if (state.user?.bank_accounts && state.user?.bank_accounts.length > 0) {
                state.user.selected_bank_account = state.user?.bank_accounts.find((item) => item.is_default_selected);
                if (state.user.selected_bank_account) {
                    state.user.sdk.balance = state.user.selected_bank_account.balance;
                }
            }
        });
        builder.addCase(renewOTPRequestID.fulfilled, (state, action: PayloadAction<OTPRequestResponse>) => {
            state.settings.partner_session_otp_request_id = action.payload.otpRequestId;
        });
        builder.addCase(createSession.fulfilled, (state, action: PayloadAction<CreateSessionRequestResponse>) => {
            state.user = action.payload.user;
            state.settings = action.payload.settings;
        });
        builder.addCase(getOTPSessionState.fulfilled, (state, action: PayloadAction<Partial<PartnerConfigState>>) => {
            state.settings = {
                ...state.settings,
                ...action.payload.settings,
            };
        });
    },
    initialState: partnerInitialState,
    name: 'partnerConfig',
    reducers: {
        setSelectedUserBankAccount(state, action: PayloadAction<PartnerUserBankAccount>) {
            if (state.user) {
                state.user.selected_bank_account = action.payload;
            }
        },
        setHasPreviousGiftSends(state) {
            state.settings.has_previous_gift_sends = true;
        },
        updateUserBalance(state, action: PayloadAction<number>) {
            if (state.user?.sdk?.balance) {
                state.user.sdk.balance -= action.payload;
            }

            if (state.user?.selected_bank_account) {
                const selectedBankAccountInList = state.user.bank_accounts.find(
                    ({ id }) => id === state.user.selected_bank_account.id,
                );

                if (selectedBankAccountInList) {
                    selectedBankAccountInList.balance -= action.payload;
                }

                state.user.selected_bank_account = selectedBankAccountInList;
            }
        },
        resetPartnerConfigSlice() {
            return partnerInitialState;
        },
        resetSelectedAccountToDefault(state) {
            state.user.selected_bank_account = state.user?.bank_accounts.find((item) => item.is_default_selected);
        },
    },
});

export const {
    resetPartnerConfigSlice,
    setHasPreviousGiftSends,
    setSelectedUserBankAccount,
    updateUserBalance,
    resetSelectedAccountToDefault,
} = partnerSlice.actions;

const selectPartnerConfigState = ({ partnerConfig }: RootState): PartnerConfigState =>
    partnerConfig || partnerInitialState;

const selectMiniWidgetSettings = createSelector(selectPartnerConfigState, ({ settings }) => settings?.mini_widget);

export const selectCountryFilter = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.country_filter || [],
);

export const selectCashoutEmail = createSelector(selectPartnerConfigState, ({ user }) => user?.cashout_email);

export const selectAuthEmail = createSelector(selectPartnerConfigState, ({ user }) => user?.auth_email);

export const selectWithdrawalTerm = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.withdrawal_term,
);

export const selectGiftCardValueTerm = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.giftcard_value_term,
);

export const selectIsShareGiftCardEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.is_share_gift_card_enabled,
);

export const selectIsOfferHighlightEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.is_offer_highlight_enabled,
);

export const selectIsShareApiEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.is_share_api_enabled,
);

export const selectIsUseCashbackForGiftcardsEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.is_use_cashback_for_giftcards_enabled,
);

export const selectIsCashbackEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.is_cashback_enabled,
);

export const selectIsSingleWithdrawal = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.single_withdrawal_only,
);

export const selectIsNavBarEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.nav && settings.nav.length > 0,
);

export const selectIsPointsEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.is_points_enabled,
);

export const selectProductName = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.partner_product_name,
);

export const selectPartnerSupportEmail = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.partner_support_email,
);

export const selectCustomFilterOrder = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.custom_filter_order,
);

export const selectHideBalance = createSelector(selectPartnerConfigState, ({ settings }) => settings.hide_balance);

export const selectFullWidgetCSSOverride = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.custom_css,
);

export const selectMiniWidgetCSSOverride = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.custom_css_mini,
);

export const selectCustomCSS = createSelector(
    selectIsMiniWidget,
    selectFullWidgetCSSOverride,
    selectMiniWidgetCSSOverride,
    (
        isMiniWidget: boolean,
        fullWidgetCustomCSSOverride: Record<string, string>,
        miniWidgetCustomCSSOverride: Record<string, string>,
    ) => {
        if (isMiniWidget && (miniWidgetCustomCSSOverride || fullWidgetCustomCSSOverride)) {
            const updatedMiniWidgetCustomCSSOverride = {
                ...fullWidgetCustomCSSOverride,
                ...miniWidgetCustomCSSOverride,
            };
            return updatedMiniWidgetCustomCSSOverride;
        } else if (!isMiniWidget && fullWidgetCustomCSSOverride) {
            return fullWidgetCustomCSSOverride;
        }
    },
);

export const selectPrizeoutUserSession = createSelector(selectPartnerConfigState, ({ user }) => {
    if (user) {
        return user.prizeout_session_id;
    }
    return '';
});

export const selectPrizeoutAccountIsActive = createSelector(selectPartnerConfigState, ({ user }) => {
    if (user) {
        return user.account_active;
    }
    return false;
});

export const selectHideExitButton = createSelector(selectPartnerConfigState, ({ settings }) => {
    if (settings) {
        return settings.hide_exit;
    }

    // todo: update this to pull application module type from common state
    if (process.env.APPLICATION != 'widget-v2') {
        return true;
    }

    return false;
});

export const selectPartnerCurrency = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.partner_currency,
);

export const selectUserBalance = createSelector(selectPartnerConfigState, ({ user }) => {
    if (user) {
        return user?.selected_bank_account?.balance || user?.selected_bank_account?.balance === 0
            ? user?.selected_bank_account.balance
            : user.sdk.balance;
    }
    return 0;
});

export const selectIs2FAEnabled = createSelector(selectPartnerConfigState, ({ settings }) => settings?.is_2fa_enabled);

export const selectIsActionsEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_actions_enabled,
);

export const selectIsAccountsEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_accounts_enabled,
);

export const selectIsCancelRequestEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_cancel_request_enabled,
);

export const selectIsProfileEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_profile_enabled,
);

export const selectIsActualCloseFirstCashout = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.actual_close_first_cashout,
);

export const selectIsOnCloseFirstCashout = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.on_close_first_cashout,
);

export const selectIsClosingFirstCashout = createSelector(
    selectIsActualCloseFirstCashout,
    selectIsOnCloseFirstCashout,
    selectCheckedOutCard,
    (isOnCloseFirstCashout: boolean, isActualCloseFirstCashout: boolean, checkedOutCard: CheckoutOfferResponse) => {
        return (isOnCloseFirstCashout || isActualCloseFirstCashout) && checkedOutCard;
    },
);

export const selectIsCategoryFilterEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_category_filter_enabled,
);

export const selectIsCategorySelectionEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_category_selection_enabled,
);

export const selectIsSearchEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_search_enabled,
);

export const selectIsSearchV2Enabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_search_v2_enabled,
);

export const selectSpendRedemptionTitle = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.spend_redemption_title,
);

export const selectMiniWidgetCRMCopy = createSelector(selectMiniWidgetSettings, ({ crm_copy }) => crm_copy);

export const selectMiniWidgetHeaderTitle = createSelector(selectMiniWidgetSettings, ({ header_title }) => header_title);

export const selectIsMiniWidgetGiftCardEnabled = createSelector(
    selectMiniWidgetSettings,
    ({ is_my_gift_card_cta_enabled }) => is_my_gift_card_cta_enabled,
);

export const selectMiniWidgetPoweredByPOEnabled = createSelector(
    selectMiniWidgetSettings,
    ({ is_powered_by_po_enabled }) => is_powered_by_po_enabled,
);

export const selectIsShowCUOnboardingBanner = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.show_cu_onboarding_banner,
);

export const selectUserCountryCode = createSelector(selectPartnerConfigState, ({ user }) => user?.location.country);

export const selectUserRegion = createSelector(selectPartnerConfigState, ({ user }) => user?.location.region);

export const selectUserSdkRegion = createSelector(selectPartnerConfigState, ({ user }) => user?.sdk.region);
export const selectUserSdkCountry = createSelector(selectPartnerConfigState, ({ user }) => user?.sdk.country);

export const selectUserPrizeoutSession = createSelector(
    selectPartnerConfigState,
    ({ user }) => user?.prizeout_session_id,
);

export const selectUserOptIns = createSelector(selectPartnerConfigState, ({ user }) => user?.show_opt_ins);

export const selectOpenMyGiftCards = createSelector(selectPartnerConfigState, ({ user }) => user?.open_my_gift_cards);

export const selectNavBarOptions = createSelector(selectPartnerConfigState, ({ settings }) => settings?.nav);

export const selectUserBankAccounts = createSelector(selectPartnerConfigState, ({ user }) => user?.bank_accounts);

export const selectGiftCardWalletSummary = createSelector(
    selectPartnerConfigState,
    ({ user }) => user?.giftcard_wallet_summary,
);

export const selectDefaultUserBankAccounts = createSelector(selectPartnerConfigState, ({ user }) =>
    user?.bank_accounts?.find((item) => item.is_default_selected),
);

export const selectUserCashbackAccount = createSelector(
    selectPartnerConfigState,
    ({ user }) => user?.internal_accounts?.cashback_account,
);

export const selectIsUsersFirstSession = createSelector(selectPartnerConfigState, ({ user }) => user?.is_first_session);

export const selectPartnerName = createSelector(selectPartnerConfigState, ({ settings }) => settings?.display_name);

export const selectCurrentBankAccount = createSelector(
    selectPartnerConfigState,
    ({ user }) => user?.selected_bank_account,
);

export const selectHasPreviousGiftSends = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.has_previous_gift_sends,
);

export const selectCobrandedPartnerLogomark = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.cobrand_logo,
);

export const selectCheckoutButtonCta = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.checkout_button_cta,
);

export const selectCheckoutButtonConfirmCta = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.checkout_button_confirm_cta,
);

export const selectCheckoutFlowType = createSelector(selectPartnerConfigState, ({ settings }) => {
    return settings?.checkout_flow_version;
});

export const selectBalanceCurrencyCopy = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.balance_currency_copy,
);

export const selectPartnerProgramName = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.partner_program_name,
);

export const selectPrizeoutProductVersion = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.prizeout_product_version,
);

export const selectIsCreditUnion = createSelector(
    selectPartnerConfigState,
    ({ settings }) =>
        settings.prizeout_product_version == PrizeoutProductVersion.BONUS ||
        settings.prizeout_product_version == PrizeoutProductVersion.CASHBACK ||
        settings.prizeout_product_version == PrizeoutProductVersion.REWARDS,
);

export const selectIsCashbackPlusProduct = createSelector(
    selectPartnerConfigState,
    ({ settings }) =>
        settings.prizeout_product_version == PrizeoutProductVersion.CASHBACK ||
        settings.prizeout_product_version == PrizeoutProductVersion.REWARDS,
);

export const selectPrizeoutProductName = createSelector(selectPartnerConfigState, ({ settings }) => {
    switch (settings?.prizeout_product_version) {
        default:
        case PrizeoutProductVersion.STANDARD:
            return 'Prizeout';
        case PrizeoutProductVersion.BONUS:
            return 'Prizeout Offers';
        case PrizeoutProductVersion.CASHBACK:
        case PrizeoutProductVersion.REWARDS:
            return settings.partner_product_name;
    }
});

export const selectIsRewardsEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings.prizeout_product_version === PrizeoutProductVersion.REWARDS,
);

export const selectIsRegEEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.checkout_settings.is_reg_e_enabled,
);

export const selectShowCheckoutOnboarding = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.checkout_settings.show_checkout_onboarding,
);

export const selectPartnerSessionOtpRequestId = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.partner_session_otp_request_id,
);

export const selectForceEmailCaptureOnCheckout = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.force_email_capture_on_checkout,
);

export const selectForcePartnerSessionOtp = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.force_partner_session_otp,
);

export const selectIsOnboardingEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_onboarding_enabled,
);

export const selectIsOptInPreferencePageEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_opt_in_preference_page_enabled,
);

export const selectConsumerOnboardingFlowId = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.consumer_onboarding_flow_id,
);

export const selectIsCategorySortEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_category_sort_enabled,
);

export const selectCBOnboaringTestimonial = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.testimonial,
);

export const selectShouldShowNavBar = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.nav.length > 0,
);

export const selectIsCRMEnabled = createSelector(selectPartnerConfigState, ({ settings }) => settings?.is_crm_enabled);

export const selectAlwaysShowCostEqualBalance = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.always_show_cost_equal_balance,
);

export const selectIsChromeExtensionEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_chrome_extension_enabled,
);

export const selectIsCashbackTermsDisclaimerEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.is_cashback_terms_disclaimer_enabled,
);

export const selectIsSweepstakesEnabled = createSelector(
    selectPartnerConfigState,
    ({ settings }) => !!settings?.sweepstakes,
);

export const selectIsSweepstakesBaseData = createSelector(
    selectPartnerConfigState,
    ({ settings }) => settings?.sweepstakes,
);

export const selectUserLocation = createSelector(selectPartnerConfigState, ({ settings }) => settings?.country_code);

export default partnerSlice.reducer;
