export type ConditionName = string;
export type TargetName = string;
export type TargetSelector = string;

export enum ConditionOperatorName {
    ELEMENT_ATTRIBUTE_MATCH = 'elementAttributeMatch',
    ELEMENT_EXISTS = 'elementExists',
    ELEMENT_HAS_CLASS = 'elementHasClass',
    ELEMENT_HAS_VALUE = 'elementHasValue',
    ELEMENT_IS_CHECKED = 'elementIsChecked',
    ELEMENT_IS_ENABLED = 'elementIsEnabled',
    ELEMENT_IS_VALID = 'elementIsValid',
    ELEMENT_STYLE_MATCH = 'elementStyleMatch',
}

export enum TargetOperatorName {
    CLOSEST = 'closest',
    GET_BY_ATTRIBUTE_VALUE = 'getByAttributeValue',
    GET_BY_TEXT_CONTENT = 'getByTextContent',
    QUERY_SELECTOR = 'querySelector',
}

export enum StepActionName {
    CHECK_INPUT_VALUE = 'checkInputValue',
    CLICK_ELEMENT = 'clickElement',
    FAIL = 'fail',
    TYPE_TEXT = 'typeText',
    WAIT = 'wait',
    WAIT_FOR_ELEMENT = 'waitForElement',
    WAIT_UNTIL_CONDITION = 'waitUntilCondition',
}
