import React, { ChangeEventHandler, ReactFragment } from 'react';
import { uuid_v4 } from '../../../utils';
import { DividerRow, DividerRowEdgeType } from '../ui-widgets';
import { RadioButtonInput } from './radio-button-input';

import './radio-button-list-item.scss';

interface RadioButtonListItemProps {
    ichildren: ReactFragment; // Content that displays on left side of radio button input
    id?: string;
    isChecked: boolean; // Item selected
    isDisabled?: boolean;
    name: string; // Name of group of radio buttons
    onChange: ChangeEventHandler<HTMLInputElement>;
    register?: Record<string, unknown>;
    title: string;
    value: string;
}

export const RadioButtonListItem: React.FC<RadioButtonListItemProps> = ({
    ichildren,
    id,
    isChecked,
    isDisabled,
    name,
    onChange,
    register,
    title,
    value,
}): React.ReactElement => {
    const elmId = id || `radio-button-list-item-${uuid_v4()}`;

    return (
        <>
            <label className="radio-button-list-item" htmlFor={elmId}>
                <div
                    data-testid={id ? `radio-button-list-item-content-${id}` : 'radio-button-input-control'}
                    aria-checked={isChecked}
                    aria-labelledby={elmId}
                >
                    {ichildren}
                </div>
                <RadioButtonInput
                    id={elmId}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    name={name}
                    onChange={onChange}
                    register={register}
                    title={title}
                    value={value}
                />
            </label>
            <DividerRow shouldExtendEdges edgeType={DividerRowEdgeType.SPACE_PRIMARY} />
        </>
    );
};
