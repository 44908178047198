import { isHTMLElement } from '../types';

export const getScrollPosition = (listElement: Element | EventTarget): number => {
    if (isHTMLElement(listElement)) {
        const listBounds = listElement.getBoundingClientRect();
        const parentElement = listElement.parentElement;
        const parentBounds = parentElement.getBoundingClientRect();
        return parentBounds.top - listBounds.top;
    }

    return 0;
};
