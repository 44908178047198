import React, { useEffect, useState } from 'react';
import {
    SkeletonLoaderBorderRadius,
    SkeletonLoaderElementGeneric,
    SkeletonLoaderWidth,
} from '../../../common/skeleton-loaders';
import { routes } from '../../../../utils';
import { CRMTileClickableCard } from './crm-tile-clickable-card';

import './balance-skeleton-loader.scss';

export enum BalanceSkeletonLoaderType {
    STANDARD = 'standard',
    LUMIN = 'lumin',
}

interface BalanceSkeletonLoaderProps {
    headline?: string;
    isDisabled?: boolean;
    balanceLoaderType?: BalanceSkeletonLoaderType;
}

export const BalanceSkeletonLoader: React.FC<BalanceSkeletonLoaderProps> = ({
    headline,
    balanceLoaderType = BalanceSkeletonLoaderType.STANDARD,
    isDisabled = false,
}) => {
    const route = routes.HOME;
    const [bottomSkeletonSize, setBottomSkeletonSize] = useState<number>(16);

    useEffect(() => {
        switch (balanceLoaderType) {
            case BalanceSkeletonLoaderType.LUMIN:
                setBottomSkeletonSize(12);
                break;
            case BalanceSkeletonLoaderType.STANDARD:
                setBottomSkeletonSize(16);
                break;
            default:
                setBottomSkeletonSize(16);
        }
    }, [balanceLoaderType]);

    return (
        <CRMTileClickableCard isDisabled={isDisabled} className="balance-skeleton-loader" route={route}>
            {headline ? (
                <h2>{headline}</h2>
            ) : (
                <SkeletonLoaderElementGeneric
                    width={SkeletonLoaderWidth.ONE_THIRD}
                    borderRadius={SkeletonLoaderBorderRadius.PRIMARY}
                    height={16}
                />
            )}
            <SkeletonLoaderElementGeneric
                width={SkeletonLoaderWidth.ONE_QUARTER}
                borderRadius={SkeletonLoaderBorderRadius.PRIMARY}
                height={16}
            />
            <SkeletonLoaderElementGeneric
                width={SkeletonLoaderWidth.THREE_QUARTERS}
                borderRadius={SkeletonLoaderBorderRadius.PRIMARY}
                height={bottomSkeletonSize}
            />
        </CRMTileClickableCard>
    );
};
