import React, { useEffect, useState } from 'react';
import Classnames from 'classnames';

import './progress-bar.scss';

interface ProgressBarViewProps {
    value: number;
    max: number;
    pendingPercent?: number;
}

export const ProgressBar: React.FC<ProgressBarViewProps> = ({ value, max, pendingPercent }): React.ReactElement => {
    const progressPercent = Math.max(0, Math.min(100, (value / max) * 100));

    const isInComplete = progressPercent < 100;

    const [progressStyles, setProgressStyles] = useState({
        width: '0%',
    });
    const [pendingStyles, setPendingStyles] = useState({
        width: '0%',
    });

    const setProgress = function (current: number, pending: number) {
        setProgressStyles({
            width: current.toFixed() + '%',
        });
        setPendingStyles({
            width: pending.toFixed() + '%',
        });
    };

    useEffect(() => {
        setProgress(0, 0);
        const timer = setTimeout(() => setProgress(progressPercent, pendingPercent + progressPercent), 200);
        return () => clearTimeout(timer);
    }, []);

    const progressBarClass = Classnames('progress-bar', {
        'progress-bar--theme-incomplete': isInComplete,
    });

    return (
        <div className={progressBarClass}>
            <div className="progress-bar__bar">
                {!!pendingPercent && <div className="progress-bar__pending" style={pendingStyles}></div>}
                <div className="progress-bar__progress" style={progressStyles}></div>
            </div>
            <div className="progress-bar__label">
                {value} / {max} pts
            </div>
        </div>
    );
};
