import React from 'react';
import QRCode from 'react-qr-code';
import { constants } from '../../../utils/constants';

import './qr-code.scss';

interface QrCodeProps {
    size?: number;
    value: string;
}

export const QrCode: React.FC<QrCodeProps> = ({ size, value }): React.ReactElement => {
    return (
        <div className="qr-code">
            <div className="qr-code__wrapper">
                <QRCode data-testid="qr-code" value={value} size={size || constants.defaultQrCodeSize} />
            </div>
        </div>
    );
};
