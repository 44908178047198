import React from 'react';
import Classnames from 'classnames';
import CurrencyFormatter from './currency-formatter';
import { floor, removeDecimals } from '../../../utils/helpers';
import { displayOfferType } from '../../../store/slices/offers-slice';

import './bonus-tag.scss';

export enum BonusTagType {
    MONETARY = 'monetary',
    PERCENTAGE = 'percentage',
}

export enum BonusTagTheme {
    STANDARD = 'standard',
    PILL = 'pill',
}

export enum BonusTagSize {
    LARGE = 'large',
    EXTRA_LARGE = 'extra-large',
}

interface BonusTagProps {
    type: BonusTagType;
    value: number;
    currency?: string;
    className?: string;
    offerType?: displayOfferType;
    theme?: BonusTagTheme;
    size?: BonusTagSize;
    showPlus?: boolean;
    hideIcon?: boolean;
    hideText?: boolean;
    showDecimals?: boolean;
}

export const BonusTag: React.FC<BonusTagProps> = ({
    type,
    value,
    currency,
    className,
    offerType,
    theme = BonusTagTheme.STANDARD,
    size,
    showPlus,
    hideText = false,
    showDecimals,
    hideIcon = false,
}): React.ReactElement => {
    const monetaryType = type === BonusTagType.MONETARY;
    const percentageType = type === BonusTagType.PERCENTAGE;

    const classes: string = Classnames(
        `bonus-tag`,
        {
            [`bonus-tag--size-${size}`]: size,
            'bonus-tag--pill': theme == BonusTagTheme.PILL,
            'bonus-tag--without-icon': hideIcon,
        },
        className,
    );

    const iconClasses: string = Classnames({ [`fa-kit fa-prizeout-bonus-tag-${type}`]: type });

    const bonusCopy = offerType === displayOfferType.CASHBACK ? 'cashback' : 'bonus';

    return (
        <div data-testid="bonus-tag" className={classes}>
            {!hideIcon && (
                <span className="bonus-tag__icon">
                    <i data-testid="bonus-tag-icon" className={iconClasses}></i>
                </span>
            )}
            <p className="bonus-tag__label">
                {showPlus && '+ '}
                {monetaryType && (
                    <>
                        <CurrencyFormatter value={value} currencyOverride={currency} decimals={showDecimals} inline />{' '}
                    </>
                )}
                {percentageType && <>{removeDecimals(floor(value))}% </>}
                {!hideText && bonusCopy}
            </p>
        </div>
    );
};
