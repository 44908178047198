import React from 'react';
import {
    SkeletonLoaderElementGeneric,
    SkeletonLoaderBorderRadius,
    SkeletonLoaderWidth,
} from './skeleton-loader-element-generic';

export enum SkeletonLoaderElementTextHeight {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    CUSTOM = 'custom',
}

interface SkeletonLoaderElementTextProps {
    customHeight?: number; // use only with SkeletonLoaderElementTextHeight.CUSTOM
    textHeight?: SkeletonLoaderElementTextHeight;
    width?: SkeletonLoaderWidth;
}

export const SkeletonLoaderElementText: React.FC<SkeletonLoaderElementTextProps> = ({
    customHeight,
    textHeight = SkeletonLoaderElementTextHeight.SMALL,
    width,
}): React.ReactElement => {
    let height;

    switch (textHeight) {
        case SkeletonLoaderElementTextHeight.SMALL:
            height = 12;
            break;

        case SkeletonLoaderElementTextHeight.MEDIUM:
            height = 24;
            break;

        case SkeletonLoaderElementTextHeight.LARGE:
            height = 36;
            break;

        case SkeletonLoaderElementTextHeight.CUSTOM:
            height = customHeight;
            break;

        default:
            height = 12;
    }

    return (
        <div data-testid="skeleton-loader-element-text" className="skeleton-loader-element-text">
            <SkeletonLoaderElementGeneric
                borderRadius={SkeletonLoaderBorderRadius.PILL}
                height={height}
                width={width}
            />
        </div>
    );
};
