import React from 'react';
import { constants } from '../../../../../utils/constants';
import { OfferName } from '../../../../../modules/widget/components/offers/offer-gift-card/common/offer-name';
import { PrizeoutOffer } from '../../../../../store/slices/offers-slice';
import { RoundImage } from '../../../../common/ui-widgets';
import { RoundImageSizes } from '../../../../../store/slices/common-slice';
import { SearchResultPresenterProps } from './object-presenter-factory';
import { useAppSelector } from '../../../../../hooks';
import { BonusTag, BonusTagType, BonusTagTheme } from '../../../../common';
import { selectCheckoutFlowType, CheckoutFlowTypes } from '../../../../../store/slices/partner-config-slice';

interface OfferPresenterProps extends SearchResultPresenterProps<PrizeoutOffer> {
    item: PrizeoutOffer;
}

export const OfferPresenter: React.FC<OfferPresenterProps> = ({ item }): React.ReactElement => {
    const checkoutFlowType = useAppSelector(selectCheckoutFlowType);
    const bonusValue = item.display_monetary_bonus ? item.display_monetary_bonus : item.display_bonus;

    return (
        <>
            <div className="search-widget__brand-wrapper">
                <RoundImage
                    imageUrl={item.image_url || constants.defaultRoundImageUrl}
                    imageAltText={`${item.name} logo`}
                    size={RoundImageSizes.THREE}
                />
                <OfferName rows={1} name={item.name} />
            </div>
            {bonusValue > 0 && (
                <BonusTag
                    type={item.display_monetary_bonus ? BonusTagType.MONETARY : BonusTagType.PERCENTAGE}
                    value={bonusValue}
                    currency={item.currency_code}
                    offerType={item.display_offer_type}
                    theme={
                        checkoutFlowType === CheckoutFlowTypes.MULTI_PANEL ? BonusTagTheme.PILL : BonusTagTheme.STANDARD
                    }
                    hideText
                />
            )}
        </>
    );
};
